import React from 'react';
import "./index.css";
import {Grid, Select, TextField, Typography, MenuItem} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {DATE_PICKER_FORMAT, DAY_BEFORE_YESTERDAY} from "../../../Constant";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CalendarIcon from "../../../assests/icons/calendar";
import {IntegratedReportContext} from "../../../Context/IntegratedReportContext";

const IntegratedReportField = () => {

    const [reportNameErrorMessage, setReportNameErrorMessage] = React.useState('');
    const {reportName, updateReportName, date, updateDate, updateDataSource} = React.useContext(IntegratedReportContext);

    const CustomCalendarIcon = () => {
        return <CalendarIcon />;
    };

    const handleReportNameChange = (e) => {
        const { value } = e.target;

        // Regular expression to allow only letters, numbers, spaces, hyphens, and underscores -> no special characters
        const regex = /^[a-zA-Z0-9 _-]*$/;

        if (regex.test(value)) {
            updateReportName(value);
            setReportNameErrorMessage(''); // Clear the error message if validation passes
        } else {
            setReportNameErrorMessage('Report name cannot contain special characters!');
        }
    };

    return (
        <React.Fragment>
            <Grid container xs={12} spacing={2} className="integrated-report-input-grid">
                <Grid item xs={4} >
                    <Typography
                        className="amg-form-label">
                        Report title
                        <span className="required-input-span">*</span>
                    </Typography>
                </Grid>

                <Grid item xs={4} >
                    <Typography className="amg-form-label"> Date </Typography>
                </Grid>

                <Grid item xs={4} >
                    <Typography className="amg-form-label"> Data source </Typography>
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        required
                        id="standard-basic"
                        variant="standard"
                        placeholder={"Enter report name"}
                        className="text-field-standard text-field-width"
                        onChange={handleReportNameChange}
                        value={reportName}
                        error={!!reportNameErrorMessage} // Set error prop based on validation
                        helperText={reportNameErrorMessage} // Display the error message
                        size="small"
                        InputProps={{
                            className: "reportNameTextFieldInput",
                        }}
                        inputProps={{ maxLength: 64 }}
                    />
                </Grid>

                <Grid item xs={4}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    className="calendar-popover"

                >
                    <DatePicker
                        defaultValue={dayjs(DAY_BEFORE_YESTERDAY)}
                        autoFocus={false}
                        value={date}
                        onChange={(newDate) =>{
                            const adjustedDate = dayjs(newDate).set('hour', 12).set('minute', 0).set('second', 0);
                            updateDate(adjustedDate)
                        }}
                        renderInput={(props) => <input {...props} />}
                        format={DATE_PICKER_FORMAT}
                        className="date-picker-integrated"
                        maxDate={dayjs(DAY_BEFORE_YESTERDAY)}
                        slots={{
                            openPickerIcon: CustomCalendarIcon,
                        }}
                        disableFuture={true}
                        disableHighlightToday={true}
                        PopperProps={{
                            placement: "bottom-start"
                        }}
                    />
                </LocalizationProvider>
                </Grid>

                <Grid item xs={4}>
                    <Select
                        id="data-source"
                        label="Data source"
                        variant="standard"
                        defaultValue="SS Core"
                        className="custom-select-underline criteria-operator-select-integrated"
                        onChange={(e) => updateDataSource(e.target.value)}
                    >
                        <MenuItem value="SS Core">SS Core</MenuItem>
                        <MenuItem value="SS Ads+">SS Ads+</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default IntegratedReportField
