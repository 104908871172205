import axios from "axios";
import {BFF_LAYER_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

const addRequestComment = async (config={}, status="", request_job_id="", comments=[], requested_user="", type="") => {
    const mutation = `
        mutation MyMutation($createAdsPlusConfig: AdsPlusInputConfig = {status: "", requested_user: ""}) {
          createAdsPlusConfig(createAdsPlusConfig: $createAdsPlusConfig)
        }
    `;
    const headers = adsHeader()

    const variables = {
        createAdsPlusConfig: {
            config: config,
            status: status,
            request_job_id: request_job_id,
            comments: comments,
            requested_user: requested_user,
            type: type
        }
    };

    try {
        const response = await axios.post(BFF_LAYER_URI, {
            query: mutation,
            variables: variables,
        }, {headers: headers});
        return {"request_job_id": response.data};
    } catch (error) {
        console.error("Error adding comment:", error.response ? error.response.data : error.message);
        return {message: "Error adding comment", details: error.response ? error.response.data : error.message};
    }
};

export default addRequestComment;