import { useState, useEffect, useRef, useCallback } from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Checkbox, Grid,
} from "@mui/material";
import {LIMIT_STRING_SHOW, MAX_LIMIT_SHOW_TABLE_DATA, SEARCHED_TEXT_NOT_FOUND} from "../../../Constant";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import {GET_TSTAG, getTStag} from "../../../utils/helper";
import {extractAdServer, getEpochData, isEmpty} from "../../../utils/helper";
import { EmptyScreen } from "../../CommonScreens/EmptyScreen";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";

function TableContentDemand(props) {
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection);
        if (observer && elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [props?.tableContent]);

    const onIntersection = ([entry]) => {
        if (entry.isIntersecting && !props.isLoading && props?.isHavingMoreData && props?.tableContent?.length >= MAX_LIMIT_SHOW_TABLE_DATA) {
            const newCount = props?.currentOffset + MAX_LIMIT_SHOW_TABLE_DATA;
            props?.newOffset(newCount);
        }
    }

    const handleSelectAll = () => {
        if (props?.selectedRowData.length === props?.tableContent?.length) {
            props?.onSelectedRowsChangeDemand([]);
            props?.onShowAddButton(false);
        } else {
            const allRows = props?.tableContent?.map((rowData) => ({
                channel_id: rowData?.channel_id,
                demand_tag_id: rowData?.demand_tag_id,
            }));
            props?.onSelectedRowsChangeDemand(allRows);
            props?.onShowAddButton(true);
        }
    };

    const handleRowCheckboxChange = (demand_tag_id, channel_id) => {
        const index = props?.selectedRowData.findIndex(
            (row) => row?.demand_tag_id === demand_tag_id && row?.channel_id === channel_id
        );
        const newSelectedRows = [...props?.selectedRowData];

        if (index === -1) {
            newSelectedRows.push({ channel_id, demand_tag_id });
        } else {
            newSelectedRows.splice(index, 1);
        }

        props?.onShowAddButton(true);
        props?.onSelectedRowsChangeDemand(newSelectedRows);
    };

    return (
        <Grid item xs={12}>
            <Table className="off-white-table" stickyHeader>
                <TableHead>
                    {!props.adsPlusPlatform && <TableCell width={1}>
                        <Checkbox
                            className="amg-white-checkbox"
                            checked={
                                props?.selectedRowData.length ===
                                props?.tableContent?.length &&
                                props?.tableContent?.length !== 0
                            }
                            onChange={handleSelectAll}
                        />
                    </TableCell>}
                    <TableCell> DEMAND TAG NAME </TableCell>
                    <TableCell> SUPPLY ROUTER NAME </TableCell>
                    <TableCell> DEMAND OWNER</TableCell>
                    <TableCell> TIER </TableCell>
                    <TableCell> ADS SERVERS </TableCell>
                    <TableCell> LAST UPDATED </TableCell>
                </TableHead>
                <TableBody>
                    {props?.tableContent?.length > 0 ?
                        props.tableContent.map((data, index) => {
                            return (
                                <TableRow key={`${index}-${data?.demand_tag_name}`}>
                                    {!props.adsPlusPlatform && <TableCell width={1}>
                                        <Checkbox
                                            className="amg-white-checkbox"
                                            checked={props?.selectedRowData?.some(
                                                (row) =>
                                                    row?.demand_tag_id === data?.demand_tag_id
                                            )}
                                            onChange={() =>
                                                handleRowCheckboxChange(
                                                    data?.demand_tag_id,
                                                    data?.channel_id
                                                )
                                            }
                                        />
                                    </TableCell>}
                                    <TableCell
                                    >
                                        {data?.demand_tag_name}
                                        <span
                                            className="table-meta-info"
                                            title={data?.vast_endpoint_url}
                                        >
                                            {`${data?.vast_endpoint_url?.substring(
                                                0,
                                                LIMIT_STRING_SHOW
                                            )}...`}
                                        </span>
                                    </TableCell>
                                    <TableCell> {data?.supply_router_name} </TableCell>
                                    <TableCell> {data?.demand_partner_name} </TableCell>
                                    <TableCell> {data?.demand_class} </TableCell>
                                    <TableCell>
                                        {extractAdServer(data?.vast_endpoint_url)}
                                    </TableCell>
                                    <TableCell> {getEpochData(data?.updated_at)} </TableCell>
                                </TableRow>
                            )
                        }) : (!isEmpty(props?.searchedText)  && !props?.isLoading) && (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <EmptyScreen message="No results found"/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    {!props?.isLoading && props?.isHavingMoreData && (
                        <TableRow ref={elementRef}>
                            <TableCell colSpan={props.adsPlusPlatform ? 6 : 7}></TableCell>
                        </TableRow>
                    )}

                    {props?.isLoading && (
                        <LoadingScreen rowsNumber={props.adsPlusPlatform? 6 : 7}/>
                    )}
                    {!props.isLoading && props?.tableContent?.length === 0 && isEmpty(props?.searchedText) && (

                        <TableRow>
                            <TableCell colSpan={7}>
                                <EmptyScreen message="No demand tag added yet!"/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Grid>
    );
}

TableContentDemand.propTypes = {
    data: PropTypes.shape({
        demand_tag_name: PropTypes.string,
        vast_url: PropTypes.string,
        thunderstorm_tag: PropTypes.number,
        demand_partner: PropTypes.number,
        tier: PropTypes.number,
        demand_server: PropTypes.string,
        ads_plus: PropTypes.bool,
        last_updated: PropTypes.string,
        vast_endpoint_url: PropTypes.string,
        demand_partner_name: PropTypes.string,
        demand_class: PropTypes.number
    }),
    onClick: PropTypes.func,
    newOffset: PropTypes.func,
    selectedRowData: PropTypes.arrayOf(PropTypes.string),
    searchedText: PropTypes.string
};

export default TableContentDemand;