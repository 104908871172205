import {Grid, Typography, Chip } from "@mui/material";
import React, {useEffect, useState} from "react";
import "./index.css"
import TableActivityLog from "../../../Components/Tables/TableActivityLog";
import FilterSidebar from "../../../Components/FilterSidebar";
import FilterContent from "../../../Components/TableTopFilters/FilterContent";
import {isEmpty} from "../../../utils/helper";
import {getLocalStorageData} from "../../../utils/localStorageHelper";
import {
    ACCEPTED_LOG_ACTIVITY,
    ACCEPTED_TYPE_LOG_FILTER,
    ALL_DATA_FETCHED,
    FAILURE_LOG_ACTIVITY,
    FAILURE_TYPE_LOG_FILTER,
    FILTER_VALUE_FOR_LOG,
    MAX_LIMIT_SHOW_TABLE_DATA,
    NO_DATA_AVAILABLE,
    REJECTED_LOG_ACTIVITY,
    SEVERITY_TYPE,
    SUCCESS_LOG_ACTIVITY,
    SUCCESS_TYPE,
    SUCCESS_TYPE_LOG_FILTER,
    USER_DATA_KEY
} from "../../../Constant";
import Notification from "../../../Components/Notification";
import getAllAuditLogJob from "../../../api/getAllJobs";
import getAllAuditLogUsers from "../../../api/getAllAuditLogUsers";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";
const ActivityLogs = () =>{
    const [searchedData, setSearchedData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [filterType, setFilterType]=useState("")
    const [jobList,setJobList]=useState([])
    const [successData, setSuccessData] = useState(0)
    const [failureData, setFailureData] = useState(0)
    const [acceptedData, setAcceptedData] = useState(0)
    const [loading, setLoading] = useState(true)
    const [hasMoreData, setHasMoreData] = useState(true);
    const [offset,setOffset]=useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [alertType, setAlertType] = useState("")
    const [isFilterOpen, setIsFilterOpen]= useState(false)
    const [totalFilter, setTotalFilter] = useState(0)
    const [statusSelected,setStatusSelected]=useState("")
    const [selectedFilterData, setSelectedFilterData] = useState(FILTER_VALUE_FOR_LOG)
    const [filterData, setFilterData] = useState([{heading: 'Users', data: []}, {heading: 'Status', data: ['Success', 'Pending', 'Failed']}]);
    const [queryData,setQueryData] = useState({
        userId: [],
        jobStatus: [],
        gteDate: "",
        lteDate: ""})
    const [lastApiCallTime, setLastApiCallTime] = useState(null);

    useEffect(() => {
        getJobList(0)
        getUsers()
        useWebStorageHelper.removeOnbFile();
    }, []);

    useEffect(() => {
        getJobList(0)
    }, [queryData]);

    const getUsers = async () => {
        try {
            const users = await getAllAuditLogUsers(); // Fetch users from API
            const usersData = users?.map(user => user.user_id); // Assuming you get user objects with a 'name' property

            // Update the filterData state to include the fetched users under the 'Users' heading
            setFilterData(prevState => {
                const updatedFilterData = [...prevState];
                const usersIndex = updatedFilterData.findIndex(filter => filter.heading === 'Users');
                if (usersIndex !== -1) {
                    updatedFilterData[usersIndex].data = usersData;
                }
                return updatedFilterData;
            });
        } catch(error) {
            console.error("Error fetching users:", error);
        }
    };

    const getJobList = async (offset) => {
        setLoading(true)
        setHasMoreData(false)

        setLastApiCallTime(new Date());
        let contentData = []
        try {
            contentData = await getAllAuditLogJob(MAX_LIMIT_SHOW_TABLE_DATA,offset,queryData);

            if (contentData && contentData.length > 0) {
                const getStateData = offset > 0 ? Object.assign([], jobList) : []
                getStateData.push(...contentData)
                const getFilteredSuccessData = getStateData.filter((item) => item.status === SUCCESS_LOG_ACTIVITY);
                const getFilteredAcceptedData = getStateData.filter((item) => item.status === ACCEPTED_LOG_ACTIVITY);
                const getFilteredFailureData = getStateData.filter((item) => item.status === FAILURE_LOG_ACTIVITY || item.status === REJECTED_LOG_ACTIVITY);
                setSuccessData(getFilteredSuccessData.length)
                setFailureData(getFilteredFailureData.length)
                setAcceptedData(getFilteredAcceptedData.length)
                setJobList(getStateData);
                setSearchedData(getStateData)
                setHasMoreData(true)
            } else if (contentData && contentData.length === 0 ) {
                if(offset>0) {
                    setHasMoreData(false)
                    setIsOpen(true)
                    setNotificationMessage(ALL_DATA_FETCHED)
                    setAlertType(SUCCESS_TYPE)
                }else{
                    setSuccessData(0)
                    setFailureData(0)
                    setAcceptedData(0)
                    setJobList([]);
                    setSearchedData([])
                    setHasMoreData(false)
                }
            }
            else {
                setHasMoreData(false)
                setIsOpen(true)
                setNotificationMessage(NO_DATA_AVAILABLE)
                setAlertType(SEVERITY_TYPE)
            }
        } catch (error) {
            setAlertType(SEVERITY_TYPE)
            setNotificationMessage(NO_DATA_AVAILABLE)
            setIsOpen(true)
        }
        setLoading(false)
    };

    const onSearchFilter = (text = "") => {
        let getFilteredData = []
        if(filterType === SUCCESS_TYPE_LOG_FILTER) {
            getFilteredData = jobList.filter((item) => item.status === SUCCESS_LOG_ACTIVITY);
            setSearchedData(getFilteredData)
        } else if(filterType === FAILURE_TYPE_LOG_FILTER) {
            getFilteredData = jobList.filter((item) => item.status === FAILURE_LOG_ACTIVITY);
            setSearchedData(getFilteredData)
        }else if(filterType === ACCEPTED_TYPE_LOG_FILTER) {
            getFilteredData = jobList.filter((item) => item.status === ACCEPTED_LOG_ACTIVITY);
            setSearchedData(getFilteredData)
        } else{
            getFilteredData = jobList
        }
        if(!isEmpty(text)){
            const newData = getFilteredData.filter((data) =>
                data.description.toLowerCase().includes(text.toLowerCase()) ||
                data.user_id.toLowerCase().includes(text.toLowerCase()) ||
                data.action.toLowerCase().includes(text.toLowerCase())
            );
            setSearchedData(newData);
        }else {
            setSearchedData(getFilteredData)
        }

        setSearchText(text)
    }

    const handleChipOnClick = (status) => {
        if(status === SUCCESS_LOG_ACTIVITY && filterType !== SUCCESS_TYPE_LOG_FILTER) {
            const filteredData = jobList.filter((item) => item?.status === SUCCESS_LOG_ACTIVITY);
            setSearchedData(filteredData);
            setFilterType(SUCCESS_TYPE_LOG_FILTER)
            setSearchText("")
            setStatusSelected(SUCCESS_TYPE_LOG_FILTER)
        }
        else if(status === FAILURE_LOG_ACTIVITY && filterType !== FAILURE_TYPE_LOG_FILTER) {
            const filteredData = jobList.filter((item) => item?.Status === FAILURE_LOG_ACTIVITY || item?.status === REJECTED_LOG_ACTIVITY);
            setSearchedData(filteredData);
            setFilterType(FAILURE_TYPE_LOG_FILTER)
            setSearchText("")
            setStatusSelected(FAILURE_TYPE_LOG_FILTER)
        }else if(status === ACCEPTED_LOG_ACTIVITY && filterType !== ACCEPTED_TYPE_LOG_FILTER) {
            const filteredData = jobList.filter((item) => item?.status === ACCEPTED_LOG_ACTIVITY);
            setSearchedData(filteredData);
            setFilterType(ACCEPTED_TYPE_LOG_FILTER)
            setSearchText("")
            setStatusSelected(ACCEPTED_LOG_ACTIVITY)
        }
        else{
            setSearchedData(jobList)
            setFilterType("")
            setSearchText("")
            setStatusSelected("")
        }
    };

    const handleNotificationClose = () => {
        setIsOpen(false);
    };
    const changeOffset = (count) => {
        if (count > 0 ) {
            setOffset(count)
            getJobList(count)
        }
    }

    const filterOpen = () =>{
        setIsFilterOpen(true);
    }
    const convertFiltersToQueryData = (filters) => {
        const queryData = {
            userId: [],
            jobStatus: [],
            gteDate: "",
            lteDate: ""
        };

        filters?.forEach(filter => {
            switch (filter.heading) {
                case "Users":
                    queryData.userId = filter.data;
                    break;
                case "Status":
                    // Initialize jobStatus as an empty array
                    let jobStatus = [];

                    // Check each status in filter.data
                    filter.data.forEach(status => {
                        switch (status) {
                            case "Failed":
                                // If status is "Failed", add ["Failure", "Rejected"] to jobStatus
                                jobStatus = [...jobStatus, "Failure", "Rejected"];
                                break;
                            case "Pending":
                                // If status is "Pending", add "Accepted" to jobStatus
                                jobStatus = [...jobStatus, "Accepted"];
                                break;
                            case "Success":
                                // If status is "Success", add "Success" to jobStatus
                                jobStatus = [...jobStatus, "Success"];
                                break;
                            // Handle other statuses if needed
                            default:
                                break;
                        }
                    });

                    // Set queryData.jobStatus to the final jobStatus array
                    queryData.jobStatus = jobStatus;
                    break;
                case "gteDate":
                    // Parse the gteDate string into the desired format
                    queryData.gteDate = filter.data ;
                    break;
                case "lteDate":
                    // Parse the lteDate string into the desired format
                    queryData.lteDate = filter.data ;
                    break;
                default:
                    break;
            }
        });

        return queryData;
    };
    const onClickRefresh =   () =>{
      getJobList(0)
    }

    return(
        <React.Fragment>
            {isFilterOpen &&(
                <FilterSidebar
                    isFilterSidebarOpen={isFilterOpen}
                    closeFilterSidebar={() => setIsFilterOpen(false)}
                    filterData={filterData}
                    filterDataHasDate ={true}
                    updateQueryData={(filters) => {
                        const newQueryData = convertFiltersToQueryData(filters);
                        setQueryData(newQueryData);
                    }}
                    selectedFilterData={selectedFilterData}
                    setSelectedFilterData={setSelectedFilterData}
                    setTotalFilter={setTotalFilter}
                />
            )}

            <Grid item xs={12} className="amg-body-page-header">
                <Typography> Activity Log</Typography>
            </Grid>

            <Grid item xs={12} className="chip-grid" sx={{marginTop:"16px"}} >
                {
                    successData > 0 &&
                    <Chip
                        className={filterType === SUCCESS_TYPE_LOG_FILTER ? "activity-log-chip active" : "activity-log-chip"}
                        label={`Success : ${successData}`}
                        variant="outlined" onClick={() =>handleChipOnClick(SUCCESS_LOG_ACTIVITY)}
                    />
                }
                {
                    acceptedData > 0 &&
                    <Chip
                        className={filterType === ACCEPTED_TYPE_LOG_FILTER ? "activity-log-chip active" : "activity-log-chip"}
                        label={`Pending : ${acceptedData}`}
                        variant="outlined" onClick={() =>handleChipOnClick(ACCEPTED_LOG_ACTIVITY)}
                    />
                }
                {
                    failureData > 0 &&
                    <Chip
                        className={filterType === FAILURE_TYPE_LOG_FILTER ? "activity-log-chip active" : "activity-log-chip"}
                        label={`Failed : ${failureData}`}
                        variant="outlined" onClick={() =>handleChipOnClick(FAILURE_LOG_ACTIVITY)}
                    />
                }

            </Grid>

            <Grid item xs={12} className="amg-filter-content" sx={{marginTop:"16px"}}>
                <FilterContent
                    showFilter={true}
                    titleText="logs"
                    count={searchedData.length}
                    onSearch={onSearchFilter}
                    searchText={searchText}
                    filterOpen={filterOpen}
                    filterCount={totalFilter}
                    lastApiCallTime={lastApiCallTime}
                    isLoading={loading}
                    onClickRefresh={onClickRefresh}
                />
            </Grid>

            <Grid container className="amg-body-table-content" sx={{maxHeight: "560px"}}>
                <TableActivityLog
                    tableContent={searchedData}
                    searchedText={searchText}
                    newOffset={changeOffset}
                    isLoading={loading}
                    currentOffset={offset}
                    isHavingMoreData={hasMoreData}
                    filterType={filterType}
                    statusSelected={statusSelected}
                />
                <Notification
                    open={isOpen}
                    message={notificationMessage}
                    handleClose={handleNotificationClose}
                    type={alertType}
                />
            </Grid>
        </React.Fragment>
    )
}

export default ActivityLogs