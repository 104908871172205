import React from "react";
import PropTypes from "prop-types";

function SupplyTransparencyReportBreadCrumbs(props) {
    return (
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/reports"
                )}
                className="amg-bread-crumbs-links"
            >
                Reports &nbsp;
            </span>
            / &nbsp;
            <span> New supply transparency report {props.currentMonthYear? `(${props.currentMonthYear})`: ""} </span>

        </React.Fragment>
    )
}

SupplyTransparencyReportBreadCrumbs.propTypes = {
    "currentMonthYear": PropTypes.string.isRequired,
}


export default SupplyTransparencyReportBreadCrumbs