import axios from "axios";
import {BFF_LAYER_URI, NON_TS_PLATFORM_TYPE, REPORT_BFF_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

    const getAdsPlusAllSupplyPartner = async (limit, offset, type = NON_TS_PLATFORM_TYPE) => {
    try {
        const query = JSON.stringify({
            query: `query MyQuery($limit: Int = 10, $offset: Int = 10, $type: String = "") {
              getSupplyPartners(limit: $limit, offset: $offset, type: $type) {
                supply_partner_id
                supply_partner_name
              }
            }`,
            variables: {"limit":limit,"offset":offset,"type":type}
        });
        const headers = adsHeader()

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BFF_LAYER_URI,
            headers: headers,
            data : query
        };

        const response = await axios.request(config);
        if(response?.data?.data?.getSupplyPartners){
            return response.data.data.getSupplyPartners;
        }

        return []
    } catch (error) {
        console.error("Error fetching while getAllSupplyPartner:", error);
        return null;
    }
};
export default getAdsPlusAllSupplyPartner;