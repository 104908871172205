import {BFF_LAYER_URI} from "../Constant";
import axios from "axios";
import {adsHeader} from "../utils/helper";

export const createAdsPlusRequest = (body = {}) => {
    let data = JSON.stringify({
        query: `mutation MyMutation($createAdsPlusConfig: AdsPlusInputConfig = {status: "", requested_user: ""}) {
            createAdsPlusConfig(createAdsPlusConfig: $createAdsPlusConfig)
        }`,
        variables: {...body}
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data : data
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data?.createAdsPlusConfig && response.status === 200){
                return response.data.data.createAdsPlusConfig
            }

            return  ''
        })
        .catch((error) => {
            console.log(error);
            return ''
        });

}