import React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import PropTypes from 'prop-types';

function HorizontalRadioButtons(props) {
    const handleRadioChange = (event) => {
        const value = event.target.value;
        props.onRadioChange(value, props.index, props.type, props?.formKey);
    };

    return (
        <RadioGroup row value={props.value} onChange={handleRadioChange}>
            {props.options.map((option, index) => (
                <FormControlLabel
                    className="horizontal-radio-btn-label"
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                />
            ))}
        </RadioGroup>
    );
}

HorizontalRadioButtons.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    value: PropTypes.string.isRequired,
    onRadioChange: PropTypes.func.isRequired,
};
export default HorizontalRadioButtons;