import { TextField } from "@mui/material";
import PropTypes from "prop-types";

function SimpleTelInput(props) {

    const handleChange = (e) => {
        const value = e?.target?.value;

        // Ensure that the value contains only digits and does not exceed 10 digits
        if (value.length > 10 || isNaN(value)) {
            return;
        }

        if (value < 0) {
            return;
        }

        props.onChange(value, props.index, props.type, props.formKey);
    }

    return (
        <>
            {props.preUnit && <span className="pre-unit-simple-input">{props.preUnit}</span>}
            <TextField
                variant="standard"
                placeholder={props.placeholder || "Enter the value"}
                size="small"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                value={props.value}
                className="number-simple-input"
                type="tel"
                min={0}
                InputProps={{
                    inputProps: { maxLength: 10 } // Restrict input to 10 digits
                }}
                style={{ width: props?.textWidth ?? "90%" }}
            />
            {props.postUnit && <span className="post-unit-simple-input">{props.postUnit}</span>}
        </>
    );
}

SimpleTelInput.propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    postUnit: PropTypes.string,
    preUnit: PropTypes.string,
    formKey: PropTypes.string.isRequired,
};

export default SimpleTelInput;
