import React from "react";
import PropTypes from "prop-types";
import {INITIAL_PAGE} from "../../../Constant";

function ChannelBreadCrumbs (props) {
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/platforms"
                )}
                className="amg-bread-crumbs-links"
            >
                All Platforms &nbsp;
            </span>
            / &nbsp;<span> {props?.platformName}:Channels </span> &nbsp;/
            <span
                onClick={() =>
                    props.onClickBreadCrumbsLink(
                        `/dashboard/platform/channels/${props?.platformId}`
                    )
                }
                className="amg-bread-crumbs-links"
            >
            </span>
             &nbsp;<span> {props?.channelName} </span> &nbsp;
        </React.Fragment>
    )
}

ChannelBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
    platformName: PropTypes.string,
    channelId: PropTypes.string
}

export default ChannelBreadCrumbs