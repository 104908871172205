import axios from "axios";
import {BFF_LAYER_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

const approveRequestById = async (request_job_id="", status="", user_email="") => {
    const mutation = `
        mutation MyMutation($where: AdsPlusConfigInput = {request_job_id: "", status: "", user_email: ""}) {
          updateAdsPlusConfigByID(where: $where)
        }
    `;
    const headers = adsHeader()

    const variables = {
        where: {
            request_job_id: request_job_id,
            status: status,
            user_email: user_email
        }
    };

    try {
        const response = await axios.post(BFF_LAYER_URI, {
            query: mutation,
            variables: variables,
        }, {headers: headers});
        return {"request_job_id": response.data};
    } catch (error) {
        console.error("Error approving request:", error.response ? error.response.data : error.message);
        return {message: "Error approving request", details: error.response ? error.response.data : error.message};
    }
};

export default approveRequestById;