

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Checkbox, Grid, Typography,Tooltip
} from "@mui/material";
import {GET_TSTAG, getTStag} from "../../../utils/helper";
import {extractAdServer, getEpochData, isEmpty} from "../../../utils/helper";
import { EmptyScreen } from "../../CommonScreens/EmptyScreen";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";
import getJobStatusById from "../../../api/getJobStatus";
import {useParams} from "react-router-dom";
import SuccessIcon from "../../../assests/icons/successIcon";
import {ACCEPTED_LOG_ACTIVITY, SUCCESS_LOG_ACTIVITY} from "../../../Constant";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SkippedIcon from "../../../assests/icons/skippedIcon";

function TableActivityLog(props) {
    const {jobId}=useParams()


    return (
        <Grid item xs={12}>
            <Table className="off-white-table" stickyHeader>
                <TableHead>
                <TableCell>TAG NAME </TableCell>
                <TableCell>TYPE</TableCell>
                <TableCell>MACRO</TableCell>
                <TableCell >VALUE BEFORE UPDATE</TableCell>
                <TableCell>VALUE AFTER UPDATE</TableCell>
                <TableCell>STATUS</TableCell>
                </TableHead>
                <TableBody>

                    {props.jobDetail?.length > 0 ?
                        props.jobDetail.map((data, index) => {
                            return (
                                <TableRow key={`${index}-${data?.CreatedAt}`}>

                                    <TableCell>
                                        {data.EntityName}
                                    </TableCell>

                                    <TableCell>  {data?.EntityType}</TableCell>
                                    <TableCell> {data?.macro} </TableCell>

                                    <TableCell> {data?.old_value} </TableCell>
                                    <TableCell>{data.new_value}</TableCell>

                                    <TableCell>
                                        {data?.Status === SUCCESS_LOG_ACTIVITY ? (
                                            <Tooltip title={data.Reason || "No Reason Found"} arrow placement="top" >
                                                <div className="status-div">
                                                    <CheckCircleOutlineIcon className="error-success" sx={{ marginRight: "3px" }} />
                                                    <Typography className="log-status-success">Success</Typography>
                                                </div>
                                            </Tooltip>
                                        ) : data.Status === "Skipped" ? (
                                            <Tooltip  title={data.Reason || "No Reason Found"} arrow placement="top" >

                                                <div className="status-div">
                                                    <SkippedIcon />


                                                    <Typography className="log-status-pending">Skipped</Typography>


                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={data.Reason || "No Reason Found"} arrow placement="top">
                                                <div className="status-div">
                                                    <ErrorOutlineIcon className="error-icon" sx={{ marginRight: "3px" }} />
                                                    <Typography className="log-status-failed">Failed</Typography>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        }) : (!props?.isLoading) && (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <EmptyScreen message="No results found"/>
                            </TableCell>
                        </TableRow>
                    )
                    }


                    {props?.isLoading && (
                        <LoadingScreen rowsNumber={6}/>
                    )}

                </TableBody>
            </Table>
        </Grid>
    );
}



export default TableActivityLog;



