import React, {useEffect, useState} from "react";
import { TableBody, TableCell, TableHead, TableRow, Table } from "@mui/material";
import AdsSimpleTextInput from "../AdsSimpleTextInput";
import ContactTableConfig from "../../../FormConfig/ContactTableConfig";
import "./index.css";
import {DEFAULT_CUSTOMER_CONNECTIONS} from "../../../Constant";
import PropTypes from "prop-types";

const ContactTableContainer = (props) => {
    const [contactFormData, setContactFormData] = useState(DEFAULT_CUSTOMER_CONNECTIONS);

    useEffect(() => {
        if (props?.contactsData?.length > 0) {
            setContactFormData(props.contactsData);
        }
    }, [props.contactsData]);

    const onClickChange = (value, index, type, key) => {
        const getState = Object.assign([], contactFormData)
        getState[index][key] = value
        setContactFormData(getState)
        props.onChange(props.formKey, getState)
    };

    return (
        <div className="Contact-inner-div">
            <Table className="off-white-table ads-supply-table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {ContactTableConfig?.headers?.map((header, index) => (
                            <TableCell key={index}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ContactTableConfig?.rows?.map((contact, rowIndex) => {
                        return(
                            <TableRow key={rowIndex}>
                                <TableCell>{contact.contactType}</TableCell>
                                {contact?.fields?.length > 0 && contact?.fields?.map((field) => {
                                    return (
                                        <TableCell key={`${rowIndex}-${field.key}`}>
                                            <AdsSimpleTextInput
                                                type={field.type}
                                                onChange={onClickChange}
                                                textWidth={field.width}
                                                placeholder={field.placeholder}
                                                formKey={field.key}
                                                value={contactFormData[rowIndex][field.key] || ""}
                                                index={rowIndex}
                                            />
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

ContactTableContainer.propTypes = {
    formKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default ContactTableContainer;
