const SortIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={props.className} onClick={props.onClick}>
            <path
                d="M8.66645 5.21802V13.3327H7.33312V5.21802L3.75712 8.79402L2.81445 7.85135L7.99979 2.66602L13.1851 7.85135L12.2425 8.79402L8.66645 5.21802Z"
                fill="#999999"/>
        </svg>
    )
}

export default SortIcon