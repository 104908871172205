const CheckedIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none" className="checked-icon">
            <rect width="16" height="16" rx="2" fill="white" fill-opacity="0.2" />
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="white" stroke-opacity="0.08" />
            <path opacity="0.5" d="M6.49988 10.118L12.9994 4L14 4.94102L6.49988 12L1.99997 7.76474L2.99979 6.82373L6.49988 10.118Z" fill="#0F0F0F" />
        </svg>
    )
}

export default CheckedIcon