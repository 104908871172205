export const EmptyBar = () => {
  return (
      <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="line-chart-line">
              <path id="Vector" d="M28.3333 17V107.667H119V119H17V17H28.3333Z" fill="white" fill-opacity="0.12"/>
              <path id="Vector_2"
                    d="M118.654 42.8623L110.641 34.8496L86.3143 59.1709L69.3143 42.1766L36.9746 74.5163L44.9873 82.5289L69.3143 58.2076L86.3143 75.2019L118.654 42.8623Z"
                    fill="white" fill-opacity="0.12"/>
          </g>
      </svg>
  )
}