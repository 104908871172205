import { useEffect, useRef, useState, useCallback } from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Typography, Grid,
} from "@mui/material";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import {getEpochData, getImageName, isEmpty} from "../../../utils/helper";
import {FORMAT_TIMESTAMP, SEARCHED_TEXT_NOT_FOUND} from "../../../Constant";
import { MAX_LIMIT_SHOW_TABLE_DATA } from "../../../Constant";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";
import {EmptyScreen} from "../../CommonScreens/EmptyScreen";

function TableContentPlatform(props) {
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection);
        if (observer && elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [props?.tableContent]);

    const onIntersection = ([entry]) => {
        if (entry.isIntersecting && props?.isHavingMoreData && !props?.isLoading && props?.tableContent?.length >= MAX_LIMIT_SHOW_TABLE_DATA) {
            const newCount = props.currentOffset + MAX_LIMIT_SHOW_TABLE_DATA;
            props.newOffset(newCount);
        }
    }

    

    return (
        <Grid item xs={12}>
            <Table className="off-white-table" stickyHeader>
                <TableHead>
                    <TableCell colSpan={2}> PLATFORM NAME </TableCell>
                    <TableCell> AMG ID </TableCell>
                    <TableCell> REGION </TableCell>
                    <TableCell> CHANNELS </TableCell>
                    <TableCell> ROUTERS </TableCell>
                    <TableCell> DEAL TYPE </TableCell>
                    <TableCell> LAST UPDATED </TableCell>
                </TableHead>

                <TableBody>
                    {props?.tableContent?.length > 0 ?
                        props?.tableContent?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell width="8%">

                                        {item?.platform_logo_url && item.platform_logo_url !== "null"  && item.platform_logo_url !== null? (
                                            <div className="logo-div white-bg">
                                                <img src={item?.platform_logo_url} alt="logo" />
                                            </div>
                                        ) : (
                                            <div className="logo-div white-bg">
                                                <Typography className="img-typography">
                                                    {getImageName(item?.name)}
                                                </Typography>
                                            </div>
                                        )}
                                </TableCell>
                                <TableCell
                                    className="action-table-button"
                                    onClick={() => props.onClickPlatform(item?.amg_id)}
                                    width="15%"
                                >
                                    {item?.name}
                                </TableCell>
                                <TableCell> {item?.amg_id} </TableCell>
                                <TableCell> {item?.region} </TableCell>
                                <TableCell> {item?.channels} </TableCell>
                                <TableCell> {item?.routers} </TableCell>
                                <TableCell>
                                    {item?.deal_type.map((type) => (
                                        <span className="tables-tags" key={type}>
                                            {type}
                                        </span>
                                    ))}
                                </TableCell>
                                <TableCell> {getEpochData(item?.updated_at)} </TableCell>
                            </TableRow>
                        ))
                        : (!isEmpty(props?.searchText)  && !props?.isLoading) && (
                            <TableRow >
                                <TableCell colspan={8}> <EmptyScreen message="No results found"/> </TableCell>
                            </TableRow>
                        )
                    }

                    {!props?.isLoading && props?.isHavingMoreData && (
                        <TableRow ref={elementRef}>
                            <TableCell colspan={8}></TableCell>
                        </TableRow>
                    )}
                    {props?.isLoading && (
                        <LoadingScreen rowsNumber={8} />
                    )}

                </TableBody>
            </Table>
        </Grid>
    );
}

TableContentPlatform.propTypes = {
    tableContent: PropTypes.shape({
        image_path: PropTypes.string,
        amg_id: PropTypes.string,
        platform_logo_url: PropTypes.string,
        region: PropTypes.string,
        name: PropTypes.string,
        routers: PropTypes.number,
        deal_type: PropTypes.arrayOf(PropTypes.string),
        updated_at: PropTypes.string,
    }),
    onClickPlatform: PropTypes.func,
    loading: PropTypes.bool,
    setOffset: PropTypes.func,
    searchText: PropTypes.string
};

export default TableContentPlatform;