import "./index.css"
import {
    Grid,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Popover,
    Skeleton
} from "@mui/material"
import React, {useEffect, useState} from "react"
import LayoutColumnIcon from "../../../assests/icons/layoutColumnIcon"
import {
    reduceString,
    formatNumber,
    RemoveDecimalUSANumberFormat,
    FormatNumberToTwoDecimals
} from "../../../utils/helper";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen"
import { EmptyScreen } from "../../CommonScreens/EmptyScreen"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownIcon from "../../../assests/icons/downIcon";
import {SELECT1_OPTION_STR, SORT_STR_LIST, TADR_HTL, TADR_LTH, TI_HTL,TI_LTH} from "../../../Constant";

const TableAvailsReport = (props) => {






    return (
        <React.Fragment>
            <Grid item xs={12} className="table-frame">
                <div className="avails-report-count">
                    {props.isLoading ? (
                        <Skeleton animation="wave" className="skleton-loading-screen" width="5%" height={20} />
                    ) : (
                        `${props.reportCount} results`
                    )}
                </div>

                <Table className="report-table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {props?.tableHeader?.map((column) => (

                                <TableCell
                                    key={column.id}
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {column.title}
                                </TableCell>

                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.tableContentData?.length > 0 ?
                            props.tableContentData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {props?.tableHeader?.map((column, colIndex) => (
                                        <TableCell
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                            key={`${rowIndex}-${colIndex}`}
                                        >
                                            {
                                                column.id === "monthly_available_30s"
                                                    ? RemoveDecimalUSANumberFormat(row[column.id])
                                                    : (
                                                        column.id === "average_clearing_price"
                                                            ? `$ ${FormatNumberToTwoDecimals(row[column.id])}`
                                                            : (
                                                                column.id === "max_floor_price"
                                                                    ? `$ ${row[column.id]}`
                                                                    : reduceString(row[column.id])
                                                            )
                                                    )
                                            }


                                        </TableCell>
                                    ))}
                                </TableRow>


                            )) : (!props?.isLoading) && (
                            <TableRow>

                                <TableCell colspan={13}> <EmptyScreen message="No results found"/> </TableCell>

                            </TableRow>
                        )
                        }
                        {props?.isLoading && (
                            <LoadingScreen rowsNumber={9}/>
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </React.Fragment>

    )
}

export default TableAvailsReport