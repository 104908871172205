import axios from "axios";
import {BFF_LAYER_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

const getAllNonTsSupplyLabelIds = async () => {
    try {
        const query = `
                query MyQuery2 {
                    getSupplyLabels(limit: 100, offset: 0, type: "NON_TS") {
                        supply_label_id
                        supply_label_name
                    }
                }
            `;

        const headers = adsHeader()

        const response = await axios.post(BFF_LAYER_URI, { query }, { headers: headers });

        if(response.status === 200){
            return response.data.data.getSupplyLabels;
        }
    } catch (error) {
        console.error("Error fetching Supply Label IDs:", error);
        throw error;
    }
}

export default getAllNonTsSupplyLabelIds;