import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Divider, Grid } from '@mui/material';
import { Input } from '@mui/material';
import { InputAdornment } from '@mui/material';
import SearchIcon from '../../../../assests/icons/searchIcon';
import { TextField,Table,TableCell,TableRow,TableBody } from '@mui/material';
import FilterIconSet from '../../../../assests/icons/FilterIcon';
import { Checkbox } from '@mui/material';
import { extractAdServer } from '../../../../utils/helper';
import "./index.css";

export default function MacroFilter(props) {
    const [searchText, setSearchText] = useState('');
    const [selectedFilters, setSelectedFilters] = useState([]);


    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <Grid container>
                    <Grid item xs={12}>
                        <Grid  className="add-macro-option allign-left" {...bindTrigger(popupState)}>
                           <FilterIconSet  />
                        </Grid>
                        <Popover
                            className='add-macros-autocomplete'
                            anchorReference="anchorPosition"
                            anchorPosition={{top: 300, left: 900}}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            {...bindPopover(popupState)}
                            onClose={() => {
                                props.cancelSelected()
                                popupState.close();
                            }}
                        >
                            <Grid className='autocomplete-grid'>
                                <TextField
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder={`Search by demand providers`}
                                    variant="outlined"
                                    className="generic-search-container "
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon></SearchIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid className="filter-table-div" >
                                <Table className="white-table filter-table">
                                    <TableBody>
                                        {props.filterData
                                            .filter(demand_name =>
                                                demand_name.toLowerCase().includes(searchText.toLowerCase())
                                            ).map((demand_name, index) => (
                                            <TableRow key={index} >
                                                <TableCell className="filter-checkbox-cell" sx={{width:"10%"}} >
                                                    <Checkbox
                                                        className="amg-white-checkbox"
                                                        checked={props.selectedFilters.includes(demand_name)}
                                                        onChange={() => props.handleFilterSelect(demand_name)}
                                                    />
                                                </TableCell >
                                                <TableCell sx={{width:"90%"}} >{demand_name}</TableCell>
                                            </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </Grid>


                            <Grid container justifyContent="flex-end" className="filter-footer">
                                <Button
                                    variant="contained"
                                    className="reset-button"
                                    style={{ marginRight: "40px" }}
                                    onClick={()=>{
                                        props.resetFilterSelect();
                                        popupState.close();
                                    }}

                                >
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    className="cancel-button"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                        props.cancelSelected()
                                        popupState.close();
                                        setSearchText('')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    className="create-button"
                                    style={{ marginRight: "15px" }}
                                onClick={
                                    () => {
                                        props.applyFilterSelect();
                                        popupState.close();
                                    }
                                }

                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Popover>
                    </Grid>
                </Grid>
            )}
        </PopupState>
    );
}
