const useCookieStorageHelper = {

    // Function to store items in cookie storage securely
    setItemCookie: ( name, value, days, secure = true, sameSite = 'Strict', httpOnlyFlag="" ) => {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        // Secure flag ensures cookies are sent over HTTPS
        // HttpOnly prevents access to cookies via JavaScript (add if cookies are set server-side)
        // SameSite prevents cross-site request forgery (CSRF)
        // httpOnlyFlag Set `; HttpOnly` if managing cookies server-side
        const secureFlag = secure ? "; Secure" : "";
        const sameSiteFlag = `; SameSite=${sameSite}`;

        document.cookie = `${name}=${value || ""}${expires}; path=/${secureFlag}${httpOnlyFlag}${sameSiteFlag}`;
    },

    // Function to get items from cookie storage
    getItemCookie: (name) => {
        const cookieName = `${name}=`;
        const cookiesArray = document.cookie.split(";");
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i];
            while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length);
            if (cookie.indexOf(cookieName) === 0) return cookie.substring(cookieName.length, cookie.length);
        }
        return null;
    },

    // Function to remove items from cookie storage
    removeItemCookie: (name) => {
        // Set Max-Age to negative value to delete the cookie
        document.cookie = `${name}=; Max-Age=-99999999; path=/; Secure; SameSite=Strict`;
    },

    // Function to clear all cookies securely
    clearAllCookie: () => {
        // Iterate over all cookies and remove them
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=; Max-Age=-99999999; path=/; Secure; SameSite=Strict`;
        }
    },
};

export default useCookieStorageHelper;

