import React, {useEffect, useState} from 'react';
import BarChartComponent from "../../Common/BarChart";
import {ButtonGroup, CircularProgress, Grid, MenuItem, Select, Tab, Tabs, Typography} from "@mui/material";
import "./index.css"
import PropTypes from "prop-types";
import {EmptyBar} from "../../assests/icons/emptyBar";
import {
    AUTO_HIDE_DOWNLOAD_TIME,
    BAR_CHART_VIEW_TYPE,
    DEFAULT_DOWNLOAD_API_RESPONSE_TIME,
    DEMAND_DASHBOARD_BAR_CHART_VIEW_TYPE,
    DEMAND_MARKET_SUMMERY, DEMAND_PARTNER_KEY, DEMAND_PARTNER_OPEN_MARKET_KEY, DEMAND_PARTNER_OPEN_MARKET_TABLE_DETAILS,
    DEMAND_PARTNER_TABLE_DETAILS, OPEN_MARKET_SUMMERY,
    TABLE_VIEW_TYPE,
    TOTAL_REVENUE_KEY
} from "../../Constant";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StickyTable from "../../Common/StickyTable";
import DetailsStickyTable from "../../Common/DetailsStickyTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {downloadCSV} from "../../utils/fileDowloader";
import {getCurrentTime, getDefaultDemandPartner, getDefaultSupplyPartner, isEmpty} from "../../utils/helper";
import {NoFilterData} from "../../assests/icons/noFilterData";

function AdsDemandHealthTabView(props) {
    const [barChartViewType, setBarChartViewType] = useState("")
    const [barViewVisible, setBarViewVisible] = useState(false)
    const [tableViewVisible, setTableViewVisible] = useState(false)
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    const [fileDownloadSuccess, setFileDownloadSuccess] = useState(false)
    const [barChartData, setBarChartData] = useState([])
    const [barChartCategoryType, setBarChartCategoryType] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableColumns, setTableColumns] = useState([])
    const [allDayPartnerTotalRevenue, setAllDayPartnerTotalRevenue] = useState(0)
    const [allDayOpenBidderTotalRevenue, setAllDayOpenBidderTotalRevenue] = useState(0)
    const [allPartnerTotalRevenue, setPartnerWiseTotalRevenue] = useState([])
    const [openMarketBidderWiseTotalRevenue, setOpenMarketBidderWiseTotalRevenue] = useState([])
    const [detailsTableView, setDetailsTableView] = useState(false)
    const [filterPartnerName, setFilterPartnerName] = useState("")
    const [detailsTableHeaderColumns, setDetailsTableHeaderColumns] = useState([])
    const [dateOrderAsc,setDateOrderAsc]=useState(true)
    const [detailsTableKey,setDetailsTableKey]=useState([])
    const [openMarketTableColumns,setOpenMarketTableColumns]=useState([])

    useEffect(() => {
        if (props?.demandHealthTracker?.daily?.length > 0 && props?.demandPartnerOrder?.length > 0) {
            let getTableData = []
            let allDayTotalRevenue = 0
            let partnerWiseTotalRevenue = {}
            let orderedPartnerData = []
            let demandPartnerTableColumn = []
            let allOpenMarketRevenue = 0
            let openMarketBidder = []
            let openMarketBidderWisRevenue = {}
            let openMarketBidderOrder = []

            props.demandHealthTracker.daily.map((dailyData) => {
                if (dailyData?.demand_partner?.length > 0) {
                    let demandPartnerData = []
                    let totalRevenue = 0
                    let dailyOpenMarketRevenue = 0
                    props.demandPartnerOrder.map((partnerData) => {
                        const getDemandPartnerData = dailyData?.demand_partner?.find((data) => data?.demand_partner_id === partnerData?.demand_partner_id && data) || getDefaultDemandPartner(partnerData?.demand_partner_id, partnerData?.demand_partner_name, dailyData.date)
                        if(!isEmpty(getDemandPartnerData)) {
                            totalRevenue = getDemandPartnerData?.[TOTAL_REVENUE_KEY] + totalRevenue
                            demandPartnerData.push(getDemandPartnerData)

                            if (partnerWiseTotalRevenue?.[getDemandPartnerData.demand_partner_id]) {
                                partnerWiseTotalRevenue[getDemandPartnerData.demand_partner_id] = partnerWiseTotalRevenue[getDemandPartnerData.demand_partner_id] + getDemandPartnerData?.[TOTAL_REVENUE_KEY]
                            } else {
                                partnerWiseTotalRevenue[getDemandPartnerData.demand_partner_id] = getDemandPartnerData?.[TOTAL_REVENUE_KEY]
                            }
                        }

                        return true
                    })

                    dailyData?.open_market_summary?.length > 0 && dailyData.open_market_summary.map((openMarketSummary) => {
                        dailyOpenMarketRevenue = openMarketSummary?.[TOTAL_REVENUE_KEY] + dailyOpenMarketRevenue
                        if(openMarketBidderWisRevenue?.[openMarketSummary?.bidder_account_name]){
                            openMarketBidderWisRevenue[openMarketSummary?.bidder_account_name] = openMarketSummary?.[TOTAL_REVENUE_KEY] + openMarketBidderWisRevenue[openMarketSummary?.bidder_account_name]
                        }else {
                            openMarketBidderWisRevenue[openMarketSummary?.bidder_account_name] = openMarketSummary?.[TOTAL_REVENUE_KEY]
                        }

                        if(openMarketBidder.indexOf(openMarketSummary?.bidder_account_name) < 0){
                            openMarketBidder.push(openMarketSummary?.bidder_account_name)
                        }

                    })

                    allDayTotalRevenue = totalRevenue + allDayTotalRevenue
                    allOpenMarketRevenue = dailyOpenMarketRevenue + allOpenMarketRevenue
                    getTableData.push({
                        "date": dailyData?.date,
                        "partner_details": demandPartnerData,
                        "total_revenue": totalRevenue,
                        "impressions": dailyData?.impressions,
                        "revenue": dailyData?.revenue,
                        "open_market_summery": dailyData?.open_market_summary || [],
                        "dailyOpenMarketRevenue": dailyOpenMarketRevenue
                    })
                }
                return true
            })

            props.demandPartnerOrder.map((data) => {
                orderedPartnerData.push(partnerWiseTotalRevenue?.[data?.demand_partner_id] || 0)
                demandPartnerTableColumn.push(data?.demand_partner_name)
                return true
            })
            getTableData.sort((a, b) => new Date(a.date) - new Date(b.date));
            openMarketBidder.map((data) => {
                openMarketBidderOrder.push(openMarketBidderWisRevenue?.[data] || 0)
            })

            setBarChartData(getTableData)
            setBarChartViewType(DEMAND_MARKET_SUMMERY)
            setBarChartCategoryType(DEMAND_DASHBOARD_BAR_CHART_VIEW_TYPE)
            setTableColumns(demandPartnerTableColumn)
            setTableData(getTableData)
            setAllDayPartnerTotalRevenue(allDayTotalRevenue)
            setPartnerWiseTotalRevenue(orderedPartnerData)
            setOpenMarketTableColumns(openMarketBidder)
            setOpenMarketBidderWiseTotalRevenue(openMarketBidderOrder)
            setAllDayOpenBidderTotalRevenue(allOpenMarketRevenue)
            setBarViewVisible(true)
            setTableViewVisible(false)
            setDetailsTableView(false)
        } else {
            setBarChartData([])
            setBarChartViewType("")
            setBarChartCategoryType("")
            setTableColumns([])
            setTableData([])
            setAllDayPartnerTotalRevenue(0)
            setPartnerWiseTotalRevenue([])
            setOpenMarketTableColumns([])
            setOpenMarketBidderWiseTotalRevenue([])
            setAllDayOpenBidderTotalRevenue([])
            setAllDayOpenBidderTotalRevenue(0)
            setBarViewVisible(false)
            setTableViewVisible(false)
            setBarChartData([])
            setDetailsTableView(false)
        }
    }, [props])

    const handleBarViewType = (event, newValue) => {
        setBarChartViewType(newValue);
        setBarViewVisible(true)
        setTableViewVisible(false)
        setDetailsTableView(false)
    }

    const onClickViewType = (type) => {
        if (type === BAR_CHART_VIEW_TYPE) {
            setBarViewVisible(true)
            setTableViewVisible(false)
            setDetailsTableView(false)
        }

        if (type === TABLE_VIEW_TYPE) {
            setBarViewVisible(false)
            setTableViewVisible(true)
            setDetailsTableView(false)
        }
    }

    const onClickDownloadButton = () => {
        //TODO adding functionality to download the file locally, later move to real API
        setShowDownloadButton(true)
        let downloadJsonData = []
        const nowTime = getCurrentTime()
        let fileName = ""

        if((barViewVisible || tableViewVisible) && tableData.length > 0) {
            const key = barChartViewType === OPEN_MARKET_SUMMERY ? "bidder_account_name" :"demand_partner_name"
            fileName = `Revenue-Sheet-All-Partner-${nowTime}.csv`
            tableData.map((data, index) => {
                downloadJsonData.push({
                    "Date": data?.date,
                    "All Partner": barChartViewType === OPEN_MARKET_SUMMERY ? data?.["dailyOpenMarketRevenue"] :data?.[TOTAL_REVENUE_KEY]
                })
                if(data?.partner_details?.length > 0 && barChartViewType !== OPEN_MARKET_SUMMERY) {
                    let partnerDetails = {}
                    data.partner_details.map((partner_detail) => {
                        partnerDetails[partner_detail?.[key]] = partner_detail?.[TOTAL_REVENUE_KEY]
                        return true
                    })
                    Object.assign(downloadJsonData[index],partnerDetails)
                }

                if(data?.partner_details?.length > 0 && barChartViewType === OPEN_MARKET_SUMMERY) {
                    let partnerDetails = {}
                    data.open_market_summery.map((partner_detail) => {
                        partnerDetails[partner_detail?.[key]] = partner_detail?.[TOTAL_REVENUE_KEY]
                        return true
                    })
                    Object.assign(downloadJsonData[index],partnerDetails)
                }
                return true
            })
        }else if(detailsTableView && tableData?.length > 0){
            const key = barChartViewType === OPEN_MARKET_SUMMERY ? "bidder_account_name" :"demand_partner_name"
            const detailsTableHeader = barChartViewType === OPEN_MARKET_SUMMERY ? DEMAND_PARTNER_OPEN_MARKET_TABLE_DETAILS : DEMAND_PARTNER_TABLE_DETAILS
            const detailsTableKey = barChartViewType === OPEN_MARKET_SUMMERY ? DEMAND_PARTNER_OPEN_MARKET_KEY :DEMAND_PARTNER_KEY
            fileName = `${filterPartnerName}-Sheet-All-Data-${nowTime}.csv`
            tableData.map((data, index) => {
                downloadJsonData.push({
                    "Date": data?.date,
                })
                if(data?.partner_details?.length > 0 && barChartViewType !== OPEN_MARKET_SUMMERY) {
                    let partnerDetails = {}
                    data.partner_details.map((partnerData) => {
                        if(partnerData?.[key] === filterPartnerName){
                            detailsTableHeader.map((header, index) => {
                                partnerDetails[header] = partnerData?.[detailsTableKey?.[index]] || 0
                            })
                        }
                        return true
                    })
                    Object.assign(downloadJsonData[index],partnerDetails)
                }

                if(data?.partner_details?.length > 0 && barChartViewType === OPEN_MARKET_SUMMERY) {
                    let partnerDetails = {}
                    data.open_market_summery.map((partnerData) => {
                        if(partnerData?.[key] === filterPartnerName){
                            detailsTableHeader.map((header, index) => {
                                partnerDetails[header] = partnerData?.[detailsTableKey?.[index]] || 0
                            })
                        }
                        return true
                    })
                    Object.assign(downloadJsonData[index],partnerDetails)
                }
                return true
            })
        }

        setTimeout(() => {
            if(downloadJsonData.length > 0 && fileName !== ""){
                downloadCSV(downloadJsonData, fileName)
            }
            setFileDownloadSuccess(true)
        }, [DEFAULT_DOWNLOAD_API_RESPONSE_TIME])

        // TODO add some logic to remove the download button
        setTimeout(() => {
            setFileDownloadSuccess(false)
            setShowDownloadButton(false)
        }, [AUTO_HIDE_DOWNLOAD_TIME])
    }

    const onClickPartnerDetails = (name) => {
        let detailsTableHeader = []
        let detailsTableKey = []
        detailsTableHeader = barChartViewType === OPEN_MARKET_SUMMERY ? DEMAND_PARTNER_OPEN_MARKET_TABLE_DETAILS :DEMAND_PARTNER_TABLE_DETAILS
        detailsTableKey = barChartViewType === OPEN_MARKET_SUMMERY ? DEMAND_PARTNER_OPEN_MARKET_KEY :DEMAND_PARTNER_KEY
        setFilterPartnerName(name)
        setTableViewVisible(false)
        setBarViewVisible(false)
        setDetailsTableView(true)
        setDetailsTableHeaderColumns(detailsTableHeader)
        setDetailsTableKey(detailsTableKey)
    }

    const onBackScreenFromDetails = () => {
        setFilterPartnerName("")
        setTableViewVisible(true)
        setBarViewVisible(false)
        setDetailsTableView(false)
        setDetailsTableHeaderColumns([])
    }

    const handleOnsSortDate = () =>{
        const getTableData = Object.assign([],tableData);
        if(dateOrderAsc){
            getTableData.sort((a, b) => new Date(b.date) - new Date(a.date));
            setTableData(getTableData);
            setDateOrderAsc(false)
        }
        else{
            getTableData.sort((a, b) => new Date(a.date) - new Date(b.date));
            setTableData(getTableData);
            setDateOrderAsc(true)
        }
    }


    return (
        <Grid container className="content-view-component ads-demand-health-tab-view">
            {props?.loading &&
                <Grid item xs={12} className="empty-bar-view-screen">
                    <EmptyBar/>
                </Grid>
            }
            {!props?.isDataAvailable &&
                <Grid item xs={12} className="empty-bar-view-screen">
                    <NoFilterData />
                </Grid>
            }
            {!props?.loading && props?.isDataAvailable &&
                <React.Fragment>
                    <Grid item xs={12} className="content-tab-component">
                        <Tabs value={barChartViewType} onChange={handleBarViewType} className="content-tabs-view-component">
                            {barChartCategoryType?.length > 0 && barChartCategoryType.map((data, index) => {
                                return(
                                    <Tab value={data} label={data} key={index} />
                                )
                            })}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} className="content-bar-table-filter-component">
                        <Grid container className="content-filter-component">
                            <Grid item xs={4}>
                                {tableViewVisible ? <Typography className="table-count"> {tableData.length} result </Typography> : detailsTableView &&
                                    <Typography className="table-header">
                                        <ArrowBackIcon onClick={(_) => onBackScreenFromDetails()}/> {filterPartnerName}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={1} className="content-action-component">
                                {showDownloadButton && <IconButton
                                    className={fileDownloadSuccess ? "download-progress-div success-download" : "download-progress-div"}>
                                    <ArrowDownwardIcon/>
                                    {!fileDownloadSuccess && <CircularProgress
                                        size={35}
                                        sx={{
                                            color: "#4999E9",
                                            position: 'absolute'
                                        }}
                                    />}
                                </IconButton>}
                                <IconButton className="icon-button" onClick={(_) => onClickDownloadButton()}>
                                    <FileDownloadIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={1} className="content-action-component">
                                <ButtonGroup variant="contained" aria-label="Basic button group" className="button-group">
                                    <IconButton className={barViewVisible ? "icon-button active-icon" : "icon-button"}
                                                onClick={(_) => onClickViewType(BAR_CHART_VIEW_TYPE)}>
                                        <ShowChartIcon/>
                                    </IconButton>
                                    <IconButton className={tableViewVisible ? "icon-button active-icon" : "icon-button"}
                                                onClick={(_) => onClickViewType(TABLE_VIEW_TYPE)}>
                                        <TableRowsIcon/>
                                    </IconButton>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container className="content-bar-chart-component">
                            {barViewVisible && <BarChartComponent
                                barChartData={barChartData}
                                type={barChartViewType}
                            />}
                            {tableViewVisible && <StickyTable
                                tableData={tableData}
                                tableColumns={tableColumns}
                                allDayPartnerTotalRevenue={allDayPartnerTotalRevenue}
                                allPartnerTotalRevenue={allPartnerTotalRevenue}
                                onClickPartnerDetails={onClickPartnerDetails}
                                handleOnsSortDate={handleOnsSortDate}
                                type={barChartViewType}
                                openMarketTableColumns={openMarketTableColumns}
                                openMarketBidderWiseTotalRevenue={openMarketBidderWiseTotalRevenue}
                                allDayOpenBidderTotalRevenue={allDayOpenBidderTotalRevenue}
                            />}
                            {detailsTableView && <DetailsStickyTable
                                tableData={tableData}
                                tableColumns={detailsTableHeaderColumns}
                                allDayPartnerTotalRevenue={allDayPartnerTotalRevenue}
                                allPartnerTotalRevenue={allPartnerTotalRevenue}
                                filterPartnerName={filterPartnerName}
                                tableKey={detailsTableKey}
                                type={barChartViewType}
                            />}
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    )
}

AdsDemandHealthTabView.prototype = {
    "loading": PropTypes.bool.isRequired,
    "demandHealthTracker": PropTypes.shape({
        "ad_requests": PropTypes.shape({
            "total": PropTypes.number.isRequired,
            "change": PropTypes.number.isRequired,
        }).isRequired,
        "e_rpm": PropTypes.shape({
            "total": PropTypes.number.isRequired,
            "change": PropTypes.number.isRequired,
        }).isRequired,
        "revenue": PropTypes.shape({
            "total": PropTypes.number.isRequired,
            "change": PropTypes.number.isRequired,
        }).isRequired,
        "bid_rate": PropTypes.shape({
            "total": PropTypes.number.isRequired,
            "change": PropTypes.number.isRequired,
        }).isRequired,
        "win_rate": PropTypes.shape({
            "total": PropTypes.number.isRequired,
            "change": PropTypes.number.isRequired,
        }).isRequired,
        "use_rate": PropTypes.shape({
            "total": PropTypes.number.isRequired,
            "change": PropTypes.number.isRequired,
        }).isRequired,
        "date": PropTypes.arrayOf(PropTypes.shape({
            "date": PropTypes.string.isRequired,
            "revenue": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "impressions": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "e_rpm": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "bid_rate": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "win_rate": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "use_rate": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "open_market_summary": PropTypes.shape({
                "bid_rate": PropTypes.number.isRequired,
                "bidder_account_id": PropTypes.number.isRequired,
                "bidder_account_name": PropTypes.string.isRequired,
                "bidder_errors": PropTypes.number.isRequired,
                "bids": PropTypes.number.isRequired,
                "cost": PropTypes.number.isRequired,
                'date': PropTypes.string.isRequired,
                "demand_partner_id": PropTypes.number.isRequired,
                "erpm": PropTypes.number.isRequired,
                "impressions": PropTypes.number.isRequired,
                "margin": PropTypes.number.isRequired,
                "margin_per": PropTypes.number.isRequired,
                "requests": PropTypes.number.isRequired,
                "response_time": PropTypes.number.isRequired,
                "revenue": PropTypes.number.isRequired,
                "use_rate": PropTypes.number.isRequired,
                "win_rate": PropTypes.number.isRequired,
                "wins": PropTypes.number.isRequired
            }),
            "demand_partner": PropTypes.shape({
                "bid_rate": PropTypes.number.isRequired,
                "bids": PropTypes.number.isRequired,
                "cost": PropTypes.number.isRequired,
                'date': PropTypes.string.isRequired,
                "demand_partner_id": PropTypes.number.isRequired,
                "erpm": PropTypes.number.isRequired,
                "impressions": PropTypes.number.isRequired,
                "margin": PropTypes.number.isRequired,
                "margin_per": PropTypes.number.isRequired,
                "requests": PropTypes.number.isRequired,
                "response_time": PropTypes.number.isRequired,
                "revenue": PropTypes.number.isRequired,
                "use_rate": PropTypes.number.isRequired,
                "win_rate": PropTypes.number.isRequired,
                "wins": PropTypes.number.isRequired
            })
        })),
        "demandPartnerOrder": PropTypes.arrayOf(PropTypes.shape({
            "demand_partner_id": PropTypes.number.isRequired,
            "demand_partner_name": PropTypes.string.isRequired,
        }))
    }),
    "isDataAvailable": PropTypes.bool.isRequired,
}

export default AdsDemandHealthTabView