import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {Table, TableHead, TableCell, TableBody, TableRow, Typography, Grid, Tooltip} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from "../../../assests/icons/EditIcon";
import {getEpochData, getImageName, isEmpty} from '../../../utils/helper';
import FilterContent from '../../TableTopFilters/FilterContent';
import './index.css';
import {
  COPY_TO_CLIPBOARD_FAILED_NOTIFICATION_TYPE,
  COPY_TO_CLIPBOARD_SUCCESS_NOTIFICATION_TYPE,
  MAX_LIMIT_SHOW_TABLE_DATA, REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_SUBMIT,
  SUCCESS_TYPE,
  WARNING_NOTIFICATION_TYPE
} from '../../../Constant';
import { LoadingScreen } from '../../CommonScreens/LoadingScreen';
import { EmptyScreen } from '../../CommonScreens/EmptyScreen';
import ProcessingIcon from '../../../assests/icons/ProcessingIcon';
import CommentsIcon from '../../../assests/icons/CommentsIcon';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CopyIcon from "../../../assests/icons/CopyIcon";

function TableRequests(props) {

  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState(props.tableContent || []);
  const elementRef = useRef(null);

  useEffect(() => {
    if (isEmpty(searchText)) {
      setSearchedData(props.tableContent);
    }
    else{
        handleSearch(searchText);
    }
  }, [props.tableContent]);

  const onIntersection = useCallback(
    ([entry]) => {
      if (
        entry.isIntersecting &&
        (props?.isHavingMoreData ) &&
        !props?.isLoading &&
        props?.tableContent?.length >= MAX_LIMIT_SHOW_TABLE_DATA
      ) {
        const newCount = props.currentOffset + MAX_LIMIT_SHOW_TABLE_DATA;
        props?.setOffset(newCount);
      }
    },
    [props.isLoading, props.tableContent.length, props.currentOffset, props]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [onIntersection, searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
    if (!isEmpty(text)) {
      const filteredData = props?.tableContent?.filter(
          (data) =>
              data?.config?.customer_details?.channel_name?.toLowerCase().includes(text.toLowerCase()) ||
              data?.type?.toLowerCase().includes(text.toLowerCase())
      );
      setSearchedData(filteredData);
    } else {
      setSearchedData(props.tableContent);
    }
  };

  const handleCopyToClipboard = (job_id) => () => {
    navigator.clipboard
      .writeText(job_id)
      .catch((err) => {
        props.setNotificationMessage(COPY_TO_CLIPBOARD_FAILED_NOTIFICATION_TYPE);
        props.setAlertType(WARNING_NOTIFICATION_TYPE);
      });
      props.open(true);
      props.message(COPY_TO_CLIPBOARD_SUCCESS_NOTIFICATION_TYPE);
      props.type(SUCCESS_TYPE);
  };

  return (
    <React.Fragment>
      <FilterContent
        className="filter-content"
        count={searchedData.length}
        totalCount={props.totalCount}
        titleText="Requests"
        onSearch={handleSearch}
        searchText={searchText}
        showFilter={props.showFilter}
        filterOpen={props.filterOpen}
        isLoading={props.isLoading}
      />
      <Grid item xs={12} className="requests-table-frame">
        <Table className="off-white-table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header' colSpan={2}>
                Channel Name
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Channel Type
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Platform
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Request Type
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Request ID
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Submitted By
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Approved By
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Date & Time
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='table-cell-header'>
                Status
              </TableCell>
              <TableCell component="th" scope="row" size="medium" className='last-table-cell-heading' rowSpan={2}>

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="request-table-body">
            {searchedData?.length > 0 && (
              <React.Fragment>
                {searchedData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {data?.logo_url ? (
                          <div className="req-logo-div white-bg">
                            <img src={data.logo_url} alt="logo" />
                          </div>
                      ) : (
                          <div className="req-logo-div white-bg">
                            <Typography className="img-typography">
                              {getImageName(data?.config?.customer_details?.channel_name)}
                            </Typography>
                          </div>
                      )}
                    </TableCell>
                    <TableCell className='table-cell action-cell' style={{color: '#7FB8F0', cursor: 'pointer'}} onClick={
                      () => props.onClickRequest(data?.request_job_id)
                    }>
                      {data?.config?.customer_details?.channel_name}
                    </TableCell>
                    <TableCell className='table-cell'>{data?.type}</TableCell>
                    <TableCell className='table-cell'>{data?.config?.platform_name}</TableCell>
                    <TableCell className='table-cell'>{data?.meta?.creation_type}</TableCell>
                    <TableCell className='table-cell'>
                      <div className="request-id-cell">
                        <div style={{paddingTop:'2px', cursor: "pointer"}} onClick={handleCopyToClipboard(data?.request_job_id)} ><CopyIcon />
                        </div>
                        <div>{data?.request_job_id?.slice(0, 14) + '...'}</div>
                      </div>
                    </TableCell>
                    <TableCell className='table-cell'>{data?.requested_user}</TableCell>
                    <TableCell className='table-cell'>{data?.approved_by || 'N/A'}</TableCell>
                    <TableCell className='table-cell'>{getEpochData(data?.created_at)}</TableCell>
                    <TableCell className='table-cell'>
                      {data?.status === REQUEST_STATUS_SUBMIT ? (
                        <div className="status-div">
                          <CheckCircleOutlineIcon className="error-success" sx={{ marginRight: '3px' }} />
                          <Typography className="log-status-success">Approved</Typography>
                        </div>
                      ) : data.status === REQUEST_STATUS_DRAFT ? (
                        <div className="status-div">
                          <EditIcon className="error-pending" />
                          <Typography>Draft</Typography>
                        </div>
                      ) : (
                          <div className="status-div">
                            <ProcessingIcon className="error-pending"/>
                            <Typography>Approval pending</Typography>
                          </div>
                      )}
                    </TableCell>
                    <TableCell className='last-table-cell'>
                      <div className='actions-div'>
                        <div className="comment-icon"  >
                          <CommentsIcon />
                          <Typography>{data?.comments?.length}</Typography>
                        </div>
                        <div className="more-icon">
                          <Tooltip title={"More actions in next roll-out"}>
                            <MoreVertOutlinedIcon />
                          </Tooltip>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {props.isHavingMoreData && !searchText && (
                    <TableRow ref={elementRef}>
                      <TableCell colSpan={12}></TableCell>
                    </TableRow>
                )}
              </React.Fragment>
            )}
            {props.isLoading &&  (
                <TableRow>
                  <TableCell colSpan={12}>
                    <LoadingScreen rowsNumber={15} />
                  </TableCell>
                </TableRow>
            )}
            {!props?.isLoading && searchedData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <EmptyScreen message="No data found" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </React.Fragment>
  );
}

TableRequests.propTypes = {
  tableContent: PropTypes.arrayOf(
    PropTypes.shape({
      config: PropTypes.shape({
        channel_name: PropTypes.string,
        platform_name: PropTypes.string,
      }),
      type: PropTypes.string,
      meta: PropTypes.shape({
        creation_type: PropTypes.string,
      }),
      request_job_id: PropTypes.string,
      requested_user: PropTypes.string,
      created_at: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  showFilter: PropTypes.bool,
  filterOpen: PropTypes.func,
  filterCount: PropTypes.number,
  isLoading: PropTypes.bool,
  isHavingMoreData: PropTypes.bool,
  setOffset: PropTypes.func,
  currentOffset: PropTypes.number,
  onClickRequest: PropTypes.func,
};

export default TableRequests;
