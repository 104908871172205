export const RefreshIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M6.43775 2.59423C6.15051 2.42398 6.05874 2.06489 6.2334 1.7874C6.40979 1.50716 6.79112 1.41627 7.08513 1.5844L8.52593 2.40832C8.67493 2.49353 8.77965 2.63436 8.81464 2.7966C8.84962 2.95884 8.81167 3.12763 8.71 3.26195L7.82226 4.43482C7.62166 4.69985 7.23363 4.7597 6.95558 4.56849C6.67752 4.37728 6.61474 4.00743 6.81534 3.7424L6.83529 3.71604C6.0022 3.95319 5.23629 4.38518 4.61191 4.98164C3.95784 5.60508 3.51194 6.39984 3.33149 7.26454C3.15103 8.12926 3.24364 9.02556 3.59761 9.8401C3.87205 10.4716 4.29404 11.032 4.82826 11.4803C5.08562 11.6962 5.16567 12.0609 4.97518 12.3327C4.7847 12.6044 4.39667 12.6798 4.1331 12.4707C3.39764 11.8875 2.81898 11.1409 2.4505 10.293C2.00256 9.2622 1.88536 8.12794 2.11373 7.03366C2.34207 5.93948 2.90566 4.9344 3.73323 4.14549C4.49406 3.41877 5.42493 2.88969 6.43775 2.59423Z"
                fill="#E7E7E7"/>
            <path
                d="M9.56225 13.4058C9.84949 13.576 9.94126 13.9351 9.7666 14.2126C9.59021 14.4928 9.20888 14.5837 8.91487 14.4156L7.47407 13.5917C7.32507 13.5065 7.22035 13.3656 7.18536 13.2034C7.15038 13.0412 7.18833 12.8724 7.29 12.7381L8.17774 11.5652C8.37834 11.3002 8.76637 11.2403 9.04442 11.4315C9.32248 11.6227 9.38526 11.9926 9.18466 12.2576L9.16471 12.284C9.9978 12.0468 10.7637 11.6148 11.3881 11.0184C12.0422 10.3949 12.4881 9.60016 12.6685 8.73546C12.849 7.87074 12.7564 6.97444 12.4024 6.1599C12.128 5.52837 11.706 4.96797 11.1717 4.51975C10.9144 4.30381 10.8343 3.93906 11.0248 3.66733C11.2153 3.3956 11.6033 3.32024 11.8669 3.52926C12.6024 4.11249 13.181 4.85908 13.5495 5.70701C13.9974 6.7378 14.1146 7.87206 13.8863 8.96634C13.6579 10.0605 13.0943 11.0656 12.2668 11.8545C11.5059 12.5812 10.5751 13.1103 9.56225 13.4058Z"
                fill="#E7E7E7"/>
        </svg>
    )
}