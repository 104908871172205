import React, { useEffect } from "react"
import { Button, Grid, Typography } from "@mui/material";
import "./index.css"
import {ERROR_FOUND, INITIAL_PAGE} from "../../../Constant";
import { useRouteError } from "react-router-dom";
import PageNotFound from "../../../assests/icons/pageNotFoundIcon";
import ServiceFailure from "../../../assests/icons/serviceFailure";

function Error(props) {
    const error = useRouteError()

    useEffect(() => {
        if(error.status !== ERROR_FOUND){
            console.log(error)
        }
    }, [])

    const onClickButton = () => {
        window.location.href = INITIAL_PAGE
    }

    return (
        <Grid container className="error-div">
            {
                error.status === ERROR_FOUND ? (
                    <React.Fragment>
                        <Grid item xs={12} className="error-header-div">
                            <PageNotFound />
                        </Grid>
                        <Grid item xs={12} className="error-description-div">
                            <Typography className="white-text" variant="h5">
                                Sorry, the page couldn’t be found!
                            </Typography>
                            <Typography className="white-text" variant="h6" sx={{fontSize: "14px", color: "#999999"}}>
                                It looks like the page you are trying to access has either been deleted or does not exist.<br/>
                                Click on the button below to go back to the homepage.
                            </Typography>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12} className="error-header-div">
                            <Typography className="white-text" variant="h2">
                                <ServiceFailure />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="error-description-div">
                            <Typography className="white-text" variant="h5">
                                Service  unavailable
                            </Typography>
                            <Typography className="white-text" variant="h6" sx={{fontSize: "14px", color: "#999999"}}>
                                The service is temporarily unavailable, please try again later
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )
            }

            <Grid item xs={12} className="error-button-div">
                <Button variant="contained" className="filled-submit-button" onClick={() => onClickButton()}> Go to Homepage </Button>
            </Grid>

        </Grid>
    )
}

export default Error