
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getSupplyPartnerName = async () => {
    try {
        const query = `
                    query MyQuery {
                        supply_partner {
                            supply_partner_name
                          }
                     } `;
        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        return response.data.data.supply_partner;
    } catch (error) {
        console.error("Error fetching Supply Partner Name:", error);
        return null;
    }
};

export default getSupplyPartnerName;
