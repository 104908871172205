import React, { useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Chip, Popover, Typography } from "@mui/material";
import './index.css';
import { DEVICE_DISTRIBUTION_MAP, INVENTORY_RELATIONS_SHIP_MAP, NA } from "../../../Constant";

function TableAppSupplyDetails(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverData, setPopoverData] = useState([]);
    const show_value = 1;

    // Handle popover open
    const handlePopoverOpen = (event, moreLabels) => {
        setAnchorEl(event.currentTarget);
        setPopoverData(moreLabels);
    };

    // Handle popover close
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverData([]);
    };

    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Grid item xs={12} className="app-supply-details-table-frame">
                <Table className="off-white-table whitespace" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" size="medium">
                                INV. RELATIONSHIP
                            </TableCell>
                            <TableCell component="th" scope="row" size="medium">
                                APP NAME IN APP STORE
                            </TableCell>
                            <TableCell component="th" scope="row" size="medium">
                                CHANNEL NAME/STREAM
                            </TableCell>
                            <TableCell component="th" scope="row" size="medium">
                                DEVICE/PLATFORM
                            </TableCell>
                            <TableCell component="th" scope="row" size="medium">
                                SUPPLY LABEL
                            </TableCell>
                            <TableCell component="th" scope="row" size="medium">
                                APP BUNDLE ID
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="request-table-body">
                        {props?.tableData?.map((data, index) => (
                            <TableRow key={index}>
                                <TableCell className='table-cell'>{INVENTORY_RELATIONS_SHIP_MAP[data.inventory_relationship] || NA}</TableCell>
                                <TableCell className='table-cell'>{data.app_name_in_app_store || NA}</TableCell>
                                <TableCell className='table-cell'>{data.channel_name_stream || NA}</TableCell>
                                <TableCell className='table-cell'>{DEVICE_DISTRIBUTION_MAP[data.device_type] || NA}</TableCell>
                                <TableCell className='table-cell'>
                                    <div className="supply-label-chips">
                                        {Array.isArray(data.supply_label_id) && data.supply_label_id.length > 0 ? (
                                            <>
                                                {data.supply_label_id.slice(0, show_value).map((label, idx) => (
                                                    <Chip
                                                        key={idx}
                                                        label={label}
                                                        className="multiselect-chip"
                                                        size="small"
                                                    />
                                                ))}
                                                {data.supply_label_id.length > show_value && (
                                                    <Chip
                                                        label={`+${data.supply_label_id.length - show_value} more`}
                                                        onClick={(event) => handlePopoverOpen(event, data.supply_label_id.slice(show_value))}
                                                        className="multiselect-more-chip"
                                                        size="small"
                                                        clickable
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            NA
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell className='table-cell'>{data.app_bundle_id || NA}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>

            {/* Popover to display more supply labels */}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="popover-content">
                    {popoverData.map((label, idx) => (
                        <Typography key={idx} style={{ padding: '5px 10px' }}>
                            {label}
                        </Typography>
                    ))}
                </div>
            </Popover>
        </React.Fragment>
    );
}

export default TableAppSupplyDetails;