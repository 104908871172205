import React from "react"
import { Grid, Button, Typography, Card } from "@mui/material";
import {
    AMG_LOGO,
    AMG_BRANDING_TEXT,
} from "../../Constant";
import "./index.css"
import PropTypes from "prop-types";
function Login(props){
    return (
        <Grid container className="login-component">
            <Grid item xs={12} className="amg-branding">
                <img src={AMG_LOGO} alt="amagi" className="amg-branding-logo" />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={8} className="amg-branding-div">
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography className="amg-branding-text">
                                    {AMG_BRANDING_TEXT}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Card variant="outlined" className="amg-card-login">
                            <Grid container>
                                <Grid item xs={12}>
                                    <img src={AMG_LOGO} alt="amagi" className="amg-login-branding" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className="text-align-center">
                                            <Button variant="contained" className="filled-submit-button" size="medium" fullWidth onClick={() => props.onClickLoginButton()}> Sign In using SSO </Button>
                                            {/*{isDataValidating && <LoopIcon className="loading-icon" />}*/}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

Login.propTypes = {
    onClickLoginButton: PropTypes.func
}

export default Login
