import React from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

function TableGeoDistribution (props) {
    return (
        <React.Fragment>
            <React.Fragment>
                <Grid item xs={10} className="app-supply-details-table-frame">
                    <Table className="off-white-table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" size="medium" style={{whiteSpace: "nowrap"}}>
                                    REGIONS
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium" style={{whiteSpace: "nowrap"}}>
                                    PERCENTAGE (%)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="request-table-body">
                            <React.Fragment>
                                {props?.GeoData?.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='table-cell'> {data.region_name} </TableCell>
                                        <TableCell className='table-cell'> {data?.percentage ? `${data?.percentage}%` :""}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        </TableBody>
                    </Table>
                </Grid>
            </React.Fragment>
        </React.Fragment>
    )
}

export default TableGeoDistribution;