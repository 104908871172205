import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Checkbox,
    Typography, Grid, Button, CircularProgress,
} from "@mui/material";
import {REPORT_DOWNLOAD_URI} from "../../../Constant";

import Pagination from '@mui/material/Pagination';
import "./index.css"
import PendingIcon from "../../../assests/icons/pendingIcon";
import DownloadIcon from "../../../assests/icons/downloadIcon";
import DownIcon from "../../../assests/icons/downIcon";
import SuccessIcon from "../../../assests/icons/successIcon";
import { downloadReportFile } from "../../../api/downloadReportFile";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";
import FailedAlert from "../../../assests/icons/FailedAlert";
import {ITEMS_PER_PAGE, SEVERITY_TYPE, SUCCESS_TYPE} from "../../../Constant";
import NoReportGeneratedIcon from "../../../assests/icons/noreportIcon";
import {reduceString} from "../../../utils/helper";
import Notification from "../../Notification";


function TableReports(props) {
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [displayData, setDisplayData] = useState([])
    const [downloadingButtons, setDownloadingButtons] = useState(false);
    const [downloadUrlOfFileDownloading,setDownloadUrlOfFileDownloading] = useState("")

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    useEffect(() => {
        if (props?.tableContent?.length > 0) {
            const page_count = Math.ceil(props.count / ITEMS_PER_PAGE);
            const display_data = props.tableContent.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
            setDisplayData(display_data);
            setPageCount(page_count);
        }
    }, [props?.tableContent, page]);



    const handleDownloadReportFile =  async (fileName, downloadUrl) => {

            try {
                // Create a temporary link element
                const link = document.createElement('a');
                link.href = `${REPORT_DOWNLOAD_URI}/download?file_name=${downloadUrl}`;
                link.setAttribute('download', `${fileName}.xlsx`);

                // Simulate click on the link to initiate download
                document.body.appendChild(link);
                link.click();

                // Cleanup: remove the temporary link
                document.body.removeChild(link);

                setDownloadingButtons(false);
                props.NotificationOpen();
                props.SetMessage("Report Downloaded Successfully!");
                props.SetAlert(SUCCESS_TYPE);
            } catch (error) {
                console.error("Error downloading report:", error);
                setDownloadingButtons(false);
                props.NotificationOpen();
                props.SetMessage("Please Try Again!");
                props.SetAlert(SEVERITY_TYPE);

        };

        // setDownloadingButtons(true);
        // setDownloadUrlOfFileDownloading(downloadUrl)
        // try {
        //     const response = await downloadReportFile(fileName, downloadUrl);
        //     setDownloadingButtons(false);
        //     props.NotificationOpen();
        //     props.SetMessage("Report Downloaded Successfully!");
        //     props.SetAlert(SUCCESS_TYPE);
        //     return;
        //
        // } catch (error) {
        //     console.error("Error downloading report:", error);
        //     props.NotificationOpen();
        //     props.SetMessage("Please Try Again!");
        //     props.SetAlert(SEVERITY_TYPE);
        //     return;
        // }
    }





    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Table className="report-table" stickyHeader>

                    <TableHead>
                        <TableCell>REPORT NAME </TableCell>
                        <TableCell>REPORT TYPE</TableCell>
                        <TableCell>CREATED ON</TableCell>
                        <TableCell width="15%"></TableCell>
                        <TableCell width="3%"></TableCell>
                    </TableHead>
                    <TableBody>
                        {props?.isLoading && (
                            <LoadingScreen rowsNumber={5} />
                        )}
                        {!props.isLoading && displayData.length>0 ? (displayData?.map((data, index) => {
                            return (
                                <TableRow key={`${index}-${data?.created_at}`} sx={{ height: "56px" }}>
                                    <TableCell>
                                    {reduceString(data?.name)}
                                    </TableCell>
                                    <TableCell>
                                        {data?.report_type}
                                    </TableCell>
                                    <TableCell>
                                        {data?.created_at}
                                    </TableCell>
                                    <TableCell>
                                        <div >


                                            {data?.status === "in_progress" && (


                                                <div className="status-div">
                                                    <PendingIcon />
                                                    <span>In progress</span>
                                                </div>
                                            )}
                                            {data?.status === "success" && (
                                                <div className="status-div">
                                                    <SuccessIcon />
                                                    <span>Success</span>

                                                </div>
                                            )}
                                            {data?.status === "failed" && (
                                                <div className="status-div">
                                                    <FailedAlert />
                                                    <span className="failed-span">Failed</span>
                                                </div>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell>



                                        {data.status === "success" ? (

                                            <React.Fragment>
                                                <Button
                                                    className="download-button-div"
                                                    disabled={downloadingButtons}
                                                    onClick={() => handleDownloadReportFile(data.name, data.download_url)}
                                                >
                                                    {downloadingButtons && data.download_url === downloadUrlOfFileDownloading?(<CircularProgress className="dowload-progress"/>):(<DownloadIcon />)}

                                                </Button>
                                            </React.Fragment>

                                        ):("NA")}


                                    </TableCell>
                                </TableRow>
                            )
                        })):( !props.isLoading && (


                                <TableCell colSpan={5}>


                                    <div className="no-report-div">
                                        <NoReportGeneratedIcon/>
                                        <div className="no-report-span-div">
                                        <span className="no-report-span">
                                            No reports generated  yet!
                                        </span>
                                            <span className="no-report-sub-span">
                                            Create a new report to save and generate one
                                        </span>

                                        </div>

                                    </div>


                                </TableCell>

                            )
                        )}

                    </TableBody>
                </Table>
                <Grid item xs={12} className="pagination-grid">
                    <Pagination
                        className="pagination-button"
                        count={pageCount}
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={handleChangePage}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default TableReports;
