import React from 'react';
import {Button, FormControl, Grid, Input, InputAdornment, MenuItem, Select, TextField} from "@mui/material";

import "./index.css"
import CrossIcon from "../../assests/icons/crossIcon";
import AddIcon from "../../assests/icons/addIcon";
import PropTypes from "prop-types";
import {isEmpty} from "../../utils/helper";
import SimpleSelectWithGroup from "../SimpleSelectWithGroup";
import SimpleSelect from "../SimpleSelect";
import MultiSelectWithSearch from "../MultiSelectWithSearch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "../../assests/icons/deleteIcon";
import SimpleInput from "../SimpleInput";

function ReportSimpleInput(props) {

    const clearText = () => {
        props.changeReportName(""); // Clear the report name by setting it to an empty string
    };

    const handleAddInput = () => {
        props.addNewFilter()
    }

    return(
        <Grid container className="report-simple-input-container">
            {props.showReportName && (
                <Grid item xs={12} className="report-simple-input-form-item">
                    <Grid container className="report-simple-input-form-item-container">
                        <Grid item xs={1} className="report-simple-input-form-label">
                            Report Name <span className="required-symbol"> * </span>
                        </Grid>
                        <Grid item xs={11} className="report-simple-input-form-field">
                            <TextField
                                className="text-field-standard report-name-field"
                                variant="standard"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CrossIcon clearData={clearText} />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ maxLength: 32 }}
                                placeholder="Enter report name"
                                onChange={(e) => props.changeReportName(e?.target?.value)}
                                value={props.reportName}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} className="report-simple-input-form-item">
                <Grid container className="report-simple-input-form-item-container">
                    <Grid item xs={1} className="report-simple-input-form-label">
                        Report Criteria
                    </Grid>
                    <Grid item xs={11} className="report-simple-input-form-field">
                        {!isEmpty(props.formFields) && props.formFields.map((field, index) => {
                            return(
                                <Grid container className="report-simple-select-criteria-container" key={index}>
                                    <Grid item xs={2}>
                                        <SimpleSelectWithGroup
                                            value={field.value}
                                            options={field.fields}
                                            onChange={props.onChangeMainCriteria}
                                            index={index}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <SimpleSelect
                                            value={field.operator_value}
                                            options={field?.operator}
                                            index={index}
                                            onChange={props.onChangeOperator}
                                            type={field.type}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* TODO Add Date Support */}
                                        {field.type === "string" && <MultiSelectWithSearch
                                            onChange={props.onChangeValues}
                                            value={field.options_value}
                                            index={index}
                                            options={field.options}
                                            type={field.type}
                                        />}
                                        {field.type === "boolean" && <MultiSelectWithSearch
                                            onChange={props.onChangeValues}
                                            value={field.options_value}
                                            index={index}
                                            options={field.options}
                                            type={field.type}
                                        />}
                                        {field.type === "integer" && <SimpleInput
                                            onChange={props.onChangeValues}
                                            value={field.options_value}
                                            index={index}
                                            type={field.type}
                                        />}
                                        <IconButton
                                            onClick={() => props.onDeleteCriteria(field.value, index)}
                                            className="report-criteria-delete-icon"
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Button
                            className="add-criteria-button report-add-button"
                            onClick={() => {
                                handleAddInput();
                            }}
                            size="small"
                        >
                            <AddIcon />
                            <span className="add-criteria-span">Add Criteria</span>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="report-simple-input-form-item report-action-items">
                <Grid container clasName="report-actions-item-container">
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3} className="report-actions-item">
                        <Button
                            size="small"
                            variant="outlined"
                            className="report-action-button"
                            onClick={() => props.onCancel()}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className="report-action-button"
                            onClick={() => props.onUpdatePreview()}
                        >
                            Update preview
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            className="report-action-button save-generate"
                            onClick={() => props.saveGenerate()}
                        >
                            Save & generate
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ReportSimpleInput.propTypes = {
    "formFields": PropTypes.arrayOf(PropTypes.shape({
        "value": PropTypes.string.isRequired,
        "operator_value": PropTypes.string.isRequired,
        "operator": PropTypes.arrayOf(PropTypes.shape({
            "label": PropTypes.string.isRequired,
            "value": PropTypes.string.isRequired,
        })),
        "options": PropTypes.arrayOf(PropTypes.shape({
            "label": PropTypes.string.isRequired,
            "value": PropTypes.string.isRequired,
        })),
        "fields": PropTypes.arrayOf(PropTypes.shape({
            "key": PropTypes.shape({
                "label": PropTypes.string.isRequired,
                "operator": PropTypes.arrayOf(
                    PropTypes.shape({
                        "label": PropTypes.string.isRequired,
                        "value": PropTypes.string.isRequired,
                    })
                ),
                "value": PropTypes.string.isRequired,
                "type": PropTypes.string.isRequired
            })
        }))
    })),
    "changeReportName": PropTypes.func.isRequired,
    "reportName": PropTypes.string.isRequired,
    "addNewFilter": PropTypes.func.isRequired,
    "onChangeMainCriteria": PropTypes.func.isRequired,
    "onChangeOperator": PropTypes.func.isRequired,
    "onChangeValues": PropTypes.func.isRequired,
    "showReportName": PropTypes.bool.isRequired,
    "onCancel": PropTypes.func.isRequired,
    "onUpdatePreview": PropTypes.func.isRequired,
    "saveGenerate": PropTypes.func.isRequired,
    "onDeleteCriteria": PropTypes.func.isRequired,
}

export default ReportSimpleInput;