import React from "react";
import PropTypes from "prop-types";
import {NA} from "../../../Constant";

function RequestsBreadCrumbs (props) {
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/requests"
                )}
                className="amg-bread-crumbs-links"
            >
                Requests &nbsp;
            </span>/ &nbsp;<span>{props?.ChannelName ? props.ChannelName : NA} </span>
        </React.Fragment>
    )
}

RequestsBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
    platformName: PropTypes.string,
    channelId: PropTypes.string
}

export default RequestsBreadCrumbs