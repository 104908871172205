import React from "react"
import "./index.css";
import {Avatar} from "@mui/material";

function CircleBox(props){
    return(
        <div className="circle-user-info">
            {props.nameIntials}
        </div>
    )
}

export default CircleBox