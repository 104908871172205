
import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import getAllSupplyPartner from "./getAllSupplyPartner";
import {adsHeader} from "../utils/helper";
const getAllAuditLogJob = async (limit, offset, data) => {
    try {
        const query = `
            query MyQuery($limit: Int!, $offset: Int!, $data: GetJobList!) {
                getAuditLogJob(limit: $limit, offset: $offset, data: $data) {
                    action
                    channel_ids
                    created_at
                    description
                    entity_type
                    job_id
                    platform_id
                    status
                    summary
                    user_id
                }
            }
        `;
        const headers = adsHeader()

        const response = await axios.post(BFF_LAYER_URI, {
            query,
            variables: { limit, offset, data }
        }, { headers: headers });

        if (response?.data?.data?.getAuditLogJob) {
            return response.data.data.getAuditLogJob;
        }

        return [];
    } catch (error) {
        console.error("Error fetching while getAuditLogJob:", error);
        return null;
    }
};
export default getAllAuditLogJob;