import "./index.css";
import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import AdsSimpleSelect from "../AdsSimpleSelect";
import AdsSimpleTextInput from "../AdsSimpleTextInput";
import {
    MULTI_SELECT_TYPE,
    SIMPLE_SELECT_TYPE,
    TYPE_INPUT,
    TYPE_RADIO,
    WARNING_NOTIFICATION_TYPE
} from "../../../Constant";
import HorizontalRadioButtons from "../../../Common/HorizontalRadioButtons";
import VectorFullWidthIcon from "../../../assests/icons/vectorFullWidthIcon";
import DeviceDistribution from "../DeviceDistribution";
import GeoDistribution from "../GeoDistribution";
import AdsAppSupplyDetails from "../AdsAppSupplyDetails";
import { RequestContext } from "../../../Context/RequestContext";
import PropTypes from "prop-types";
import SimpleTelInput from "../../../Common/SimpleTelInput";
import DropInputOrLink from "../../../Common/DropInputOrLink";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";
import AdsMultiSelectWithSearch from "../AdsMultiSelectWithSearch";

const OtherDetails = (props) => {
    const { otherDetails, updateOtherDetails, onboardingFileData } = useContext(RequestContext);
    const [activeSection, setActiveSection] = useState("");
    const [otherFormLabelMap, setOtherFormLabelMap] = useState({});
    const [fileLoading, setFileLoading] = useState(false);

    const sections = props.formConfig?.filter(item => item.type === "heading").map(item => item.label);

    const handleNavClick = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            const offsetPosition = element.offsetTop - 75;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    const handleScroll = () => {
        const sectionOffsets = props.formConfig?.filter(item => item.type === "heading").map(item => {
            const element = document.getElementById(item.sectionId);
            return {
                sectionId: item.sectionId,
                offsetTop: element ? element.offsetTop - 75 : 0,
                height: element ? element.offsetHeight : 0
            };
        });

        const scrollPosition = window.scrollY;
        let currentSection = null;

        sectionOffsets.forEach((section, index) => {
            const nextSection = sectionOffsets[index + 1];
            if (
                scrollPosition >= section.offsetTop - 10 &&
                (!nextSection || scrollPosition < nextSection.offsetTop - 10)
            ) {
                currentSection = section.sectionId;
            }
        });

        if (currentSection && currentSection !== activeSection) {
            setActiveSection(currentSection);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [activeSection, props.formConfig]);

    const onClickChange = (value, index, type, key, label) => {
        const updatedDetails = { ...otherDetails };
        const updatedLabelMap = { ...otherFormLabelMap };

        if (type === MULTI_SELECT_TYPE) {
            if (updatedDetails[key]?.indexOf(value) >= 0) {
                const getIndex = updatedDetails[key].indexOf(value);
                updatedDetails[key].splice(getIndex, 1);
                updatedLabelMap[key].splice(getIndex, 1);
            } else if (updatedDetails[key]?.indexOf(value) < 0 && updatedDetails[key]?.length > 0) {
                updatedDetails[key].push(value);
                updatedLabelMap[key].push(label);
            } else {
                updatedDetails[key] = [value];
                updatedLabelMap[key] = [label];
            }
        } else if (type === SIMPLE_SELECT_TYPE) {
            if(value === "basic_sensitivity_category_blocking" && key === "ad_quality_requirements"){
                useWebStorageHelper.removeItem("ad_domain_blocklist_file_url");
            }
            updatedDetails[key] = value;
            updatedLabelMap[key] = label;
        } else if (type === TYPE_RADIO) {
            updatedDetails[key] = value === "true" ? true : value === "false" ? false : value;
        } else if(type === TYPE_INPUT){
            updatedDetails[key] = parseInt(value);
        } else if(type === "file_input_or_link" && key === "link_to_sale_collateral"){
            useWebStorageHelper.removeItem("link_to_sale_collateral");
            updatedDetails["sales_collateral_file_url"] = "";
            updatedDetails[key] = value;
            updateOtherDetails(updatedDetails);
        }
        else {
            updatedDetails[key] = value;
        }
        updateOtherDetails(updatedDetails);
        setOtherFormLabelMap(updatedLabelMap);
    };

    const onInnerHTMLChange = (value = [], key = "") => {
        const updatedDetails = { ...otherDetails };
        updatedDetails[key] = value;
        updateOtherDetails(updatedDetails);
    };

    useEffect(() => {
        const fillData = (fileData) => {
            const updatedDetails = { ...otherDetails };
            const updatedLabelMap = { ...otherFormLabelMap };

            fileData?.forEach(row => {
                const [label, value] = row;
                const configItem = props.formConfig?.find(item => item.label?.toLowerCase() === label?.toLowerCase());
                if (configItem && value !== undefined) {
                    updatedDetails[configItem.key] = value;
                    updatedLabelMap[configItem.key] = label;
                }
            });

            updateOtherDetails(updatedDetails);
            setOtherFormLabelMap(updatedLabelMap);
        };

        if (onboardingFileData.config?.length > 0) {
            fillData(onboardingFileData.config);
        }

    }, [onboardingFileData.config]);

    useEffect(() => {
        const labelMap = {};
        // Iterate through the formConfig to create the label map
        props.formConfig.forEach((field) => {
            if (field.type === SIMPLE_SELECT_TYPE && otherDetails[field.key]) {
                const selectedOption = field.options.find(
                    (option) => option.value === otherDetails[field.key]
                );
                labelMap[field.key] = selectedOption ? selectedOption.label : "";
            }
        });
        setOtherFormLabelMap(labelMap);
    }, [otherDetails]);

    const handleFileChange = async (file, key) => {

        setFileLoading(true);
        const updatedDetails = { ...otherDetails };

        if (file) {
            const status = await useWebStorageHelper.setFileItem(file, key);
            if (status) {
                setFileLoading(false);
                if(key === "link_to_sale_collateral"){
                    updatedDetails["sales_collateral_file_url"] = file.name;
                    updateOtherDetails(updatedDetails);
                    updatedDetails[key] = "";
                    updateOtherDetails(updatedDetails);
                }
                else{
                    updatedDetails[key] = file.name;
                    updateOtherDetails(updatedDetails);
                }
            } else {
                props.showNotification(WARNING_NOTIFICATION_TYPE, "Not able to upload file");
                setFileLoading(false);
            }
        } else {
            updatedDetails[key] = "";
            updateOtherDetails(updatedDetails);
            if(key === "link_to_sale_collateral"){
                updatedDetails["sales_collateral_file_url"] = "";
                updateOtherDetails(updatedDetails);
            }
            await useWebStorageHelper.removeItem(key);
            props.showNotification(WARNING_NOTIFICATION_TYPE, "File Deleted");
            setFileLoading(false);
        }
    };

    const onClickMultiSelect = (value, index, type, key, selectAll=false, deleteAll=false ) => {
        const updatedDetails = { ...otherDetails };

        if (selectAll) {
            updatedDetails[key] = value.map(item => item.value);
        }
        else if(deleteAll){
            updatedDetails[key] = [];
        }
        else {
            if (updatedDetails[key]?.indexOf(value) >= 0) {
                const getIndex = updatedDetails[key].indexOf(value);
                updatedDetails[key].splice(getIndex, 1);
            } else if (updatedDetails[key]?.indexOf(value) < 0 && updatedDetails[key]?.length > 0) {
                updatedDetails[key].push(value);
            } else {
                updatedDetails[key] = [value];
            }
        }
        updateOtherDetails(updatedDetails);
    }

    return (
        <Grid container className="other-details-grid">
            <Grid item xs={10} className="left-side-form">
                {props.formConfig.map((field, index) => {
                    const shouldRenderField = !field.dependant_key || otherDetails[field.dependant_key] === true || otherDetails[field.dependant_key] === "ad_domain_blocklist";
                    if (shouldRenderField) {
                        return (
                            <React.Fragment key={index}>
                                {field.type === "heading" ? (
                                    <React.Fragment>
                                        <div id={field.sectionId} className="form-section">
                                            {field.label}
                                        </div>
                                        {index < props.formConfig.length - 1}
                                    </React.Fragment>
                                ) : field.type === "vector" ? (
                                    <VectorFullWidthIcon width="100%" />
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12} md={1} className="request-form-label-grid">
                                            {field.label} <span className="necessary-label-asterisk">{field?.necessary ? "*" : ""}</span>
                                        </Grid>
                                        <Grid item xs={12} md={10} className="request-form-value-grid">
                                            {field.type === "text" ? (
                                                field.value
                                            ) : field.type === "select" ? (
                                                <AdsSimpleSelect
                                                    type={field.type}
                                                    index={index}
                                                    onChange={onClickChange}
                                                    options={field.options}
                                                    label={field.label}
                                                    placeholder={field.placeholder}
                                                    formKey={field.key}
                                                    fieldValue={otherFormLabelMap[field.key] || ""}
                                                    textWidth={field.width}
                                                    value={otherDetails[field.key] || ""}
                                                    leftMargin={field.leftMargin || "0"}
                                                />
                                            ) : field.type === "input" ? (
                                                <SimpleTelInput
                                                    type={field.type}
                                                    onChange={onClickChange}
                                                    index={index}
                                                    textWidth={field.width}
                                                    placeholder={field.placeholder}
                                                    formKey={field.key}
                                                    postUnit={field.post_unit ? field.post_unit : ""}
                                                    value={otherDetails[field.key] || ""}
                                                    preUnit={field.preUnit ? field.preUnit: ""}
                                                />
                                            ) : field.type === "text_input" ? (
                                                <AdsSimpleTextInput
                                                    type={field.type}
                                                    onChange={onClickChange}
                                                    index={index}
                                                    textWidth={field.width}
                                                    placeholder={field.placeholder}
                                                    formKey={field.key}
                                                    value={otherDetails[field.key] || ""}
                                                    textSize={field.textLimit}
                                                />
                                            ) : field.type === "radio" ? (
                                                <HorizontalRadioButtons
                                                    type={field.type}
                                                    onRadioChange={onClickChange}
                                                    index={index}
                                                    options={field.options}
                                                    formKey={field.key}
                                                    value={`${otherDetails[field.key]}`}
                                                />
                                            ) : field.type === "inner_html_device_table" ? (
                                                <DeviceDistribution
                                                    formKey={field.key}
                                                    onChange={onInnerHTMLChange}
                                                    initialData={otherDetails.device_type}
                                                />
                                            ) : field.type === "inner_html_geo_table" ? (
                                                <GeoDistribution
                                                    formKey={field.key}
                                                    onChange={onInnerHTMLChange}
                                                    initialData={otherDetails.inventory_available_in_geo}
                                                />
                                            ) : field.type === "file_input_or_link" ? (
                                                <DropInputOrLink
                                                    onFileChange={handleFileChange}
                                                    formKey={field.key}
                                                    fileType={field.config.fileType}
                                                    supportedType={field.config.supportedType}
                                                    subMessage={field.config.subMessage}
                                                    fileData={otherDetails[field.key]}
                                                    linkField={field.allowLink}
                                                    type={field.type}
                                                    onChange={onClickChange}
                                                    index={index}
                                                    textWidth={field.width}
                                                    placeholder={field.placeholder}
                                                    value={otherDetails[field.key] || ""}
                                                    textSize={field.textLimit}
                                                    showNotification={props?.showNotification}
                                                />
                                            ) : field.type === "multi-select" ? (
                                                <AdsMultiSelectWithSearch
                                                    label={field.label}
                                                    type={field.type}
                                                    index={index}
                                                    onChange={onClickMultiSelect}
                                                    options={field.options}
                                                    placeholder={field.placeholder}
                                                    formKey={field.key}
                                                    textWidth={field.width}
                                                    value={otherDetails[field.key] || ""}
                                                    allowSelectAll={field.allowSelectAll || false}
                                                    />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                })}
                <AdsAppSupplyDetails supplyLabels={props.supplyLabels} />
            </Grid>
            <Grid item xs={2} className="sticky-nav">
                {sections?.map((section, index) => {
                    return (
                        <div
                            key={index}
                            className={`nav-item ${activeSection === props.formConfig?.find(item => item.label === section)?.sectionId ? "active" : ""}`}
                            onClick={() => handleNavClick(props.formConfig?.find(item => item.label === section)?.sectionId)}
                        >
                            {section}
                        </div>
                    );
                })}
            </Grid>
        </Grid>
    );
};

OtherDetails.propTypes = {
    formConfig: PropTypes.arrayOf(PropTypes.shape({
        "label": PropTypes.string,
        "value": PropTypes.string,
        "type": PropTypes.string,
        "key": PropTypes.string,
        "width": PropTypes.string,
        "placeholder": PropTypes.string,
        "preUnit": PropTypes.string,
        "postUnit": PropTypes.string,
        "necessary": PropTypes.bool,
        "options": PropTypes.arrayOf(PropTypes.shape({
            "label": PropTypes.string,
            "value": PropTypes.string,
        }))
    })),
    showNotification: PropTypes.func.isRequired
};

export default OtherDetails;

