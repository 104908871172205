import React, { useState, useEffect } from "react";
import { Grid, Typography, Tabs, Tab, Skeleton } from "@mui/material";
import TableContentChannels from "../../../Components/Tables/TableContentChannels";
import {
    CREATE_CHANNEL_DETAILS_PATH,
    CREATE_DEMAND_TAGS_PATH,
    TABS_PLATFORM,
    VIEW_CHANNEL_TYPE,
    VIEW_DEMAND_TAG_TYPE,
    VIEW_MACRO_TYPE,
    ONGOING_API_CALL_KEY,
    ALL_DATA_FETCHED,
    SEVERITY_TYPE,
    SUCCESS_TYPE,
    MAX_LIMIT_SHOW_TABLE_DATA,
    NO_DATA_AVAILABLE,
    SELECTED_PLATFORM_KEY,
    DEMAND_TAG_TITLE_HEADER,
    SEARCHED_TEXT_NOT_FOUND,
    WARNING_TYPE,
    SUPPLY_ROUTER_TAG_TITLE_HEADER,
    USER_DATA_KEY,
    TIMEOUT_NAVIGATION_CALL,
    DATA_IN_LOADING_STATE,
    WARNING_NOTIFICATION_TYPE,
    INTERNAL_AMG_ID,
    ADS_PLUS_PLATFORM_ID,
    NON_TS_PLATFORM_TYPE,
    TS_PLATFORM_TYPE

} from "../../../Constant";
import {useParams, useNavigate} from "react-router-dom";
import TableContentDemand from "../../../Components/Tables/TableContentDemand";
import TableContentMacro from "../../../Components/Tables/TableContentMacro";
import PropTypes from "prop-types";
import { getChannelsListing } from "../../../api/getChannelsListing";
import { getPlatformDetails } from "../../../api/getPlatformDetails";
import { getDemandTagListingByPlatformId } from "../../../api/getDemandTagListingByPlatformId";
import { getMacroListByAmgId } from "../../../api/getMacroListByAmgId";
import TemporaryDrawer from "../../../Components/AddMacroSidebar";
import FilterTable from "../../../Components/TableTopFilters/FilterContent";
import { getLocalStorageData, setLocalStorageData } from "../../../utils/localStorageHelper";
import Notification from "../../../Components/Notification";
import FilterTableWithClearAllButton from "../../../Components/TableTopFilters/FilterTableWithClearAllButton";
import PlatformBreadCrumbs from "../../../Components/BreadCrumbs/PlatformBreadCrumbs";
import {getUniqueData, isEmpty} from "../../../utils/helper";
import PlatformHeader from "../../../Components/PlatformHeader";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";

function PlatformDetails(props) {
    const [titleTextHeader, setTitleTextHeader] = useState("");
    const [selectedTabType, setSelectedType] = useState("");
    const [platformDetails, setPlatformDetails] = useState({});
    const [showAddButton, setShowAddButton] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsDemand, setSelectedRowsDemand] = useState([]);
    const [offset, setOffset] = useState(0)
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [platformDetailsData, setPlatformDetailsData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [searchText, setSearchText] = useState("")
    const [alertType, setAlertType] = useState("")
    const [searchedData, setSearchedData] = useState([])
    const [sideBarTitleHeader, setSideBarTitleHeader] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [totalCount,setTotalCount]= useState(0);
    const { platformId } = useParams();
    const navigation = useNavigate();
    const [userData, setUserData] = useState({});
    const [platformType, setPlatformType] = useState("");


    useEffect(() => {
        if(props.type !== selectedTabType){
            setPlatformDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setSelectedRowsDemand([])
            setShowAddButton(false)
            setSearchText("")
            setOffset(0)
            setLoading(true)
        }

        if (props.type !== "") {
            setSelectedType(props.type);
            loadData(props.type, 0);
        }
        useWebStorageHelper.removeOnbFile();
    }, [props?.type])


    const loadData = async (type = "", offset = 0) => {
        const getLocalStorageValue = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
        const user_data = getLocalStorageData(USER_DATA_KEY,true);
        let getLocalStoragePlatformValue = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
        if (!getLocalStorageValue || getLocalStorageValue !== platformId) {
            setLocalStorageData(SELECTED_PLATFORM_KEY, false, platformId)
        }
        getLocalStoragePlatformValue = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
        setUserData(user_data)
        let contentData = []
        let userEmail = user_data?.email || ""
        setLoading(true)
        setHasMoreData(false)
        setUserEmail(userEmail)

        if(getLocalStoragePlatformValue === ADS_PLUS_PLATFORM_ID )
            setPlatformType(NON_TS_PLATFORM_TYPE )
        else setPlatformType(TS_PLATFORM_TYPE);



        const getPlatformResponseData = await getPlatformDetails(platformId, userEmail);
        if (!isEmpty(getPlatformResponseData)) {
            setPlatformDetails(getPlatformResponseData)
        }

        if (type === VIEW_CHANNEL_TYPE) {
            const getChannelsData = await getChannelsListing(
                platformId,
                MAX_LIMIT_SHOW_TABLE_DATA,
                offset,
                userEmail
            );
            contentData = getChannelsData?.data || []
            setTotalCount(getChannelsData?.total_count)
            setTitleTextHeader("Channels");

            setSideBarTitleHeader(SUPPLY_ROUTER_TAG_TITLE_HEADER)
        }

        if (type === VIEW_DEMAND_TAG_TYPE) {
            let content = await getDemandTagListingByPlatformId(
                platformId,
                MAX_LIMIT_SHOW_TABLE_DATA,
                offset,
                userEmail
            );
            contentData = content?.data || [];
            setTitleTextHeader("Demand Tags");
            setSideBarTitleHeader(DEMAND_TAG_TITLE_HEADER)
        }

        if (type === VIEW_MACRO_TYPE) {
            let getMacrosData = await getMacroListByAmgId(platformId, userEmail);
            contentData = getUniqueData(getMacrosData)
            setTitleTextHeader("Macros");
        }

        if (contentData && contentData.length > 0) {
            const getStateData = offset > 0 ? Object.assign([], platformDetailsData) : []
            getStateData.push(...contentData)
            setPlatformDetailsData(getStateData);
            setSearchedData(getStateData)
            setHasMoreData(true)
            setLoading(false)
        } else if (contentData && contentData.length === 0) {
            setLoading(false)
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(ALL_DATA_FETCHED)
            setAlertType(SUCCESS_TYPE)
        } else {
            setLoading(false)
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(NO_DATA_AVAILABLE)
            setAlertType(SEVERITY_TYPE)
            setPlatformDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setShowAddButton(false)
            setIsOpen(true)
        }
    };

    const searchFunction = (text) => {
        let newData = []
        if (props.type === VIEW_CHANNEL_TYPE) {
            if (text !== "") {
                newData = platformDetailsData.filter((data) =>
                    data.name.toLowerCase().includes(text.toLowerCase())
                );
            }
        }
        else if (props.type === VIEW_DEMAND_TAG_TYPE) {
            if (text !== "") {
                newData = platformDetailsData.filter((data) =>
                    data.demand_tag_name.toLowerCase().includes(text.toLowerCase())
                );
            }
        }
        else {
            if (text !== "") {
                newData = platformDetailsData.filter((data) =>
                    data.amagi_key.toLowerCase().includes(text.toLowerCase())
                );
            }
        }

        if (newData.length > 0 && !isEmpty(text)) {
            setSearchedData(newData);
            setHasMoreData(false)
        } else if (newData.length === 0 && !isEmpty(text)) {
            setSearchedData([]);
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(SEARCHED_TEXT_NOT_FOUND)
            setAlertType(WARNING_TYPE)
        } else {
            setHasMoreData(true)
            setSearchedData(platformDetailsData);
        }
    }

    const onClickOnItem = (id) => {
        if (id && selectedTabType === VIEW_CHANNEL_TYPE) {
            navigation(`${CREATE_CHANNEL_DETAILS_PATH}${id}`);
        }

        if (id && selectedTabType === VIEW_DEMAND_TAG_TYPE) {
            navigation(`${CREATE_DEMAND_TAGS_PATH}${id}`);
        }
    };

    const onClickHandleChange = (value, path) => {
        if (selectedTabType !== value) {
            setLoading(true)
            setPlatformDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setSelectedRowsDemand([])
            setShowAddButton(false)
            setSearchText("")
            setOffset(0)
            setSelectedType(value);
            navigation(`${path}${platformId}`);
        }
    };

    const handleSelectedRowsChange = (rows) => {
        setSelectedRows(rows);
    };

    const handleSelectedRowsChangeDemand = (rows) => {
        setSelectedRowsDemand(rows);
    };

    const handleNotificationClose = () => {
        setIsOpen(false);
    }
    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };

    const onShowAddButton = (value) => {
        setShowAddButton(value);
    };

    const onClickAddMacros = () => {
        setSidebarOpen(true);
    };

    const changeOffset = (count) => {
        const getOnGoingApiCall = getLocalStorageData(ONGOING_API_CALL_KEY, false)
        if (count > 0 && getOnGoingApiCall === "false") {
            setOffset(count)
            loadData(props.type, count)
        }

    }


    const onShowNotification = (text = "", type = SUCCESS_TYPE) => {
        setIsOpen(true)
        setNotificationMessage(text)
        setAlertType(type)
    }

    const onSearchFilter = (text = "") => {
        setSearchText(text)
        searchFunction(text)
    }
    const clearSelection = () =>{
        setSelectedRows([])
        setSelectedRowsDemand([])
        setShowAddButton(false)
    }

    return (
        <React.Fragment>
            {isSidebarOpen && (
                <TemporaryDrawer
                    isSidebarOpen={isSidebarOpen}
                    closeSidebar={() => setSidebarOpen(false)}
                    selectedChannels={selectedRows}
                    selectedDemandTags={selectedRowsDemand}
                    tabType={props.type}
                    screen="platform"
                    sideBarTitleHeader={sideBarTitleHeader}
                    onShowNotification={onShowNotification}
                    userEmail={userEmail}
                />
            )}

            <Grid item xs={12} className="amg-bread-crumbs">
                {loading && searchedData.length === 0?
                    <Skeleton className="text-skeleton-breadcrumbs" animation="wave"/> :
                    <PlatformBreadCrumbs
                        onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                        platformName={platformDetails?.name}
                    />
                }
            </Grid>
            {loading && searchedData.length === 0 ? (
                <Skeleton animation="wave" className="text-skeleton-loading" />) :
                <PlatformHeader
                    userData={userData}
                    Detail={platformDetails}
                    platformType={platformType}
                    headerType="Platform"
                    status="Live"
                />
            }
            <Grid item xs={12} className="amg-body-content">
                <Grid container className="amg-body-tab-content">
                    <Grid item xs={12} className="amg-white-tabs-content">
                        {
                            loading && searchedData.length === 0 ? <Skeleton  animation="wave" className="tab-skeleton-loading"/> : (
                                <Tabs className="amg-white-tabs">
                                    {TABS_PLATFORM.map((data) => {
                                        return (
                                            <Tab
                                                label={data.name}
                                                value={data.value}
                                                className={selectedTabType === data.value && "active"}
                                                onClick={() => onClickHandleChange(data.value, data.path)}
                                                key={data.value}
                                            />
                                        );
                                    })}
                                </Tabs>
                            )
                        }
                    </Grid>
                </Grid>
                {selectedTabType === VIEW_CHANNEL_TYPE && (
                    <React.Fragment>
                        <Grid item xs={12} className="amg-filter-content">
                            <FilterTableWithClearAllButton
                                titleText={titleTextHeader}
                                showActionButton={showAddButton}
                                actionButtonName="Thunderstorm tags macros"
                                onClickActionButton={onClickAddMacros}
                                count={totalCount}
                                onSearch={onSearchFilter}
                                selectedRowLength={selectedRows.length}
                                clearSelection={clearSelection}
                            />
                        </Grid>
                        <Grid container className="amg-body-table-content">
                            <TableContentChannels
                                tableContent={searchedData}
                                onClick={onClickOnItem}
                                onShowAddButton={onShowAddButton}
                                onSelectedRowsChange={handleSelectedRowsChange}
                                newOffset={changeOffset}
                                isLoading={loading}
                                currentOffset={offset}
                                isHavingMoreData={hasMoreData}
                                selectedRowData={selectedRows}
                                searchedText={searchText}
                                adsPlusPlatform={platformId === INTERNAL_AMG_ID}
                            />
                        </Grid>
                    </React.Fragment>
                )}
                    {selectedTabType === VIEW_DEMAND_TAG_TYPE && (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                                <FilterTableWithClearAllButton
                                    titleText={titleTextHeader}
                                    showActionButton={showAddButton}
                                    actionButtonName="Add Macros"
                                    onClickActionButton={onClickAddMacros}
                                    count={searchedData.length}
                                    onSearch={onSearchFilter}
                                    selectedRowLength={selectedRowsDemand.length}
                                    clearSelection={clearSelection}
                                />
                            </Grid>
                            <Grid container className="amg-body-table-content">
                                <TableContentDemand
                                    tableContent={searchedData}
                                    onClick={onClickOnItem}
                                    onShowAddButton={onShowAddButton}
                                    onSelectedRowsChangeDemand={handleSelectedRowsChangeDemand}
                                    newOffset={changeOffset}
                                    isLoading={loading}
                                    currentOffset={offset}
                                    isHavingMoreData={hasMoreData}
                                    selectedRowData={selectedRowsDemand}
                                    searchedText={searchText}
                                    adsPlusPlatform={platformId === INTERNAL_AMG_ID}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    {selectedTabType === VIEW_MACRO_TYPE && (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                            <FilterTable
                                onSearch={onSearchFilter}
                                count={searchedData.length}
                                titleText="macros"
                            />
                            </Grid>
                            <Grid container className="amg-body-table-content">
                                <TableContentMacro
                                    tableContent={searchedData}
                                    isLoading={loading}
                                    searchedText={searchText}
                                    adsPlusPlatform={platformId === INTERNAL_AMG_ID}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
            </Grid>
        </React.Fragment>
    );
}

PlatformDetails.propsTypes = {
    type: PropTypes.string,
};

export default PlatformDetails;
