import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {CLIENT_ID, AUTH_GET_ACCESS_DOMAIN, ORG_ID, AUTH_AUD_VALUE} from "./Constant";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain={AUTH_GET_ACCESS_DOMAIN}
        clientId={CLIENT_ID}
        authorizationParams={{
            redirect_uri: window.location.origin,
            organization: ORG_ID,
            audience: AUTH_AUD_VALUE
        }}
    >
        <App/>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
