import axios from "axios";
import {
    BFF_LAYER_URI,
    ERROR_NOTIFICATION_TYPE,
    SUCCESS_NOTIFICATION_TYPE,
    WARNING_NOTIFICATION_TYPE
} from "../Constant";
import {adsHeader, isEmpty} from "../utils/helper";

export const submitRequestApi = (id) => {
    let data = JSON.stringify({
        query: `mutation MyMutation($id: String = "") {
          submitAdsPlusConfig(id: $id) {
            status
            data {
              channel_name
              channel_id
            }
            err_msg
            source
          }
        }`,
        variables: {"id":id}
    });
    const headers = adsHeader()


    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data : data
    };

    return axios.request(config)
        .then((response) => {
            if(response.data?.data?.submitAdsPlusConfig?.length > 0 && response.status === 200){
                const failedData = response.data.data.submitAdsPlusConfig.find((data) => data?.status === "409")
                if(!isEmpty(failedData)){
                    return {"status": false, "msg" :failedData?.err_msg || "Issue in submit api" , type: WARNING_NOTIFICATION_TYPE}
                }
                return {"status": true, "msg": "Data Created Successfully", type: SUCCESS_NOTIFICATION_TYPE};
            }
            return {"status": false, "msg": "Service is not able create.", type: WARNING_NOTIFICATION_TYPE};
        })
        .catch((error) => {
            return {"status": false, "msg": error.message, type: ERROR_NOTIFICATION_TYPE};
        });

}