export const PlusMinus = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M3.91226 4.23226C4.089 4.23226 4.23226 4.089 4.23226 3.91226V1.92001C4.23226 1.74328 4.37553 1.60001 4.55226 1.60001H4.78968C4.96642 1.60001 5.10968 1.74327 5.10968 1.92001V3.91226C5.10968 4.08899 5.25295 4.23226 5.42968 4.23226H7.42194C7.59867 4.23226 7.74194 4.37553 7.74194 4.55226V4.78968C7.74194 4.96641 7.59867 5.10968 7.42194 5.10968H5.42968C5.25295 5.10968 5.10968 5.25295 5.10968 5.42968V7.42194C5.10968 7.59867 4.96641 7.74194 4.78968 7.74194H4.55226C4.37553 7.74194 4.23226 7.59867 4.23226 7.42194V5.42968C4.23226 5.25295 4.089 5.10968 3.91226 5.10968H1.92001C1.74328 5.10968 1.60001 4.96641 1.60001 4.78968V4.55226C1.60001 4.37553 1.74328 4.23226 1.92001 4.23226H3.91226Z"
                fill="#E7E7E7"/>
            <path
                d="M8.25807 12.8103C8.25807 12.6336 8.40134 12.4903 8.57807 12.4903H14.08C14.2567 12.4903 14.4 12.6336 14.4 12.8103V13.0478C14.4 13.2245 14.2567 13.3678 14.08 13.3678H8.57807C8.40134 13.3678 8.25807 13.2245 8.25807 13.0478V12.8103Z"
                fill="#E7E7E7"/>
            <path
                d="M14.2233 3.17813C14.4107 2.99068 14.4107 2.68676 14.2233 2.49931C14.0358 2.31186 13.7319 2.31186 13.5445 2.49931L2.57673 13.4671C2.38927 13.6545 2.38927 13.9584 2.57673 14.1459C2.76418 14.3333 3.0681 14.3333 3.25555 14.1459L14.2233 3.17813Z"
                fill="#E7E7E7"/>
        </svg>
    )
}