export const getPlatformListingQuery = `query MyQuery($platform_name: String = "", $limit: Int = 10, $offset: Int = 10) {
  getPlatformListing(
    limit: $limit
    offset: $offset
    where: {platform_name: $platform_name}
  ) {
    data {
      amg_id
      channels
      created_at
      deal_type
      name
      platform_logo_url
      region
      routers
      updated_at
    }
    total_count
  }
}`;