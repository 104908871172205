const CommentsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="message-3-line">
        <path
          id="Vector"
          d="M1.66663 7.49501C1.66619 6.83851 1.79525 6.18837 2.04643 5.58183C2.29761 4.97528 2.66597 4.42424 3.13042 3.96026C3.59486 3.49628 4.14627 3.12847 4.75307 2.8779C5.35987 2.62733 6.01013 2.49891 6.66663 2.50001H13.3333C16.0941 2.50001 18.3333 4.74584 18.3333 7.49501V17.5H6.66663C3.90579 17.5 1.66663 15.2542 1.66663 12.505V7.49501ZM16.6666 15.8333V7.49501C16.6644 6.61209 16.3124 5.76604 15.6876 5.14219C15.0628 4.51834 14.2162 4.16755 13.3333 4.16667H6.66663C6.229 4.16558 5.79546 4.25088 5.39087 4.4177C4.98629 4.58451 4.61861 4.82955 4.30893 5.13877C3.99925 5.44799 3.75365 5.8153 3.58623 6.21963C3.41881 6.62397 3.33285 7.05738 3.33329 7.49501V12.505C3.3355 13.3879 3.68755 14.234 4.31234 14.8578C4.93713 15.4817 5.78371 15.8325 6.66663 15.8333H16.6666ZM11.6666 9.16667H13.3333V10.8333H11.6666V9.16667ZM6.66663 9.16667H8.33329V10.8333H6.66663V9.16667Z"
          fill="#E7E7E7"
        />
      </g>
    </svg>
  );
};

export default CommentsIcon;
