import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getReportDownloadList = async (EmailId) => {
    try {
        const query = `query MyQuery {


              report_downloads( where: {user_id: {equals:"${EmailId}"}}, take: 1000) {


                    created_at
                    download_url
                    job_details
                    organisation_id
                    name
                    report_type
                    status
                    user_id
              }
        }`;
        const headers = adsHeader()
        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        return response.data.data.report_downloads;
    } catch (error) {
        console.error("Error fetching Download List : ", error);
        return null;
    }
};

export default getReportDownloadList;
