import "./index.css"
import React, {useEffect} from "react";
import {useState, use} from "react";
import {jobDetails} from "../../../__mock__/jobDetials";
import {
    SEVERITY_TYPE,
    SUCCESS_NOTIFICATION_TYPE,
    SUCCESS_TYPE,
    USER_DATA_KEY
} from "../../../Constant";
import { useParams, useNavigate } from "react-router-dom";
import ActivityLogBreadCrumbs from "../../../Components/BreadCrumbs/ActivityLogBreadCrumbs";
import {Button, Grid, Skeleton, Tooltip} from "@mui/material";
import Badge from "@mui/material/Badge";
import NotRevertibleIcon from "../../../assests/icons/notRevertibleIcon";
import TableActivityLogDetail from "../../../Components/Tables/TableActivityLogDetail";
import FilterTableWithoutSearch from "../../../Components/TableTopFilters/FilterContentWithoutSearch";
import getJobStatusById from "../../../api/getJobStatus";
import {formatTheDate, getEpochData, isEmpty} from "../../../utils/helper";
import getValidateJobRevert from "../../../api/getValidateJobRevert";
import {getLocalStorageData} from "../../../utils/localStorageHelper";
import putRevertJob from "../../../api/putRevertJob";
import Notification from "../../../Components/Notification";


export const ActivityLogDetail = () => {
    const navigation = useNavigate();
    const [jobDetail, setJobDetail] = useState({});
    const [isRevertible, setIsRevertible] = useState(false)
    const [revertReason, setRevertReason] = useState("")
    const [isLoading, setIsLoading]=useState(false)
    const [dataCount, setDataCount]=useState(0)
    const [jobDetailTable, setJobDetailTable] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [alertType, setAlertType] = useState("")
    const {jobId}= useParams()

    useEffect(() => {
        showJobDetail(jobId)
    }, []);


    const showJobDetail = async (job_id) => {
        setIsLoading(true);
        const user_data = getLocalStorageData(USER_DATA_KEY,true);
        let userEmail = user_data?.email || ""
        try {
            const data = await getJobStatusById(job_id);
            if (!isEmpty(data?.data)) {
                const revertFlag = await getValidateJobRevert(job_id,userEmail)
                setIsRevertible(revertFlag?.data?.valid)
                setJobDetail(data.data)
                setRevertReason(revertFlag?.data?.reason)
                const transformedMbJobDetails = transformMbJobDetails(data.data.mb_job_details); // Transform the data
                setJobDetailTable(transformedMbJobDetails); // Set the transformed data
                setDataCount(transformedMbJobDetails?.length)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const transformMbJobDetails = (mbJobDetails) => {
        let transformedDetails = [];

        mbJobDetails?.forEach(jobDetail => {
            if (jobDetail.macro_level_details.length > 0) {
                jobDetail.macro_level_details.forEach(detail => {
                    transformedDetails.push({
                        "EntityName":jobDetail.EntityName,
                        "EntityType": jobDetail.EntityType,
                        "PlatformID": jobDetail.PlatformID,
                        "Reason":jobDetail.Reason,
                        "Status": jobDetail.Status,
                        "macro": detail.macro? detail.macro: "N/A",
                        "old_value": detail.old_value? detail.old_value: "N/A",
                        "new_value": detail.new_value? detail.new_value:"N/A"
                    });
                });
            } else {
                transformedDetails.push({
                    "EntityName":jobDetail.EntityName,
                    "EntityType": jobDetail.EntityType,
                    "PlatformID": jobDetail.PlatformID,
                    "ChannelID": jobDetail.ChannelID,
                    "Reason":jobDetail.Reason,
                    "Status": jobDetail.Status,
                    "macro": "N/A",
                    "old_value": "N/A",
                    "new_value": "N/A"
                });
            }
        });
        return transformedDetails;

    };
    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };
    const revertJob = async (jobId) =>{

        try {
            const user_data = getLocalStorageData(USER_DATA_KEY, true);
            let userEmail = user_data?.email || ""



            let userRole = user_data?.user_roles.includes("macroBulkUpdate_account_admin") ? "ADMIN" : "NOT_ADMIN"
            if(userRole === "ADMIN") {
                const revertResponse = await putRevertJob(jobId, userEmail, userRole)
                if (revertResponse?.data?.job_status === "Accepted") {

                    setIsOpen(true)
                    setAlertType(SUCCESS_TYPE)
                    setNotificationMessage("Revert was successful!")
                } else {
                    setIsOpen(true)
                    setAlertType(SEVERITY_TYPE)
                    setNotificationMessage("Revert was Unsuccessful!")
                }
            }
            else{
                setIsOpen(true)
                setAlertType(SEVERITY_TYPE)
                setNotificationMessage("Only admin can revert!")


            }
        }catch (err){
            console.log(`Error while Reverting ${err}`)
        }
    }
    const handleNotificationClose = () => {
        setIsOpen(false);
    };
    return (
        <React.Fragment>
            <Grid item xs={12} className="amg-bread-crumbs">
            <ActivityLogBreadCrumbs
                onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                activityName={jobDetail?.mb_job_status?.Action}
            />
            </Grid>

                <Grid item xs={12}  className="activity-detail-header">
                    {!isLoading?(
                    <Grid container justifyContent="space-between" >
                        <Grid item xs={9}>
                        <span className="job-action-span">{jobDetail?.mb_job_status?.Action}</span>
                            <Badge className={`log-details-badge ${jobDetail?.mb_job_status?.Status === 'Success' ? 'log-details-badge-success' : 'log-details-badge-failed'}`}>
                                {jobDetail?.mb_job_status?.Status}
                            </Badge>
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {isRevertible ? (<Button className="revert-button" onClick={()=>revertJob(jobDetail?.mb_job_status?.JobID)}>Revert</Button>) :(
                                <Tooltip  title={revertReason || "No Reason Found"} arrow placement="bottom" ><span className="not-revertible-span"><NotRevertibleIcon/>This activity cannot be rolled back</span></Tooltip>
                                )}
                        </Grid>
                        <Grid item xs={12}>
                            <span className="activity-detail-header-message">{jobDetail?.mb_job_status?.Summary}</span>
                        </Grid>
                        <Grid item xs={12} className="activity-detail-header-details-grid">
                            <div className="activity-detail-header-details-div">Activity By
                                <div className="activity-detail-header-details-subdiv">{jobDetail?.mb_job_status?.UserID}</div>
                            </div>
                            <div className="activity-detail-header-details-div">Executed on
                                <div className="activity-detail-header-details-subdiv">{formatTheDate(jobDetail?.mb_job_status?.CreatedAt)}</div>
                            </div>
                            <div className="activity-detail-header-details-div">Platform
                                <div className="activity-detail-header-details-subdiv">{jobDetail?.mb_job_status?.PlatformID}</div>
                            </div>
                            {/*<div className="activity-detail-header-details-div">Channel*/}
                            {/*    <div className="activity-detail-header-details-subdiv">Too Many Channels ???</div>*/}
                            {/*</div>*/}

                        </Grid>

                </Grid>):(
                <Skeleton animation="wave" className="log-header" width="100%" height={110} />
            )}      </Grid>
<Grid item xs={12} style={{marginTop:"16px"}}>
    <FilterTableWithoutSearch
        titleText="ad tags"
        count={dataCount}
        NoSearchBar={true}/>
    <TableActivityLogDetail
        isLoading={isLoading}
        setDataCount={setDataCount}
        jobDetail={jobDetailTable}
    />
</Grid>
<Notification
    message={notificationMessage}
    handleClose={handleNotificationClose}
    type={alertType}
    open={isOpen}>
</Notification>


        </React.Fragment>
    )
}

export default ActivityLogDetail;
