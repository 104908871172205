import React, {useState, useEffect, useRef, useCallback} from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Checkbox,
    Typography, Grid,
} from "@mui/material";
import {
    ACCEPTED_LOG_ACTIVITY,
    LOG_DETAILS_FILTER_LIST,
    MAX_LIMIT_SHOW_ACTIVITY_LOG,
    MAX_LIMIT_SHOW_TABLE_DATA, NO_DATA_AVAILABLE,
    SEARCHED_TEXT_NOT_FOUND, SEVERITY_TYPE, SUCCESS_LOG_ACTIVITY
} from "../../../Constant";
import {LoadingScreen} from "../../CommonScreens/LoadingScreen";
import {isEmpty, formatDate} from "../../../utils/helper";
import {EmptyScreen} from "../../CommonScreens/EmptyScreen";
import DescriptionSideBar from "../../DescriptionSideBar";
import getJobStatusById from "../../../api/getJobStatus";
import "./index.css"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import Notification from "../../Notification";
import FilterSidebar from "../../FilterSidebar";
import { useParams, useNavigate } from "react-router-dom";
import SkippedIcon from "../../../assests/icons/skippedIcon";

function TableActivityLog(props) {
    const navigation = useNavigate();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [jobDetails, setJobDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const elementRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [alertType, setAlertType] = useState("")






    const onClickShowJobDetail = async (job_id) => {
        navigation(`${job_id}`);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection);
        if (observer && elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [props?.tableContent]);

    const onIntersection = ([entry]) => {
        if (entry.isIntersecting && props.isHavingMoreData && !props.isLoading ) {
            const newCount = props.currentOffset + MAX_LIMIT_SHOW_TABLE_DATA;
            props.newOffset(newCount);
        }
    }


    const handleNotificationClose = () => {
        setIsOpen(false);
    };

    return (
        <React.Fragment>
            {isSidebarOpen && (
                <DescriptionSideBar
                    isSidebarOpen={isSidebarOpen}
                    closeSidebar={() => setSidebarOpen(false)}
                    logsData={jobDetails}
                    loading={isLoading}
                />
            )}


            <Grid item xs={12}>
                <Table className="off-white-table" stickyHeader>
                    <TableHead>
                        <TableCell>DATE/TIME </TableCell>
                        <TableCell width={"10%"}> ACTION</TableCell>
                        <TableCell> DESCRIPTION</TableCell>
                        <TableCell> PLATFORM/CHANNEL </TableCell>
                        <TableCell> USER </TableCell>
                        <TableCell> STATUS </TableCell>
                    </TableHead>
                    <TableBody>
                        {props?.tableContent?.length > 0 ?
                            props.tableContent.map((data, index) => {
                                return (
                                    <TableRow key={data?.JobID} sx={{height: "56px"}}>
                                        <TableCell>
                                            {formatDate(data?.created_at)}
                                        </TableCell>
                                        <TableCell
                                        >
                                            {data?.action}
                                        </TableCell>
                                        <TableCell
                                            onClick={() => onClickShowJobDetail(data?.job_id)}>
                                            <Typography className="description-typography">
                                                {data?.description.length < 60 ? data?.description : `${data?.description?.substring(0, 60)}...`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell> {data?.platform_id} </TableCell>
                                        <TableCell> {data?.user_id} </TableCell>
                                        <TableCell>  {
                                            data?.status === SUCCESS_LOG_ACTIVITY ? (
                                                <React.Fragment>
                                                    <div className="status-div">
                                                        <CheckCircleOutlineIcon className="error-success"
                                                                                sx={{marginRight: "3px"}}/>
                                                        <Typography className="log-status-success">Success</Typography>
                                                    </div>
                                                </React.Fragment>
                                            ) : data.status === ACCEPTED_LOG_ACTIVITY ? (
                                                <React.Fragment>
                                                    <div className="status-div">
                                                       <SkippedIcon/>
                                                        <Typography className="log-status-pending">Pending</Typography>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="status-div">
                                                        <ErrorOutlineIcon className="error-icon"
                                                                          sx={{marginRight: "3px"}}/>
                                                        <Typography className="log-status-failed">Failed</Typography>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        } </TableCell>

                                    </TableRow>
                                )
                            }) : (!isEmpty(props?.searchedText) && !props?.isLoading) && (
                            <TableRow>
                                <TableCell colSpan={6}> <EmptyScreen message="No results found"/> </TableCell>
                            </TableRow>

                        )}
                        {!props?.isLoading && props?.tableContent?.length === 0 && isEmpty(props?.searchedText) &&
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <EmptyScreen message="No Log Available!"/>
                                </TableCell>
                            </TableRow>
                        }

                        {!props?.isLoading && props?.isHavingMoreData && isEmpty(props?.searchedText) && (props.statusSelected==="")
                            && (
                            <TableRow ref={elementRef}>
                                <TableCell colSpan={6}></TableCell>
                            </TableRow>
                        )}
                        {
                            props?.isLoading && (
                                <LoadingScreen rowsNumber={6}/>
                            )
                        }
                    </TableBody>
                </Table>
            </Grid>
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </React.Fragment>
    )
}

TableActivityLog.defaultProps = {
    onClickJobDescription: () => {
    } // Provide a default no-op function
};


export default TableActivityLog;