import {Grid, Skeleton, Typography} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import "./index.css"

function LoadingQuickFilter(props){
    return(
        <Grid item className="quick-view-item">
            <Grid container>
                <Grid item xs={12}>
                    <Typography className="amg-quick-view-text">
                        <Skeleton animation="wave"/>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="amg-quick-view-text-type">
                        <Skeleton animation="wave" />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoadingQuickFilter