const DownloadArrow = (props) => {
    return (
        <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="download-arrow">
                <path id="Vector"
                      d="M6.35132 5H8.85132L5.85132 8L2.85132 5H5.35132V1.5H6.35132V5ZM1.85132 9.5H9.85132V6H10.8513V10C10.8513 10.1326 10.7986 10.2598 10.7049 10.3536C10.6111 10.4473 10.4839 10.5 10.3513 10.5H1.35132C1.21871 10.5 1.09153 10.4473 0.997765 10.3536C0.903997 10.2598 0.851318 10.1326 0.851318 10V6H1.85132V9.5Z"
                      fill="#7FB8F0"/>
            </g>
        </svg>
    )
}

export default DownloadArrow