
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getSupplyPartnerId = async () => {
    try {
        // Construct the GraphQL query
        const query = `
                    query MyQuery {
                        supply_partner {
                                supply_partner_id
                            }
                    }`;
        const headers = adsHeader()
        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        return response.data.data.supply_partner;
    } catch (error) {
        console.error("Error fetching Supply Partner Id:", error);
        return null;
    }
};

export default getSupplyPartnerId;
