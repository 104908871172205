import { Table, TableHead, TableCell, TableBody, TableRow , Grid } from "@mui/material";
import PropTypes from "prop-types";
import { EmptyScreen } from "../../CommonScreens/EmptyScreen";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";
import {getEpochData, isEmpty} from "../../../utils/helper";
import {SEARCHED_TEXT_NOT_FOUND} from "../../../Constant";

function TableSupplyTags(props) {

    return (
        <Grid item xs={12}>
            <Table className="off-white-table">
                <TableHead>
                    <TableCell> SUPPLY TAGS </TableCell>
                    <TableCell > CHANNEL NAME </TableCell>
                    <TableCell > SUPPLY TAG ID </TableCell>
                    <TableCell > SUPPLY PARTNER </TableCell>
                    <TableCell > SUPPLY PARTNER ID </TableCell>
                    <TableCell > DEMAND </TableCell>
                    <TableCell > LAST UPDATED </TableCell>
                </TableHead>
                <TableBody>
                    {props.isLoading && (
                        <LoadingScreen rowsNumber={7} />
                    )}
                    { props?.tableContent?.length > 0 ? props.tableContent.map((data, index) => {
                        return (
                            <TableRow key={index}>

                                <TableCell className="action-table-button" > {data?.supply_tag_name} </TableCell>

                                <TableCell> {data?.delivery_name} </TableCell>
                                <TableCell> {data?.supply_tag_id} </TableCell>
                                <TableCell> {data?.supply_partner_name} </TableCell>
                                <TableCell> {data?.supply_partner_id} </TableCell>
                                <TableCell> {data?.demand_tags_count} </TableCell>
                                <TableCell> {getEpochData(data?.updated_at)} </TableCell>
                            </TableRow>

                        )})
                        : (!isEmpty(props?.searchedText)  && !props?.isLoading) && (

                            <TableRow>
                                <TableCell colSpan={8}>
                                    <EmptyScreen message="No results found"/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    {!props?.isLoading && props?.tableContent?.length === 0 && isEmpty(props?.searchedText) && (
                        <TableRow>
                            <TableCell colSpan={7}>
                                <EmptyScreen message="No thunderstorm tags added yet!"/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Grid>
    )
}

TableSupplyTags.propTypes = {
    tableContent: PropTypes.shape({
        "channel_id": PropTypes.string,
        "supply_router_name": PropTypes.string,
        "channel_name": PropTypes.string,
        "content_partner": PropTypes.string,
        "supply_partner_name": PropTypes.string,
        "supply_partner_id": PropTypes.number,
        "demand_tags_count": PropTypes.number,
        "last_updated": PropTypes.string,
        "delivery_name": PropTypes.string
    }),
    onClick: PropTypes.func,
    searchedText: PropTypes.string,
    isLoading: PropTypes.bool
}

export default TableSupplyTags