import "./index.css";
import {useState, useContext, useEffect, useRef} from "react";
import React from "react";
import DropIcon from "../../assests/icons/dropIcon";
import EllipseIcon from "../../assests/icons/ellipseIcon";
import PinIcon from "../../assests/icons/pinIcon";
import DeleteIcon from "../../assests/icons/deleteIcon";
import PropTypes from "prop-types";
import { RequestContext } from "../../Context/RequestContext";
import useWebStorageHelper from "../../utils/sessionStorageHelper";
import {FILE_SIZE_LIMIT_EXCEEDED_NOTIFICATION_TYPE, MAX_FILE_SIZE, WARNING_NOTIFICATION_TYPE} from "../../Constant";
import sessionStorageHelper from "../../utils/sessionStorageHelper"; // Import RequestContext

const DropInput = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const { generalData } = useContext(RequestContext); // Use RequestContext to access generalData
    const fileInputRef = useRef(null);

    // Load the file from context if it exists
    useEffect(() => {
        if (generalData?.so_document_file_url) {
            const existingFile = {
                name: generalData.so_document_file_url.split('/').pop(), // Extract the file name from the URL
                url: generalData.so_document_file_url
            };
            setSelectedFile(existingFile);
        }
    }, [generalData?.so_document_file_url]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(file?.size > MAX_FILE_SIZE) {
            props.showNotification(WARNING_NOTIFICATION_TYPE, FILE_SIZE_LIMIT_EXCEEDED_NOTIFICATION_TYPE);
            event.target.value = '';
            return;
        }
        if (file) {
            setSelectedFile(file);
            if (props.onFileChange) {
                props.onFileChange(file, props.formKey);
            }
        }
    };

    const handleDropAreaClick = () => {
        document.getElementById('file-upload-input').click();
    };

    const handleDeleteClick = () => {
        useWebStorageHelper.removeItem(props.formKey);
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (props.onFileChange) {
            props.onFileChange("", props.formKey);
        }
    };

    return (
        <React.Fragment>
            <div className="drop-input-div">
                <div className="drop-div">
                    {props?.headMessage && (
                        <div className="head-message">
                            {props.headMessage}
                        </div>
                    )}
                    <div className="drop-area-div" onDoubleClick={handleDropAreaClick}>
                        <DropIcon />
                        <div className="drop-span">
                            <span className="drop-typography">Drop your files here or
                                <span style={{ color: "#7FB8F0", cursor: "pointer" }} onClick={handleDropAreaClick}> browse
                                </span>
                            </span>
                            <div className="drop-sub-typography">Up to 1MB<EllipseIcon />
                                {props?.fileType}
                            </div>
                        </div>
                        <input
                            id="file-upload-input"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept={props.supportedType}
                            ref={fileInputRef}
                        />
                    </div>
                    <div className="drop-subhead">
                        {props.download && <span className="drop-subhead-typography">{props?.subMessage}<span
                            style={{ color: "#7FB8F0" }}> Download sample</span> here.</span>
                        }
                    </div>
                </div>
                {selectedFile && (
                    <div className="dropped-file-div">
                        <div className="pin-dropped-div">
                            <PinIcon />
                            {selectedFile.name}
                        </div>
                        <DeleteIcon deleteData={handleDeleteClick} className="delete-icon" />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

DropInput.propTypes = {
    fileType: PropTypes.string.isRequired,
    onFileChange: PropTypes.func.isRequired,
    headMessage: PropTypes.string,
    subMessage: PropTypes.string,
    supportedType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    fileLoading: PropTypes.bool,
    download: PropTypes.bool
}

export default DropInput;
