import React, { useEffect, useState } from "react";
import {Bar, ComposedChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Grid} from "@mui/material";
import "./index.css"
import PropTypes from "prop-types";
import {calculateFivePercentPlusAndMinus, formatNumberToMillion, isEmpty} from "../../utils/helper";
import {CUMULATIVE_CONFIG_DATA, INCREMENT_ON_NUMBER, UNDER_PACING_KEY} from "../../Constant";
import CustomToolTip from "./CustomToolTip";

function VerticalBarChart(props) {
    const [data, setData] = useState([CUMULATIVE_CONFIG_DATA]);
    const [targetValue, setTargetValue] = useState(0)
    const [fillAreas, setFillAreas] = useState(0)
    const [percentageValue, setPercentageValue] = useState({})
    const [targetFillColor, setTargetFillColor] = useState("")
    const [projectedFillColor, setProjectedFillColor] = useState("")

    useEffect(() =>{
        if(!isEmpty(props.data)){
            let mappedData = []
            let projectedValue = props.data?.projected ? props.data?.projected - props.data?.achieved : 0
            let goalStatusColor = props.data?.track === UNDER_PACING_KEY ? "#F1B521" :  "#82C77F"
            let projectedStatusColor = props.data?.track === UNDER_PACING_KEY ? "#F1B521" :  "#82C77F"
            mappedData.push({...props.data, "projected": projectedValue})
            const getPercentageValue = calculateFivePercentPlusAndMinus(props.data?.target)

            setData(mappedData)
            setTargetValue(props.data.target)
            setFillAreas(props.data.target + INCREMENT_ON_NUMBER)
            setPercentageValue(getPercentageValue)
            setTargetFillColor(goalStatusColor)
            setProjectedFillColor(projectedStatusColor)
        }

    }, [props?.data])

    return(
        <Grid container className="demand-goal-tracking-vertical-graph">
            <ResponsiveContainer
                width={"100%"}
                height={80}
            >
                <ComposedChart
                    data={data}
                    layout="vertical"
                >
                    <XAxis type="number" tickFormatter={formatNumberToMillion} domain={[0, fillAreas]} allowDataOverflow/>
                    <YAxis dataKey="month" type="category" hide={true} />
                    <Tooltip cursor={false} content={<CustomToolTip data={data[0]}/>}/>
                    <ReferenceArea x1={0} x2={fillAreas} fill="#FFFFFF" fillOpacity={0.1}/>
                    <Bar
                        dataKey="achieved"
                        stackId="a"
                        fill={targetFillColor}
                        barSize="15"
                        tooltipType="none"
                    />
                    <Bar
                        dataKey="projected"
                        stackId="a"
                        fill={projectedFillColor}
                        barSize="15"
                        tooltipType="none"
                        fillOpacity={0.4}
                    />
                    <ReferenceLine x={targetValue} stroke="#E7E7E7" strokeWidth={2}/>
                    <ReferenceArea x1={targetValue} x2={percentageValue?.plus} fill="#FFFFFF" fillOpacity={0.1}/>
                    <ReferenceArea x1={targetValue} x2={percentageValue?.minus} fill="#FFFFFF" fillOpacity={0.1}/>
                </ComposedChart>
            </ResponsiveContainer>
        </Grid>
    )
}

VerticalBarChart.defaultProps = {
    "data": PropTypes.shape({
        "target": PropTypes.number.isRequired,
        "projected": PropTypes.number.isRequired,
        "achieved": PropTypes.number.isRequired
    })
}

export default VerticalBarChart;