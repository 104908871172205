import axios from "axios";
import {BFF_LAYER_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

export const getPlatformDetails = (id = "", email = "") => {
    let data = JSON.stringify({
        query: `query MyQuery($id: String = "") {
            getPlatformDetails(id: $id) {
            amg_id
            platform_logo_url
            channels
            deal_type
            name
            region
            routers
            created_at
            updated_at
          }
        }`,
        variables: {"id": id}
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data : data
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data?.getPlatformDetails && response.status === 200){
                return response.data.data.getPlatformDetails
            }else {
                return {}
            }
        })
        .catch((_) => {
            return null
        });
}