const PageNotFound = () => {
    return (
        <svg width="804" height="394" viewBox="0 0 804 394" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M149.975 0.466553H171.687C177.254 0.466553 181.771 5.00766 181.771 10.6059V22.8413C181.771 28.4395 177.254 32.9806 171.687 32.9806H166.751L165.964 38.634L159.967 32.9806H149.975C144.408 32.9806 139.892 28.4395 139.892 22.8413V10.6059C139.892 5.00766 144.408 0.466553 149.975 0.466553Z"
                fill="#455162"/>
            <path
                d="M155.706 16.7682C155.706 18.2114 154.545 19.3788 153.11 19.3788C151.675 19.3788 150.514 18.2114 150.514 16.7682C150.514 15.3249 151.675 14.1575 153.11 14.1575C154.545 14.1575 155.706 15.3249 155.706 16.7682Z"
                fill="#D4E4FA"/>
            <path
                d="M164.547 16.7682C164.547 18.2114 163.386 19.3788 161.951 19.3788C160.515 19.3788 159.354 18.2114 159.354 16.7682C159.354 15.3249 160.515 14.1575 161.951 14.1575C163.386 14.1575 164.547 15.3249 164.547 16.7682Z"
                fill="#D4E4FA"/>
            <path
                d="M173.627 16.7682C173.627 18.2114 172.466 19.3788 171.031 19.3788C169.595 19.3788 168.435 18.2114 168.435 16.7682C168.435 15.3249 169.595 14.1575 171.031 14.1575C172.466 14.1575 173.627 15.3249 173.627 16.7682Z"
                fill="#D4E4FA"/>
            <path opacity="0.4"
                  d="M804 386.333C804 390.316 624.017 393.533 402 393.533C179.984 393.533 0 390.303 0 386.333C0 382.363 179.984 379.133 402 379.133C624.017 379.133 804 382.363 804 386.333Z"
                  fill="#4D5156"/>
            <path
                d="M186.129 88.3634C185.981 88.7815 185.793 89.1918 185.58 89.5863C184.45 91.764 182.685 93.5471 180.528 94.6912C179.986 94.9831 179.421 95.2356 178.84 95.4329C175.74 96.569 172.289 96.2376 169.456 94.5254C168.2 93.7601 167.1 92.7503 166.238 91.5588C162.589 86.5644 163.358 81.5226 164.064 72.8988C164.504 66.3501 170.137 61.4108 176.651 61.8527C178.472 61.979 180.244 62.5233 181.822 63.4543C189.661 68.0149 189.056 80.9152 186.138 88.3555L186.129 88.3634Z"
                fill="#64686C"/>
            <path
                d="M156.15 98.9125C157.547 104.483 163.535 105.004 163.535 105.004C163.535 105.004 174.97 105.817 174.962 103.363C172.129 100.72 173.764 98.3706 174.384 96.0431C174.816 94.6228 175.522 93.3052 176.464 92.1691C176.966 91.5221 177.508 90.8988 178.08 90.3149L172.626 75.2212C170.516 81.4149 162.835 92.9147 157.821 96.7966L156.15 98.9125Z"
                fill="#64686C"/>
            <path
                d="M170.828 64.6997C170.828 64.6997 173.176 70.1772 177.757 70.0506C182.338 69.9242 182.888 67.381 182.888 67.381C182.888 67.381 185.132 74.9529 191.191 71.0519C195.749 68.1174 192.176 63.9631 192.176 63.9631C192.176 63.9631 194.593 60.2922 191.742 56.9319C188.889 53.5717 185.316 55.3783 185.316 55.3783C185.316 55.3783 184.262 52.0182 179.245 52.0872C174.23 52.1447 173.863 55.1367 173.863 55.1367C173.863 55.1367 172.81 52.7777 169.351 53.0884C165.881 53.399 162.971 57.4384 163.314 59.7399C163.314 59.7399 157.405 58.497 156.912 65.8964V69.4983C156.912 69.4983 152.892 73.8252 156.603 78.0025L158.836 80.212C158.836 80.212 156.214 85.6897 160.245 88.3364C164.265 90.9946 167.872 88.5206 168.309 85.7817C168.743 83.0429 167.678 76.7943 167.678 76.7943C167.678 76.7943 171.16 74.93 170.484 71.351C170.484 71.351 176.669 68.9229 170.851 64.6997H170.828Z"
                fill="#2F3133"/>
            <path
                d="M168.418 84.6072C170.8 84.6072 172.73 82.667 172.73 80.2733C172.73 77.8799 170.8 75.9395 168.418 75.9395C166.035 75.9395 164.104 77.8799 164.104 80.2733C164.104 82.667 166.035 84.6072 168.418 84.6072Z"
                fill="#64686C"/>
            <path
                d="M146.33 347.571L146.31 367.662L156.355 368.388L159.887 349.515C155.297 346.436 150.768 345.699 146.33 347.571Z"
                fill="#64686C"/>
            <path
                d="M146.656 365.553C145.668 368.204 145.607 379.052 145.607 379.052L166.899 379.072C168.163 375.153 166.208 372.471 163.724 372.113C162.655 371.959 161.404 370.864 160.488 369.902C160.488 369.779 159.175 363.751 158.371 363.465C158.371 363.465 157.821 362.84 157.343 363.465C156.875 364.089 156.457 366.127 156.457 366.127C153.129 361.981 149.872 362.534 146.656 365.553Z"
                fill="#64686C"/>
            <path
                d="M142.604 370.602L149.42 371.905C151.743 372.348 154.117 372.474 156.474 372.28L164.009 371.657C168.812 357.206 173.555 292.268 174.044 276.71C174.634 258.125 181.799 211.477 175.458 201.775C169.117 192.073 140.456 199.8 140.456 199.8C140.456 199.8 144.028 256.601 142.604 274.716C141.362 290.414 139.448 336.562 142.604 357.48V370.602Z"
                fill="#455162"/>
            <path
                d="M189.666 344.949L189.646 367.617L199.316 368.354L204.069 347.138C200.191 344.243 195.682 342.82 189.676 344.949H189.666Z"
                fill="#64686C"/>
            <path
                d="M189.648 365.6C188.659 368.25 188.6 379.099 188.6 379.099L213.056 379.129C214.319 375.209 211.683 372.887 209.2 372.528C208.13 372.375 204.395 370.921 203.48 369.96C203.48 369.836 202.167 363.808 201.363 363.521C201.363 363.521 200.812 362.898 200.335 363.521C199.866 364.146 199.449 366.183 199.449 366.183C196.12 362.039 192.864 362.59 189.648 365.61V365.6Z"
                fill="#64686C"/>
            <path
                d="M185.352 370.6L190.814 372.009C194.354 372.921 198.047 373.081 201.652 372.478L206.572 371.654C209.159 355.657 219.539 284.272 218.685 273.116C217.646 259.485 205.352 200.483 200.781 195.939C196.212 191.395 162.746 203.185 162.746 203.185C162.746 203.185 176.497 253.917 187.337 275.859C189.585 280.413 183.601 338.011 185.341 357.468L185.352 370.6Z"
                fill="#455162"/>
            <path
                d="M153.372 100.827C152.497 100.898 138.278 104.992 136.884 116.148C135.5 127.303 139.5 187.859 139.54 189.415C139.581 190.96 139.225 200.571 140.558 202.433C141.891 204.296 157.301 208.789 176.405 204.142C195.51 199.496 201.87 199.107 202.858 196.201C203.835 193.294 198.319 172.375 198.614 168.967C198.909 165.558 189.83 109.546 189.83 109.546C189.83 109.546 169.087 94.6853 153.372 95.9748V100.827Z"
                fill="#2A6BD3"/>
            <path opacity="0.3"
                  d="M178.706 133.533C178.706 133.533 179.144 177.07 184.6 181.307C190.065 185.554 200.742 181.522 200.742 181.522L196.07 151.444L178.696 133.533H178.706Z"
                  fill="black"/>
            <path
                d="M184.608 163.354C184.608 163.354 186.349 179.883 195.509 178.9C204.67 177.918 219.235 167.244 219.235 167.244C219.235 167.244 225.83 171.614 227.367 170.078C228.904 168.543 228.761 166.895 228.761 166.895C228.761 166.895 230.298 164.756 230.156 163.364C230.013 161.962 231.132 160.56 231.132 158.738C231.132 156.917 230.573 154.941 229.595 154.88C228.619 154.819 223.418 154.88 223.418 154.88V151.461C223.418 151.461 217.759 153.979 216.924 156.22C216.09 158.461 215.672 159.025 215.672 159.025L202.247 160.653L202.023 155.904L184.618 163.364L184.608 163.354Z"
                fill="#64686C"/>
            <path
                d="M187.856 109.318C187.856 109.318 177.882 115.756 178.156 125.97C178.431 136.194 183 165.627 183 165.627C183 165.627 190.329 165.7 196.436 162.813C202.869 159.773 205.26 156.222 205.26 156.222C205.26 156.222 201.77 133.257 199.835 124.598C197.902 115.94 193.189 108.96 187.846 109.308L187.856 109.318Z"
                fill="#2A6BD3"/>
            <path opacity="0.3"
                  d="M202.237 160.64L199.988 164.601C199.988 164.601 202.177 164.008 203.112 160.527L202.237 160.63V160.64Z"
                  fill="black"/>
            <path
                d="M123.607 76.2476C123.607 76.2476 118.767 62.3457 126.489 59.6211C134.212 56.8965 149.929 59.8606 149.929 59.8606C149.929 59.8606 153.574 53.8332 155.386 54.4734C157.199 55.1135 157.707 56.4875 157.707 56.4875C157.707 56.4875 159.747 57.6111 160.158 58.7802C160.573 59.9575 162 60.651 162.687 62.1096C163.376 63.5684 163.671 65.3645 162.91 65.7883C162.15 66.212 157.952 68.1565 157.952 68.1565L159.242 70.8936C159.242 70.8936 153.75 71.0469 152.234 69.5721C150.719 68.0973 150.171 67.8066 150.171 67.8066L138.783 71.6497L140.395 75.538L123.611 76.2355L123.607 76.2476Z"
                fill="#64686C"/>
            <path
                d="M137.36 145.222C137.36 145.222 136.557 121.137 132.92 112.846C129.279 104.547 121.495 76.6908 121.495 76.6908C121.495 76.6908 127.355 73.8218 133.35 73.7919C139.666 73.76 142.928 75.6885 142.928 75.6885C142.928 75.6885 154.494 94.8381 156.21 102.519C157.927 110.2 151.078 124.561 146.655 126.332L137.36 145.222Z"
                fill="#2A6BD3"/>
            <path opacity="0.3"
                  d="M145.316 76.5864L142.015 74.2759C142.015 74.2759 143.996 73.9121 146.062 76.3409L145.32 76.5947L145.316 76.5864Z"
                  fill="black"/>
            <path d="M436.272 287.687H297.559V377.362H436.272V287.687Z" fill="#464646"/>
            <path d="M574.985 287.687H436.271V377.362H574.985V287.687Z" fill="#464646"/>
            <path d="M713.699 287.687H574.985V377.362H713.699V287.687Z" fill="#464646"/>
            <path d="M519.677 287.687H509.498L421.043 377.362H431.223L519.677 287.687Z" fill="#424242"/>
            <path d="M537.712 287.687H527.533L439.078 377.362H449.258L537.712 287.687Z" fill="#424242"/>
            <path d="M462.236 287.687H433.583L345.128 377.362H373.781L462.236 287.687Z" fill="#424242"/>
            <path d="M332.734 287.687L297.559 323.348V377.362H322.17L410.625 287.687H332.734Z" fill="#424242"/>
            <path d="M631.3 287.687H576.349L487.895 377.362H542.845L631.3 287.687Z" fill="#424242"/>
            <path d="M713.697 336.849V307.8L645.082 377.362H673.735L713.697 336.849Z" fill="#424242"/>
            <path d="M691.362 287.687H662.709L574.254 377.362H602.907L691.362 287.687Z" fill="#424242"/>
            <path d="M710.934 287.687H700.754L612.299 377.362H622.479L710.934 287.687Z" fill="#424242"/>
            <path d="M578.503 343.478L574.985 346.535V318.513L578.503 321.57V343.478Z" fill="#2E2E2E"/>
            <path d="M432.755 343.478L436.273 346.535V318.513L432.755 321.57V343.478Z" fill="#2E2E2E"/>
            <path d="M706.661 382.457H693.594L690.578 377.362H706.661V382.457Z" fill="#464646"/>
            <path d="M304.596 382.457H317.663L320.679 377.362H304.596V382.457Z" fill="#464646"/>
            <path d="M706.661 380.227V377.362H690.578L692.274 380.227H706.661Z" fill="#64686C"/>
            <path d="M318.983 380.227L320.679 377.362H304.596V380.227H318.983Z" fill="#64686C"/>
            <path
                d="M455.24 287.687H556.017V277.016C556.017 276.394 555.519 275.889 554.905 275.889H456.352C455.738 275.889 455.24 276.394 455.24 277.016V287.687Z"
                fill="#64686C"/>
            <path d="M523.919 278.688H487.337V284.888H523.919V278.688Z" fill="#464646"/>
            <path
                d="M465.364 281.253C465.364 281.962 464.797 282.536 464.098 282.536C463.4 282.536 462.833 281.962 462.833 281.253C462.833 280.544 463.4 279.97 464.098 279.97C464.798 279.97 465.364 280.545 465.364 281.253Z"
                fill="#464646"/>
            <path
                d="M471.346 281.253C471.346 281.962 470.779 282.536 470.081 282.536C469.383 282.536 468.815 281.962 468.815 281.253C468.815 280.544 469.383 279.97 470.081 279.97C470.78 279.97 471.346 280.545 471.346 281.253Z"
                fill="#464646"/>
            <path
                d="M477.33 281.253C477.33 281.962 476.764 282.536 476.064 282.536C475.365 282.536 474.799 281.962 474.799 281.253C474.799 280.544 475.365 279.97 476.064 279.97C476.764 279.97 477.33 280.545 477.33 281.253Z"
                fill="#464646"/>
            <path
                d="M545.893 281.253C545.893 281.962 546.46 282.536 547.158 282.536C547.856 282.536 548.423 281.962 548.423 281.253C548.423 280.544 547.856 279.97 547.158 279.97C546.459 279.97 545.893 280.545 545.893 281.253Z"
                fill="#464646"/>
            <path
                d="M539.91 281.253C539.91 281.962 540.476 282.536 541.176 282.536C541.874 282.536 542.441 281.962 542.441 281.253C542.441 280.544 541.874 279.97 541.176 279.97C540.476 279.97 539.91 280.545 539.91 281.253Z"
                fill="#464646"/>
            <path
                d="M533.929 281.253C533.929 281.962 534.495 282.536 535.194 282.536C535.893 282.536 536.459 281.962 536.459 281.253C536.459 280.544 535.893 279.97 535.194 279.97C534.495 279.97 533.929 280.545 533.929 281.253Z"
                fill="#464646"/>
            <path opacity="0.07"
                  d="M455.24 281.683H556.017V277.016C556.017 276.394 555.519 275.889 554.905 275.889H456.352C455.738 275.889 455.24 276.394 455.24 277.016V281.683Z"
                  fill="white"/>
            <path
                d="M397.802 276.403V274.163C397.802 273.638 397.381 273.213 396.864 273.213H356.988V277.353H396.864C397.381 277.354 397.802 276.928 397.802 276.403Z"
                fill="#A3A3A3"/>
            <path
                d="M396.864 273.213H356.051V272.263H396.864C397.899 272.263 398.739 273.114 398.739 274.163V276.403C398.739 277.453 397.899 278.304 396.864 278.304H356.051V277.353H396.864C397.382 277.353 397.802 276.927 397.802 276.403V274.163C397.803 273.639 397.382 273.213 396.864 273.213Z"
                fill="#EFEFEF"/>
            <path
                d="M396.863 272.263H377.395V273.213H396.863C397.381 273.213 397.801 273.639 397.801 274.163V276.403C397.801 276.928 397.38 277.353 396.863 277.353H377.395V278.304H396.863C397.899 278.304 398.738 277.453 398.738 276.403V274.163C398.739 273.114 397.9 272.263 396.863 272.263Z"
                fill="#D3D3D3"/>
            <path d="M378.276 273.213H356.988V277.353H378.276V273.213Z" fill="#929292"/>
            <path
                d="M362.065 285.297V280.205C362.065 279.68 362.486 279.255 363.003 279.255H402.879V286.247H363.003C362.486 286.247 362.065 285.821 362.065 285.297Z"
                fill="#A3A3A3"/>
            <path
                d="M363.003 279.255H403.816V278.305H363.003C361.967 278.305 361.128 279.156 361.128 280.206V285.297C361.128 286.347 361.967 287.198 363.003 287.198H403.816V286.247H363.003C362.484 286.247 362.065 285.821 362.065 285.297V280.205C362.065 279.68 362.486 279.255 363.003 279.255Z"
                fill="#D3D3D3"/>
            <path
                d="M363.003 278.305H382.471V279.255H363.003C362.484 279.255 362.065 279.681 362.065 280.205V285.297C362.065 285.822 362.486 286.247 363.003 286.247H382.471V287.198H363.003C361.967 287.198 361.128 286.347 361.128 285.297V280.206C361.128 279.156 361.967 278.305 363.003 278.305Z"
                fill="#EFEFEF"/>
            <path d="M402.879 279.255H381.591V286.247H402.879V279.255Z" fill="#929292"/>
            <path
                d="M698.62 287.687H662.435V218.138C662.435 217.294 663.109 216.609 663.942 216.609H697.113C697.946 216.609 698.621 217.294 698.621 218.138V287.687H698.62Z"
                fill="#64686C"/>
            <path
                d="M680.528 276.877C687.051 276.877 692.339 271.516 692.339 264.903C692.339 258.29 687.051 252.929 680.528 252.929C674.005 252.929 668.717 258.29 668.717 264.903C668.717 271.516 674.005 276.877 680.528 276.877Z"
                fill="#464646"/>
            <path
                d="M687.955 264.903C687.955 269.062 684.629 272.434 680.526 272.434C676.423 272.434 673.098 269.062 673.098 264.903C673.098 260.743 676.423 257.372 680.526 257.372C684.629 257.37 687.955 260.743 687.955 264.903Z"
                fill="#64686C"/>
            <path d="M692.339 223.233H668.717V226.8H692.339V223.233Z" fill="#464646"/>
            <path opacity="0.07"
                  d="M663.94 216.609C663.108 216.609 662.433 217.294 662.433 218.138V287.687H680.526V216.609H663.94Z"
                  fill="white"/>
            <path opacity="0.4" d="M641.13 93.4014H363.019V238.104H641.13V93.4014Z" fill="#B7BBD6"/>
            <path d="M644.682 91.5864H366.57V236.289H644.682V91.5864Z" fill="#64686C"/>
            <g opacity="0.07">
                <path d="M509.21 233.377H502.044V234.505H509.21V233.377Z" fill="white"/>
            </g>
            <path opacity="0.07" d="M602.745 94.6433H369.587V231.703H467.55L602.745 94.6433Z" fill="white"/>
            <path
                d="M378.963 194.866V176.803L420.325 117.64H445.325V175.494H456.059V194.866H445.325V211.882H422.943V194.866H378.963ZM424.514 142.248L402.524 175.494H424.514V142.248ZM467.022 162.798C467.022 147.789 469.901 135.965 475.661 127.326C481.507 118.687 490.888 114.368 503.802 114.368C516.717 114.368 526.054 118.687 531.813 127.326C537.659 135.965 540.583 147.789 540.583 162.798C540.583 177.981 537.659 189.892 531.813 198.531C526.054 207.17 516.717 211.489 503.802 211.489C490.888 211.489 481.507 207.17 475.661 198.531C469.901 189.892 467.022 177.981 467.022 162.798ZM518.593 162.798C518.593 153.984 517.633 147.221 515.713 142.509C513.794 137.71 509.823 135.31 503.802 135.31C497.781 135.31 493.811 137.71 491.891 142.509C489.971 147.221 489.011 153.984 489.011 162.798C489.011 168.731 489.36 173.662 490.059 177.588C490.757 181.428 492.153 184.569 494.247 187.012C496.429 189.369 499.614 190.547 503.802 190.547C507.991 190.547 511.132 189.369 513.226 187.012C515.408 184.569 516.848 181.428 517.546 177.588C518.244 173.662 518.593 168.731 518.593 162.798ZM552.931 194.866V176.803L594.293 117.64H619.293V175.494H630.026V194.866H619.293V211.882H596.911V194.866H552.931ZM598.482 142.248L576.492 175.494H598.482V142.248Z"
                fill="#D4E4FA"/>
            <path
                d="M348.821 287.687H312.635V218.138C312.635 217.294 313.31 216.609 314.143 216.609H347.314C348.146 216.609 348.821 217.294 348.821 218.138V287.687Z"
                fill="#64686C"/>
            <path
                d="M330.728 276.877C337.251 276.877 342.539 271.516 342.539 264.903C342.539 258.29 337.251 252.929 330.728 252.929C324.205 252.929 318.917 258.29 318.917 264.903C318.917 271.516 324.205 276.877 330.728 276.877Z"
                fill="#464646"/>
            <path
                d="M338.157 264.903C338.157 269.062 334.832 272.434 330.729 272.434C326.626 272.434 323.3 269.062 323.3 264.903C323.3 260.743 326.626 257.372 330.729 257.372C334.832 257.37 338.157 260.743 338.157 264.903Z"
                fill="#64686C"/>
            <path d="M342.539 223.233H318.917V226.8H342.539V223.233Z" fill="#464646"/>
            <path opacity="0.07"
                  d="M314.144 216.609C313.311 216.609 312.636 217.294 312.636 218.138V287.687H330.729V216.609H314.144Z"
                  fill="white"/>
            <path d="M436.828 287.687H435.716V377.362H436.828V287.687Z" fill="#2E2E2E"/>
            <path d="M436.827 287.687H436.271V377.362H436.827V287.687Z" fill="#2E2E2E"/>
            <path d="M575.54 287.687H574.428V377.362H575.54V287.687Z" fill="#2E2E2E"/>
            <path d="M574.984 287.687H574.428V377.362H574.984V287.687Z" fill="#2E2E2E"/>
        </svg>
    )
}

export default PageNotFound