import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getSupplyRoutersByChannelId = async (channelId = "", email = "", type="" ) => {
    const query = `
        query MyQuery($id: String = "", $type: String = "") {
            getSupplyRoutersListingByChannelId(id: $id, type: $type) {
                data{
                    ad_tag_url
                    channel_id
                    delivery_name
                    demand_tags_count
                    supply_partner_id
                    supply_partner_name
                    supply_router_id
                    supply_router_name
                    created_at
                    updated_at
                }
            }
        }
    `;

    const variables = { "id": channelId, "type": type };

    const data = JSON.stringify({
        query,
        variables
    });
    const headers = adsHeader()

    const config = {
        method: 'post',
        url: BFF_LAYER_URI,
        headers: headers,
        data
    };

    try {
        const response = await axios.request(config);
        if (response?.data?.data?.getSupplyRoutersListingByChannelId && response.status === 200) {
            return { loading: false, error: null, data: response.data.data.getSupplyRoutersListingByChannelId, refetch: () => { } };
        } else {
            return { loading: false, error: "Error fetching supply routers", data: null, refetch: () => { } };
        }
    } catch (error) {
        console.error("Error fetching supply routers:", error);
        return { loading: false, error, data: null, refetch: () => { } };
    }
};
