import axios from "axios";

import { REPORT_DOWNLOAD_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const downloadSTRIntegratedReport = async (requestData) => {
    const url = `${REPORT_DOWNLOAD_URI}/supply_transparency_integrated_report/download`;
    const headers = adsHeader();

    try {
        const response = await axios.post(url, requestData, { headers: headers });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};
