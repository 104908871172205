import {useState, useEffect} from "react";
import Login from "./Screens/Login";
import Container from "./Container";
import {Box} from "@mui/material"
import './App.css';
import {getLocalStorageData, setLocalStorageData} from "./utils/localStorageHelper";
import {
    INITIAL_PAGE, JWT_TOKEN_KEY,
    ONGOING_API_CALL_KEY,
    REACT_APP_SENTRY_DSN,
    USER_DATA_KEY,
    USER_VALIDATION_ON_GOING
} from "./Constant";
import {calculateTimeFromNow, getCurrentTime, isEmpty} from "./utils/helper";
import {useAuth0} from "@auth0/auth0-react";
import LazyLoading from "./Components/CommonScreens/LazyLoading";
import * as Sentry from "@sentry/react";
import useWebStorageHelper from "./utils/sessionStorageHelper";
import useCookieStorageHelper from "./utils/cookieStorageHelper";

Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
    const [isUserAuthenticated, setUserAuthenticated] = useState(false)
    const [isLoading,setIsLoading]=useState(true)
    const {
        isAuthenticated,
        loginWithRedirect,
        getIdTokenClaims,
        getAccessTokenSilently,
        user
    } = useAuth0();

    useEffect( () => {
        const getLocalUserData = useWebStorageHelper.getItem(USER_DATA_KEY)
        const getCurrentEpochTime = getCurrentTime()
        const getLocalUserValidation = getLocalStorageData(USER_VALIDATION_ON_GOING, false)
        if(getLocalUserData?.expires_on && getLocalUserData.expires_on > getCurrentEpochTime && getLocalUserValidation === "false"){
            setUserAuthenticated(true)
            setIsLoading(false)
            const getWindowLocationPath = window.location.pathname
            if(getWindowLocationPath === "/"){
                window.history.pushState('/dashboard/', '', INITIAL_PAGE);
            }
        }else if(getLocalUserValidation === "true" && isAuthenticated){
            validateToken()
        }else {
            setUserAuthenticated(false)
            setIsLoading(false)
            useWebStorageHelper.removeItem(USER_DATA_KEY)
        }
    }, [isAuthenticated]);

    const validateToken = async () => {
        const token = await getAccessTokenSilently()
        const futureEpochTime = calculateTimeFromNow()
        if(!isEmpty(token) && !isEmpty(user)){
            useCookieStorageHelper.setItemCookie(JWT_TOKEN_KEY, token, 1, true, 'Strict')
            //TODO Need to move user data to session
            useWebStorageHelper.setItem(USER_DATA_KEY, {...user, expires_on: futureEpochTime });
            setLocalStorageData(USER_VALIDATION_ON_GOING, false, false)
            setLocalStorageData(ONGOING_API_CALL_KEY, false, false)
            setTimeout(() => {window.location.href = INITIAL_PAGE}, 500)
        }
    }

    const onClickLoginButton = () => {
        setLocalStorageData(USER_VALIDATION_ON_GOING, false, true)
        loginWithRedirect();
    };

    return (
        <Sentry.ErrorBoundary fallback={Error}>
            <Box className="main-component">
                {isUserAuthenticated && !isLoading ? <Container /> : !isLoading &&
                    <Login onClickLoginButton={onClickLoginButton}/>}
                {isLoading && <LazyLoading />}
            </Box>
        </Sentry.ErrorBoundary>
    );
}
export default App;