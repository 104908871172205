export const RequestSupplyDetailTable = [
    {
        heading: "inv. relationship",
        type: "select",
        width: "136px",
        placeholder: "Select",
        options: [
            { value: "ono", label: "O&O" },
            { value: "exclusive", label: "Exclusive" },
            { value: "direct", label: "Direct" },
            { value: "reseller", label: "Reseller" },
        ],
        key: "inv_relationship",
        mappedKey: "Amagi's Monetization Rights"  // New key to match the data from the Excel sheet
    },
    {
        heading: "app name in app store",
        type: "text_input",
        width: "171px",
        placeholder: "App name",
        key: "app_name_in_app_store"
    },
    {
        heading: "channel name",
        type: "text_input",
        width: "171px",
        placeholder: "Channel/stream",
        key: "channel_name_stream"
    },
    {
        heading: "device type",
        type: "select",
        width: "158px",
        options: [
            { label: "CTV", value: "ctv" },
            { label: "Desktop", value: "desktop" },
            // { label: "DOOH", value: "dooh" },
            { label: "Mobile In-App", value: "in_app" },
            { label: "Mobile Web", value: "mobile_web" }
        ],
        placeholder: "Device Type",
        key: "device_type",
        mappedKey: "Device Type",
    },
    {
        heading: "device/platform",
        type: "text_input",
        width: "158px",
        placeholder: "Device/platform",
        key: "operating_system",
        mappedKey: "Operating System",
    },
    {
        heading: "Supply Label Id",
        type: "multi-select",
        width: "225px",
        placeholder: "Supply Label Id",
        key: "supply_label_id"
    },
    {
        heading: "app bundle id",
        type: "input",
        width: "171px",
        placeholder: "App bundle",
        key: "app_bundle_id",
        mappedKey: "Bundle ID / Domain"
    },
];
