import React, { useState } from 'react';
import {
    Checkbox,
    FormControl,
    InputAdornment,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField,
    Chip,
    IconButton,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Popover from "@mui/material/Popover";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";

const LIMIT_CHIP_SHOW = 1;

const SupplyLabelsMultiSelect = (props) => {
    const { options, value, onChange, formKey, index } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const open = Boolean(anchorEl);

    // Handle opening and closing of the popover
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopover(true);
        setFilteredOptions(options);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenPopover(false);
    };

    // Handle search inside the popover
    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase().trim();
        const filtered = options.filter(option => option.label.toLowerCase().includes(searchValue));
        setFilteredOptions(filtered);
    };

    const handleSelectLabel = (labelValue) => {
        const updatedValues = value.includes(labelValue)
            ? value.filter((val) => val !== labelValue)  // Remove if already selected
            : [...value, labelValue];  // Add if not selected

        // Call onChange to update the parent component with new selection
        onChange([...updatedValues], index, 'multi-select', formKey, labelValue);
    };

    const handleDeleteChip = (labelValue) => {
        // Filter out the label to remove it from the selected values
        const updatedValues = value.filter((val) => val !== labelValue);

        // Call onChange to update the parent with the new selection
        onChange([...updatedValues], index, 'multi-select', formKey, labelValue);
    };

    return (
        <FormControl variant="standard" size="small" className="multi-select-component" style={{ width: props.width }}>
            <ListItem
                secondaryAction={
                    <IconButton edge="end" aria-label="toggle-popover">
                        {openPopover ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                }
                onClick={handleClick}
                disablePadding
            >
                <ListItemText>
                    {value.length > 0 ? (
                        <>
                            {value.slice(0, LIMIT_CHIP_SHOW).map((label, idx) => (
                                <Chip
                                    key={idx}
                                    label={label}
                                    onDelete={() => handleDeleteChip(label)}
                                    deleteIcon={<CloseIcon />}
                                    className="multiselect-chip-level"
                                />
                            ))}
                            {value.length > LIMIT_CHIP_SHOW && (
                                <Chip
                                    label={`+${value.length - LIMIT_CHIP_SHOW} more`}
                                    className="multiselect-chip-level"
                                />
                            )}
                        </>
                    ) : (
                        <span className="list-placeholder width-class">Select value</span>
                    )}
                </ListItemText>
            </ListItem>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="multi-select-item-popover"
            >
                <MenuItem className="select-options-item search-item" disableRipple>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        style={{width: '100%'}}
                        onChange={handleSearch}
                    />
                </MenuItem>
                {filteredOptions?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.label}
                        onClick={() => handleSelectLabel(option.value)}
                        className="select-options-item"
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={value.includes(option.value)}
                                className="amg-white-checkbox"
                            />
                        </ListItemIcon>
                        {option.label}
                    </MenuItem>
                ))}
            </Popover>
        </FormControl>
    );
};

SupplyLabelsMultiSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    formKey: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    width: PropTypes.string
};

export default SupplyLabelsMultiSelect;
