import {TextField} from "@mui/material";
import PropTypes from "prop-types";

function SimpleInput(props){

    const handleChange = (e) => {
        if ( e.target.value < 0) {
            return;
        }
        const value = e?.target?.value;
        props.onChange(value, props.index, props.type, props.formKey)
    }

    return(
        <>
            {props.preUnit && <span className="pre-unit-simple-input">{props.preUnit}</span>}
            <TextField
                variant="standard"
                placeholder={props.placeholder || "Enter the value"}
                size="small"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                value={props.value}
                className="number-simple-input"
                type="number"
                min={0}
                InputProps={{
                    inputProps: {max: 999999999}
                }}
                style={{width: props?.textWidth ?? "90%"}}
            />
            {props.postUnit && ( <span className="post-unit-simple-input">{props.postUnit}</span>)}
        </>
    )
}

SimpleInput.propTypes = {
    "value": PropTypes.string.isRequired,
    "type": PropTypes.string.isRequired,
    "index": PropTypes.number.isRequired,
    "onChange": PropTypes.func.isRequired,
    "postUnit": PropTypes.string,
    "preUnit": PropTypes.string,
    "formKey": PropTypes.string.isRequired,

}

export default SimpleInput