import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import PropTypes from "prop-types";
import "./index.css"
import {OPEN_MARKET_SUMMERY, TOTAL_REVENUE_KEY} from "../../Constant";
import {removeFractions, getColorByKey, getMinMaxValueByKey} from "../../utils/helper";
import SortIcon from "../../assests/icons/sortIcon";

function StickyTable(props) {

    const getMinMaxValuesForEachIndex = (key) => {

        const maxIndex = Math.max(...props.tableData.map(item => item.partner_details.length));
        const minMaxValues = Array.from({length: maxIndex}).map((_, index) => {
            const values = props.tableData
                .map(item => item.partner_details[index]?.[key]) // access the key at each index
                .filter(value => typeof value === 'number' && !isNaN(value)); // Filter valid values

            if (values.length === 0) return {min: 0, max: 0};// Handle cases with no valid data

            return {
                min: Math.min(...values),
                max: Math.max(...values)
            };
        });

        return minMaxValues;
    }

    const minMaxValues = getMinMaxValuesForEachIndex(TOTAL_REVENUE_KEY);
    const minMaxValueAllPartners = getMinMaxValueByKey(props.tableData, TOTAL_REVENUE_KEY);

    return(
        <Grid item xs={12} className="ads-plus-table-container revenue-table">
            <Table className="ads-plus-reporting-table" stickyHeader>
                <TableHead>
                    <TableCell component="th" scope="row" style={{
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        left: 0,
                        zIndex: 9,
                        color: "#999999"
                    }} size="medium">
                        Date <SortIcon className="sort-sticky-table" onClick={props.handleOnsSortDate}/>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        left: 123,
                        zIndex: 9,
                        color: "#999999"
                    }} className="right-border" size="medium">
                        All Partner
                    </TableCell>
                    {props.type === OPEN_MARKET_SUMMERY ? props.openMarketTableColumns.map((header) => {
                        return(
                            <TableCell key={header} component="th" size="medium" scope="row" onClick={(_) => props.onClickPartnerDetails(header)}>
                                {header}
                            </TableCell>
                        )
                    }) : props.tableColumns.map((header) => {
                        return(
                            <TableCell key={header} component="th" size="medium" scope="row" onClick={(_) => props.onClickPartnerDetails(header)}>
                                {header}
                            </TableCell>
                        )
                    })}
                </TableHead>
                <TableBody>
                    {props.tableData.map((data) => {
                        return(
                            <TableRow key={data.date}>
                                <TableCell style={{
                                    whiteSpace: 'nowrap',
                                    position: 'sticky',
                                    left: 0
                                }} size="medium">
                                    {data.date}
                                </TableCell>
                                <TableCell style={{
                                    whiteSpace: 'nowrap',
                                    position: 'sticky',
                                    left: 123,
                                    backgroundColor: props.type !== OPEN_MARKET_SUMMERY
                                        ? getColorByKey(removeFractions(data?.[TOTAL_REVENUE_KEY]), minMaxValueAllPartners.min, minMaxValueAllPartners.max)
                                        : null
                                }} className="right-border" size="medium">
                                    ${props.type === OPEN_MARKET_SUMMERY ? removeFractions(data?.dailyOpenMarketRevenue) : removeFractions(data?.[TOTAL_REVENUE_KEY]) || 0}
                                </TableCell>
                                {props.type === OPEN_MARKET_SUMMERY ? data.open_market_summery.map((partnerData) => {
                                    return(
                                        <TableCell scope="row" size="medium" className="text-color-white">
                                            ${removeFractions(partnerData?.[TOTAL_REVENUE_KEY])}
                                        </TableCell>
                                    )
                                }) : data.partner_details.map((partnerData, partnerIndex) => {
                                    const { min, max } = minMaxValues[partnerIndex];
                                    return(
                                        <TableCell scope="row" size="medium" className="text-color-white"
                                                   style={{backgroundColor : getColorByKey(removeFractions(partnerData?.[TOTAL_REVENUE_KEY]), min, max)}}>
                                            ${removeFractions(partnerData?.[TOTAL_REVENUE_KEY])}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                    <TableRow className="total-row-sticky-table">
                        <TableCell style={{
                            whiteSpace: 'nowrap',
                            position: 'sticky',
                            left: 0
                        }} size="medium"> Total Revenue </TableCell>
                        <TableCell style={{
                            whiteSpace: 'nowrap',
                            position: 'sticky',
                            left: 123
                        }} className="right-border" size="medium">
                            ${props.type === OPEN_MARKET_SUMMERY ? removeFractions(props.allDayOpenBidderTotalRevenue) : removeFractions(props?.allDayPartnerTotalRevenue) || 0}
                        </TableCell>
                        {props.type === OPEN_MARKET_SUMMERY ? props.openMarketBidderWiseTotalRevenue.map((data) => {
                            return(
                                <TableCell key={data} size="medium"> ${removeFractions(data) || 0} </TableCell>
                            )
                        }) : props.allPartnerTotalRevenue.length > 0 && props.allPartnerTotalRevenue.map((data) => {
                            return(
                                <TableCell key={data} size="medium"> ${removeFractions(data) || 0} </TableCell>
                            )
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
    )
}

StickyTable.propTypes = {
    "tableColumns": PropTypes.arrayOf(PropTypes.string.isRequired),
    "tableData": PropTypes.arrayOf(PropTypes.shape({
        "date": PropTypes.string.isRequired,
        "partner_details": PropTypes.arrayOf(
            PropTypes.shape({
                "date": PropTypes.string.isRequired,
                "supply_partner_id": PropTypes.number.isRequired,
                "supply_partner_name": PropTypes.string.isRequired,
                "revenue": PropTypes.number.isRequired,
                "cost": PropTypes.number.isRequired,
                "impressions": PropTypes.number.isRequired,
                "net_revenue": PropTypes.number.isRequired,
                "margin_per": PropTypes.number.isRequired,
                "bids": PropTypes.number.isRequired,
                "wins": PropTypes.number.isRequired,
                "fill_rate": PropTypes.number.isRequired,
                "supply_timeouts": PropTypes.number.isRequired,
                "ecpm": PropTypes.number.isRequired,
                "rpm": PropTypes.number.isRequired,
                "pod_time_request": PropTypes.number.isRequired,
                "pod_time_return": PropTypes.number.isRequired,
                "pod_time_filled": PropTypes.number.isRequired,
                "pod_time_fill_rate": PropTypes.number.isRequired
            })
        ),
        "total_revenue": PropTypes.number.isRequired,
        "impressions": PropTypes.number.isRequired,
        "revenue": PropTypes.number.isRequired,
        "open_market_summery": PropTypes.arrayOf(PropTypes.shape({
            "bid_rate": PropTypes.number.isRequired,
            "bidder_account_id": PropTypes.number.isRequired,
            "bidder_account_name": PropTypes.string.isRequired,
            "bidder_errors": PropTypes.number.isRequired,
            "bids": PropTypes.number.isRequired,
            "cost": PropTypes.number.isRequired,
            'date': PropTypes.string.isRequired,
            "demand_partner_id": PropTypes.number.isRequired,
            "erpm": PropTypes.number.isRequired,
            "impressions": PropTypes.number.isRequired,
            "margin": PropTypes.number.isRequired,
            "margin_per": PropTypes.number.isRequired,
            "requests": PropTypes.number.isRequired,
            "response_time": PropTypes.number.isRequired,
            "revenue": PropTypes.number.isRequired,
            "use_rate": PropTypes.number.isRequired,
            "win_rate": PropTypes.number.isRequired,
            "wins": PropTypes.number.isRequired
        })),
    })),
    "allDayPartnerTotalRevenue": PropTypes.number.isRequired,
    "onClickPartnerDetails": PropTypes.func.isRequired,
    "allPartnerTotalRevenue": PropTypes.arrayOf(PropTypes.number),
    "handleOnsSortDate": PropTypes.func.isRequired,
    "type": PropTypes.string.isRequired,
    "openMarketTableColumns": PropTypes.arrayOf(PropTypes.string.isRequired),
    "openMarketBidderWiseTotalRevenue": PropTypes.arrayOf(PropTypes.number.isRequired),
    "allDayOpenBidderTotalRevenue": PropTypes.number

}

export default StickyTable