import {BFF_LAYER_URI} from "../Constant";
import axios from "axios";
import {adsHeader} from "../utils/helper";

export const getConfigDetailsByJobID = (id = "") => {
    let data = JSON.stringify({
        query: `query MyQuery($id: String = "") {
          getAdsPlusRequestConfigByID(id: $id) {
              comments
              created_at
              request_job_id
              requested_user
              updated_at
              type
              status
              meta 
              config
              approved_by
            }
        }`,
        variables: {"id":id}
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data : data
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data?.getAdsPlusRequestConfigByID && response.status === 200){
                return response.data.data.getAdsPlusRequestConfigByID
            }

            return {}
        })
        .catch((error) => {
            console.log(error);
            return {}
        });
}