export const DeviceDistributionConfig = [
    {
        rowLabel: "CTV",
        fields: [
            {
                type: "text_input",
                key: "ctv",
                placeholder: " ",
                width: "99px"
            }
        ]
    },
    {
        rowLabel: "Desktop",
        fields: [
            {
                type: "text_input",
                key: "desktop",
                placeholder: " ",
                width: "99px",
            }
        ]
    },
    {
        rowLabel: "DOOH",
        fields: [
            {
                type: "text_input",
                key: "dooh",
                placeholder: " ",
                width: "99px",
            }
        ]
    },
    {
        rowLabel: "Mobile In-app",
        fields: [
            {
                type: "text_input",
                key: "in_app",
                placeholder: " ",
                width: "99px"
            }
        ]
    },
    {
        rowLabel: "Mobile Web",
        fields: [
            {
                type: "text_input",
                key: "mobile_web",
                placeholder: " ",
                width: "99px"
            }
        ]
    }
];
