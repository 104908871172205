import React from "react";
import PropTypes from "prop-types";

function ActivityLogBreadCrumbs (props) {
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/activity-log"
                )}
                className="amg-bread-crumbs-links"
            >
                Activity Log &nbsp;
            </span>
            / &nbsp;<span> {props?.activityName} </span> &nbsp;

        </React.Fragment>
    )
}

ActivityLogBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
}

export default ActivityLogBreadCrumbs