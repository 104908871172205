import { Grid, Typography, InputAdornment, Button, Divider } from "@mui/material";

import {useState} from "react";

import SearchIcon from "../../../assests/icons/searchIcon";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import React, {useEffect} from "react";
import "./index.css";
import {FilterIcon} from "../../../assests/icons/FilterIcon";
import FilterIconLog from "../../../assests/icons/FilterIconLog";

function FilterTableWithClearAllButton(props) {

    const [searchText, setSearchText] = useState(props.searchText || "");

    const onSearchSetText = (e) => {
        setSearchText(e.target.value);
        props.onSearch(e.target.value);
    }

    useEffect(()=>{
        if(props.selectedRowLength===0) {
            props.clearSelection()
        }
    },[props.selectedRowLength])

    return (
        <Grid item xs={12}>
            <Grid container className="amg-table-content">
                <Grid item xs className="amg-table-content-filter ">
                    <Grid container className= "amg-action-header-clear">
                        {!props?.showActionButton && (
                            <React.Fragment>
                                <Grid item xs className="amg-table-content-count">
                                    <Typography>
                                        <span className="amg-white-text"> {props?.count && props.count !== 0 ? props.count : ""}  </span>
                                        {props?.count && props.count !== 0 ?props.titleText: ""}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    className="amg-table-content-search"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <TextField

                                        value={searchText}

                                        onChange={(e) => onSearchSetText(e)}
                                        placeholder={`Search`}
                                        variant="outlined"
                                        className="generic-search-container"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon></SearchIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />


                                </Grid>
                            </React.Fragment>
                        )}
                        {props.showActionButton &&(
                            <React.Fragment>
                                <Grid item xs={9} className="left-action-bar">
                                    <Button className="add-macros-black-button" onClick={() => props.onClickActionButton()}><AddIcon/>{props.actionButtonName}</Button>
                                </Grid>

                                <Grid item  xs={3} className="right-action-bar">
                                    <Grid container>
                                        <Grid item xs={7} sx={{textAlign: "left"}}>
                                            <Typography className="selected-count-text">{props.selectedRowLength} {props.titleText.toLowerCase()} selected</Typography>
                                        </Grid>
                                        <Grid item xs={1} >
                                            <Divider className="clear-all-seperator" orientation="vertical" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button className="clear-all-black-button" onClick={()=>props.clearSelection()} variant="outlined">Clear all</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

FilterTableWithClearAllButton.propTypes = {
    count: PropTypes.number,
    titleText: PropTypes.string,
    showActionButton: PropTypes.bool,
    onClickCreateNewPlatform: PropTypes.func,
    onSearch: PropTypes.func,
    actionButtonName: PropTypes.string,
    onClickActionButton: PropTypes.func,
    clearSelection: PropTypes.func,
    selectedRowLength: PropTypes.number
};

export default FilterTableWithClearAllButton;