import React from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import styled from "styled-components";
import IconContainer from "./IconContainer";

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 10px;
    right: 10px;
    background-color: #fda560; // Set the badge color to white
  }
`;

const StyledIconButton = styled(IconButton)`
  color: white;
  padding: 0;
`;

export const FilterIcon = ({ onClick }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M17.5 2.5V4.16667H16.6667L12.5 10.4167V17.5H7.5V10.4167L3.33333 4.16667H2.5V2.5H17.5ZM5.33667 4.16667L9.16667 9.91167V15.8333H10.8333V9.91167L14.6633 4.16667H5.33667Z"
                fill="#E7E7E7"
            />
        </svg>
    );
};

function FilterIconSet(props ) {


    return (
        <StyledBadge>
            <StyledIconButton  data-testid="filter-icon-button">
                <IconContainer>
                    <FilterIcon />
                </IconContainer>
            </StyledIconButton>
        </StyledBadge>
    );
}

export default FilterIconSet;
