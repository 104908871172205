import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {ADS_TYPE_CONVERSION, CURRENCY_DENOTE_TYPE, NUMBER_DENOTE_TYPE, PERCENTAGE_DENOTE_TYPE} from "../../Constant";
import {addPercentage, formatNumber, formatRevenue, removeFractions} from "../../utils/helper";

const CustomTooltip = (props) => {
    const [visible, setVisible] = useState(false);
    const [payload, setPayload] = useState([]);
    const [label, setLabel] = useState("");

    useEffect(() => {
        if(props?.active && props.payload.length > 0 && props?.label){
            let date = new Date(props.label);
            const options = { month: 'long', day: 'numeric' , year: 'numeric'};
            let formattedData = date.toLocaleDateString('en-US', options)

            setVisible(true);
            setPayload(props.payload);
            setLabel(formattedData);
        }
    }, [props])

    if (visible) {
        return (
            <Grid className="custom-tool-tip">
                <Typography className="custom-tool-tip-label">
                    {label}
                </Typography>
                {
                    payload.map((item, index) => {
                        return(
                            <Typography className="custom-tool-tip-item" key={index}>
                                <span className="custom-tool-tip-item-filler" style={{backgroundColor: item.color}} />
                                <span className="custom-tool-tip-item-label">
                                    {item.dataKey}
                                </span>
                                <span>
                                    {
                                        ADS_TYPE_CONVERSION[item.dataKey] === NUMBER_DENOTE_TYPE ? formatNumber(removeFractions(item.value)) :
                                            ADS_TYPE_CONVERSION[item.dataKey] === CURRENCY_DENOTE_TYPE ? formatRevenue(removeFractions(item.value)) :
                                                ADS_TYPE_CONVERSION[item.dataKey] === PERCENTAGE_DENOTE_TYPE ? addPercentage(removeFractions(item.value)):
                                                    item.value
                                    }
                                </span>
                            </Typography>
                        )
                    })
                }
            </Grid>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    "payload": PropTypes.arrayOf(PropTypes.shape({
        "dataKey": PropTypes.string.isRequired,
        "color": PropTypes.string.isRequired,
        "value": PropTypes.number.isRequired,
    })),
    "active": PropTypes.bool
}

export default CustomTooltip