import { gql } from "@apollo/client";
import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getMacroListByChannelId = async (id = "", email = "", type = "" ) => {
    const query = gql`
        query MyQuery($id: String = "", $type: String = "") {
            getMacroListingByChannelId(id: $id, type: $type) {
                amagi_key
                required
                ctv
                hardcoded
                web
                spring_serve_macro
                ts_version
                missing_in_platform
                source
                created_at
                updated_at
            }
        }
    `;

    const variables = { "id": id, "type": type };

    const data = JSON.stringify({
        query: query.loc.source.body,
        variables
    });
    const headers = adsHeader()

    const config = {
        method: 'post',
        url: BFF_LAYER_URI,
        headers: headers,
        data
    };

    try {
        const response = await axios.request(config);
        if (response?.data?.data?.getMacroListingByChannelId && response.status === 200) {
            return response.data.data.getMacroListingByChannelId;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching macro list:", error);
        return null;
    }
};
