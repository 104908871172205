
import { Box, Button, Chip, Grid, InputAdornment, MenuItem, Popover, Select, TextField, Typography, Checkbox, FormControlLabel, Divider, Input, Skeleton } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";


import getStreamIds from "../../../api/getStreamIds";
import getSupplyPartnerId from "../../../api/getSupplyPartnerIds";
import getSupplyPartnerName from "../../../api/getSupplyPartnerName";
import getSupplyTagIds from "../../../api/getSupplyTagIds";
import AddIcon from "../../../assests/icons/addIcon";
import CalendarIcon from "../../../assests/icons/calendar";
import CrossIcon from "../../../assests/icons/crossIcon";
import DeleteIcon from "../../../assests/icons/deleteIcon";
import DownIcon from "../../../assests/icons/downIcon";
import {
    SELECT_COMPARISON_EQUALS,
    SELECT_COMPARISON_NUM,
    SELECT_COMPARISON_STRING,
    SELECT1_OPTION_STR,
    SEVERITY_TYPE,
    TODAYS_DATE,
    SUCCESS_TYPE,
    REPORT_LANDING_PAGE,
    MAX_DATE, DATE_PICKER_FORMAT
} from "../../../Constant";
import SearchIcon from "../../../assests/icons/searchIcon";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";

import "./index.css";
import DownIconBig from "../../../assests/icons/downIconBig";
import {checkNegative} from "../../../utils/helper";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useDebounce from '../../../utils/useDebounce';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const CustomCalendarIcon = () => {
    return <CalendarIcon />;
};


const CustomDropDownIcon = () => {
    return <DownIcon/>;
};


function getStyles(name, value, theme) {
    return {
        fontWeight:
            value?.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ReportGenerationInput = (props) => {
    const theme = useTheme();
    const [criteriaInputs, setCriteriaInputs] = useState([
        {
            index: 0,
            criterion: "Date",
            operator: "Equals to(=)",
            value: [TODAYS_DATE],
        },
    ]);
    const [streamIds, setStreamIds] = useState([]);
    const [supplyTagId, setSupplyTagId] = useState([]);
    const [supplyPartnerId, setSupplyPartnerId] = useState([]);
    const [supplyPartnerName, setSupplyPartnerName] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState(["Date"]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorOperatorEl, setAnchorOperatorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState();
    const [menuLoading, setMenuLoading] = useState(false);
    const navigation = useNavigate();
    const [newWhere, setNewWhere] = useState({
        date: { equals: TODAYS_DATE },
        stream_id: {},
        supply_partner_id: {},


        supply_partner_name: {},

        supply_tag_id: {},
        total_ad_request: {},
        total_impression: {}
    });

    const [allSupplyTagID, setAllSupplyTagID] = useState([]);
    const [allSupplyPartnerID, setAllSupplyPartnerID] = useState([]);
    const [allStreamID, setAllStreamID] = useState([]);
    const [allSupplyPartnerName, setAllSupplyPartnerName] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');


    const handleChange = (event, index) => {
        if(!checkNegative(event?.target?.value)) {
            const value = event.target.value;
            const updatedCriteriaInputs = [...criteriaInputs];
            const criteria = updatedCriteriaInputs[index].criterion;

            if (criteria === "Stream ID" || criteria === "Supply tag ID" || criteria === "Supply partner ID" || criteria === "Supply partner name") {
                const parsedValue = typeof value === "number" ? String(value) : value;
                const selectedValues = updatedCriteriaInputs?.[index]?.value;

                // Check if the value is already present in the selected values
                const valueIndex = selectedValues.indexOf(parsedValue);


                if (valueIndex === -1) {
                    // If the value is not present, add it
                    selectedValues.push(parsedValue);
                } else {
                    // If the value is present, remove it
                    selectedValues.splice(valueIndex, 1);
                }

                // Update the state with the modified selected values
                setValueValue(index, selectedValues);
            }
            else if (criteria === "Total Ad requests" || criteria === "Total impressions") {
                const numberValue = Number(value);
                setValueValue(index, numberValue);
            }
            else {
                setValueValue(index, value);
            }
        }
    };

    const handleAddInput = () => {
        // Check if any criteria input is empty
        const hasEmptyInput = criteriaInputs.some(
            (input) => !input.criterion || !input.operator ||
                (input.value === undefined || input.value === "" || (Array.isArray(input.value) && input.value.length === 0))
        );

        // If any input is empty, prevent adding more criteria
        if (hasEmptyInput) {
            props.NotificationOpen();
            props.SetMessage("Please fill all criteria fields before adding more.");
            props.SetAlert(SEVERITY_TYPE);
            return;
        }
        // Otherwise, add a new empty criteria input with an index
        const newIndex = criteriaInputs?.length; // Get the index for the new criteria input
        setCriteriaInputs([
            ...criteriaInputs,
            { index: newIndex, criterion: "", operator: "", value: [] },
        ]);
    };
    const handleCancel = () => {
        setCriteriaInputs([{
            index: 0,
            criterion: "Date",
            operator: "Equals to(=)",
            value: [TODAYS_DATE],
        }]);
        setNewWhere({
            date: { equals: TODAYS_DATE },
            stream_id: {},
            supply_partner_id: {},
            supply_partner_name: {},
            supply_tag_id: {},
            total_ad_request: {},
            total_impression: {}
        })
    };

    const setCriterionValue = (index, value) => {
        const  selected_criteria = [...selectedCriteria]
        selected_criteria[index]=value

        const updatedCriteriaInputs = [...criteriaInputs];


        if (updatedCriteriaInputs[index].criterion) {
            const criteriaIndex = updatedCriteriaInputs?.findIndex(criteria => criteria.index === index);
            console.log(criteriaIndex)
            if (criteriaIndex !== -1) {
                // Get the deleted criterion
                const deletedCriterion = updatedCriteriaInputs?.[criteriaIndex]?.criterion;
                const updatedSelectedCriteria = selectedCriteria.filter(criterion => criterion !== deletedCriterion);

                setSelectedCriteria(updatedSelectedCriteria);

            }
        }

        updatedCriteriaInputs[index].criterion = value;
        setCriteriaInputs(updatedCriteriaInputs);
        setSelectedCriteria(selected_criteria)
        updatedCriteriaInputs[index].operator = ""; // Reset operator to empty
        updatedCriteriaInputs[index].value = []; // Reset value to empty
        handleClose();
        setSearchQuery("")
        if (criteriaInputs[index].criterion === "Date") {
            setOperatorValue(index, "Equals to(=)");
            setValueValue(index, TODAYS_DATE);
        }
        if (value === "Stream ID") {
            getStreamIdList();
        } else if (value === "Supply tag ID") {
            getSupplyTagList();
        } else if (value === "Supply partner ID") {
            getSupplyPartnerList();
        } else if (value === "Supply partner name") {
            getSupplyPartnerNameList();
        }
    };

    const setOperatorValue = (index, event) => {
        const value = event?.target?.value;
        const updatedCriteriaInputs = [...criteriaInputs];
        updatedCriteriaInputs[index].operator = value;
        setCriteriaInputs(updatedCriteriaInputs);
        handleCloseOperator();
    };

    const setValueValue = (index, value) => {
        const updatedCriteriaInputs = [...criteriaInputs];
        updatedCriteriaInputs[index].value = value;
        setCriteriaInputs(updatedCriteriaInputs);
        handleCloseOperator();
    };


    const handleDeleteCriteria = (index) => {
        const updatedCriteriaInputs = [...criteriaInputs];
        const new_where_query = newWhere
        const delete_criteria = [...selectedCriteria]
        // Find the index of the criteria with the specified index



        const criteriaIndex = updatedCriteriaInputs?.findIndex(criteria => criteria.index === index);

        const deletedCriterion = updatedCriteriaInputs?.[index]?.criterion || "";
        delete_criteria.splice(index,1)
        // Remove the criterion, operator, and value from criteriaInputs
        updatedCriteriaInputs.splice(criteriaIndex, 1);



       const new_criteria_input =  updatedCriteriaInputs.map((data)=>{
           if(data?.index>=index){
               return {
                   ...data,index : data.index-1
               }
           }
           return data
       })


        // Update criteriaInputs state
        switch (deletedCriterion) {
            case "Stream ID":
                new_where_query["stream_id"]= {};
                break;
            case "Supply tag ID":
                new_where_query["supply_tag_id"]= {};
                break;
            case "Supply partner ID":
                new_where_query["supply_partner_id"]= {};
                break;
            case "Supply partner name":
                new_where_query["supply_partner_name"]= {};
                break;
            case "Total Ad requests":
                new_where_query["total_ad_request"]= {};
                break;
            case "Total impressions":
                new_where_query["total_impression"]= {};
                break;
            default:
                break;
        }
        setCriteriaInputs(new_criteria_input);
        setSelectedCriteria(delete_criteria)


    };


    const handleDateChange = (newDate, index) => {
        const updatedCriteriaInputs = [...criteriaInputs];
        updatedCriteriaInputs[index].value = newDate;
        setCriteriaInputs(updatedCriteriaInputs);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOperator = (event) => {
        setAnchorOperatorEl(event.currentTarget);
    };

    const handleCloseOperator = () => {
        setAnchorOperatorEl(null);
    };

    const onSetReportName = (e) => {
        props.changeReportName(e.target.value);
    };

    const getStreamIdList = async () => {
        setMenuLoading(true)
        try {
            const streamIds = await getStreamIds();
            setStreamIds(streamIds);
            setMenuLoading(false)
            setAllStreamID(streamIds)
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getSupplyTagList = async () => {
        setMenuLoading(true)
        try {
            const supplyTagIds = await getSupplyTagIds();
            const getFirst100Data = supplyTagIds?.filter((data, index) => index < 100 && data)
            setSupplyTagId(getFirst100Data);
            setAllSupplyTagID(supplyTagIds)
            setMenuLoading(false)
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getSupplyPartnerNameList = async () => {
        setMenuLoading(true)
        try {
            const supplyPartners = await getSupplyPartnerName();
            setSupplyPartnerName(supplyPartners);
            setMenuLoading(false)
            setAllSupplyPartnerName(supplyPartners)
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getSupplyPartnerList = async () => {
        setMenuLoading(true)
        try {
            const supplyPartnerIds = await getSupplyPartnerId();
            setSupplyPartnerId(supplyPartnerIds);
            setMenuLoading(false)
            setAllSupplyPartnerID(supplyPartnerIds)
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handlePreviewClick = () => {

        const new_where_query = newWhere


        const hasEmptyInput = criteriaInputs.some(
            (input) => !input.criterion || !input.operator ||
                (input.value === undefined || input.value === "" || (Array.isArray(input.value) && input.value.length === 0))
        );
        if (hasEmptyInput) {
            props.NotificationOpen();
            props.SetMessage("Please fill all criteria !.");
            props.SetAlert(SEVERITY_TYPE);
            return;
        }
        else{
            criteriaInputs.forEach((input) => {
                if (input.criterion === "Stream ID") {
                    const operator = input.operator === "is" ? "in" : "notIn";
                    new_where_query["stream_id"]= { [operator]: input.value };
                }
                if (input.criterion === "Supply tag ID") {
                    const operator = input.operator === "is" ? "in" : "notIn";
                    new_where_query["supply_tag_id"]= { [operator]: input.value };
                }
                if (input.criterion === "Supply partner ID") {
                    const operator = input.operator === "is" ? "in" : "notIn";
                    new_where_query["supply_partner_id"]= { [operator]: input.value };
                }
                if (input.criterion === "Supply partner name") {
                    const operator = input.operator === "is" ? "in" : "notIn";
                    new_where_query["supply_partner_name"]= { [operator]: input.value };
                }

                if (input.criterion === "Total Ad requests") {
                    const operator = input.operator === "Greater than or equal to (>=)" ? "gte" : "lte";
                    new_where_query["total_ad_request"]= { [operator]: input.value };
                }
                if (input.criterion === "Total impressions") {
                    const operator = input.operator === "Greater than or equal to (>=)" ? "gte" : "lte";
                    new_where_query["total_impression"]= { [operator]: input.value };
                }
                if (input.criterion === "Date") {
                    const dateObject = new Date(input.value);
                    const convertedDate = format(dateObject, "yyyy-MM-dd");
                    new_where_query["date"]= { equals: convertedDate };
                }

            })
        }
        props.getPreviewData(new_where_query)
        setNewWhere(new_where_query)


    };

    const clearText = () => {
        props.changeReportName(""); // Clear the report name by setting it to an empty string
    };

    const startDownload = async () => {
        await handlePreviewClick(); // Wait for the preview update to finish

        if (props.reportName) {
            props.ReportDownload(newWhere);
            props.NotificationOpen();
            props.SetMessage("Report is generating, you are being redirected for downloading it!");
            props.SetAlert(SUCCESS_TYPE);
        } else {
            props.NotificationOpen();
            props.SetMessage("Please Enter Report Name!");
            props.SetAlert(SEVERITY_TYPE);
            return;
        }

        // Navigate after a delay of 5 seconds
        setTimeout(() => {
            navigation(`/${REPORT_LANDING_PAGE}`);
        }, 3000);
    }

    const debounceSearch = useDebounce((searchValue, type) => {
        if (searchValue.length > 0) {
            if (type === "Stream ID") {
                const getAllSupplyStreamId = allStreamID.filter((data) => data.stream_id.includes(searchValue));
                setStreamIds(getAllSupplyStreamId);
            }

            if (type === "Supply tag ID") {
                const getAllSupplyStreamId = allSupplyTagID.filter((data) => String(data.supply_tag_id).startsWith(searchValue));
                const getFirst100Data = getAllSupplyStreamId.slice(0, 100); // Get first 100 results
                setSupplyTagId(getFirst100Data);
            }

            if (type === "Supply partner ID") {
                const getAllSupplyPartnerId = allSupplyPartnerID.filter((data) =>
                    String(data.supply_partner_id).startsWith(searchValue)
                );
                const getFirst100Data = getAllSupplyPartnerId.slice(0, 100); // Get first 100 results
                setSupplyPartnerId(getFirst100Data);
            }

            if (type === "Supply partner name") {
                const getAllSupplyPartnerName = allSupplyPartnerName.filter((data) => data.supply_partner_name.toLowerCase().includes(searchValue));
                setSupplyPartnerName(getAllSupplyPartnerName);
            }
        } else if (searchValue.length === 0) {
            if (type === "Stream ID") {
                setStreamIds(allStreamID);
            }

            if (type === "Supply tag ID") {
                const getFirst100Data = allSupplyTagID.slice(0, 100); // Get first 100 results
                setSupplyTagId(getFirst100Data);
            }

            if (type === "Supply partner ID") {
                const getFirst100Data = allSupplyPartnerID.slice(0, 100);
                setSupplyPartnerId(getFirst100Data);
            }

            if (type === "Supply partner name") {
                setSupplyPartnerName(allSupplyPartnerName);
            }
        }

        setSearchQuery(searchValue);
    }, 0);

    const handleSearchChange = (event, type) => {
        const searchValue = event.target.value;
        debounceSearch(searchValue, type);
    };

    return (
        <React.Fragment>
            <Grid item xs={12} className="report-generation-input-grid">
                <Grid item xs={12} className="report-condition-grid">
                    <Grid item xs={12} className="condition-textfield-grid">
                        <Grid item xs={1}>
                            <Typography className="amg-form-label">
                                {" "}
                                Report Name
                                <span className="required-input-span">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <TextField
                                id="standard-basic"
                                variant="standard"
                                placeholder={"Enter report name"}
                                className="text-field-standard"
                                onChange={(e) => onSetReportName(e)}
                                value={props.reportName}
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CrossIcon clearData={clearText} />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ maxLength: 32 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="condition-textfield-grid">
                        <Grid container>
                            <Grid item xs={1}>
                                <Typography className="amg-form-label">
                                    {" "}
                                    Report Criteria
                                    <span className="required-input-span">*</span>
                                </Typography>
                            </Grid>
                            <Grid className="criteria-input-select-grid" item xs={11}>
                                {criteriaInputs?.length > 0 ? (
                                    criteriaInputs?.map((input, index) => (
                                        <Grid
                                            container
                                            key={`${index}-${input.criterion}`}
                                            className="criteria-container-grid"
                                        >
                                            <Grid item className="criteria-input-select-cell">
                                                <TextField
                                                    id="standard-select-currency"
                                                    variant="standard"
                                                    className="select-1"
                                                    value={input.criterion}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <DownIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onClick={(event) => {
                                                        index!==0 &&
                                                        handleClick(event);
                                                    }}
                                                />
                                                <Popover
                                                    className="report-criteria-select-popover"
                                                    open={Boolean(anchorEl)}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                >
                                                    <div className="option-popover-div">
                                                        {SELECT1_OPTION_STR.map((option) => {
                                                            if(selectedCriteria.indexOf(option)<0) {
                                                                return (
                                                                <div
                                                                    className="options-popover"
                                                                    onClick={() =>
                                                                        setCriterionValue(input.index, option)
                                                                    }
                                                                    key={option}
                                                                >
                                                                    {option}
                                                                </div>
                                                                )
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                </Popover>
                                            </Grid>
                                            {input.criterion && (
                                                <Grid item className="criteria-input-select-cell">
                                                    <Select
                                                        className="criteria-operator-select"
                                                        value={input.criterion === "Date" ? "Equals to (=)"  : input.operator}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        labelId="demo-operator-chip-label"
                                                        onChange={(event) => setOperatorValue(input.index, event)}
                                                    >
                                                        {input.criterion === "Stream ID" ||
                                                        input.criterion === "Supply tag ID" ||
                                                        input.criterion === "Supply partner ID" ||
                                                        input.criterion === "Supply partner name" ? SELECT_COMPARISON_STRING.map((option) => (
                                                            <MenuItem value={option} className="criteria-operator-options" key={option}>{option}</MenuItem>
                                                        )) : input.criterion === "Date" ? SELECT_COMPARISON_EQUALS.map((option) => (
                                                            <MenuItem value={option} className="criteria-operator-options" key={option}>{option}</MenuItem>
                                                        )) : SELECT_COMPARISON_NUM.map((option) => (
                                                            <MenuItem value={option} className="criteria-operator-options" key={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>)}
                                            {input.criterion && input.operator && (
                                                <Grid item className="criteria-input-select-value-cell">
                                                    {input.criterion === "Stream ID" ||
                                                        input.criterion === "Supply tag ID" ||
                                                        input.criterion === "Supply partner ID" ||
                                                        input.criterion === "Supply partner name" ? (
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            className="multiselect-chip"
                                                            value={criteriaInputs?.[input?.index]?.value || []}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                            renderValue={(selected) => {
                                                                const additionalChipsCount = selected.length > 2 ? selected.length - 2 : 0;
                                                                const visibleChips = selected.slice(0, 2);
                                                                return (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexWrap: "wrap",
                                                                            gap: 0.5,
                                                                        }}
                                                                    >
                                                                        {visibleChips.map((value) => (
                                                                            <Chip
                                                                                key={value}
                                                                                label={value}
                                                                            />
                                                                        ))}
                                                                        {additionalChipsCount > 0 && (
                                                                            <Chip
                                                                                key="more"
                                                                                label={`+${additionalChipsCount}`}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                );
                                                            }}
                                                            MenuProps={MenuProps}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                        >
                                                            {menuLoading ? (
                                                                <div style={{padding: "10px 10px"}}>
                                                                    {[...Array(5)].map((_, index) => (
                                                                        <div key={index}>
                                                                            <Skeleton variant="text" animation="wave" height={20} sx={{
                                                                                width: "850px", padding: "5px 5px", backgroundColor: "rgba(255, 255, 255, 0.08)"
                                                                            }} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <MenuItem className="search-select-input" onKeyDown={(e) => e.stopPropagation()} disableRipple>
                                                                        <Input
                                                                            className="amg-input-white amg-input-white-report"
                                                                            startAdornment={
                                                                                <InputAdornment position="start">
                                                                                    <SearchIcon/>
                                                                                </InputAdornment>
                                                                            }
                                                                            size="small"
                                                                            fullWidth
                                                                            placeholder="Search"
                                                                            value={searchQuery}
                                                                            onChange={(e) => handleSearchChange(e, input.criterion)}
                                                                        />
                                                                    </MenuItem>
                                                                    {input.criterion === "Stream ID" ? (
                                                                        // Render Stream ID options
                                                                        streamIds?.map((option) => (
                                                                            <MenuItem
                                                                                className="multiselect-menu"
                                                                                key={option.stream_id}
                                                                                value={option.stream_id}
                                                                                style={getStyles(
                                                                                    option.stream_id,
                                                                                    criteriaInputs?.[input?.index]?.value,
                                                                                    theme
                                                                                )}
                                                                                onClick={(event) => handleChange(event, input.index)}
                                                                            >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            className="amg-white-checkbox"
                                                                                            checked={criteriaInputs?.[input?.index]?.value.includes(option.stream_id)}
                                                                                            value={option.stream_id}
                                                                                        />
                                                                                    }
                                                                                    label={option.stream_id !== null ? option.stream_id : "N/A"}
                                                                                />
                                                                            </MenuItem>
                                                                        ))

                                                                    ) : input.criterion === "Supply tag ID" ?
                                                                        (supplyTagId && supplyTagId.length > 0 ? supplyTagId : allSupplyTagID).map((option) =>  {
                                                                            return (
                                                                                <MenuItem
                                                                                    className="multiselect-menu"
                                                                                    key={option.supply_tag_id}
                                                                                    value={option.supply_tag_id}
                                                                                    style={getStyles(
                                                                                        option.supply_tag_id,
                                                                                        criteriaInputs?.[input?.index]?.value,
                                                                                        theme
                                                                                    )}
                                                                                    onClick={(event) => handleChange(event, input.index)}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                className="amg-white-checkbox"
                                                                                                checked={criteriaInputs?.[input?.index]?.value?.includes(`${option.supply_tag_id}`)}
                                                                                                value={option.supply_tag_id}
                                                                                            />
                                                                                        }
                                                                                        label={option.supply_tag_id}
                                                                                    />
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                        : input.criterion === "Supply partner ID" ? (
                                                                            (supplyPartnerId && supplyPartnerId.length > 0 ? supplyPartnerId : allSupplyPartnerID).map((option) => (
                                                                                <MenuItem
                                                                                    className="multiselect-menu"
                                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                                    key={option.supply_partner_id}
                                                                                    value={option.supply_partner_id}
                                                                                    style={getStyles(
                                                                                        option.supply_partner_id,
                                                                                        criteriaInputs?.[input?.index]?.value,
                                                                                        theme
                                                                                    )}
                                                                                    onClick={(event) => handleChange(event, input.index)}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                className="amg-white-checkbox"
                                                                                                checked={criteriaInputs?.[input?.index]?.value.includes(`${option.supply_partner_id}`)}
                                                                                                value={option.supply_partner_id}
                                                                                            />
                                                                                        }
                                                                                        label={option.supply_partner_id}
                                                                                    />
                                                                                </MenuItem>
                                                                            ))
                                                                        ) : input.criterion === "Supply partner name" ? (
                                                                            // Render Supply Partner Name options
                                                                            supplyPartnerName?.map((option) => (
                                                                                <MenuItem
                                                                                    className="multiselect-menu"
                                                                                    key={option.supply_partner_name}
                                                                                    value={option.supply_partner_name}
                                                                                    style={getStyles(
                                                                                        option.supply_partner_name,
                                                                                        criteriaInputs?.[input?.index]?.value,
                                                                                        theme
                                                                                    )}
                                                                                    onClick={(event) => handleChange(event, input.index)}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                className="amg-white-checkbox"
                                                                                                checked={criteriaInputs?.[input?.index]?.value.includes(option.supply_partner_name)}
                                                                                                value={option.supply_partner_name}
                                                                                            />
                                                                                        }
                                                                                        label={option.supply_partner_name}
                                                                                    />
                                                                                </MenuItem>
                                                                            ))
                                                                        ) : null}
                                                                </React.Fragment>
                                                            )}
                                                        </Select>
                                                    ) : input.criterion === "Date" ? (
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterDayjs}
                                                            className="calendar-popover"
                                                        >
                                                            <DatePicker
                                                                defaultValue={dayjs(TODAYS_DATE)}
                                                                autoFocus={false}


                                                                value={dayjs(criteriaInputs?.[index]?.value)}


                                                                onChange={(newDate) =>
                                                                    handleDateChange(newDate, input.index)
                                                                }
                                                                renderInput={(props) => <input {...props} />}
                                                                format={DATE_PICKER_FORMAT}
                                                                className="date-picker"
                                                                maxDate={dayjs(MAX_DATE)}


                                                                slots={{
                                                                    openPickerIcon: CustomCalendarIcon,
                                                                }}
                                                                disableFuture={true}


                                                                disableHighlightToday={true}

                                                                PopperProps={{
                                                                    placement: "bottom-start"
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : (
                                                        <TextField
                                                            id="standard-basic"
                                                            value={criteriaInputs?.[index]?.value}
                                                            variant="standard"
                                                            type="tel"
                                                            className="text-field-standard"
                                                            InputProps={{
                                                                inputProps: { max: 999999999 }
                                                            }}
                                                            onKeyPress={(event) => {
                                                                if (event?.key === '-' || event?.key === '+') {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(event) =>
                                                                handleChange(event, input.index)
                                                            }
                                                            size="small"
                                                        />
                                                    )}
                                                </Grid>
                                            )}
                                            {index !== 0 && (
                                                <Grid>
                                                    <IconButton sx={{ marginLeft: "15px", paddingLeft: "0px" }}
                                                        onClick={() => handleDeleteCriteria(input.index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))
                                ) : (
                                    <Button
                                        className="add-criteria-button"
                                        onClick={() => {
                                            handleAddInput();
                                        }}
                                    >
                                        <AddIcon />
                                        <span className="add-criteria-span">Add Criteria</span>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {criteriaInputs?.length > 0 && (
                            <Button
                                className="add-criteria-button"
                                onClick={() => {
                                    handleAddInput();
                                }}
                            >
                                <AddIcon />
                                <span className="add-criteria-span">Add Criteria</span>
                            </Button>
                        )}
                    </Grid>

                    {/* PUT HERE */}
                </Grid>
                <Grid item xs={12} className="report-input-footer">
                    <Grid item xs={12} className="report-input-footer-button-grid">
                        <Button className="report-cancel-button" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            className="report-update-preview-button"
                            onClick={() => {
                                handlePreviewClick();
                            }}
                        >
                            Update preview
                        </Button>
                        <Button className="orange-button" onClick={() => startDownload()} >Save & generate</Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ReportGenerationInput;
