import { Alert, Snackbar, IconButton, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import PropTypes from "prop-types";
import {SUCCESS_TYPE} from "../../Constant"

function Notification(props) {
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={props.handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={props.handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <Snackbar
            className="user-del-snack"
            open={props.open}
            autoHideDuration={4000}
            onClose={props.handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={action}
        >
            <Alert
                onClose={props.handleClose}
                severity={props?.type || SUCCESS_TYPE}
                sx={{ width: "100%" }}
            >
                {props.message}
            </Alert>
        </Snackbar>
    );
}

Notification.propTypes = {
    message: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
}

export default Notification;
