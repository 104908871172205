import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AdsMultiSelectWithSearch from "../AdsMultiSelectWithSearch";
import {
    DEVICE_DISTRIBUTION_MAP,
    DYNAMIC_MACROS,
    INVENTORY_RELATIONS_SHIP_MAP,
    MULTI_SELECT_TYPE, OPERATING_SYSTEM_MAP, SEVERITY_TYPE,
    SIMPLE_SELECT_TYPE,
    TYPE_INPUT
} from "../../../Constant";
import AddIcon from "../../../assests/icons/addIcon";
import "./index.css";
import { RequestSupplyDetailTable } from "../../../FormConfig/requestSupplyDetailTable";
import AdsSimpleTextInput from "../AdsSimpleTextInput";
import AdsSimpleSelect from "../AdsSimpleSelect";
import { RequestContext } from "../../../Context/RequestContext";
import DeleteIcon from "../../../assests/icons/deleteIcon";
import SimpleTelInput from "../../../Common/SimpleTelInput";
import Notification from "../../Notification";
import SupplyLabelsMultiSelect from "../SupplyLabelsMultiSelect";


const AdsAppSupplyDetails = (props) => {
    const { supplyDetails, updateSupplyDetails, updateSupplyLabelFormData } = useContext(RequestContext);
    const [appSupplyFormDataMap, setAppSupplyFormDataMap] = useState({ "supply_tags": [] });
    const [appSupplyFormLabelMap, setAppSupplyFormLabelMap] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationType, setNotificationType] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");

    useEffect(() => {
        const dynamicMacros = supplyDetails?.dynamic_macros_list || [];
        setAppSupplyFormDataMap({
            ...supplyDetails,
            dynamic_macros_list: dynamicMacros,
        });
        if (supplyDetails.supply_tags?.length) {
            let  supplyFormLabelData = []
            supplyDetails.supply_tags.map((tag) => {
                const tagData = {}
                if(tag?.["inv_relationship"]){
                    tagData["inv_relationship"] = INVENTORY_RELATIONS_SHIP_MAP[tag["inv_relationship"]]
                }
                if(tag?.["device_type"]){
                    tagData["device_type"] = DEVICE_DISTRIBUTION_MAP[tag["device_type"]]
                }

                if(tag?.["operating_system"]){
                    tagData["operating_system"] = OPERATING_SYSTEM_MAP[tag["operating_system"]]
                }

                supplyFormLabelData.push(tagData)
            })
            setAppSupplyFormDataMap(supplyDetails);
            setAppSupplyFormLabelMap(supplyFormLabelData)
        }
    }, [supplyDetails]);

    const onClickChange = (value, rowIndex, type, key, label) => {
        const updatedLabelMap = { ...appSupplyFormDataMap };
        const updateFormLabelMap = Object.assign([], appSupplyFormLabelMap);
        if (!updatedLabelMap?.['supply_tags']?.[rowIndex]) {
            updatedLabelMap["supply_tags"][rowIndex] = {};
        }
        const getStateFormFields = updatedLabelMap["supply_tags"][rowIndex];

        if (type === SIMPLE_SELECT_TYPE) {
            getStateFormFields[key] = value;

            if(updateFormLabelMap?.[rowIndex]?.[key]){
                updateFormLabelMap[rowIndex][key] = label
            }else {
                updateFormLabelMap.push({"inv_relationship": "", "device_platform": "", "operating_system": ""})
                updateFormLabelMap[rowIndex][key] = label
            }

        }else if(type === TYPE_INPUT){
            getStateFormFields[key] = parseInt(value);
        } else {
            getStateFormFields[key] = value;
        }

        updatedLabelMap["supply_tags"][rowIndex] = getStateFormFields;
        setAppSupplyFormDataMap(updatedLabelMap);
        setAppSupplyFormLabelMap(updateFormLabelMap);

        updateSupplyDetails(updatedLabelMap);
        updateSupplyLabelFormData(updateFormLabelMap)
    };

    const onChangeDynamicMacros = (value, index, type, key, selectAll = false, deleteAll = false) => {
        const updatedValueMap = { ...appSupplyFormDataMap };

        if (selectAll) {
            updatedValueMap[key] = DYNAMIC_MACROS.map((data) => data.value);
            setAppSupplyFormDataMap(updatedValueMap);
            updateSupplyDetails(updatedValueMap);
            return;
        } else if (deleteAll) {
            updatedValueMap[key] = [];
            setAppSupplyFormDataMap(updatedValueMap);
            updateSupplyDetails(updatedValueMap);
            return;
        }

        if (type === MULTI_SELECT_TYPE) {
            if (updatedValueMap[key]?.includes(value)) {
                updatedValueMap[key] = updatedValueMap[key].filter((item) => item !== value);
            } else {
                updatedValueMap[key] = updatedValueMap[key] ? [...updatedValueMap[key], value] : [value];
            }
        } else {
            updatedValueMap[key] = value;
        }

        setAppSupplyFormDataMap(updatedValueMap);
        updateSupplyDetails(updatedValueMap);
    };

    const onChangeSupplyLabel = (updatedValues, index, type, key, label) => {
        const updatedLabelMap = { ...appSupplyFormDataMap };
        if (!updatedLabelMap['supply_tags']?.[index]) {
            updatedLabelMap['supply_tags'][index] = {};
        }
        updatedLabelMap['supply_tags'][index][key] = [...updatedValues];
        setAppSupplyFormDataMap(updatedLabelMap);
        updateSupplyDetails(updatedLabelMap);
    };

    const handleAddRow = () => {
        const updatedLabelMap = { ...appSupplyFormDataMap };
        const updateFormLabelMap = Object.assign([], appSupplyFormLabelMap)
        if (updatedLabelMap.supply_tags?.length >= 25) {
            setShowNotifications(true)
            setNotificationType(SEVERITY_TYPE)
            setNotificationMessage("Only 25 rows can be added")
            return; // Exit if there are already 25 rows
        }
        const newRow = RequestSupplyDetailTable.reduce((acc, field) => {
            acc[field.key] = field.type === MULTI_SELECT_TYPE ? [] : ""; // Initialize fields based on their type
            return acc;
        }, {});
        const newLabelRow = {"inv_relationship": "", "device_platform": "", "supply_label_id": 0}

        if (!updatedLabelMap.supply_tags) {
            updatedLabelMap.supply_tags = []; // Ensure supply_tags array exists
        }

        updatedLabelMap.supply_tags.push(newRow);
        updateFormLabelMap.push(newLabelRow);
        setAppSupplyFormDataMap(updatedLabelMap);
        setAppSupplyFormLabelMap(updateFormLabelMap);

        updateSupplyDetails(updatedLabelMap);
        scrollToBottom();
    }

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
        });
    };

    const deleteRow = (index) => {
        const getStateData = Object.assign({}, appSupplyFormDataMap)
        const getStateFormLabelData = Object.assign([], appSupplyFormLabelMap)

        getStateData.supply_tags.splice(index, 1)
        getStateFormLabelData.splice(index, 1)

        setAppSupplyFormDataMap(getStateData)
        setAppSupplyFormLabelMap(getStateFormLabelData)
        updateSupplyDetails(getStateData);
    }
    const handleNotificationClose = () => {
        setShowNotifications(false);
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} lg={1} className="request-form-label-grid">Macros sent dynamically</Grid>
                <Grid item xs={12} lg={10} className="request-form-value-grid">
                    <AdsMultiSelectWithSearch
                        onChange={onChangeDynamicMacros}
                        value={appSupplyFormDataMap?.["dynamic_macros_list"] || []}
                        index={0}
                        type={MULTI_SELECT_TYPE}
                        formKey="dynamic_macros_list"
                        mapKey="config"
                        options={DYNAMIC_MACROS}
                        allowSelectAll={true}
                    />
                </Grid>
            </Grid>

            {/* Render the table if there are rows to display */}
            {appSupplyFormDataMap?.supply_tags?.length > 0 && (
                <Grid container className="table-app-supply-detail-grid">
                    <Grid item lg={10} xs={12} md={10} marginTop="24px">
                        <Table className="off-white-table ads-supply-table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {RequestSupplyDetailTable.map((column, index) => (
                                        <TableCell key={index} style={{ width: column.width }}>
                                            {column.heading}
                                        </TableCell>
                                    ))}
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appSupplyFormDataMap?.supply_tags?.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {RequestSupplyDetailTable.map((field, colIndex) => (
                                            <TableCell key={colIndex} style={{ width: field.width }}>
                                                {field.type === "select" ? (
                                                    <AdsSimpleSelect
                                                        type={field.type}
                                                        index={rowIndex}
                                                        onChange={onClickChange}
                                                        options={ field.key === "supply_label_id" ? props?.supplyLabels : field.options }
                                                        placeholder={field.placeholder}
                                                        formKey={field.key}
                                                        fieldValue={field.key === "supply_label_id" ? supplyDetails.supply_tags?.[rowIndex]?.[field.key] : appSupplyFormLabelMap?.[rowIndex]?.[field.key] || ""}
                                                        textWidth={field.width}
                                                        value={supplyDetails.supply_tags?.[rowIndex]?.[field.key] || ""}
                                                    />
                                                ) : field.type === "text_input" ? (
                                                    <AdsSimpleTextInput
                                                        type={field.type}
                                                        onChange={onClickChange}
                                                        index={rowIndex}
                                                        textWidth={field.width}
                                                        placeholder={field.placeholder}
                                                        formKey={field.key}
                                                        key={field?.key}
                                                        value={row[field.key] || ""}
                                                        label={field.label}
                                                    />
                                                ) : field.type === "input" ? (
                                                        <SimpleTelInput
                                                            type={field.type}
                                                            onChange={onClickChange}
                                                            index={rowIndex}
                                                            textWidth={field.width}
                                                            placeholder={field.placeholder}
                                                            formKey={field.key}
                                                            postUnit={field.post_unit ? field.post_unit : ""}
                                                            value={row[field.key] || ""}
                                                        />
                                                ) : field.type === "multi-select" ? (
                                                    <SupplyLabelsMultiSelect
                                                        type={field.type}
                                                        index={rowIndex}
                                                        onChange={onChangeSupplyLabel}  // Handle selection changes here
                                                        options={props?.supplyLabels }  // Pass correct options
                                                        formKey={field.key}
                                                        value={appSupplyFormDataMap?.supply_tags?.[rowIndex]?.[field.key] || []}  // Ensure the value is passed as an array
                                                        mapKey="config"
                                                        width={field.width}
                                                    />
                                                ) : null
                                                }
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <span onClick={(_) => deleteRow(rowIndex)}>
                                                <DeleteIcon/>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12} lg={1} className="request-form-label-grid" marginTop="24px">
                <Button
                    className="add-table-supply-form"
                    onClick={handleAddRow}
                >
                    <AddIcon />
                    <span className="add-criteria-span">Add</span>
                </Button>
            </Grid>
            <Notification
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={notificationType}
                open={showNotifications}
            />
        </React.Fragment>
    );

};

export default AdsAppSupplyDetails;