import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getAvailsValues = async (type) => {
    try {
        const query = `query MyQuery { 
      avails_metadata(where: { type: "${type}" }) { 
        type 
        value 
      } 
    }`;
        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        return response.data.data.avails_metadata;
    } catch (error) {
        console.error("Error fetching avails_metadata:", error);
        return null;
    }
};

export default getAvailsValues;
