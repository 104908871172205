import axios from "axios";
import {ADS_PLUS_DOWNLOAD_SERVICE} from "../Constant";
import {adsHeader} from "../utils/helper";

export const fileUploadApi = (fileData = {}, channelName = "") => {
    const authHeaders = adsHeader()
    let headers = {}
    headers['x-user-id'] = authHeaders['x-user-id']
    headers['Authorization'] = authHeaders['Authorization']
    headers['Content-Type'] = 'multipart/form-data'
    headers['channel_id'] = channelName

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${ADS_PLUS_DOWNLOAD_SERVICE}publisher-onb-file-upload`,
        headers: headers,
        data : fileData
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data?.file?.length > 0){
                let fileData = {}
                response.data.data.file.map((data) => {
                    fileData[data?.file_type] = data?.file_id
                })

                return fileData
            }

            return []
        })
        .catch((error) => {
            console.log(error);
        });
}