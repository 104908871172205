import axios from "axios";
import {
    JOB_SUCCESS, LAST_JOB_ID,
    SELF_SERVE_POST_URI,
    SEVERITY_TYPE,
    SUCCESS_TYPE,
    TAG_IS_NOT_UPDATED,
    TS_TAG_UPDATE_SUCCESSFUL,
    WARNING_TYPE
} from "../Constant";
import {setLocalStorageData} from "../utils/localStorageHelper";
import {adsHeader} from "../utils/helper";
export const updateDemandTagsBulk = async (platformId, demandTagData) => {
    const url = `${SELF_SERVE_POST_URI}demand-tags/bulk/add-macros/${platformId}`;
    const adsHeaders = adsHeader()

    const config = {
        headers: adsHeaders,
        data: demandTagData,
        method: "put",
        url: url
    };

    try {
        const response = await axios.request(config);
        if(response.data?.data?.job_status === JOB_SUCCESS){
            const jobID = response.data?.data?.job_id || ""
            setLocalStorageData(LAST_JOB_ID, false, jobID)
            return {message: response.data.data?.message || TS_TAG_UPDATE_SUCCESSFUL, type: SUCCESS_TYPE};
        }else {
            return {message: TAG_IS_NOT_UPDATED, type: SEVERITY_TYPE}
        }
    } catch (error) {
        if(error?.response?.data?.message){
            return {message: error.response.data.message, type: WARNING_TYPE}
        }else {
            return {message: TAG_IS_NOT_UPDATED, type: SEVERITY_TYPE}
        }
    }
};
