
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getAllSupplyPartner = async () => {
    try {
        const query = `
                    query MyQuery {
                        supply_partner {
                            supply_partner_name
                            supply_partner_id
                          }
                     } `;

        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, {headers: headers});
        if(response?.data?.data?.supply_partner){
            return response.data.data.supply_partner;
        }

        return []
    } catch (error) {
        console.error("Error fetching while getAllSupplyPartner:", error);
        return null;
    }
};

export default getAllSupplyPartner;
