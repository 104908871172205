import React, {useState} from "react";
import {FormControl, InputAdornment, Menu, MenuItem, Select, TextField} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {isEmpty} from "../../utils/helper";
import PropTypes from "prop-types";

function SimpleSelect(props){
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopover(true)
    };
    const handleClose = (value) => {
        setAnchorEl(null);
        setOpenPopover(false)
        if(typeof value === "string"){
            props.onChange(value, props.index, props.type)
        }
    };

    return(
        <FormControl variant="standard" size="small" className="select-box-standard">
            <TextField
                value={props.value}
                placeholder={props.placeholder || "Select Operator"}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {openPopover ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon  />}
                        </InputAdornment>
                    ),
                }}
                variant="standard"
                onClick={handleClick}
                autoComplete="off"
                onKeyDown={handleClick}
                style={{width: props?.textWidth ?? "90%"}}
            />
            {!isEmpty(props.options) && (
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className="select-box-standard-popover"
                >
                    {props.options.map((option) => {
                        return(
                            <MenuItem
                                value={option.label}
                                className={props.value === option.label ? "select-options-item active-item" : "select-options-item"}
                                key={option.label}
                                onClick={() => handleClose(option.label)}
                            >
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </Menu>
            )}
        </FormControl>
    )
}

SimpleSelect.propTypes = {
    "value": PropTypes.string.isRequired,
    "index": PropTypes.number.isRequired,
    "onChange": PropTypes.func.isRequired,
    "options": PropTypes.arrayOf(PropTypes.object.isRequired),
    "type": PropTypes.string.isRequired
}

export default SimpleSelect