import { Drawer, Grid, Typography, Checkbox, Divider, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SUPPLY_TRANSPARENCY_COLUMNS } from "../../../Constant";
import DragIcon from "../../../assests/icons/dragIcon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DisabledDragIcon from "../../../assests/icons/disabledDragIcon";
import CheckedIcon from "../../../assests/icons/checkedIcon";

import "./index.css";

const ReportManageColumn = (props) => {
    const [columns, setColumns] = useState(SUPPLY_TRANSPARENCY_COLUMNS);
    const [checkedIdsTemp, setCheckedIdsTemp] = useState(props.checkedHeaderIds);



    useEffect(() => {
        setColumns(SUPPLY_TRANSPARENCY_COLUMNS)
    }, [props.contentLength])



    const toggleColumn = (id) => {
        if (id === "supply_tag_id") {
            return; // Prevent toggling for the "supply_tag_id" column
        }
        setCheckedIdsTemp(prevIds => ({
            ...prevIds,
            [id]: !prevIds[id]
        }));

    };

    const onClickCancel = () => {
        props.closeFilterSidebar();

    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newColumns = Array.from(columns);
        const [movedColumn] = newColumns.splice(result.source.index, 1);
        newColumns.splice(result.destination.index, 0, movedColumn);
        setColumns(newColumns);

    };

    const onClickApply = () => {
        props.setCheckedHeaderIds(checkedIdsTemp)
        const selectedColumns = columns.filter(column => checkedIdsTemp[column.id]);
        props.handleColumns(selectedColumns.map(column => column.id))
        props.handleTableHeader(selectedColumns);
        props.closeFilterSidebar();
    };


    return (
        <React.Fragment>
            <Drawer
                className="sidebar-manage-column"
                anchor="right"
                open={props.isFilterSidebarOpen}
                onClose={onClickCancel}
            >
                <Grid item xs={12} className="sidebar-header">
                    <Grid container>
                        <Grid item xs={10} className="amagi-header-typography" padding="20px">
                            <Typography className="filter-typography">Manage columns</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CloseIcon className="close-icon-sidebar" onClick={onClickCancel} />
                        </Grid>
                    </Grid>
                </Grid>
                <div className="sidebar-div">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="columns">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {columns.map((column, index) => (
                                        // Render Draggable only for draggable items
                                        column.id !== "supply_tag_id" ? (
                                            <Draggable key={column.id} draggableId={column.id} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Grid item xs={12} className="sidebar-column-name-grid">
                                                            <DragIcon />
                                                            <Checkbox className="amg-white-checkbox"
                                                                checked={checkedIdsTemp[column.id]}
                                                                onChange={() => toggleColumn(column.id)}
                                                            />
                                                            {column.title}
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ) : (
                                            // Render non-draggable item
                                            <div key={column.id}>
                                                <Grid item xs={12} className="sidebar-column-name-grid">
                                                    <DisabledDragIcon />
                                                    <CheckedIcon />
                                                    {column.title}
                                                </Grid>
                                            </div>
                                        )
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                <Grid item xs={12} className="sidebar-footer">
                    <Divider className="divider-add-macros" />
                    <Grid container justifyContent="flex-end">
                        <Button variant="contained" className="cancel-button" style={{ marginRight: "10px" }} onClick={onClickCancel}>
                            Close
                        </Button>
                        <Button className="orange-button" style={{ height: "32px", marginTop: "20px" }} onClick={onClickApply}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment>
    );
};

export default ReportManageColumn;

