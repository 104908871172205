import { Grid, TableRow, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import MacroOption from "./AddMacroOptionPopover";
import { Table, TableBody, TableHead, TableCell } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MacroFilter from "./AddMacroOptionPopover/MacroFilter";
import { LoadingScreen } from "../CommonScreens/LoadingScreen";
import {
    VIEW_CHANNEL_TYPE,
    VIEW_DEMAND_TAG_TYPE,
    VIEW_MACRO_TYPE,
    SUPPLY_ROUTER_TAG_TITLE_HEADER,
    SEVERITY_TYPE,
    ONGOING_API_CALL_KEY,
    DEMAND_TAG_TITLE_HEADER,
    CURRENT_DEMAND_TAG_LIMIT,
    TAG_IS_NOT_UPDATED,
    MACRO_NOT_SELECTED,
    ADS_PLUS_PLATFORM_ID,
    NON_TS_PLATFORM_TYPE,
    TS_PLATFORM_TYPE,
    SELECTED_PLATFORM_KEY
} from "../../Constant";
import { updateDemandTagsBulk } from "../../api/addMacrosToDemandTag";

import { useParams } from "react-router-dom";
import { updateMacrosToThunderstormBulk } from "../../api/addMacrosToThunderstormTags";
import { getDemandTagListingByChannelsId } from "../../api/getDemandTagListingByChannelId";
import Notification from "../Notification";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import {Group} from "@mui/icons-material";
import {getLocalStorageData,setLocalStorageData} from "../../utils/localStorageHelper";
import {EmptyScreen} from "../CommonScreens/EmptyScreen";
export default function TemporaryDrawer(props) {
    const { platformId, channelId } = useParams();
    const [selectedRows, setSelectedRows] = useState([]);
    const [demandTagDataList, setDemandTagDataList] = useState([]);
    const [macroList, setMacroList] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [offset, setOffset] = useState(0)
    const [isHavingMoreData, setIsHavingMoreData] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [alertType, setAlertType] = useState("")
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredDemandTag,setFilteredDemandTag] =useState([])
    const [appliedFilterList,setAppliedFilterList] = useState([])

    useEffect(() => {
        getDemandTagData()
    }, []);
    useEffect(() => {
        if(macroList.length===0) {
            setSelectedRows([])
        }
    }, [macroList]);


    const handleSelectAll = () => {
        if (selectedRows.length === demandTagDataList?.length) {
            setSelectedRows([]); // Deselect all rows
        } else {
            const allRows = demandTagDataList?.map((rowData) => ({
                channel_id: rowData.channel_id,
                demand_tag_id: rowData.demand_tag_id,
            })); // Extract channel_id and demand_tag_id of all rows
            setSelectedRows(allRows); // Select all rows
        }
    };

    const handleRowCheckboxChange = (demand_tag_id, channel_id) => {
        const index = selectedRows.findIndex(
            (row) =>
                row.demand_tag_id === demand_tag_id && row.channel_id === channel_id
        );
        if (index === -1) {
            // If the row is not already selected, add it to the array
            setSelectedRows((prevRows) => [
                ...prevRows,
                { channel_id, demand_tag_id },
            ]);
        } else {
            // If the row is already selected, remove it from the array
            setSelectedRows((prevRows) =>
                prevRows.filter(
                    (row) =>
                        !(
                            row.demand_tag_id === demand_tag_id &&
                            row.channel_id === channel_id
                        )
                )
            );
        }
    };

    const getDemandTagData = async () => {
        setIsHavingMoreData(false)
        setIsLoading(true)
        const getLocalStoragePlatformValue = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
        let platform_type;
        getLocalStoragePlatformValue === ADS_PLUS_PLATFORM_ID ?
            platform_type=NON_TS_PLATFORM_TYPE  :platform_type=TS_PLATFORM_TYPE;
        const getOnGoingApiCall = getLocalStorageData(ONGOING_API_CALL_KEY, false)
        if (!getOnGoingApiCall || getOnGoingApiCall === "false") {
            setLocalStorageData(ONGOING_API_CALL_KEY, false, true)
            if (props.tabType === VIEW_CHANNEL_TYPE || props.tabType===VIEW_MACRO_TYPE) {
               const channelList = props.tabType === VIEW_CHANNEL_TYPE?props.selectedChannels:props.selectedChannelId
                const requestData = await getDemandTagListingByChannelsId(
                    CURRENT_DEMAND_TAG_LIMIT,
                    offset,
                    props.userEmail,
                    channelList,
                    platform_type
                );
                const demandTagsData = requestData.data;

                if (demandTagsData && demandTagsData.length > 0) {
                    setDemandTagDataList(demandTagsData);
                    setIsLoading(false)
                    setIsHavingMoreData(true)
                    setFilteredDemandTag(demandTagsData)
                }
                else {
                    setIsHavingMoreData(false)
                    setIsLoading(false)
                    setDemandTagDataList([])
                }
            } else {
                const channelIdArr = [channelId];
                const demandTagsData = await getDemandTagListingByChannelsId(
                    channelIdArr,
                    CURRENT_DEMAND_TAG_LIMIT,
                    offset,
                    props.userEmail
                );
                if (demandTagsData && demandTagsData.length > 0) {
                    setDemandTagDataList(demandTagsData);
                    setIsLoading(false)
                    setIsHavingMoreData(true)
                }
                else {
                    setIsHavingMoreData(false)
                    setIsLoading(false)
                    setDemandTagDataList([])
                }
            }
            setLocalStorageData(ONGOING_API_CALL_KEY, false, false)
        }
    };
    const handleOnSubmit = async (platformId, macroList) => {
        if (macroList.length === 0) {
            props.onShowNotification(MACRO_NOT_SELECTED, SEVERITY_TYPE)
            return;
        }
        try {
            if (props.tabType === VIEW_DEMAND_TAG_TYPE ){
                // Call updateDemandTags function
                await updateDemandTags(platformId ? platformId : props.platform_id, macroList);
            } else {
                // Call updateMacrosToThunderstorm function
                await updateMacrosToThunderstorm(platformId?platformId:props.platform_id, macroList);
            }
            props.closeSidebar()
        } catch (error) {
            props.onShowNotification(TAG_IS_NOT_UPDATED, SEVERITY_TYPE)
        }
    };
    const updateDemandTags = async (platformId, macroList) => {
        let demandTagDetails;

        if (props.tabType === VIEW_DEMAND_TAG_TYPE) {
            demandTagDetails = props.selectedDemandTags;
        } else {
            demandTagDetails = selectedRows;
        }
        const noDuplicateMap = {}

        demandTagDetails.map((row) => {
            if(row?.demand_tag_id && !noDuplicateMap[row.demand_tag_id]){
                noDuplicateMap[row.demand_tag_id] = row
            }
        })

        const uniqueDemandTagDetails = Object.values(noDuplicateMap);
        const demandTagData = {
            macros: macroList,
            demand_tag_details: uniqueDemandTagDetails,
        };

        try {
            const response = await updateDemandTagsBulk(platformId, demandTagData, props.userEmail);
            if(response?.message && response?.type){
                props.onShowNotification(response.message, response.type)
            }else {
                props.onShowNotification(TAG_IS_NOT_UPDATED, SEVERITY_TYPE)
            }
        } catch (_) {
            props.onShowNotification(TAG_IS_NOT_UPDATED, SEVERITY_TYPE)
        }
    };

    const updateMacrosToThunderstorm = async (platformId, macroList) => {
        let demandTagDetails;

        if (props.tabType === "demand_tags_ts_api_call") {
            demandTagDetails = props.selectedDemandTags;
        } else {
            demandTagDetails = selectedRows;
        }
        const channelIdList = props.tabType === VIEW_CHANNEL_TYPE ? props.selectedChannels: props.selectedChannelId
        const noDuplicateArray = []
        const noDuplicateMap = {}

        demandTagDetails.map((row) => {
            if(row?.demand_tag_id && !noDuplicateMap[row.demand_tag_id]){
                noDuplicateMap[row.demand_tag_id] = row
            }
        })

        channelIdList.map((id) => {
            if(noDuplicateArray.indexOf(id) === -1){
                noDuplicateArray.push(id)
            }
        })

        const uniqueDemandTagDetails = Object.values(noDuplicateMap);

        const demandTagData = {
            channel_ids: noDuplicateArray,
            macros: macroList,
            demand_tag_details: uniqueDemandTagDetails,
        };

        try {
            const response = await updateMacrosToThunderstormBulk(
                platformId,
                demandTagData,
                props.userEmail
            );
            if(response?.message && response?.type){
                props.onShowNotification(response.message, response.type)
            }else {
                props.onShowNotification(TAG_IS_NOT_UPDATED, SEVERITY_TYPE)
            }
        } catch (_) {
            props.onShowNotification(TAG_IS_NOT_UPDATED, SEVERITY_TYPE)
        }
    };

    const onClickCancel = () => {
        props.closeSidebar()
    };
    const updateSelecetedMacroList = (newMacros) => {
        setMacroList((prevData) => [...prevData, newMacros]);
    };
    const removeMacroFromMacroList = (oldMacro) => {
        setMacroList((prevMacros) =>
            prevMacros.filter((macro) => macro !== oldMacro)
        );
    }
    const setMacrosalertType = (type) =>{
        setAlertType(type)
    }
    const setMacroAlertNotificationMessage = (message)=>{
        setNotificationMessage(message)
    }

    const setOpenNotification = ()=>{
        setIsOpen(true)
    }
    const handleFilterSelection = (filter) => {
        if (selectedFilters.includes(filter)) {
            setSelectedFilters(selectedFilters.filter(item => item !== filter));
        } else {
            setSelectedFilters([...selectedFilters, filter]);
        }
    };


    const applyFilterSelection=()=> {
        const filteredData = selectedFilters.length === 0 ?
            demandTagDataList :
            demandTagDataList.filter(row =>
                selectedFilters.some(filter =>
                    row.demand_partner_name.toLowerCase() === filter.toLowerCase() // Convert both to lowercase for case-insensitive comparison
                )
            );
        setFilteredDemandTag(filteredData)
        setAppliedFilterList(selectedFilters)
    }

    const resetFilter=()=>{
        setSelectedFilters([])
        setAppliedFilterList([])
        setFilteredDemandTag(demandTagDataList)
    }
    const cancelSelection = () => {
        setSelectedFilters(appliedFilterList);
    };



    return (
        <Drawer
            className="sidenav-add-macros"
            anchor="right"
            open={props.isSidebarOpen}
            onClose={props.closeSidebar}
        >
            <Grid item xs={12} className="sidebar-header">
                <Grid container>
                    <Grid item xs={10} className="amagi-header-typography" padding="20px">
                        {props?.sideBarTitleHeader && props.sideBarTitleHeader !== "" ? props.sideBarTitleHeader : SUPPLY_ROUTER_TAG_TITLE_HEADER}
                    </Grid>
                    <Grid item xs={2}>
                        <CloseIcon className="close-icon-sidebar" onClick={()=>{onClickCancel()}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="sidebar-content">
                <Typography className="add-macros-heading">
                    {props?.sideBarTitleHeader && props.sideBarTitleHeader === DEMAND_TAG_TITLE_HEADER ? "Add macros to include in demand tags": "Add macros to include in thunderstorm tags"}
                </Typography>
                <MacroOption
                    macrosSelected={updateSelecetedMacroList}
                    screen={props.screen}
                    channel_id={props.channelId}
                    removeMacro={removeMacroFromMacroList}
                    alert={setMacrosalertType}
                    message={setMacroAlertNotificationMessage}
                    isOpen={setOpenNotification}
                    platform_id={props.platform_id}
                    tabType={props. tabType}
                    userEmail={props.userEmail}
                />
                {(props.tabType === VIEW_CHANNEL_TYPE  || props.tabType === VIEW_MACRO_TYPE ) && macroList.length > 0 ? (
                    <React.Fragment>
                        <Typography className="add-macros-heading" sx={{marginTop: "40px"}}>
                            Demand tags
                        </Typography>
                        <Typography  className="add-macros-heading-suggestion" sx={{marginTop: "8px !important"}}>
                            Please select the required demand tags for synchronization.
                        </Typography>

                        <Grid container className="add-macro-demand-tag-grid">
                            {filteredDemandTag?.length>0 &&(
                            <Grid container className="add-macros-toolbar-grid">
                                <Grid item xs={6}>
                                    <Typography className="add-macros-toolbar-typography">
                                        {filteredDemandTag.length} demand tags
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}  >
                                    <MacroFilter
                                        filterData={Array.from(new Set(demandTagDataList?.map(item => item.demand_partner_name)))}
                                        selectedFilters={selectedFilters}
                                        handleFilterSelect={handleFilterSelection}
                                        applyFilterSelect={applyFilterSelection}
                                        resetFilterSelect={resetFilter}
                                        appliedFilters={appliedFilterList}
                                        cancelSelected={cancelSelection}

                                    />
                                </Grid>
                            </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                className="add-macros-listing-table-container"
                            >
                                <Table className="white-table">
                                    <TableHead>
                                        <TableCell>
                                            <Checkbox
                                                className="amg-white-checkbox"
                                                checked={
                                                    selectedRows.length === demandTagDataList?.length &&
                                                    demandTagDataList?.length !== 0
                                                }
                                                onChange={handleSelectAll}
                                            />

                                        </TableCell>
                                        <TableCell>LINKED DEMAND TAG</TableCell>
                                        <TableCell>DEMAND PARTNER</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {filteredDemandTag.length>0 && filteredDemandTag?.map((rowData, index) => (
                                            <TableRow key={`${index}-${rowData.demand_tag_id}`}>
                                                <TableCell>
                                                    <Checkbox
                                                        className="amg-white-checkbox"
                                                        checked={selectedRows.some(
                                                            (row) =>
                                                                row.demand_tag_id === rowData.demand_tag_id
                                                        )}
                                                        onChange={() =>
                                                            handleRowCheckboxChange(
                                                                rowData.demand_tag_id,
                                                                rowData.channel_id
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>{rowData.demand_tag_name}</TableCell>
                                                <TableCell>{rowData.demand_partner_name}</TableCell>
                                            </TableRow>
                                        ))}
                                        {filteredDemandTag.length === 0 && !isLoading && (
                                            <TableRow><TableCell colSpan={3}><EmptyScreen height="50px" message="No demand tags added yet!"/></TableCell></TableRow>
                                        )


                                        }

                                        {
                                            isLoading  && (
                                                <LoadingScreen rowsNumber={3} />
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ) : props.tabType === VIEW_DEMAND_TAG_TYPE ? (
                    <Grid className="add-macros-heading-suggestion">
                        Macros will get added to demand-tag only
                    </Grid>
                ) : null}
            </Grid>
            <Grid item xs={12} className="sidebar-footer">
                <Divider className="divider-add-macros" />
                <Grid container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        className="cancel-button"
                        onClick={() => {
                            onClickCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className="create-button"
                        onClick={() => {
                            handleOnSubmit(platformId, macroList);
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Drawer>
    );
}
