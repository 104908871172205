import axios from 'axios';
import {SELF_SERVE_POST_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

const getJobValidation = async ( jobId,email) => {
    try {
        const headers = adsHeader()
        const response = await axios.get(
            `${SELF_SERVE_POST_URI}rollback/validate/${jobId}`,
            {
                headers: headers
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error Validating Job :', error);
        throw error; // Re-throw the error for handling in the caller function
    }
};

export default getJobValidation;
