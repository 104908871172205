
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getSupplyHealthTrackerAPI = async (where) => {
    try {
        const query = `query MyQuery {
          supply_health_summary(
            where: {date: { gte: "${where?.date?.gte}", lte: "${where?.date?.lte}" }, inventory_type: "${where.inventory_type}", supply_partner_id: ${JSON.stringify(where?.supply_partner?.in)} }
          ) {
            daily {
              ad_requests
              date
              fill_rate
              impressions
              margin_per
              revenue
              supply_partner {
                ad_requests
                cost
                date
                ecpm
                fill_rate
                impressions
                inventory_type
                net_revenue
                margin_per
                pod_time_fill_rate
                pod_time_filled
                pod_time_requested
                revenue
                rpm
                supply_partner_id
                supply_partner_name
                timeouts
              }
            }
            metrics {
              ad_requests {
                change
                total
              }
              ecpm {
                change
                total
              }
              fill_rate {
                change
                total
              }
              impressions {
                change
                total
              }
              margin_per {
                change
                total
              }
              revenue {
                change
                total
              }
            }
          }
        }`;
        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, {headers: headers});
        if(response?.data?.data?.supply_health_summary && response.status === 200){
            return response.data.data.supply_health_summary;
        }

        return {}
    } catch (error) {
        console.error("Error fetching while getSupplyHealthTracker:", error);
        return null;
    }
};

export default getSupplyHealthTrackerAPI;
