import React, {useState} from "react";
import {FormControl, InputAdornment, InputLabel, ListSubheader, Menu, MenuItem, Select, TextField} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {isEmpty} from "../../utils/helper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function SimpleSelectWithGroup(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopover(true)
    };
    const handleClose = (value, group) => {
        setAnchorEl(null);
        setOpenPopover(false)
        if(typeof value === "string"){
            props.onChange(value, props.index, group)
        }
    };

    return(
        <FormControl variant="standard" size="small" className="select-box-standard">
            <TextField
                value={props.value}
                placeholder="Select Criteria"
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {openPopover ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon  />}
                        </InputAdornment>
                    ),
                }}
                variant="standard"
                onClick={handleClick}
                autoComplete="off"
                onKeyDown={handleClick}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {!isEmpty(props.options) && Object.keys(props.options).map((key) => {
                    return(
                        <React.Fragment key={key}>
                            <MenuItem className="select-item-group" disabled> {key} </MenuItem>
                            {!isEmpty(props.options[key]) && props.options[key].map((option) => {
                                return(
                                    <MenuItem
                                        value={option.label}
                                        className={props.value === option.label ? "select-options-item active-item" : "select-options-item"}
                                        key={option.label}
                                        onClick={() => handleClose(option.label, key)}
                                    >
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </React.Fragment>
                    )
                })}
            </Menu>
        </FormControl>
    )
}

SimpleSelectWithGroup.propTypes = {
    "value": PropTypes.string.isRequired,
    "index": PropTypes.number.isRequired,
    "onChange": PropTypes.func.isRequired,
    "options": PropTypes.arrayOf(PropTypes.object.isRequired),
}

export default SimpleSelectWithGroup