import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import {BFF_LAYER_URI, INTERNAL_AMG_ID} from "../Constant";

export function createApolloClient(user) {
    const httpLink = createHttpLink({
        uri: BFF_LAYER_URI,
        headers: {
            amg_id: user?.organization_metadata?.amg_id || INTERNAL_AMG_ID,
        },
    });

    return new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
    });
}
