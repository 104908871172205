import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import { getRequestsListQuery } from "../query/getRequestsListQuery";
import { MAX_LIMIT_SHOW_TABLE_DATA } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getRequestsList = async(offSet = 0, limit = MAX_LIMIT_SHOW_TABLE_DATA, email = "") => {
    let data = JSON.stringify({
        query: `query MyQuery($where: AdsPlusRequestConfigWhereQuery = {}) {
          getAdsPlusRequestConfig(limit: ${limit}, offset: ${offSet}, where: $where) {
            total_count
            data {
              comments
              created_at
              request_job_id
              requested_user
              updated_at
              type
              status
              meta 
              config
              approved_by
            }
          }
        }`,
        variables: { "where": {  } }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
    .then((response) => {
        if (response?.data?.data?.getAdsPlusRequestConfig && response.status === 200) {
        return response.data.data.getAdsPlusRequestConfig;
        } else {
            return [];
        }
    })
    .catch((error) => {
        console.error('Request failed:', error);
        return null;
    });
};
