
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getDemandHealthTrackerAPI = async (where) => {
    try {
        const query = `query MyQuery {
          demand_health_summary(
            where: {date: {gte: "${where.date.gte}", lte: "${where.date.lte}"}, demand_partner_id: ${JSON.stringify(where?.demand_partner_id?.in)}}
          ) {
            daily {
              date
              impressions
              revenue
              demand_partner {
                bid_rate
                bids
                cost
                date
                demand_partner_id
                demand_partner_name
                erpm
                errors
                impressions
                margin
                margin_per
                requests
                revenue
                timeouts
                use_rate
                win_rate
                wins
              }
              open_market_summary {
                bid_rate
                bidder_account_id
                bidder_account_name
                bidder_errors
                bids
                cost
                date
                demand_partner_id
                erpm
                impressions
                margin
                margin_per
                requests
                response_time
                revenue
                use_rate
                win_rate
                wins
              }
            }
            metrics {
              bid_rate {
                change
                total
              }
              erpm {
                change
                total
              }
              impressions {
                change
                total
              }
              revenue {
                change
                total
              }
              use_rate {
                change
                total
              }
              win_rate {
                change
                total
              }
            }
          }
        }`;
        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        if(response?.data?.data?.demand_health_summary && response.status === 200){
            return response.data.data.demand_health_summary;
        }

        return {}
    } catch (error) {
        console.error("Error fetching while getDemandHealthTracker:", error);
        return null;
    }
};

export default getDemandHealthTrackerAPI;
