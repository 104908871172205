import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import "./index.css"
import PropTypes from "prop-types";


function FilterTableWithoutSearch(props) {

    return (
        <Grid item xs={12}>
            <Grid container className="amg-table-content">
                <Grid item xs={12} className="amg-table-content-filter" >
                    <Grid justifyContent="space-between" container>
                        <Grid item xs className="amg-table-content-count">
                            <Typography  className="amg-table-content-count" > <span>  {props?.count && props.count !== 0 ? props.count : ""} </span> {props?.count && props.count !== 0 ? props.titleText : ""} </Typography>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid >
        </Grid>
    )
}

FilterTableWithoutSearch.propTypes = {
    count: PropTypes.number,
    titleText: PropTypes.string,
    onSearch: PropTypes.func
}

export default FilterTableWithoutSearch