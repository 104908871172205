export const getRequestsDetailsQuery = `query MyQuery($id: String = "") {
  getAdsPlusRequestConfigByID(id: $id) {
      comments
      created_at
      request_job_id
      requested_user
      updated_at
      type
      status
      meta 
      config
      approved_by
    }
}`;
