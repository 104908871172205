import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import './index.css';
import DataImage from './Data.png'; // Assuming Data.png is in the same directory as jobDetials.js

export const EmptyScreen = (props) => {
    return (
        <Grid
            container
            justifyContent="center" // Center the content horizontally
            alignItems="center" // Center the content vertically
            style={{ minHeight: "50vh" }} // Set minimum height to 60% of the viewport height
        >
            <Grid item>
                <img src={DataImage} alt="No Data" className="no-data-img" style={{
                    height:"200px",
                    width:"200px"
                }} />
            </Grid>
            <Grid container justifyContent="center">
                <Grid item>
                    <Typography className="no-data-typography">{props?.message || "No macros added yet!"}</Typography>
                    {props.message==="No results found" &&(
                        <Typography className="refine-search-typography">Please refine your search</Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

EmptyScreen.propTypes = {
    message: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    minHeight: PropTypes.string
};
