const LogoutIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M2.6665 11.9999H3.99984V13.3333H11.9998V2.66659H3.99984V3.99992H2.6665V1.99992C2.6665 1.82311 2.73674 1.65354 2.86177 1.52851C2.98679 1.40349 3.15636 1.33325 3.33317 1.33325H12.6665C12.8433 1.33325 13.0129 1.40349 13.1379 1.52851C13.2629 1.65354 13.3332 1.82311 13.3332 1.99992V13.9999C13.3332 14.1767 13.2629 14.3463 13.1379 14.4713C13.0129 14.5963 12.8433 14.6666 12.6665 14.6666H3.33317C3.15636 14.6666 2.98679 14.5963 2.86177 14.4713C2.73674 14.3463 2.6665 14.1767 2.6665 13.9999V11.9999ZM3.99984 7.33325H8.6665V8.66659H3.99984V10.6666L0.666504 7.99992L3.99984 5.33325V7.33325Z"
                fill="#E7E7E7"/>
        </svg>
    )
}

export default LogoutIcon