import {Grid, Skeleton, Typography} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function NoDataQuickFilterView(props){
    return(
        <Grid item className="quick-view-item">
            <Grid container>
                <Grid item xs={12}>
                    <Typography className="amg-quick-view-text">
                        --
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="amg-quick-view-text-type">
                        {props?.name || "--"} &nbsp;
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

NoDataQuickFilterView.propTypes = {
    "name": PropTypes.string.isRequired
}

export default NoDataQuickFilterView