import { useEffect, useState } from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Grid
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { EmptyScreen } from "../../CommonScreens/EmptyScreen";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";
import {isEmpty} from "../../../utils/helper";
import {SEARCHED_TEXT_NOT_FOUND} from "../../../Constant";

function TableContentMacro(props) {

    return (
        <Grid item xs={12}>
            <Table className="off-white-table" stickyHeader>
                <TableHead>
                    <TableCell> MACRO NAME </TableCell>
                    <TableCell> VALUE </TableCell>
                    <TableCell> SOURCE </TableCell>
                    <TableCell> TS VERSION </TableCell>
                    <TableCell> MANDATORY </TableCell>
                    <TableCell> CTV </TableCell>
                    <TableCell> WEB </TableCell>
                </TableHead>
                <TableBody>
                    {props?.isLoading && (
                        <LoadingScreen rowsNumber={7} />
                    )}
                    { props?.tableContent?.length > 0 ? props.tableContent.map((data, index) => {
                        return (
                            <TableRow key={`${index}-${data?.amagi_key}`}>
                                <TableCell> {data?.amagi_key} </TableCell>
                                <TableCell> {data?.spring_serve_macro && data.spring_serve_macro !== "false" ? data.spring_serve_macro : "N/A"} </TableCell>
                                <TableCell> {data?.source} </TableCell>
                                <TableCell> {data?.ts_version ? data.ts_version : "N/A"} </TableCell>
                                <TableCell>
                                    {data?.required === "BLOCKER" ? (
                                        <CheckIcon />
                                    ) : (
                                        <CloseIcon />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {data?.ctv ? <CheckIcon /> : <CloseIcon />}
                                </TableCell>
                                <TableCell>
                                    {data?.web ? <CheckIcon /> : <CloseIcon />}
                                </TableCell>
                            </TableRow>
                        );
                    }) : (!isEmpty(props?.searchedText)  && !props?.isLoading) && (
                        <TableRow>
                            <TableCell colSpan={7}>
                                <EmptyScreen message="No results found"/>
                            </TableCell>
                        </TableRow>
                    )}

                    {!props?.isLoading && props?.tableContent?.length === 0 && isEmpty(props?.searchedText) &&
                        <TableRow>
                            <TableCell colSpan={7}>
                                <EmptyScreen message="No macros added yet!"/>
                            </TableCell>
                        </TableRow>

                    }
                </TableBody>
            </Table>
        </Grid>
    );
}

TableContentMacro.propTypes = {
    data: PropTypes.shape({
        amg_key: PropTypes.string,
        ts_v3_macro_key: PropTypes.string,
        source: PropTypes.string,
        macro_key_type: PropTypes.string,
        critical: PropTypes.string,
        ctv: PropTypes.bool,
        web: PropTypes.bool,
        amagi_key: PropTypes.string,
        spring_serve_macro: PropTypes.string,
        ts_version: PropTypes.string
    }),
    searchedText: PropTypes.string
};

export default TableContentMacro;