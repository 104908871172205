import "./index.css"
import {Drawer, Grid, Typography, Accordion, Divider, Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import FilterAccordian from "./FilterAccordian";
import {FILTER_VALUE_FOR_LOG} from "../../Constant";


const FilterSidebar = (props) => {
    const [filterNewData, setFilterNewData]= useState({})
    useEffect(() => {
        setFilterNewData(props.selectedFilterData)
    }, []);

    const onClickCancel = () => {
        props.closeFilterSidebar()
    };


    const onClickApply = () =>{
        props.setSelectedFilterData(filterNewData)
        props.updateQueryData(filterNewData)
        getTotalSelectedFilters(filterNewData)
        props.closeFilterSidebar()
    }
    const onClickReset = () =>{
        props.setTotalFilter(0)
        props.setSelectedFilterData(FILTER_VALUE_FOR_LOG)
        setFilterNewData(FILTER_VALUE_FOR_LOG)

    }
    const getTotalSelectedFilters = (selectedFilters) => {
        let total = 0;
        selectedFilters.forEach(filter => {
            if (filter.heading === "gteDate" || filter.heading === "lteDate") {
                if (filter.data !== "") {
                    total += 1;
                }
            } else if (Array.isArray(filter.data)) {
                total += filter.data.length;
            } else {
                if (filter.data !== "") {
                    total += 1;
                }
            }
        });
        props.setTotalFilter(total);
    };


    const setFilterData = (data) =>{
        setFilterNewData(data)
    }


    return(
        <React.Fragment>
        <Drawer
            className="sidenav-filter"
            anchor="right"
            open={props.isFilterSidebarOpen}
            onClose={props. closeFilterSidebar}
        >
            <Grid item xs={12} className="sidebar-header">
                <Grid container>
                    <Grid item xs={10} className="amagi-header-typography" padding="20px">
                        <span className="sidebar-filter-span">
                        Filters
                        </span>
                    </Grid>
                    <Grid item xs={2}>
                        <CloseIcon className="close-icon-sidebar" onClick={()=>{onClickCancel()}}/>
                    </Grid>
                </Grid>
            </Grid>
            <div className="filter-sidebar-content">
            <FilterAccordian filterData={props.filterData}
                             filterDataHasDate={props.filterDataHasDate}
                             onClickApply={onClickApply}
                             setFilterData={setFilterData}
                             selectedFilterData={props.selectedFilterData}
                             setSelectedFilterData ={props.setSelectedFilterData}
                             getTotalSelectedFilters={getTotalSelectedFilters}/>
            </div>
            <Grid item xs={12} className="sidebar-footer sidebar-footer-filter">
                <Divider className="divider-add-macros" />
                <Grid container justifyContent="flex-end">
                    <Button variant="contained" className="reset-button" style={{ marginRight: "160px" }} onClick={()=>{onClickReset()}}>
                        Reset
                    </Button>
                    <Button variant="contained" className="cancel-button" style={{ marginRight: "10px" }} onClick={()=>props.closeFilterSidebar()}>
                        Close
                    </Button>
                    <Button className="orange-button" style={{ height: "32px", marginTop: "20px" }} onClick={() => onClickApply()}>
                        Apply
                    </Button>
                </Grid>
            </Grid>

        </Drawer>
       </React.Fragment>

   )
}


export default FilterSidebar;