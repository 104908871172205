const PendingIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="pending-icon-svg" >
            <path
                d="M9.09874 14.0317C8.73424 14.0886 8.36511 14.11 7.9965 14.0957H7.19609L7.30585 12.8741L7.52339 12.8946C7.97894 12.9375 8.43821 12.9177 8.8884 12.8358L9.10293 12.7968L9.31869 13.9974L9.09874 14.0317Z"
                fill="#E7E7E7"/>
            <path
                d="M4.99872 13.3003L5.18775 13.4075L5.77735 12.371L5.5899 12.263C5.19364 12.0348 4.83072 11.7529 4.51141 11.4252L4.35854 11.2684L3.5051 12.1112L3.65623 12.2659C4.05303 12.6721 4.50496 13.0203 4.99872 13.3003Z"
                fill="#E7E7E7"/>
            <path
                d="M10.5837 12.1461L11.2566 13.1331L11.435 13.0174C11.9005 12.7157 12.3226 12.3517 12.6897 11.9356L12.8378 11.7678L11.9149 10.9995L11.7749 11.1557C11.4777 11.4873 11.1393 11.7793 10.7679 12.0245L10.5837 12.1461Z"
                fill="#E7E7E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.28329 10.1497L2.36303 10.3628L3.48484 9.88465L3.41013 9.6891C3.25072 9.27183 3.14958 8.83451 3.1096 8.38952L3.09013 8.17283L1.89185 8.28086L1.91037 8.49661C1.95894 9.06209 2.0844 9.61826 2.28329 10.1497Z"
                  fill="#E7E7E7"/>
            <path
                d="M12.6562 9.53371L13.8462 9.86745L13.9013 9.65184C14.0393 9.11271 14.106 8.55777 14.0996 8.00125V7.60085H12.8987L12.8987 7.98032C12.9006 8.43462 12.8389 8.88693 12.7154 9.32406L12.6562 9.53371Z"
                fill="#E7E7E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.10729 6.19264L3.29195 6.57236L3.35977 6.36802C3.50248 5.93806 3.70478 5.53032 3.96073 5.15676L4.0827 4.97873L3.10217 4.296L2.97694 4.46927C2.64185 4.93294 2.37437 5.44202 2.18253 5.98118L2.10729 6.19264Z"
                  fill="#E7E7E7"/>
            <path
                d="M12.4215 5.84729L12.5155 6.04751L13.5942 5.51926L13.5053 5.32636C13.2686 4.813 12.9575 4.33748 12.582 3.91519L12.437 3.75221L11.5493 4.54529L11.6871 4.70696C11.9815 5.05258 12.2285 5.43608 12.4215 5.84729Z"
                fill="#E7E7E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.64616 2.84989L5.26824 3.88043L5.4536 3.77165C5.85042 3.53875 6.27942 3.36588 6.72668 3.25863L6.94217 3.20696L6.63257 2.01421L6.42145 2.06976C5.86342 2.21657 5.32884 2.44147 4.83352 2.7378L4.64616 2.84989Z"
                  fill="#E7E7E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.0404 3.49863L10.2471 3.59155L10.6922 2.48304L10.5034 2.39678C9.99818 2.16589 9.46376 2.0053 8.91504 1.91947L8.69868 1.88562L8.51912 3.08465L8.73469 3.11654C9.18539 3.18322 9.62477 3.31179 10.0404 3.49863Z"
                  fill="#E7E7E7"/>
        </svg>
    )
}

export default PendingIcon