import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import RequestsBreadCrumbs from '../../../Components/BreadCrumbs/RequestBreadCrumbs';
import {Grid, Skeleton} from '@mui/material';
import RequestDetailsHeader from '../../../Components/RequestDetailsHeader';
import {getRequestsDetails} from '../../../api/getRequestDetails';
import RequestDetailsBody from '../../../Components/RequestDetailsBody';
import './index.css';
import {
    REQUEST_DETAILS_PAGE_TYPE,
    REQUEST_STATUS_SUBMIT,
    STATUS_APPROVAL_PENDING,
    STATUS_APPROVED,
    USER_DATA_KEY
} from "../../../Constant";
import CommentsSidebar from "../../../Components/CommentsSidebar";
import Drawer from "@mui/material/Drawer";
import {getLocalStorageData} from "../../../utils/localStorageHelper";
import Notification from "../../../Components/Notification";

function RequestDetails(props) {
    const {requestId} = useParams();
    const [requestDetails, setRequestDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isCommentsBarOpen, setIsCommentsBarOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const [requestStatus, setRequestStatus] = React.useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [alertType, setAlertType] = useState('');

    useEffect(() => {
        loadData();
    }, [requestId]);

    const loadData = async () => {
        setLoading(true);
        let user_data = getLocalStorageData(USER_DATA_KEY, true);
        setUserData(user_data);
        try {
            const RequestDetailsData = await getRequestsDetails(requestId);
            setRequestDetails(RequestDetailsData);
            RequestDetailsData?.status !== REQUEST_STATUS_SUBMIT ? setRequestStatus(STATUS_APPROVAL_PENDING) : setRequestStatus(STATUS_APPROVED);
            setLoading(false);
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };

    const shoNotifications = (msg = "", type = "") => {
        setNotificationMessage(msg)
        setIsOpen(true)
        setAlertType(type)
    }

    const handleNotificationClose = () => {
        setIsOpen(false);
    };

    const onClickBreadCrumbsLink = (link) => {
        navigate(link);
    };

    const toggleDrawer = (open) => () => {
        setIsCommentsBarOpen(open);
    }

    const handleCommentsClick = (data) => {
        toggleDrawer(true)();
    }

    return (
        <React.Fragment>
            <Drawer
                anchor="right"
                open={isCommentsBarOpen}
                onClose={toggleDrawer(false)}
                className="comments-sidebar-drawer"
            >
                <CommentsSidebar
                    pageType={REQUEST_DETAILS_PAGE_TYPE}
                    requestDetails={requestDetails}
                    handleCommentsClick={handleCommentsClick}
                    requestStatus={requestStatus}
                    open={setIsOpen}
                    message={setNotificationMessage}
                    type={setAlertType}
                    toggleDrawer={toggleDrawer}
                    showNotifications={shoNotifications}
                />
            </Drawer>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} className="amg-bread-crumbs">
                    <RequestsBreadCrumbs onClickBreadCrumbsLink={onClickBreadCrumbsLink} ChannelName={requestDetails?.config?.customer_details?.channel_name ? requestDetails?.config?.customer_details?.channel_name : null}/>
                </Grid>
                <Grid item xs={12} className="request-details-header">
                    {loading ?
                        <Skeleton animation="wave" className="header-skeleton-loading" width="100%"/> :
                        <RequestDetailsHeader
                            handleCommentsClick={handleCommentsClick}
                            loading={loading}
                            headerType={'Request'}
                            Details={requestDetails}
                            userData={userData}
                            shoNotifications={shoNotifications}
                        />
                    }
                </Grid>
                <Grid container xs={12} className="request-details-body">
                    {loading?
                        <Skeleton
                            animation="wave"
                            className="body-skeleton-loading"
                        /> :
                        <RequestDetailsBody
                            loading={loading}
                            Details={requestDetails}
                            showNotification={shoNotifications}
                            userData={userData}
                        />
                    }
                </Grid>
            </Grid>
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </React.Fragment>
    );
}

export default RequestDetails;