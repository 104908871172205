import React from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {DEVICE_TYPE_OPTIONS_MAP} from "../../../Constant";
import {isEmpty} from "../../../utils/helper";

function TableDeviceDistribution (props) {
    let deviceDetails = []
    Object.keys(DEVICE_TYPE_OPTIONS_MAP).map((key, index) => {
        let deviceData = props?.DeviceData?.find((data) => data.type === key && data)
        if(!isEmpty(deviceData)){
            deviceDetails.push({"label": DEVICE_TYPE_OPTIONS_MAP[key], "value": deviceData?.percentage})
        }
    })
    return (
        <React.Fragment>
            <React.Fragment>
                <Grid item xs={10} className="app-supply-details-table-frame">
                    <Table className="off-white-table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" size="medium" style={{whiteSpace: "nowrap"}}>
                                    DEVICE TYPE
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium" style={{whiteSpace: "nowrap"}} >
                                    PERCENTAGE (%)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="request-table-body">
                            <React.Fragment>
                                {
                                    deviceDetails.map((data, index) => {
                                        return (
                                            <TableRow key={`${data?.label}-${index}`}>
                                                <TableCell className='table-cell'> {data?.label || ""} </TableCell>
                                                <TableCell className='table-cell'> {data?.value ? `${data?.value}%` :""}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </React.Fragment>
                        </TableBody>
                    </Table>
                </Grid>
            </React.Fragment>
        </React.Fragment>
    )
}

export default TableDeviceDistribution;