import "./index.css";
import {useContext, useEffect, useRef, useState} from "react";
import React from "react";
import * as XLSX from "xlsx";
import DropIcon from "../../assests/icons/dropIcon";
import EllipseIcon from "../../assests/icons/ellipseIcon";
import PinIcon from "../../assests/icons/pinIcon";
import DeleteIcon from "../../assests/icons/deleteIcon";
import PropTypes from "prop-types";
import {RequestContext} from "../../Context/RequestContext";
import useWebStorageHelper from "../../utils/sessionStorageHelper";
import {FILE_SIZE_LIMIT_EXCEEDED_NOTIFICATION_TYPE, MAX_FILE_SIZE, WARNING_NOTIFICATION_TYPE} from "../../Constant";

const DropInputOnboarding = (props) => {
    const { onboardingFileUrl } = useContext(RequestContext); // Use RequestContext to access generalData

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileData, setFileData] = useState(null);  // State to store the content of the uploaded file
    const fileInputRef = useRef(null);

    useEffect(()=>{
        if(props?.existingFileUrl){
            const existingFile = {
                name: props?.existingFileUrl?.split('/').pop(),
                url: props?.existingFileUrl?.so_document_file_url
            };
            setSelectedFile(existingFile);
        }
    },[])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(file?.size > MAX_FILE_SIZE) {
            props.showNotification(WARNING_NOTIFICATION_TYPE, FILE_SIZE_LIMIT_EXCEEDED_NOTIFICATION_TYPE);
            event.target.value = '';
            return;
        }
        setSelectedFile(file);
        if (file) {
            props.onFileChange(props.id, file, props.formKey);
        }
        readFile(file);
        // Reset the file input element's value
        event.target.value = null;
    };

    const readFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Limit to the first 65 rows
            jsonData = jsonData.slice(0, 65);

            // // Log the file name and the parsed JSON data
            // console.log(`File name: ${file.name}`);
            // console.log('Parsed JSON data:', jsonData);
            //
            // // Assuming the file has two columns, we can log the data accordingly
            // jsonData.forEach((row, index) => {
            //     console.log(`Row ${index + 1}: Column 1 - ${row[0]}, Column 2 - ${row[1]}`);
            // });
            //
            // // Store the parsed data in the state
            setFileData(jsonData);

            if (props.onDataLoaded) {
                props.onDataLoaded(props.id, file.name, jsonData);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleDropAreaClick = () => {
        document.getElementById(`file-upload-input-${props.id}`).click();
    };

    const handleDeleteClick = () => {
        setSelectedFile(null);
        useWebStorageHelper.removeItem(props.formKey);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setFileData(null);
        props.onFileChange(props.id, null, props.formKey);
    };

    const onClickSampleDownload = () => {
        window.open(props.sampleFileUrl, "_blank")
    }

    return (
        <React.Fragment>
            <div className="drop2-input-div">
                <div className="drop-div">
                    {props?.headMessage && (
                        <div className="head-message">
                            {props.headMessage}
                        </div>
                    )}
                    <div className="drop-area-div" onDoubleClick={handleDropAreaClick}>
                        <DropIcon />
                        <div className="drop-span">
                            <span className="drop-typography">Drop your files here or
                                <span style={{ color: "#7FB8F0", cursor: "pointer" }} onClick={handleDropAreaClick}> browse
                                </span>
                            </span>
                            <div className="drop-sub-typography">Up to 1MB<EllipseIcon />
                                {props?.fileType}
                            </div>
                        </div>
                        <input
                            id={`file-upload-input-${props.id}`}
                            type="file"
                            accept={props.supportedType}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            ref={fileInputRef}
                        />
                    </div>
                    <div className="drop-subhead">
                        <span className="drop-subhead-typography">{props?.subMessage}
                            <span style={{ color: "#7FB8F0" }} onClick={onClickSampleDownload}>
                                Download sample
                            </span> here.
                        </span>
                    </div>
                </div>
                {selectedFile && (
                    <div className="dropped-file-div">
                        <div className="pin-dropped-div">
                            <PinIcon />
                            {selectedFile.name}
                        </div>
                        <DeleteIcon deleteData={handleDeleteClick} className="delete-icon" />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

DropInputOnboarding.propTypes = {
    headMessage: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    onFileChange: PropTypes.func.isRequired,
    onDataLoaded: PropTypes.func.isRequired,
    subMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    supportedType: PropTypes.string.isRequired,
    sampleFileUrl: PropTypes.string.isRequired
}

export default DropInputOnboarding;