import "./index.css"
import React, {useState} from "react";
import {Checkbox, Input, InputAdornment, Divider, Badge, Button} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from "../../../assests/icons/searchIcon";
import VectorIcon from "../../../assests/icons/vectorIcon";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {MAX_DATE, MAX_DATE_FILTER, TODAYS_DATE} from "../../../Constant";
import CalendarIcon from "../../../assests/icons/calendar";
import {useEffect} from "react";
const CustomCalendarIcon = () => {
    return <CalendarIcon />;
};

const FilterAccordian = (props) =>{
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [searchQueries, setSearchQueries] = useState({});




    useEffect(() => {
        setSelectedFilters(props.selectedFilterData); // Update filter data initially
    }, [props.filterData, props.filterDataHasDate, props.selectedFilterData]);





    const handleCheckboxChange = (heading, item) => {
        setSelectedFilters((prevSelectedFilters) => {
            const updatedFilters = prevSelectedFilters.map((filter) => {
                if (filter.heading === heading) {
                    const newData = filter.data.includes(item)
                        ? filter.data.filter((i) => i !== item)
                        : [...filter.data, item];
                    return { ...filter, data: newData };
                }
                return filter;
            });
            props.setFilterData(updatedFilters); // Update the parent component's filter data with the updated filters
            return updatedFilters; // Return the updated filters
        });
    };
    const handleDateChange = (newDate, heading) => {
        setSelectedFilters((prevSelectedFilters) => {
            const updatedFilters = prevSelectedFilters.map((filter) => {
                if (filter.heading === heading) {
                    return {
                        ...filter,
                        data: newDate.format("MMM D, YYYY") // Update the data with the formatted date
                    };
                }
                return filter;
            });
            props.setFilterData(updatedFilters);
            return updatedFilters;
        });
    };


    const getSelectedCount = (heading) => {
        if (heading === "date") {
            const gteDateFilter = selectedFilters.find(f => f.heading === 'gteDate');
            const lteDateFilter = selectedFilters.find(f => f.heading === 'lteDate');

            let count = 0;
            if (gteDateFilter && gteDateFilter.data) {
                count++;
            }
            if (lteDateFilter && lteDateFilter.data) {
                count++;
            }

            return count;
        }
        const filter = selectedFilters.find(f => f.heading === heading);
        return filter ? filter.data.length : 0;
    };

    const isIntermediateChecked = (heading) => {
        const filter = selectedFilters.find((f) => f.heading === heading);
        const totalItems = props.filterData.find((f) => f.heading === heading).data.length;
        return filter && filter.data.length > 0 && filter.data.length < totalItems;
    };

    const getFilteredData = (data, heading) => {
        const query = searchQueries[heading]?.toLowerCase();
        if (!query) return data;
        return data.filter((item) => item.toLowerCase().includes(query));
    };

    const handleSearchChange = (e, heading) => {
        setSearchQueries((prevSearchQueries) => ({
            ...prevSearchQueries,
            [heading]: e.target.value,
        }));
    };

    const handleAccordionChange = (heading, isExpanded) => {
        if (!isExpanded) {
            setSearchQueries((prevSearchQueries) => ({
                ...prevSearchQueries,
                [heading]: '', // Clear search query when accordion is closed
            }));
        }
    };
    const onClickReset = () => {
        setSelectedFilters((prevSelectedFilters) => {
            const updatedFilters = prevSelectedFilters.map((filter) => {
                if (filter.heading === 'gteDate' || filter.heading === 'lteDate') {
                    return {
                        ...filter,
                        data: "" // Update the data with the formatted date
                    };
                }
                return filter;
            });
            props.setFilterData(updatedFilters);
            props.setSelectedFilterData(updatedFilters)
            props.getTotalSelectedFilters(updatedFilters)
            return updatedFilters;
        });
    };




    return(
    <React.Fragment>
        {props.filterData.map((filter, index) => (
        <Accordion key={index}
                   className="filter-sidebar-accordian"
                   onChange={(event, expanded) => handleAccordionChange(filter.heading, expanded)}>
            <AccordionSummary
                className="filter-sidebar-accordian-summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                {filter.heading}
                {getSelectedCount(filter.heading)>0 &&(<div className="badge-filter-div" > {getSelectedCount(filter.heading)}</div>)}

            </AccordionSummary>
            <AccordionDetails>

                <Input
                    className="amg-input-white"
                    value={searchQueries[filter.heading] || ''}
                    onChange={(e) => handleSearchChange(e, filter.heading)}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    }
                    size="small"
                    fullWidth
                    placeholder="Search"
                />
                <div className="filter-item-selectall">
                    <Checkbox
                        className="amg-white-checkbox filter-sidebar-checkbox"
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                                setSelectedFilters((prevSelectedFilters) => {
                                    const updatedFilters = prevSelectedFilters.map((f) =>
                                        f.heading === filter.heading ? { ...f, data: [...filter.data] } : f
                                    );
                                    props.setFilterData(updatedFilters); // Update filter data with selected filters
                                    return updatedFilters; // Return the updated filters for immediate use in state
                                });
                            } else {
                                setSelectedFilters((prevSelectedFilters) => {
                                    const updatedFilters = prevSelectedFilters.map((f) =>
                                        f.heading === filter.heading ? { ...f, data: [] } : f
                                    );
                                    props.setFilterData(updatedFilters); // Update filter data with selected filters
                                    return updatedFilters; // Return the updated filters for immediate use in state
                                });
                            }
                        }}
                        indeterminate={isIntermediateChecked(filter.heading)}
                        checked={getSelectedCount(filter.heading) > 0}
                    />

                    Select all
                </div>
                <VectorIcon/>
                <div className="all-data-div">
                {getFilteredData(filter.data, filter.heading).map((item, idx) => (
                    <div key={idx}>
                        <Checkbox
                            className="amg-white-checkbox filter-sidebar-checkbox"
                            checked={selectedFilters.find((f) => f.heading === filter.heading)?.data.includes(item) || false}
                            onChange={() => handleCheckboxChange(filter.heading, item)}
                        />
                        {item}
                    </div>
                ))}
                </div>
            </AccordionDetails>
        </Accordion>
        ))}
        {/*CHECK IF PROPS HAS DATE FILTER ENABLED*/}

        {props.filterDataHasDate &&(
            <Accordion className="filter-sidebar-accordian">
                <AccordionSummary
                    className="filter-sidebar-accordian-summary"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Date range
                    { getSelectedCount("date")> 0 &&(<div className="badge-filter-div" > {getSelectedCount("date")}</div>)}
                </AccordionSummary>
                <AccordionDetails style={{gap:"4px"}}>
                    <span className="start-date-filter">Start Date</span>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="calendar-popover"
                    >
                        <DatePicker
                            autoFocus={false}
                            value={selectedFilters.find(filter => filter.heading === 'gteDate')?.data ? dayjs(selectedFilters.find(filter => filter.heading === 'gteDate').data) : null}
                            onChange={(newDate) =>
                                handleDateChange(newDate, 'gteDate')
                            }
                            renderInput={(props) => <input {...props} />}
                            format="MMM D, YYYY"
                            className="date-picker date-picker-sidebar-filter"
                            maxDate={dayjs(MAX_DATE_FILTER)}
                            slots={{
                                openPickerIcon: CustomCalendarIcon,
                            }}
                            disableFuture={true}
                            disableHighlightToday={true}
                            PopperProps={{
                                placement: "bottom-start"
                            }}
                        />
                    </LocalizationProvider>
                    <div style={{marginTop:"16px"}}>
                    <span className="start-date-filter">End Date</span>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="calendar-popover"
                    >
                        <DatePicker
                            autoFocus={false}
                            value={selectedFilters.find(filter => filter.heading === 'lteDate')?.data ? dayjs(selectedFilters.find(filter => filter.heading === 'lteDate').data) : null}
                            onChange={(newDate) =>
                                handleDateChange(newDate, 'lteDate')
                            }
                            renderInput={(props) => <input {...props} />}
                            format="MMM D, YYYY"
                            className="date-picker date-picker-sidebar-filter"
                            maxDate={dayjs(MAX_DATE_FILTER)}
                            slots={{
                                openPickerIcon: CustomCalendarIcon,
                            }}
                            disableFuture={true}
                            disableHighlightToday={true}
                            PopperProps={{
                                placement: "bottom-start"
                            }}
                        />
                    </LocalizationProvider>
                    </div>
                    <Button variant="contained" className="reset-button" style={{ marginRight: "160px" }} onClick={()=>{onClickReset()}}>
                        Reset
                    </Button>
                </AccordionDetails>
            </Accordion>
        )}

    </React.Fragment>
    )
}

export default FilterAccordian;