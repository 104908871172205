import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    InputAdornment,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import { LIMIT_CHIP_SHOW } from "../../../Constant";

function AdsMultiSelectWithSearch(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const open = Boolean(anchorEl);
    const [fullData, setFullData] = useState([]);

    useEffect(() => {
        if (props.options.length > 0) {
            setFullData(props.options);
        }
    }, [props.options, props.value]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopover(true);
        setFullData(props.options);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenPopover(false);
    };

    const onHandleSearch = (e) => {
        const getValue = e?.target?.value?.toLowerCase()?.replace(/[^a-z0-9]/g, ''); // Convert to lowercase and remove non-alphanumeric characters
        const getPropsFullDataSet = [...props.options]; // Shallow copy of props.options
        if (getPropsFullDataSet.length > 0) {
            const filteredData = getPropsFullDataSet.filter((item) => {
                const normalizedLabel = item?.label?.toLowerCase()?.replace(/[^a-z0-9]/g, ''); // Normalize label
                return normalizedLabel?.includes(getValue);
            });
            setFullData(filteredData);
        }
    };

    const onCheckedItem = (value) => {
        props.onChange(value, props.index, props.type, props?.formKey);
    };

    const onDeleteChip = (value) => {
        props.onChange(value, props.index, props.type, props?.formKey);
    };

    const onClickSelectAll = () => {
        if (props.value.length === props.options.length) {
            props.onChange([], props.index, props.type, props?.formKey, false, true);
        } else {
            const allValues = props.options.map((data) => data.value);
            props.onChange(allValues, props.index, props.type, props?.formKey, true);
        }
    };

    return (
        <FormControl variant="standard" size="small" className="multi-select-component full-width">
            <ListItem
                secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                        {openPopover ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                }
                onClick={handleClick}
                disablePadding
            >
                <ListItemText>
                    {(Array.isArray(props.value) && props.value.length > 0) || props.type === "boolean" ? (
                        <>
                            {props.type === "boolean" && props.value.length > 0 ? (
                                <Chip
                                    label={props.value === "true" ? "True" : "False"}
                                    className="multiselect-chip-level"
                                    deleteIcon={<CloseIcon />}
                                />
                            ) : (
                                <>
                                    {props.value.slice(0, LIMIT_CHIP_SHOW).map((data, idx) => (
                                        <Chip
                                            key={idx}
                                            label={data}
                                            className="multiselect-chip-level"
                                            onDelete={() => onDeleteChip(data)}
                                            deleteIcon={<CloseIcon />}
                                        />
                                    ))}
                                    {props.value.length > LIMIT_CHIP_SHOW && (
                                        <Chip
                                            label={`+${props.value.length - LIMIT_CHIP_SHOW} more`}
                                            className="multiselect-chip-level"
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <span className="list-placeholder">{ props?.placeholder || "Select Value"}</span>
                    )}
                </ListItemText>
            </ListItem>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="multi-select-item-popover"
            >
                <MenuItem className="select-options-item search-item" disableRipple={true} disableGutters={true} disableTouchRipple={true}>
                    <TextField
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        placeholder="Search"
                        autoComplete="off"
                        onChange={(e) => onHandleSearch(e)}
                    />
                </MenuItem>
                {fullData.length > 0 && (
                    <React.Fragment>
                        { props?.allowSelectAll=== true && (<MenuItem
                            className="select-options-item select-all-item"
                            onClick={() => onClickSelectAll()}
                        >
                            <ListItemIcon>
                                {props.value.length === props.options.length ?
                                    <Checkbox className="amg-white-checkbox" checked={true}/> :
                                    <Checkbox checked={false}/>}
                            </ListItemIcon>
                            Select All
                        </MenuItem>
                        )}
                        {fullData.map((option) => {
                            return (
                                <MenuItem
                                    value={option.label}
                                    className="select-options-item"
                                    key={option.label}
                                    onClick={() => onCheckedItem(option.value, option.label)}
                                >
                                    <ListItemIcon>
                                        {props?.value?.indexOf(option.value) > -1 ? <Checkbox className="amg-white-checkbox" checked={true} /> : <Checkbox checked={false} />}
                                    </ListItemIcon>
                                    {option.value}
                                </MenuItem>
                            );
                        })}
                    </React.Fragment>
                )}
            </Popover>
        </FormControl>
    );
}

AdsMultiSelectWithSearch.propTypes = {
    "onChange": PropTypes.func.isRequired,
    "value": PropTypes.arrayOf(PropTypes.string).isRequired,
    "index": PropTypes.number.isRequired,
    "options": PropTypes.arrayOf(PropTypes.object.isRequired),
    "type": PropTypes.string.isRequired,
    "formKey": PropTypes.string.isRequired
};

export default AdsMultiSelectWithSearch;
