import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Grid, Typography} from "@mui/material";
import {formatNumberToMillion} from "../../utils/helper";
import {UNDER_PACING_KEY} from "../../Constant";

const CustomToolTip = (props) => {
    const [data, setData] = useState({});

    useEffect(() => {
        if(props.active){
            const updatedData = {
                "target": props.data.target,
                "projected": props.data.achieved + props.data.projected,
                "achieved": props.data.achieved,
                "track": props.data.track,
                "active_month": props.data.active_month
            }
            setData(updatedData);
        }

    }, [props])

    return(
        <Grid className="custom-tool-tip">
            {data?.achieved ? <Typography className="custom-tool-tip-item">
                <span className={data?.track && data.track === UNDER_PACING_KEY ? "custom-tool-tip-item-filler light-yellow" :"custom-tool-tip-item-filler light-green"}/>
                <span className="custom-tool-tip-item-label"> Achieved </span>
                <span> ${formatNumberToMillion(data?.achieved)} </span>
            </Typography> : null}
            {data?.projected && !data?.active_month ? (<Typography className="custom-tool-tip-item">
                <span className={data?.track && data.track === UNDER_PACING_KEY ? "custom-tool-tip-item-filler light-yellow" :"custom-tool-tip-item-filler light-green"} style={{opacity: 0.4}}/>
                <span className="custom-tool-tip-item-label"> Projected </span>
                <span> ${formatNumberToMillion(data?.projected)} </span>
            </Typography>): null}
            <Typography className="custom-tool-tip-item">
                <span className="custom-tool-tip-item-filler" style={{backgroundColor: "#E7E7E7"}}/>
                <span className="custom-tool-tip-item-label"> Goal </span>
                <span> ${formatNumberToMillion(data?.target)} </span>
            </Typography>
        </Grid>
    )
}

CustomToolTip.propTypes = {
    "active": PropTypes.bool,
    "data": PropTypes.shape({
        "month": PropTypes.string.isRequired,
        "target": PropTypes.number.isRequired,
        "projected": PropTypes.number.isRequired,
        "achieved": PropTypes.number.isRequired,
        "track": PropTypes.string.isRequired,
        "active_month": PropTypes.bool.isRequired
    })
}

export default CustomToolTip