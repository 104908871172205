import { useState, useEffect } from 'react';

const useDebounce = (callback, delay) => {
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    useEffect(() => {
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [debounceTimeout]);

    const debounce = (...args) => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(() => {
            callback(...args);
        }, delay);

        setDebounceTimeout(newTimeout);
    };

    return debounce;
};

export default useDebounce;