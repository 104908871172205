import axios from "axios";
import { AVAILS_WHERE_QUERY, REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const downloadAvailsReport = async ( where = AVAILS_WHERE_QUERY ) => {

    try {

        let data = JSON.stringify({
            query: `
                query MyQuery($report_details: avails_report_download_filters!, $where: avails_filter!) {
                    avails_report_download(report_details: $report_details, where: $where) {
                        message
                        status_code
                        tracking_id
                    }
                }
            `,
            variables: {...where}
        });
        const headers = adsHeader()

        let config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: `${REPORT_BFF_URI}`,
            headers: headers,
            data: data,
        };

        const response = await axios.request(config);
        if (response?.data?.data?.avails_report_download && response.status === 200) {
            return response.data.data.avails_report_download;
        }

        return {};
    } catch (error) {
        console.error("Error fetching while getSupplyHealthTracker:", error);
        return null;
    }
};

export default downloadAvailsReport;
