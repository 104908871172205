import React from 'react';
import "./index.css";
import {Grid, Typography} from "@mui/material";
import IntegratedSupplyBreadCrumbs from "../../../Components/BreadCrumbs/IntegratedSupplyBreadCrumbs";
import {useNavigate} from "react-router-dom";
import IntegratedReportField from "../../../Components/IntegratedSupply/IntegratedReportField";
import IntegratedReportCriteria from "../../../Components/IntegratedSupply/IntegratedReportCriteria";
import IntegratedReportTable from "../../../Components/IntegratedSupply/IntegratedReportTable";
import {IntegratedReportProvider} from "../../../Context/IntegratedReportContext";

const IntegratedSupplyTransparencyDetails = () => {
    const navigation = useNavigate();

    //handle breadCrumb navigation
    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };

  return (
      <React.Fragment>
          <IntegratedReportProvider>
              <Grid item xs={12} className="amg-bread-crumbs">
                  <IntegratedSupplyBreadCrumbs
                      onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                  />
              </Grid>

              <Grid item xs={12} className="report-heading-grid">
                  <Typography className="report-heading-typography">Integrated Supply transparency report</Typography>
              </Grid>

              <Grid item xs={12}>
                  <IntegratedReportField />
                  <IntegratedReportCriteria />
              </Grid>

              <Grid item xs={12} className="integrated-table-grid">
                  <IntegratedReportTable />
              </Grid>
          </IntegratedReportProvider>
      </React.Fragment>
  )
}

export default IntegratedSupplyTransparencyDetails
