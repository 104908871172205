import "./index.css"
const DownloadIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"  className="download-icon-svg">
            <path
                d="M10.5856 8.33333H14.7523L9.75228 13.3333L4.75228 8.33333H8.91895V2.5H10.5856V8.33333ZM3.08561 15.8333H16.4189V10H18.0856V16.6667C18.0856 16.8877 17.9978 17.0996 17.8415 17.2559C17.6853 17.4122 17.4733 17.5 17.2523 17.5H2.25228C2.03127 17.5 1.8193 17.4122 1.66302 17.2559C1.50674 17.0996 1.41895 16.8877 1.41895 16.6667V10H3.08561V15.8333Z"
                fill="#E7E7E7"/>
        </svg>
    )
}

export default DownloadIcon