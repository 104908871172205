import React, { useState, useEffect } from "react"
import { Grid, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom"
import TableContentMacro from "../../../Components/Tables/TableContentMacro";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FilterTableWithAction from "../../../Components/TableTopFilters/FilterTableWithAction";




function DemandTagDetails(props) {
    const [contentData, setContentData] = useState([])
    const [titleTextHeader, setTitleTextHeader] = useState("")
    const [demandTagDetails, setDemandTagDetails] = useState("")
    const navigation = useNavigate()
    const { dataLength } = 10



    useEffect(() => {
        setContentData("")
        setTitleTextHeader("Macros")
        setDemandTagDetails("")
    }, []);

    const onClickBreadCrumbsLink = (link) => {
        navigation(link)
    }


    return (
        <React.Fragment>
            <Grid item xs={12} className="amg-bread-crumbs">
                <span onClick={() => onClickBreadCrumbsLink(demandTagDetails.platform_link)} className="amg-bread-crumbs-links"> Platform </span> /
                <span> {demandTagDetails.platform_name} </span> /
                <span onClick={() => onClickBreadCrumbsLink(`${demandTagDetails.channels_link}${demandTagDetails.amg_id}`)} className="amg-bread-crumbs-links"> Channels </span> /
                <span> {demandTagDetails.channels_name} </span> /
                <span onClick={() => onClickBreadCrumbsLink(`${demandTagDetails.supply_router_link}${demandTagDetails.channel_id}`)} className="amg-bread-crumbs-links"> Thunderstorm Tags </span> /
                <span> {demandTagDetails.supply_router_name} </span> /
                <span onClick={() => onClickBreadCrumbsLink(`${demandTagDetails.demand_tag_link}${demandTagDetails.supply_router_id}`)} className="amg-bread-crumbs-links"> Demand Tags </span>
            </Grid>
            <Grid item xs={12} className="amg-body-page-header">
                <Typography> {demandTagDetails.demand_tag_name} </Typography>
            </Grid>
            <Grid item xs={12} className="amg-body-page-meta">
                <span> Tag Url : </span> <span className="ad_tag_url"> {`${demandTagDetails?.vast_url?.substring(0, 110)}...`} </span> <ContentCopyIcon className="amg-copy-icon" />
            </Grid>
            <Grid item xs={12} className="amg-body-page-meta">
                <span> Linked Thunderstorm Tags :  </span> <span className="supply_tag_name"> {`${demandTagDetails?.supply_router_name}`} </span>
            </Grid>
            <Grid item xs={12} className="amg-body-content">
                <Grid container className="amg-body-table-content">
                    <FilterTableWithAction count={dataLength} titleText={titleTextHeader} showActionButton={true} actionButtonName="Add Macros" />
                    <TableContentMacro tableContent={contentData} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default DemandTagDetails