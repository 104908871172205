import {jobDetails} from "../__mock__/jobDetials";
import axios from 'axios';
import {AUDIT_TRAIL_URL} from "../Constant";
import {adsHeader} from "../utils/helper";
const getJobStatusById = async (jobId) => {
    try {
        const headers = adsHeader()
        const response = await axios.get(`${AUDIT_TRAIL_URL}get-details/${jobId}`, {
            headers: headers
        });
        return response.data;
    } catch (error) {
        console.log("error while fetching jobDetails")

    }
};

export default getJobStatusById;
