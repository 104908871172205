import React, {useEffect, useState}  from "react"
import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./utils/localStorageHelper";
import {getCurrentTime} from "./utils/helper";
import {
    ACTIVE_MENU_PATH,
    INITIAL_PAGE,
    ONGOING_API_CALL_KEY,
    SELECTED_CHANNEL_KEY,
    SELECTED_PLATFORM_KEY, SELECTED_ROUTER_KEY,
    USER_DATA_KEY
} from "./Constant"
import {Grid} from "@mui/material";
import Header from "./Components/Header";
import Body from "./Components/Body";

function Container(props){
    const [userData, setUserData] = useState({})
    useEffect(() => {
        const getLocalUserData = getLocalStorageData(USER_DATA_KEY, true);
        const getCurrentEpochTime = getCurrentTime();
        if (
            getLocalUserData?.expires_on &&
            getLocalUserData.expires_on > getCurrentEpochTime
        ) {

            setUserData(getLocalUserData);
        } else {
            removeLocalStorageData(USER_DATA_KEY);
            removeLocalStorageData(ONGOING_API_CALL_KEY)
            removeLocalStorageData(SELECTED_CHANNEL_KEY)
            removeLocalStorageData(SELECTED_PLATFORM_KEY)
            removeLocalStorageData(SELECTED_ROUTER_KEY)
            window.location.href = "/";
        }
    }, []);

    return(
        <Grid container className="amg-content-div">
            <Header userData={userData}/>
            <Body/>
        </Grid>
    )
}

export default Container