import axios from "axios";
import { BFF_LAYER_URI, MAX_LIMIT_SHOW_TABLE_DATA } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getChannelsListing = (
    amg_id = "",
    limit = MAX_LIMIT_SHOW_TABLE_DATA,
    offset = 0,
    email = ""

) => {
    let data = JSON.stringify({
        query: `query MyQuery($limit: Int = 10, $offset: Int = 10, $amg_id: String = "") {
      getChannelsListing(
        limit: $limit
        where: {amg_id: $amg_id}
        offset: $offset
      ) {
        total_count
        data {
          channel_id
          created_at
          name
          platform_id
          policy
          supply_partner_id
          supply_partner_name
          supply_router_url
          ts_tags
          updated_at
        }
      }
    }`,
        variables: {
            limit: limit,
            offset: offset,
            amg_id: amg_id
        }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getChannelsListing && response.status === 200) {
                return response.data.data.getChannelsListing;
            } else {
                return [];
            }
        })
        .catch((_) => {
            return null;
        });
}
