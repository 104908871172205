export const GeoDistributionConfig = [
    {
        rowLabel: "NA",
        fields: [
            {
                type: "text_input",
                key: "NA",
                placeholder: " ",
                width: "99px"
            }
        ]
    },
    {
        rowLabel: "EMEA",
        fields: [
            {
                type: "text_input",
                key: "EMEA",
                placeholder: " ",
                width: "99px"
            }
        ]
    },
    {
        rowLabel: "LATAM",
        fields: [
            {
                type: "text_input",
                key: "LATAM",
                placeholder: " ",
                width: "99px"
            }
        ]
    },
    {
        rowLabel: "APAC",
        fields: [
            {
                type: "text_input",
                key: "APAC",
                placeholder: " ",
                width: "99px"
            }
        ]
    }
];
