import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getSupplyTagsByChannelId = async (channel_id = "", type = "", email = "") => {
    const query = `
        query MyQuery($channel_id: String!, $type: String!) {
            getSupplyTagsByChannelId(where: { channel_id: $channel_id, type: $type }) {
                total_count
                data {
                    created_at
                    demand_tags_count
                    delivery_name
                    delivery_id
                    supply_partner_id
                    updated_at
                    supply_tag_name
                    supply_tag_id
                    supply_partner_name
                }
            }
        }
    `;

    const variables = { "channel_id": channel_id, "type": type };

    const data = JSON.stringify({
        query,
        variables
    });
    const headers = adsHeader()

    const config = {
        method: 'post',
        url: BFF_LAYER_URI,
        headers: headers,
        data
    };

    try {
        const response = await axios.request(config);
        if (response?.data?.data?.getSupplyTagsByChannelId && response.status === 200) {
            return {
                loading: false, error: null, data: response.data.data.getSupplyTagsByChannelId, refetch: () => {
                }
            };
        } else {
            return {
                loading: false, error: "Error fetching supply tags", data: null, refetch: () => {
                }
            };
        }
    } catch (error) {
        console.error("Error fetching supply tags:", error);
        return {
            loading: false, error, data: null, refetch: () => {
            }
        };
    }
};
