import {
    Grid,
    Popover,
    Typography,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    ListItemIcon, Input, InputAdornment, Skeleton, TextField
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    ADS_PLUS_INVENTORY_TYPE_FILTER,
    DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE,
    SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE
} from "../../Constant";
import CheckIcon from "@mui/icons-material/Check";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.css"
import { getCurrentDateWithMonthYear, getItemFromArray, getLast6Months, isEmpty, getLastDateOfMonth} from "../../utils/helper";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from "../../assests/icons/searchIcon";
import {SEARCHED_TEXT_NOT_FOUND} from '../../Constant'


function AdsPlusPageHeader(props) {
    const [selectedDashboardType, setSelectedDashboardType] = useState("")
    const [openPopover, setOpenPopover] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedInventoryType, setSelectedInventoryType] = useState([])
    const [selectedSupplyPartner, setSelectedSupplyPartner] = useState([])
    const [selectedDemandPartner, setSelectedDemandPartner] = useState([])
    const [selectedLastMonth, setSelectedLastMonth] = useState("")
    const [allSupplyPartner, setAllSupplyPartner] = useState([])
    const [allDemandPartner, setAllDemandPartner] = useState([])
    const [allInventoryType, setAllInventoryType] = useState([])
    const [getLastSixMonth, setGetLastSixMonth] = useState([])
    const [filterConfig, setFilterConfig] = useState({})
    const [searchQuery,setSearchQuery] = useState("")
    const [filteredSupplyPartner, setFilteredSupplyPartner] = useState([])
    const [searchDemandQuery, setSearchDemandQuery] = useState('');
    const [searchSupplyQuery, setSearchSupplyQuery] = useState('');
    const [fullSupplyData, setFullSupplyData] = useState([]);
    const [fullDemandData, setFullDemandData] = useState([]);
    const [filteredDemandPartner, setFilteredDemandPartner] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(null);

    useEffect(() => {

        if(!props.loading) {
            const getLastMonthFilter = getLast6Months()
            const getLastSixMonthLabel = getLastMonthFilter.map((data) => data.label)
            const current = getLastMonthFilter.find(month => month.CurrentMonth);
            setCurrentMonth(current?.label);
            const getDefaultDateLabel = props.filterConfig?.date?.gte
            let dateValue = ""


            getLastMonthFilter.map((data) => {
                if (data.value === getDefaultDateLabel) {
                    dateValue = data.label
                }
                return true
            })

            if (props?.supplyPartnerData?.length > 0 && props?.dashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
                const getSupplyPartnerLabel = props.supplyPartnerData.map((data) => data.supply_partner_name).sort((firstString, secondString) => firstString.toLowerCase().localeCompare(secondString.toLowerCase()));
                const getInventoryLabel = ADS_PLUS_INVENTORY_TYPE_FILTER.map((data) => data.label)
                const getDefaultSupplyPartnerLabel = props?.filterConfig?.supply_partner?.in || ""
                const getDefaultInventoryTypeLabel = props.filterConfig?.inventory_type || ""
                let supplyPartnerValue = []
                let inventoryValue = []

                props.supplyPartnerData?.map((data) => {
                    if (getDefaultSupplyPartnerLabel.indexOf(data.supply_partner_id) > -1) {
                        supplyPartnerValue.push(data.supply_partner_name)
                    }
                    return true
                })

                if (getDefaultInventoryTypeLabel === "all") {
                    ADS_PLUS_INVENTORY_TYPE_FILTER?.map((data) => {
                        inventoryValue.push(data.label)
                        return true
                    })
                } else {
                    ADS_PLUS_INVENTORY_TYPE_FILTER?.map((data) => {
                        if (getDefaultInventoryTypeLabel === data.value) {
                            inventoryValue.push(data.label)
                        }
                        return true
                    })
                }
                setAllInventoryType(getInventoryLabel)
                setAllSupplyPartner(getSupplyPartnerLabel)
                setFilteredSupplyPartner(getSupplyPartnerLabel)
                setSelectedInventoryType(inventoryValue)
                setSelectedSupplyPartner(supplyPartnerValue)
            } else if (props?.demandPartnerData?.length > 0 && !isEmpty(props?.filterConfig) && props?.dashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
                const getDemandPartnerLabel = props.demandPartnerData
                    .map((data) => data.demand_partner_name)
                    .sort((firstString, secondString) => firstString.toLowerCase().localeCompare(secondString.toLowerCase()));

                const demandPartnerValue = []
                const getDefaultDemandPartnerValue = props?.filterConfig?.demand_partner_id?.in || ""

                props.demandPartnerData?.map((data) => {
                    if (getDefaultDemandPartnerValue.indexOf(data.demand_partner_id) > -1) {
                        demandPartnerValue.push(data.demand_partner_name)
                    }
                    return true
                })

                setAllDemandPartner(getDemandPartnerLabel)
                setSelectedDemandPartner(demandPartnerValue)
            }

            setGetLastSixMonth(getLastSixMonthLabel)
            setSelectedLastMonth(dateValue)
            setFilterConfig(props.filterConfig)
            setSelectedDashboardType(props.dashboardType)
        }else{
            setGetLastSixMonth([])
            setSelectedLastMonth([])
            setFilterConfig({})
            setSelectedDashboardType("")
        }
        setSearchSupplyQuery("")
        setSearchDemandQuery("")
        setFullSupplyData(allSupplyPartner)
        setFullDemandData(allDemandPartner)
    }, [props])

    const onClickDashboardType = (e) => {
        setOpenPopover(true);
        setAnchorEl(e?.currentTarget);
    }

    const handleClose = () => {
        setOpenPopover(false);
        setAnchorEl(null);
    }

    const onClickDashboardViewItem = (type) => {
        setSelectedDashboardType(type)
        setOpenPopover(false);
        setAnchorEl(null);
        props.changeViewDashboardType(type)
    }

    const handleChangeInventoryType = (e) => {
        if (e?.target?.value) {
            const selectInventoryType = e.target.value
            let newSelectInventory = []
            const creatNewFilterConfig = Object.assign({}, filterConfig)
            const getAllOptionsLength = ADS_PLUS_INVENTORY_TYPE_FILTER.length
            const selectedInventoryLength = selectedInventoryType.length

            if (selectInventoryType[selectInventoryType.length - 1] === "All" && selectedInventoryLength === getAllOptionsLength) {
                newSelectInventory = []
                creatNewFilterConfig.inventory_type = ""
            }else if(selectInventoryType[selectInventoryType.length - 1] === "All" && selectedInventoryLength !== getAllOptionsLength){
                newSelectInventory = allInventoryType
                creatNewFilterConfig.inventory_type = "all"
            } else {
                newSelectInventory = selectInventoryType
                creatNewFilterConfig.inventory_type = ADS_PLUS_INVENTORY_TYPE_FILTER.length === selectInventoryType.length ? "all" :
                    selectInventoryType.length > 0 ? getItemFromArray(selectInventoryType[0], ADS_PLUS_INVENTORY_TYPE_FILTER, "label", "value") : ""
            }

            setSelectedInventoryType(newSelectInventory)
            setFilterConfig(creatNewFilterConfig)
            props.onFilterChange(creatNewFilterConfig)
        }
    }

    const handleChangeSupplyPartner = (e) => {

        if (e?.target?.value) {
            const selectSupplyPartner = e.target.value
            let newSelectSupplyPartner = []
            const creatNewFilterConfig = Object.assign({}, filterConfig)
            const getAllOptionsLength = allSupplyPartner.length
            const selectedSupplyPartnerLength = selectedSupplyPartner.length

            if (selectSupplyPartner[selectSupplyPartner.length - 1] === "All" && selectedSupplyPartnerLength === getAllOptionsLength) {
                newSelectSupplyPartner = []
                creatNewFilterConfig.supply_partner.in = []
            }else if (selectSupplyPartner[selectSupplyPartner.length - 1] === "All" && selectedSupplyPartnerLength !== getAllOptionsLength) {
                newSelectSupplyPartner = allSupplyPartner
                let getSupplyPartnerValue = props.supplyPartnerData.map((data) => data.supply_partner_id)
                creatNewFilterConfig.supply_partner.in = getSupplyPartnerValue
            }else {
                newSelectSupplyPartner = selectSupplyPartner;
                let getSupplyPartnerValue = selectSupplyPartner.map(
                    (data) => getItemFromArray(data, props.supplyPartnerData, "supply_partner_name", "supply_partner_id")
                )
                creatNewFilterConfig.supply_partner.in = getSupplyPartnerValue
            }
            setSelectedSupplyPartner(newSelectSupplyPartner)
            setFilterConfig(creatNewFilterConfig)
            props.onFilterChange(creatNewFilterConfig)
        }
    }

    const handleLastMonthFilterChange = (e) => {
        if (e?.target?.value) {
            const selectedMonth = e.target.value
            const getLastMonthFilter = getLast6Months()
            const getValue = getLastMonthFilter.find((data) => data.label === selectedMonth && data)
            const getStateFilterConfig = Object.assign({}, filterConfig)
            let getCurrentDate = ""
            if(getValue?.CurrentMonth){
                getCurrentDate = getCurrentDateWithMonthYear()
            }else {
                getCurrentDate = getLastDateOfMonth(getValue.value)
            }
            getStateFilterConfig.date.gte = getValue?.value || ""
            getStateFilterConfig.date.lte = getCurrentDate
            setSelectedLastMonth(selectedMonth)
            setFilterConfig(getStateFilterConfig)
            props.onFilterChange(getStateFilterConfig)
        }
    }

    const handleChangeDemandPartner = (e) => {
        if (e?.target?.value) {
            const selectDemandPartner = e.target.value
            let newSelectDemandPartner = []
            const creatNewFilterConfig = Object.assign({}, filterConfig)
            const getAllOptionsLength = allDemandPartner.length
            const selectedDemandPartnerLength = selectedDemandPartner.length

            if (selectDemandPartner[selectDemandPartner.length - 1] === "All" && selectedDemandPartnerLength === getAllOptionsLength) {
                newSelectDemandPartner = []
                creatNewFilterConfig.demand_partner_id.in = []
            }else if (selectDemandPartner[selectDemandPartner.length - 1] === "All" && selectedDemandPartnerLength !== getAllOptionsLength) {
                newSelectDemandPartner = allDemandPartner
                let getDemandPartnerValue = props.demandPartnerData.map((data) => data.demand_partner_id)
                creatNewFilterConfig.demand_partner_id.in = getDemandPartnerValue
            }else {
                newSelectDemandPartner = selectDemandPartner;
                let getDemandPartnerValue = selectDemandPartner.map(
                    (data) => getItemFromArray(data, props.demandPartnerData, "demand_partner_name", "demand_partner_id")
                )
                creatNewFilterConfig.demand_partner_id.in = getDemandPartnerValue
            }
            setSelectedDemandPartner(newSelectDemandPartner)
            setFilterConfig(creatNewFilterConfig)
            props.onFilterChange(creatNewFilterConfig)
        }
    }
    const handleSearchChange = (event, type) => {
        const searchValue = event.target.value
        if (type===SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
            const filteredPartners = allSupplyPartner.filter((partner) =>
                partner.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredSupplyPartner(filteredPartners)
        }

        setSearchQuery(event.target.value)
    };

    const onHandleSupplySearch = (e) => {
        e.preventDefault();
        const query = e.target.value.toLowerCase();
        setSearchSupplyQuery(query);

        if (query === '') {
            setFullSupplyData(allSupplyPartner);
        } else {
            const filteredData = allSupplyPartner.filter((item) => {
                return item.toLowerCase().includes(query);
            });

            if (filteredData.length === 0) {
                setFullSupplyData([SEARCHED_TEXT_NOT_FOUND]);
            } else {
                setFullSupplyData(filteredData);
            }
        }
    };


    const onHandleDemandSearch = (e) => {
        e.preventDefault();
        const query = e.target.value.toLowerCase(); // Capture the search query
        setSearchDemandQuery(query);

        if (query === '') {
            setFullDemandData(allDemandPartner);
        } else {
            const filteredData = allDemandPartner.filter((item) => {
                return item.toLowerCase().includes(query);
            });

            if (filteredData.length === 0) {
                setFullDemandData([SEARCHED_TEXT_NOT_FOUND]);
            } else {
                setFullDemandData(filteredData);
            }
        }
    };


    return (
        <Grid container>
            <Grid item xs={7} className="amg-body-page-header">
                <Typography className="selected-dashboard-typography">
                    {!isEmpty(selectedDashboardType) ? selectedDashboardType : <Skeleton animation="wave" className="text-skeleton-header" />}
                    {!isEmpty(selectedDashboardType) &&( <span className="drop-down-icon-popover" onClick={(e) => onClickDashboardType(e)}> {openPopover ?
                        <ArrowDropUpIcon /> : <ArrowDropDownIcon />}  </span>)}
                </Typography>
                <Popover
                    id="popover-dashboard-type"
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 110, left: 0 }}
                >
                    <Grid container className="dashboard-type-view">
                        <Grid
                            item
                            xs={12}
                            className={selectedDashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE ? "dashboard-type-item active" : "dashboard-type-item"}
                            onClick={() => onClickDashboardViewItem(SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE)}
                        >
                            {SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE}
                            {selectedDashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE &&
                                <span className="active-icon"> <CheckIcon /> </span>}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={selectedDashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE ? "dashboard-type-item active" : "dashboard-type-item"}
                            onClick={() => onClickDashboardViewItem(DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE)}
                        >
                            {DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE}
                            {selectedDashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE &&
                                <span className="active-icon"> <CheckIcon /> </span>}
                        </Grid>
                    </Grid>
                </Popover>
            </Grid>
            <Grid item xs={5} className="amg-body-top-filter">
                <Grid container>
                    <Grid item xs={4} className="amg-body-top-filter-item">
                        {selectedDashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE && allInventoryType?.length > 0 &&
                            <Select
                                value={selectedInventoryType}
                                onChange={(e) => handleChangeInventoryType(e)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="amg-filter-type-select"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return "Select Inventory Type";
                                    }
                                    else if (selected.length === allInventoryType.length ) {
                                        return "All Inventory types"
                                    }

                                    return selected.join(', ');
                                }}

                                multiple
                                IconComponent={KeyboardArrowDownIcon}
                            >

                                <MenuItem value="All" className="multi-select-item all-group">
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={selectedInventoryType.length === allInventoryType.length}
                                            indeterminate={selectedInventoryType.length > 0 && selectedInventoryType.length < allInventoryType.length}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="All" />
                                </MenuItem>
                                {
                                    allInventoryType.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item}
                                                      className="multi-select-item">
                                                <ListItemIcon>
                                                    <Checkbox checked={selectedInventoryType.indexOf(item) > -1} />
                                                </ListItemIcon>
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </Grid>
                    <Grid item xs={5} className="amg-body-top-filter-item">
                        {selectedDashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE && allSupplyPartner?.length > 0 && <Select
                            value={selectedSupplyPartner}
                            onChange={(e) => handleChangeSupplyPartner(e)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="amg-filter-type-select"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return "Select Supply Partner";
                                }

                                else if (selected.length === allSupplyPartner.length) {
                                    return "All Supply Partners"
                                }

                                return selected.join(', ');
                            }}
                            multiple
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                autoFocus: false,
                                classes: { paper: 'custom-menu-paper' },
                            }}
                        >
                            <MenuItem className="multi-select-item all-group" onKeyDown={(e) => e.stopPropagation()} disableRipple>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className="fixed-width-textfield"
                                    inputProps={{
                                        className: 'partner-search',
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search"
                                    autoComplete="off"
                                    value={searchSupplyQuery}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        onHandleSupplySearch(e);
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}

                                />
                            </MenuItem>
                            {searchSupplyQuery.length === 0 && (
                                <MenuItem value="All" className="multi-select-item all-group">
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={selectedSupplyPartner.length === allSupplyPartner.length}
                                            indeterminate={
                                                selectedSupplyPartner.length > 0 &&
                                                selectedSupplyPartner.length < allSupplyPartner.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="All" />
                                </MenuItem>
                            )}
                            {(fullSupplyData && fullSupplyData.length > 0 ? fullSupplyData : allSupplyPartner).map((item) => {
                                return (
                                    <MenuItem
                                        value={item}
                                        key={item}
                                        className="multi-select-item"
                                        disabled={item === SEARCHED_TEXT_NOT_FOUND}
                                    >
                                        <ListItemIcon>
                                            {item !== SEARCHED_TEXT_NOT_FOUND && (
                                                <Checkbox checked={selectedSupplyPartner.indexOf(item) > -1} />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                )
                            })}
                        </Select>}
                        {selectedDashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE && allDemandPartner.length > 0 && <Select
                            value={selectedDemandPartner}
                            onChange={(e) => handleChangeDemandPartner(e)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="amg-filter-type-select"
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return "Select Demand Partner";
                                }
                                else if (selected.length === allDemandPartner.length) {
                                    return "All Demand Partners"
                                }

                                return selected.join(', ');
                            }}
                            multiple
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                autoFocus: false,
                                classes: { paper: 'custom-menu-paper' },
                            }}
                        >
                            <MenuItem className="multi-select-item all-group" onKeyDown={(e) => e.stopPropagation()} disableRipple>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className="fixed-width-textfield"
                                    inputProps={{
                                        className: 'partner-search',
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search"
                                    autoComplete="off"
                                    value={searchDemandQuery}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        onHandleDemandSearch(e);
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </MenuItem>

                            {searchDemandQuery.length === 0 && (
                                <MenuItem value="All" className="multi-select-item all-group">
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={selectedDemandPartner.length === allDemandPartner.length}
                                            indeterminate={
                                                selectedDemandPartner.length > 0 &&
                                                selectedDemandPartner.length < allDemandPartner.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="All" />
                                </MenuItem>
                            )}

                            {(fullDemandData && fullDemandData.length > 0 ? fullDemandData : allDemandPartner).map((item) => {
                                return (
                                    <MenuItem
                                        value={item}
                                        key={item}
                                        className="multi-select-item"
                                        disabled={item === SEARCHED_TEXT_NOT_FOUND}
                                    >
                                        <ListItemIcon>
                                            {item !== SEARCHED_TEXT_NOT_FOUND && (
                                                <Checkbox checked={selectedDemandPartner.indexOf(item) > -1} />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                )
                            })}
                        </Select>}
                    </Grid>

                    <Grid item xs={3} className="amg-body-top-filter-item">
                        {!props.loading?(<Select
                            value={selectedLastMonth}
                            onChange={(e) => handleLastMonthFilterChange(e)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="amg-filter-type-select select-calendar"
                            IconComponent={CalendarTodayIcon}
                        >
                            {getLastSixMonth.map((item) => {
                                return (
                                    <MenuItem value={item} key={item} className="single-select">
                                        {item}{item===currentMonth ? ' (Current)' : ''}
                                    </MenuItem>

                                )
                            })}
                        </Select>):(<Skeleton animation="wave" className="log-header" width="100%" height={40} />)}

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

AdsPlusPageHeader.propTypes = {
    "supplyPartnerData": PropTypes.arrayOf(PropTypes.shape({
        "supply_partner_id": PropTypes.number.isRequired,
        "supply_partner_name": PropTypes.string.isRequired
    })),
    "onFilterChange": PropTypes.func.isRequired,
    "changeViewDashboardType": PropTypes.func.isRequired,
    "dashboardType": PropTypes.string.isRequired,
    "options": PropTypes.arrayOf(PropTypes.object.isRequired),
    "filterConfig": PropTypes.shape({
        "inventory_type": PropTypes.string,
        "date": PropTypes.shape({
            "gte": PropTypes.string.isRequired,
            "lte": PropTypes.string.isRequired,
        }),
        "supply_partner": PropTypes.shape({
            "in": PropTypes.arrayOf(PropTypes.number).isRequired
        }),
        "demand_partner_id": PropTypes.shape({
            "in": PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
    }).isRequired,
    "demandPartnerData": PropTypes.arrayOf(PropTypes.shape({
        "demand_partner_id": PropTypes.number.isRequired,
        "demand_partner_name": PropTypes.string.isRequired,
    })),
    "loading": PropTypes.bool.isRequired

}

export default AdsPlusPageHeader