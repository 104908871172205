import React from "react";
import PropTypes from "prop-types";
import {INITIAL_PAGE} from "../../../Constant";

function SupplyRouterBreadCrumbs(props){
    return(
        <React.Fragment>
            <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    `/dashboard/platform/channels/${props.platformId}`
                )}
                className="amg-bread-crumbs-links"
            >
                All Platforms &nbsp;
            </span>
                / &nbsp;<span> Platform:{props?.platformName} </span> &nbsp;/
                <span
                    onClick={() =>
                        props.onClickBreadCrumbsLink(
                            `/dashboard/channel/macros/${props?.channelId}`
                        )
                    }
                    className="amg-bread-crumbs-links"
                >
                &nbsp; Channels /
            </span>
                &nbsp;<span> {props?.channelName} </span> / &nbsp;
                <span
                    onClick={() => props.onClickBreadCrumbsLink(
                        `/dashboard/channel/thunderstorm-tags/${props.channelId}`
                    )}
                    className="amg-bread-crumbs-links"
                >
                Thunderstorm Tags  &nbsp;
            </span>
                /&nbsp;<span> {props?.supplyRouterName} </span>
            </React.Fragment>
        </React.Fragment>
    )
}

SupplyRouterBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
    amgId: PropTypes.string,
    platformName: PropTypes.string,
    channelId: PropTypes.string,
    channelName: PropTypes.string,
    supplyRouterId: PropTypes.string,
    platformId: PropTypes.string
}

export default SupplyRouterBreadCrumbs