import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Grid, Popover, Skeleton, Tooltip, Typography} from "@mui/material";
import "./index.css"
import {
    CUMULATIVE_ALL_MONTH_VALUE,
    CUMULATIVE_CONFIG_DATA,
    DEMAND_GOAL_WHERE_CLAUSE,
    GOAL_STATUS_LABEL,
    ON_TRACK_KEY,
    OVER_PACING_KEY,
    Q1_MONTTHS_WISE,
    QUATAR_DATA_PRESENT,
    UNDER_PACING_KEY,
} from "../../Constant";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VerticalBarChart from "../../Common/VerticalBarChart";
import {demandGoalTrackingData} from "../../__mock__/demandGoalTrackingData";
import {
    calculateFivePercentPlusAndMinus, checkIsCurrentMonth,
    formatNumberToMillion,
    formatRevenue, getCompletedDaysForAQuarter,
    getCurrentQuarter, getMonthIndex,
    getMonthName,
    removeFractions
} from "../../utils/helper";
import PropTypes from "prop-types";
import getDemandGoalRevenue from "../../api/getDemandGoalRevenue";
import {PlusMinus} from "../../assests/icons/plusMinus";

function AdsPlusDemandGoalTracking(props) {
    const [openPopover, setOpenPopover] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedDemandGoalDate, setSelectedDemandGoalDate] = useState("FY Q1 ‘24")
    const [monthsData, setMonthsData] = useState(Q1_MONTTHS_WISE)
    const [selectedMonth, setSelectedMonth] = useState(CUMULATIVE_ALL_MONTH_VALUE)
    const [goalTrackingData, setGoalTrackingData] = useState([])
    const [currentSelectedData, setCurrentSelectedData] = useState({})
    const [currentSelectedDataType, setCurrentSelectedDataType] = useState("Quarterly")

    useEffect(() => {
        let currentTime = new Date();
        let currentMonth = currentTime.getMonth() + 1;
        loadData(currentMonth)
    }, [props])

    const loadData = async (currentMonth) => {

        const getCurrentQuarterData = getCurrentQuarter(currentMonth)
        let getData = []
        let cumulativeMonthsData = Object.assign({}, CUMULATIVE_CONFIG_DATA)
        let completedDays = getCompletedDaysForAQuarter(getCurrentQuarterData?.value, getCurrentQuarterData?.year)

        if(getCurrentQuarterData?.value && getCurrentQuarterData?.months?.length > 0 && getCurrentQuarterData?.header){
            Promise.all(getCurrentQuarterData.months.map(async (month) => {
                let createWhereMonth = month;
                let createWhereYear = getCurrentQuarterData.year;

                const response = await getDemandGoalRevenue(createWhereMonth, createWhereYear);
                if (response) {
                    const monthTarget = response?.target || 0;
                    const monthProjected = response?.projected || 0;
                    const monthAchieved = response?.achieved || 0;

                    const getPercentageValue = calculateFivePercentPlusAndMinus(response?.target);
                    const getTrackStatus = monthAchieved > 0 && monthAchieved >= getPercentageValue.plus ? OVER_PACING_KEY :
                        monthAchieved > 0 && monthAchieved >= getPercentageValue.minus ? ON_TRACK_KEY : monthAchieved > 0 ? UNDER_PACING_KEY : "";


                    getData.push({
                        "month": month,
                        "target": monthTarget || 0,
                        "projected": monthProjected || 0,
                        "achieved": monthAchieved || 0,
                        "track": getTrackStatus,
                        "achieved_precision": monthTarget > 0 ? Math.ceil((monthAchieved / monthTarget) * 100) : 0,  // Avoid division by 0
                        "projected_precision": monthTarget > 0 ? Math.ceil(((monthProjected - monthTarget) / monthTarget) * 100) : 0,  // Avoid division by 0
                    });


                    cumulativeMonthsData.target += monthTarget;
                    cumulativeMonthsData.achieved += monthAchieved;
                    cumulativeMonthsData.projected += monthProjected;
                }

                return true; // Ensure this is returned after the async operation is completed.
            })).then(() => {
                if (completedDays > 0) {
                    cumulativeMonthsData.projected = (cumulativeMonthsData.achieved / completedDays) * 90;
                    const getPercentageValue = calculateFivePercentPlusAndMinus(cumulativeMonthsData.target);
                    cumulativeMonthsData.track = cumulativeMonthsData.achieved >= getPercentageValue.plus
                        ? OVER_PACING_KEY
                        : cumulativeMonthsData.achieved >= getPercentageValue.minus
                            ? ON_TRACK_KEY
                            : UNDER_PACING_KEY;

                    cumulativeMonthsData.achieved_precision = cumulativeMonthsData.target > 0
                        ? Math.ceil((cumulativeMonthsData.achieved / cumulativeMonthsData.target) * 100)
                        : 0;

                    cumulativeMonthsData.projected_precision = cumulativeMonthsData.target > 0
                        ? Math.ceil(((cumulativeMonthsData.projected - cumulativeMonthsData.target) / cumulativeMonthsData.target) * 100)
                        : 0;
                }


                getData.push(cumulativeMonthsData)

                setMonthsData(getCurrentQuarterData?.month_label || [])
                setGoalTrackingData(getData)
                setSelectedDemandGoalDate(getCurrentQuarterData?.header || "")
                setCurrentSelectedData(cumulativeMonthsData)
                setSelectedMonth(CUMULATIVE_ALL_MONTH_VALUE)
                setCurrentSelectedDataType("Quarterly")
            });
        }
    }

    const onClickDemandGoalData = (e) => {
        setOpenPopover(true);
        setAnchorEl(e?.currentTarget);
    }

    const handleClose = () => {
        setOpenPopover(false);
        setAnchorEl(null);
    }

    const onClickChangeDate = (value) =>{
        if(value?.start_month){
            setOpenPopover(false);
            setAnchorEl(null);
            loadData(value.start_month)
        }
    }

    const onChangeViewDateType = (type) => {
        if(type === CUMULATIVE_ALL_MONTH_VALUE){
            const getFilteredData = goalTrackingData.find((item) => item?.month === CUMULATIVE_ALL_MONTH_VALUE && item)
            setSelectedMonth(CUMULATIVE_ALL_MONTH_VALUE)
            setCurrentSelectedData(getFilteredData)
            setCurrentSelectedDataType("Quarterly")
        }else {
            const monthIndex = getMonthIndex(type)
            const getFilteredData = goalTrackingData.find((item) => item?.month === monthIndex && item)
            const isCurrentMonth = checkIsCurrentMonth(monthIndex)
            setSelectedMonth(type)
            setCurrentSelectedData({...getFilteredData, "active_month": isCurrentMonth})
            setCurrentSelectedDataType("Monthly")
        }

    }

    return(
        <Grid container className="demand-goal-tracking-container">
            {props?.loading && (
                <React.Fragment>
                    <Grid item xs={10} className="item-padding-text">
                        <Skeleton animation="wave" className="loading-item-data"/>
                    </Grid>
                    <Grid item xs={2} className="item-padding-text">
                        <Skeleton animation="wave" className="loading-item-data"/>
                    </Grid>
                    <Grid item xs={4} className="item-padding-text">
                        <Skeleton animation="wave" className="loading-item-data"/>
                    </Grid>
                    <Grid item xs={8} className="item-padding-text">
                        <Skeleton animation="wave" className="loading-item-data"/>
                    </Grid>
                </React.Fragment>
            )}
            {!props?.loading && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography className="demand-goal-tracking-header">
                                    {selectedDemandGoalDate}
                                    <span
                                        className="drop-down-icon-popover"
                                        onClick={(e) => onClickDemandGoalData(e)}>
                                        {openPopover ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                                    </span>
                                </Typography>
                                <Popover
                                    id="popover-dashboard-type"
                                    open={openPopover}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    anchorReference="anchorPosition"
                                    anchorPosition={{ top: 185, left: 20 }}
                                >
                                    <Grid container className="dashboard-type-view demand-goal-tracking-pop-up">
                                        {QUATAR_DATA_PRESENT.map((data) => {
                                            return(
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={selectedDemandGoalDate === data?.label ? "dashboard-type-item active" : "dashboard-type-item"}
                                                    onClick={() => onClickChangeDate(data)}
                                                >
                                                    {data.label}
                                                    {selectedDemandGoalDate === data?.label &&
                                                        <span className="active-icon"> <CheckIcon /> </span>}
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Popover>
                                <Typography
                                    className={currentSelectedData?.track === UNDER_PACING_KEY ? "demand-goal-track-tag under-pacing" : currentSelectedData?.track === ON_TRACK_KEY  ? "demand-goal-track-tag on-track" : currentSelectedData?.track === OVER_PACING_KEY ? "demand-goal-track-tag over-pacing" :  "demand-goal-track-tag"}>
                                    {GOAL_STATUS_LABEL[currentSelectedData?.track]}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} className="align-to-right">
                                <ButtonGroup variant="outlined" size="small" className="amg-button-group">
                                    {monthsData.length > 0 && <Button onClick={() => onChangeViewDateType("all")} className={selectedMonth === "all" ? "active-button" : "button"}>Cumulative</Button>}
                                    {monthsData.length > 0 && monthsData.map((type) => {
                                        return(
                                            <Button onClick={() => onChangeViewDateType(type)} className={selectedMonth === type ? "active-button" : "button"}>{type}</Button>
                                        )
                                    })
                                    }
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="demand-goal-tracking-bar-chart">
                        <Grid container>
                            <Grid item xs={4} className="demand-goal-details-metrics">
                                <Grid container>
                                    {currentSelectedData?.target ? <Grid item xs={4} className="demand-goal-details-item">
                                        <Typography className="demand-goal-metric-header">
                                            {currentSelectedDataType} goal
                                        </Typography>
                                        <Typography className="demand-goal-metric-value">
                                            ${formatNumberToMillion(currentSelectedData?.target)}
                                            <span className="demand-goal-metric-percentage">
                                                <PlusMinus /> 5%
                                            </span>
                                        </Typography>
                                    </Grid> : null}
                                    {currentSelectedData?.achieved ? (<Grid item xs={4} className="demand-goal-details-item">
                                        <span className={currentSelectedData?.track === UNDER_PACING_KEY ? "left-border-radius light-yellow" : "left-border-radius"}> </span>
                                        <Typography className="demand-goal-metric-header"> Achieved </Typography>
                                        <Typography className="demand-goal-metric-value">
                                            ${formatNumberToMillion(currentSelectedData?.achieved)}
                                            {currentSelectedData.achieved_precision > 0 ? (
                                                <span className="demand-goal-metric-percentage">
                                                   {currentSelectedData.achieved_precision}%
                                               </span>
                                            ) : null}
                                        </Typography>
                                    </Grid>
                                    ) : null}
                                    {currentSelectedData?.projected && !currentSelectedData?.active_month ? (
                                        <Grid item xs={4} className="demand-goal-details-item">
                                            <span className={currentSelectedData?.track === UNDER_PACING_KEY ? "left-border-radius light-yellow" : "left-border-radius"} style={{opacity: 0.4}}></span>
                                            <Typography className="demand-goal-metric-header"> Projected </Typography>
                                            <Typography className="demand-goal-metric-value">
                                                ${formatNumberToMillion(currentSelectedData?.projected)}
                                                {currentSelectedData.projected_precision > 0 ? (
                                                    <span className="demand-goal-metric-percentage">
                                                       {currentSelectedData.projected_precision}%
                                                    </span>
                                                ) : null}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                {currentSelectedData.target !== 0 ? <VerticalBarChart
                                    data={currentSelectedData}
                                /> : <Skeleton animation="wave" className="loading-item-data" style={{height:"80px"}} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
}

AdsPlusDemandGoalTracking.prototype = {
    "loading": PropTypes.bool.isRequired,
}

export default AdsPlusDemandGoalTracking;