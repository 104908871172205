import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import { getRequestsDetailsQuery } from "../query/getRequestDetailsQuery";
import { MAX_LIMIT_SHOW_TABLE_DATA } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getRequestsDetails = async(id) => {
    let data = JSON.stringify({
        query: getRequestsDetailsQuery,
        variables: { "id": id }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
    .then((response) => {
        if (response?.data?.data?.getAdsPlusRequestConfigByID && response.status === 200) {
        return response.data.data.getAdsPlusRequestConfigByID;
        } else {
            return [];
        }
    })
    .catch((error) => {
        console.error('Request failed:', error);
        return null;
    });
};
