import {MenuItem, Skeleton, Grid} from "@mui/material";
import "./index.css";
import React from "react";

export const MultiSelectLoadingOption = (props) => {

    const skeletonOptions = [];

    for (let i = 0; i < props.count; i++) {
        skeletonOptions.push(
            <MenuItem disabled key={`menuItem-${i}`}>
                <Grid xs={12}>
                    <Skeleton
                        key={`skeleton-checkbox-${i}`}
                        animation="wave"
                        className="skleton-loading-screen skeleton-display"
                        width={props.checkBoxWidth}
                        height={props.checkBoxHeight}
                        sx={{ marginRight: '10px' }}
                    />

                    <Skeleton
                        key={`skeleton-text-${i}`}
                        animation="wave"
                        className="skleton-loading-screen skeleton-display"
                        width={props.width}
                        height={props.height}
                    />
                </Grid>
            </MenuItem>
        );
    }

    return <React.Fragment>{skeletonOptions}</React.Fragment>;
};
