import React from "react"
import {Grid, Skeleton} from "@mui/material";
import "./index.css"

function LazyLoading(props){
    return (
        <Grid item xs={12} className="lazy-container">
            <Skeleton
                className="lazy-loading-screen-header"
            />
            <Skeleton
                className="lazy-loading-screen"
            />
        </Grid>
    )
}

export default LazyLoading