import React, { useEffect, useState } from 'react';
import { Grid, Typography, Chip } from '@mui/material';
import './index.css';
import TableRequests from '../../../Components/Tables/TableRequests/index.js';
import Notification from '../../../Components/Notification/index.js';
import { getRequestsList } from '../../../api/getRequestsList.js';
import {
  MAX_LIMIT_SHOW_TABLE_DATA,
  USER_DATA_KEY,
  ALL_DATA_FETCHED,
  SUCCESS_TYPE,
  WARNING_NOTIFICATION_TYPE,
  DATA_IN_LOADING_STATE,
  REQUEST_STATUS_CREATE,
  REQUEST_STATUS_SUBMIT,
  REQUEST_STATUS_REVIEW,
  REQUEST_STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_APPROVAL_PENDING, REQUEST_APPROVED_NOTIFICATION_TYPE,
} from '../../../Constant.js';
import { getLocalStorageData } from '../../../utils/localStorageHelper.js';
import { REQUEST_DETAILS_PAGE_LINK } from '../../../Constant.js';
import { useNavigate } from 'react-router-dom';
import useWebStorageHelper from "../../../utils/sessionStorageHelper";

function Requests(props) {
  const [pendingData, setPendingData] = useState(0);
  const [approvedData, setApprovedData] = useState(0);
  const [draftData, setDraftData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showApproved, setShowApproved] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    loadData(0);
    handleApprovedRequestNotification();
    useWebStorageHelper.removeOnbFile();
  }, []);

  useEffect(() => {
    filterData();
  }, [requestData, showApproved, showPending, showDraft]);

  const loadData = async (offset = 0) => {
    setLoading(true);
    setHasMoreData(false);
    const user_data = getLocalStorageData(USER_DATA_KEY, true);
    let userEmail = user_data?.email || "";
    const requestsData = await getRequestsList(offset, MAX_LIMIT_SHOW_TABLE_DATA, userEmail);
    setTotalCount(requestsData?.total_count || totalCount);

    if (requestsData && requestsData.data?.length > 0) {
      const newData = offset > 0 ? [...requestData, ...requestsData.data] : requestsData.data;
      setRequestData(newData);
      setSearchedData(newData);
      setLoading(false);
      setHasMoreData(true);
    } else if (requestsData?.data === null) {
      setHasMoreData(false);
      setIsOpen(true);
      setNotificationMessage(ALL_DATA_FETCHED);
      setAlertType(SUCCESS_TYPE);
      setLoading(false);
    } else {
      setNotificationMessage(DATA_IN_LOADING_STATE);
      setAlertType(WARNING_NOTIFICATION_TYPE);
      setIsOpen(true);
      setLoading(false);
    }
  };

  const filterData = () => {
    let filteredData = requestData;
    if (showApproved && !showPending && !showDraft) {
      filteredData = requestData.filter((data) => data.status === REQUEST_STATUS_SUBMIT);
    } else if (!showApproved && showPending && !showDraft) {
      filteredData = requestData.filter((data) => data.status === REQUEST_STATUS_CREATE || data.status === REQUEST_STATUS_REVIEW);
    } else if (!showApproved && !showPending && showDraft) {
      filteredData = requestData.filter((data) => data.status === REQUEST_STATUS_DRAFT);
    }
    setSearchedData(filteredData);

    const approvedCount = requestData.filter((data) => data.status === REQUEST_STATUS_SUBMIT).length;
    const pendingCount = requestData.filter((data) => data.status === REQUEST_STATUS_CREATE || data.status === REQUEST_STATUS_REVIEW).length;
    const draftCount = requestData.filter((data) => data.status === REQUEST_STATUS_DRAFT).length;
    setApprovedData(approvedCount);
    setPendingData(pendingCount);
    setDraftData(draftCount);
  };

  const handleNotificationClose = () => {
    setIsOpen(false);
  };

  const onClickRequest = (req_id = "") => {
    navigate(`${REQUEST_DETAILS_PAGE_LINK}${req_id}`)
  }

  const changeOffset = (count) => {
    if (count > 0) {
      setOffset(count)
      loadData(count)
    }
  }

  const handleApprovedRequestNotification = () => {
    let sessionData = useWebStorageHelper.getItemSessionWithExpiry("Notify");
    if (sessionData !== null) {
      setIsOpen(true);
      setNotificationMessage(REQUEST_APPROVED_NOTIFICATION_TYPE);
      setAlertType(SUCCESS_TYPE);
    }
  }

  const handleChipClick = (type) => {
    if (type === STATUS_APPROVED) {
      setShowApproved(!showApproved);
      setShowPending(false);
      setShowDraft(false);
    } else if (type === STATUS_APPROVAL_PENDING) {
      setShowPending(!showPending);
      setShowApproved(false);
      setShowDraft(false);
    } else if (type === REQUEST_STATUS_DRAFT) {
      setShowDraft(!showDraft);
      setShowApproved(false);
      setShowPending(false);
    } else {
      setShowApproved(false);
      setShowPending(false);
      setShowDraft(false);
    }
  }

  return (
      <React.Fragment>
        <Grid container rowSpacing={2} >
          <Grid item xs={12} className="req-page-header" >
            <Typography> Requests </Typography>
          </Grid>
          <Grid item xs={12} className="chip-grid">
            <Chip
                className={`requests-chip ${approvedData === 0 ? 'requests-chip-hidden' : showApproved ? 'brighter' : !showApproved && !showPending && !showDraft ? 'active' : 'clicked'}`}
                label={`Approved: ${approvedData}`}
                variant="outlined"
                onClick={() => approvedData > 0 && handleChipClick(STATUS_APPROVED)}
            />
            <Chip
                className={`requests-chip ${pendingData === 0 ? 'requests-chip-hidden' : showPending ? 'brighter' : !showApproved && !showPending && !showDraft ? 'active' : 'clicked'}`}
                label={`Approval Pending: ${pendingData}`}
                variant="outlined"
                onClick={() => pendingData > 0 && handleChipClick(STATUS_APPROVAL_PENDING)}
            />
            <Chip
                className={`requests-chip ${draftData === 0 ? 'requests-chip-hidden' : showDraft ? 'brighter' : !showApproved && !showPending && !showDraft ? 'active' : 'clicked'}`}
                label={`Draft: ${draftData}`}
                variant="outlined"
                onClick={() => draftData > 0 && handleChipClick(REQUEST_STATUS_DRAFT)}
            />
          </Grid>
          <Grid container className='requests-table-content' >
            <TableRequests
                tableContent={searchedData}
                totalCount={totalCount}
                isLoading={loading}
                currentOffset={offset}
                setOffset={changeOffset}
                isHavingMoreData={hasMoreData}
                onClickRequest={onClickRequest}
                open={setIsOpen}
                message={setNotificationMessage}
                type={setAlertType}
            />
          </Grid>
        </Grid>
        <Notification
            open={isOpen}
            message={notificationMessage}
            handleClose={handleNotificationClose}
            type={alertType}
        />
      </React.Fragment>
  );
}

export default Requests;
