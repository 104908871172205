import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getSupplyRouterTagDetails = (id = "", email = "") => {
    let data = JSON.stringify({
        query: `query MyQuery($id: String = "") {
  getSupplyRouterTagDetails(id: $id) {
    ad_tag_url
    channel_id
    delivery_name
    demand_tags_count
    supply_partner_id
    supply_partner_name
    supply_router_id
    supply_router_name
    created_at
    updated_at
  }
}`,
        variables: { "id": id }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getSupplyRouterTagDetails && response.status === 200) {
                return response.data.data.getSupplyRouterTagDetails
            } else {
                return {}
            }
        })
        .catch((error) => {
            console.log(error);
            return null
        });
}