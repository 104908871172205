import React, {useEffect, useState} from 'react';
import BarChartComponent from "../../Common/BarChart";
import {ButtonGroup, CircularProgress, Grid, MenuItem, Select, Typography} from "@mui/material";
import "./index.css"
import PropTypes from "prop-types";
import {EmptyBar} from "../../assests/icons/emptyBar";
import {
    AD_REQUEST_AND_IMPRESSION, AUTO_HIDE_DOWNLOAD_TIME,
    BAR_CHART_VIEW_TYPE,
    DEFAULT_DOWNLOAD_API_RESPONSE_TIME,
    DEMAND_DASHBOARD_BAR_CHART_VIEW_TYPE,
    DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE,
    DEMAND_MARKET_SUMMERY, DEMAND_PARTNER_KEY,
    DEMAND_PARTNER_TABLE_DETAILS,
    PARTNER_TABLE_DETAILS,
    SUPPLY_DASHBOARD_BAR_CHART_VIEW_TYPE,
    SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE,
    SUPPLY_PARTNER_KEY,
    SUPPLY_PARTNER_TABLE_DETAILS,
    TABLE_VIEW_TYPE,
    TOTAL_REVENUE_KEY
} from "../../Constant";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StickyTable from "../../Common/StickyTable";
import DetailsStickyTable from "../../Common/DetailsStickyTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {downloadCSV} from "../../utils/fileDowloader";
import {getCurrentTime, getDefaultDemandPartner, getDefaultSupplyPartner, isEmpty} from "../../utils/helper";
import {NoFilterData} from "../../assests/icons/noFilterData";

function AdsPlusSupplyHealthSelectView(props) {
    const [barChartViewType, setBarChartViewType] = useState("")
    const [barViewVisible, setBarViewVisible] = useState(false)
    const [tableViewVisible, setTableViewVisible] = useState(false)
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    const [fileDownloadSuccess, setFileDownloadSuccess] = useState(false)
    const [barChartData, setBarChartData] = useState([])
    const [barChartCategoryType, setBarChartCategoryType] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableColumns, setTableColumns] = useState([])
    const [allDayPartnerTotalRevenue, setAllDayPartnerTotalRevenue] = useState(0)
    const [allPartnerTotalRevenue, setPartnerWiseTotalRevenue] = useState({})
    const [detailsTableView, setDetailsTableView] = useState(false)
    const [filterPartnerName, setFilterPartnerName] = useState("")
    const [detailsTableHeaderColumns, setDetailsTableHeaderColumns] = useState([])
    const [dateOrderAsc,setDateOrderAsc]=useState(true)
    const [detailsTableKey,setDetailsTableKey]=useState([])

    useEffect(() => {
        // TODO optimise for one load, it is loading 2 times.
        if (props?.supplyHealthTracker?.daily?.length > 0 && props?.supplyPartnerOrder?.length > 0) {
            let getTableData = []
            let allDayTotalRevenue = 0
            let partnerWiseTotalRevenue = {}
            let orderedPartnerData = []
            let supplyPartnerTableColumn = []

            props.supplyHealthTracker.daily.map((dailyData) => {
                if (dailyData?.supply_partner?.length > 0) {
                    let supplyPartnerData = []
                    let totalRevenue = 0
                    props.supplyPartnerOrder.map((partnerData) => {
                        const getSupplyPartnerData = dailyData?.supply_partner?.find((data) => data?.supply_partner_id === partnerData?.supply_partner_id && data) || getDefaultSupplyPartner(partnerData?.supply_partner_id, partnerData?.supply_partner_name, dailyData.date)
                        if(!isEmpty(getSupplyPartnerData)) {
                            totalRevenue = getSupplyPartnerData?.[TOTAL_REVENUE_KEY] + totalRevenue
                            supplyPartnerData.push(getSupplyPartnerData)

                            if (partnerWiseTotalRevenue?.[getSupplyPartnerData.supply_partner_id]) {
                                partnerWiseTotalRevenue[getSupplyPartnerData.supply_partner_id] = partnerWiseTotalRevenue[getSupplyPartnerData.supply_partner_id] + getSupplyPartnerData?.[TOTAL_REVENUE_KEY]
                            } else {
                                partnerWiseTotalRevenue[getSupplyPartnerData.supply_partner_id] = getSupplyPartnerData?.[TOTAL_REVENUE_KEY]
                            }
                        }

                        return true

                    })
                    allDayTotalRevenue =  totalRevenue + allDayTotalRevenue
                    getTableData.push({
                        "date": dailyData?.date,
                        "partner_details": supplyPartnerData,
                        "total_revenue": totalRevenue,
                        "impressions": dailyData?.impressions,
                        "fill_rate": dailyData?.fill_rate,
                        "margin_per": dailyData?.margin_per,
                        "revenue": dailyData?.revenue,
                        "ad_requests": dailyData?.ad_requests,
                    })
                }
                return true
            })
            props.supplyPartnerOrder.map((data) => {
                orderedPartnerData.push(partnerWiseTotalRevenue?.[data?.supply_partner_id] || 0)
                supplyPartnerTableColumn.push(data?.supply_partner_name)
                return true
            })

            getTableData.sort((a, b) => new Date(a.date) - new Date(b.date));

            setBarChartData(getTableData)
            setBarChartViewType(AD_REQUEST_AND_IMPRESSION)
            setBarChartCategoryType(SUPPLY_DASHBOARD_BAR_CHART_VIEW_TYPE)
            setTableColumns(supplyPartnerTableColumn)
            setTableData(getTableData)
            setAllDayPartnerTotalRevenue(allDayTotalRevenue)
            setPartnerWiseTotalRevenue(orderedPartnerData)
            setBarViewVisible(true)
            setTableViewVisible(false)
            setDetailsTableView(false)
        } else {
            setBarChartData([])
            setBarChartViewType("")
            setBarChartCategoryType("")
            setTableColumns([])
            setTableData([])
            setAllDayPartnerTotalRevenue(0)
            setPartnerWiseTotalRevenue({})
            setBarViewVisible(false)
            setTableViewVisible(false)
            setBarChartData([])
            setDetailsTableView(false)
        }
    }, [props])

    const handleBarViewType = (e) => {
        setBarChartViewType(e?.target?.value);
    }

    const onClickViewType = (type) => {
        if (type === BAR_CHART_VIEW_TYPE) {
            setBarViewVisible(true)
            setTableViewVisible(false)
            setDetailsTableView(false)
        }

        if (type === TABLE_VIEW_TYPE) {
            setBarViewVisible(false)
            setTableViewVisible(true)
            setDetailsTableView(false)
        }
    }

    const onClickDownloadButton = () => {
        //TODO adding functionality to download the file locally, later move to real API
        setShowDownloadButton(true)
        let downloadJsonData = []
        const nowTime = getCurrentTime()
        let fileName = ""

        if((barViewVisible || tableViewVisible) && tableData.length > 0) {
            const key = "supply_partner_name"
            fileName = `Revenue-Sheet-All-Partner-${nowTime}.csv`
            tableData.map((data, index) => {
                downloadJsonData.push({
                    "Date": data?.date,
                    "All Partner": data?.[TOTAL_REVENUE_KEY]
                })
                if(data?.partner_details?.length > 0) {
                    let partnerDetails = {}
                    data.partner_details.map((partner_detail) => {
                        partnerDetails[partner_detail?.[key]] = partner_detail?.[TOTAL_REVENUE_KEY]
                        return true
                    })
                    Object.assign(downloadJsonData[index],partnerDetails)
                }
                return true
            })
        }else if(detailsTableView && tableData?.length > 0){
            const key = "supply_partner_name"
            const detailsTableHeader = SUPPLY_PARTNER_TABLE_DETAILS
            const detailsTableKey = SUPPLY_PARTNER_KEY
            fileName = `${filterPartnerName}-Sheet-All-Data-${nowTime}.csv`
            tableData.map((data, index) => {
                downloadJsonData.push({
                    "Date": data?.date,
                })
                if(data?.partner_details?.length > 0) {
                    let partnerDetails = {}
                    data.partner_details.map((partnerData) => {
                        if(partnerData?.[key] === filterPartnerName){
                            detailsTableHeader.map((header, index) => {
                                partnerDetails[header] = partnerData?.[detailsTableKey?.[index]] || 0
                            })
                        }
                        return true
                    })
                    Object.assign(downloadJsonData[index],partnerDetails)
                }
                return true
            })
        }

        setTimeout(() => {
            if(downloadJsonData.length > 0 && fileName !== ""){
                downloadCSV(downloadJsonData, fileName)
            }
            setFileDownloadSuccess(true)
        }, [DEFAULT_DOWNLOAD_API_RESPONSE_TIME])

        // TODO add some logic to remove the download button
        setTimeout(() => {
            setFileDownloadSuccess(false)
            setShowDownloadButton(false)
        }, [AUTO_HIDE_DOWNLOAD_TIME])
    }

    const onClickPartnerDetails = (name) => {
        let detailsTableHeader = []
        let detailsTableKey = []
        detailsTableHeader = SUPPLY_PARTNER_TABLE_DETAILS
        detailsTableKey = SUPPLY_PARTNER_KEY
        setFilterPartnerName(name)
        setTableViewVisible(false)
        setBarViewVisible(false)
        setDetailsTableView(true)
        setDetailsTableHeaderColumns(detailsTableHeader)
        setDetailsTableKey(detailsTableKey)
    }

    const onBackScreenFromDetails = () => {
        setFilterPartnerName("")
        setTableViewVisible(true)
        setBarViewVisible(false)
        setDetailsTableView(false)
        setDetailsTableHeaderColumns([])
    }
    const handleOnsSortDate = () =>{
        const getTableData = Object.assign([],tableData);
        if(dateOrderAsc){
            getTableData.sort((a, b) => new Date(b.date) - new Date(a.date));
            setTableData(getTableData);
            setDateOrderAsc(false)


        }
        else{
            getTableData.sort((a, b) => new Date(a.date) - new Date(b.date));
            setTableData(getTableData);
            setDateOrderAsc(true)
        }

    }


    return (
        <Grid container className="content-view-component ads-supply-select-view">
            {props?.loading &&
                <Grid item xs={12} className="empty-bar-view-screen">
                    <EmptyBar/>
                </Grid>
            }
            {!props?.isDataAvailable &&
                <Grid item xs={12} className="empty-bar-view-screen">
                    <NoFilterData />
                </Grid>
            }
            {!props?.loading && props?.isDataAvailable &&
                <Grid item xs={12} className="content-bar-table-filter-component">
                    <Grid container className="content-filter-component">
                        <Grid item xs={4}>
                            {tableViewVisible ? <Typography className="table-count"> {tableData.length} result </Typography> : barViewVisible ? <Select
                                value={barChartViewType}
                                onChange={(e) => handleBarViewType(e)}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                className="amg-filter-type-select"
                                style={{width: "50%"}}
                            >
                                {barChartCategoryType.map((item) => {
                                    return (
                                        <MenuItem value={item} key={item}
                                                  className="single-select">{item}</MenuItem>
                                    )
                                })}
                            </Select> : detailsTableView && <Typography className="table-header"> <ArrowBackIcon onClick={(_) => onBackScreenFromDetails()}/> {filterPartnerName} </Typography>}
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={1} className="content-action-component">
                            {showDownloadButton && <IconButton
                                className={fileDownloadSuccess ? "download-progress-div success-download" : "download-progress-div"}>
                                <ArrowDownwardIcon/>
                                {!fileDownloadSuccess && <CircularProgress
                                    size={35}
                                    sx={{
                                        color: "#4999E9",
                                        position: 'absolute'
                                    }}
                                />}
                            </IconButton>}
                            <IconButton className="icon-button" onClick={(_) => onClickDownloadButton()}>
                                <FileDownloadIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} className="content-action-component">
                            <ButtonGroup variant="contained" aria-label="Basic button group" className="button-group">
                                <IconButton className={barViewVisible ? "icon-button active-icon" : "icon-button"}
                                            onClick={(_) => onClickViewType(BAR_CHART_VIEW_TYPE)}>
                                    <ShowChartIcon/>
                                </IconButton>
                                <IconButton className={tableViewVisible ? "icon-button active-icon" : "icon-button"}
                                            onClick={(_) => onClickViewType(TABLE_VIEW_TYPE)}>
                                    <TableRowsIcon/>
                                </IconButton>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container className="content-bar-chart-component">
                        {barViewVisible && <BarChartComponent
                            barChartData={barChartData}
                            type={barChartViewType}
                        />}
                        {tableViewVisible && <StickyTable
                            tableData={tableData}
                            tableColumns={tableColumns}
                            allDayPartnerTotalRevenue={allDayPartnerTotalRevenue}
                            allPartnerTotalRevenue={allPartnerTotalRevenue}
                            onClickPartnerDetails={onClickPartnerDetails}
                            handleOnsSortDate={handleOnsSortDate}
                            type={barChartViewType}
                        />}
                        {detailsTableView && <DetailsStickyTable
                            tableData={tableData}
                            tableColumns={detailsTableHeaderColumns}
                            allDayPartnerTotalRevenue={allDayPartnerTotalRevenue}
                            allPartnerTotalRevenue={allPartnerTotalRevenue}
                            filterPartnerName={filterPartnerName}
                            tableKey={detailsTableKey}
                            type={barChartViewType}
                        />}
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

AdsPlusSupplyHealthSelectView.prototype = {
    "loading": PropTypes.bool.isRequired,
    "supplyHealthTracker": PropTypes.shape({
        "metrics": PropTypes.shape({
            "ad_requests": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "impressions": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "revenue": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "fill_rate": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "margin_per": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ),
            "e_cpm": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired
        }),
        "daily": PropTypes.arrayOf(PropTypes.shape(
            {
                "date": PropTypes.string.isRequired,
                "ad_requests": PropTypes.number.isRequired,
                "impressions": PropTypes.number.isRequired,
                "revenue": PropTypes.number.isRequired,
                "fill_rate": PropTypes.number.isRequired,
                "margin_per": PropTypes.number.isRequired,
                "supply_partner": PropTypes.arrayOf(
                    PropTypes.shape({
                        "date": PropTypes.string.isRequired,
                        "supply_partner_id": PropTypes.number.isRequired,
                        "supply_partner_name": PropTypes.string.isRequired,
                        "revenue": PropTypes.number.isRequired,
                        "cost": PropTypes.number.isRequired,
                        "impressions": PropTypes.number.isRequired,
                        "net_revenue": PropTypes.number.isRequired,
                        "margin_per": PropTypes.number.isRequired,
                        "bids": PropTypes.number.isRequired,
                        "wins": PropTypes.number.isRequired,
                        "fill_rate": PropTypes.number.isRequired,
                        "supply_timeouts": PropTypes.number.isRequired,
                        "ecpm": PropTypes.number.isRequired,
                        "rpm": PropTypes.number.isRequired,
                        "pod_time_request": PropTypes.number.isRequired,
                        "pod_time_return": PropTypes.number.isRequired,
                        "pod_time_filled": PropTypes.number.isRequired,
                        "pod_time_fill_rate": PropTypes.number.isRequired
                    })
                )
            }
        ).isRequired).isRequired
    }),
    "isDataAvailable": PropTypes.bool.isRequired,
    "supplyPartnerOrder": PropTypes.arrayOf(PropTypes.shape({
        "supply_partner_id": PropTypes.number.isRequired,
        "supply_partner_name": PropTypes.string.isRequired,
    })).isRequired,

}

export default AdsPlusSupplyHealthSelectView