import "./index.css"
import {
    Button, Divider, Drawer, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from "@mui/material";
import React, {useEffect, useState, useRef} from "react";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {EmptyScreen} from "../CommonScreens/EmptyScreen";
import {isEmpty, formatDate} from "../../utils/helper";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LogDetailsFilter from "./LogDetailsFilter";
import {ACCEPTED_LOG_ACTIVITY, SUCCESS_LOG_ACTIVITY} from "../../Constant";
import {LoadingScreen} from "../CommonScreens/LoadingScreen";

const DescriptionSideBar = props => {
    const [jobDetailData, setJobDetailData] = useState([])
    const [filteredJobDetails, setFilteredJobDetails] = useState([])
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [appliedFilterList, setAppliedFilterList] = useState([])
    const [uniqueEntityTypes, setUniqueEntityTypes] = useState([]);
    const [jobMetaInfoDetails, setJobMetaInfoDetails] = useState({})

    useEffect(() => {
        if (props?.logsData?.mb_job_details && props?.logsData?.mb_job_status) {
            const entityTypes = props.logsData.mb_job_details?.length > 0 ? Array.from(new Set(props.logsData.mb_job_details.map(item => item.EntityType))) : [];
            const jobDetailsData = props.logsData.mb_job_details?.length > 0 ? props.logsData.mb_job_details : []
            const jobMetaInfoData = !isEmpty(props.logsData.mb_job_status) ? props.logsData.mb_job_status : {}
            setJobDetailData(jobDetailsData)
            setFilteredJobDetails(jobDetailsData)
            setJobMetaInfoDetails(jobMetaInfoData)
            setUniqueEntityTypes(entityTypes);
        }else {
            setJobDetailData([])
            setFilteredJobDetails([])
            setJobMetaInfoDetails({})
            setUniqueEntityTypes([]);
        }
    }, [props?.logsData]);

    const handleFilterSelection = filter => {
        if (selectedFilters.includes(filter)) {
            setSelectedFilters(selectedFilters.filter(item => item !== filter));
        } else {
            setSelectedFilters([...selectedFilters, filter]);
        }
    };


    const applyFilterSelection = () => {
        const filteredData = selectedFilters.length === 0 ? jobDetailData : jobDetailData.filter(row => selectedFilters.some(filter => row.EntityType.toLowerCase() === filter.toLowerCase()));
        setFilteredJobDetails(filteredData)
        setAppliedFilterList(selectedFilters)
    }

    const resetFilter = () => {
        setSelectedFilters([])
        setAppliedFilterList([])
        setFilteredJobDetails(jobDetailData)
    }
    const cancelSelection = () => {
        setSelectedFilters(appliedFilterList);
    };

    const onClickCancel = () => {
        props.closeSidebar()
    };

    return <Drawer
        className="sidenav-add-macros"
        anchor="right"
        open={props.isSidebarOpen}
        onClose={props.closeSidebar}
    >
        <Grid item xs={12} className="sidebar-header">
            <Grid container>
                <Grid item xs={10} className="amagi-header-typography" padding="20px">
                    <Typography className="log-details-typography">
                        Log details
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <CloseIcon className="close-icon-sidebar" onClick={() => onClickCancel()}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} className="log-sidebar-content">
            <Grid item xs={12} className="log-status-message-grid">
                <Grid container>
                    <Grid item xs={9}>
                        {props.loading ? <Skeleton className="text-skeleton-action-breadcrumbs" animation="wave"/> :
                            <Typography
                                className="bulk-sync-typography">{jobMetaInfoDetails?.Action || "Bulk Sync"}</Typography>}
                    </Grid>
                    <Grid item xs={3} className="alert-log-grid">
                        {props.loading ? <Skeleton className="text-skeleton-action-breadcrumbs" animation="wave"/> :
                            <React.Fragment>
                                {jobMetaInfoDetails?.Status === SUCCESS_LOG_ACTIVITY ? <React.Fragment>
                                    <CheckCircleOutlineIcon className="error-success"/>
                                    <Typography className="log-status-success">Success</Typography>
                                </React.Fragment> : jobMetaInfoDetails?.Status === ACCEPTED_LOG_ACTIVITY ?
                                    <React.Fragment>
                                        <PendingActionsIcon className="error-pending"/>
                                        <Typography className="log-status-pending">Pending</Typography>
                                    </React.Fragment> : <React.Fragment>
                                        <ErrorOutlineIcon className="error-icon"/>
                                        <Typography className="log-status-failed">Failed</Typography>
                                    </React.Fragment>}
                            </React.Fragment>}
                    </Grid>
                </Grid>
                <Grid item xs={12} className="bulk-sync-detail-grid">
                    <Grid container>
                        <Grid item xs={10}>
                            {props.loading ?
                                <Skeleton className="text-skeleton-summary-breadcrumbs" animation="wave"/> :
                                <Typography className="bulk-sync-detail-typography">
                                    {jobMetaInfoDetails?.Summary?.length < 60 ? jobMetaInfoDetails?.Summary : jobMetaInfoDetails?.Summary?.length > 60 ?  `${jobMetaInfoDetails?.Summary?.substring(0, 60)}...` : "N/A"}
                                </Typography>}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {props.loading ? <Skeleton className="text-skeleton-action-breadcrumbs" animation="wave"/> :
                                <Typography className="platform-date-typography">
                                    {jobMetaInfoDetails?.PlatformID}
                                </Typography>}
                        </Grid>
                        <Grid item>
                            {props.loading ? <Skeleton className="text-skeleton-action-breadcrumbs" animation="wave"/> :
                                <Typography className="platform-date-typography">
                                    {jobMetaInfoDetails?.CreatedAt ? formatDate(jobMetaInfoDetails?.CreatedAt) : "N/A"}
                                </Typography>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!props.loading && filteredJobDetails?.length > 0 ? <React.Fragment>
                <Grid item xs={12} className="log-table-description">List of synced demand/thunderstorm tags</Grid>
                <Grid container className="add-macro-demand-tag-grid">
                    <Grid container className="add-macros-toolbar-grid">
                        <Grid item xs={6}>
                            <Typography className="add-macros-toolbar-typography">
                                {filteredJobDetails.length} tags
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <LogDetailsFilter
                                selectedFilters={selectedFilters}
                                handleFilterSelect={handleFilterSelection}
                                applyFilterSelect={applyFilterSelection}
                                resetFilterSelect={resetFilter}
                                appliedFilters={appliedFilterList}
                                cancelSelected={cancelSelection}
                                filterList={uniqueEntityTypes}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className="add-macros-listing-table-container"
                    >
                        <Table className="white-table">
                            <TableHead>
                                <TableCell>TAG NAME</TableCell>
                                <TableCell>TYPE</TableCell>
                                <TableCell>STATUS</TableCell>
                            </TableHead>
                            <TableBody className="log-table-body">
                                {filteredJobDetails.map((rowData, index) => <TableRow
                                    key={`${index}-${rowData?.JobID}`}>
                                    <TableCell>
                                        {rowData.EntityName}
                                    </TableCell>
                                    <TableCell>{rowData.EntityType}</TableCell>
                                    <TableCell>
                                        {rowData.Success ? (<Typography className="table-status-tag">
                                            <CheckCircleOutlineIcon className="error-success"/>
                                            <span className="log-status-success">Success</span>
                                        </Typography>) : (<Typography className="table-status-tag">
                                            <ErrorOutlineIcon className="error-icon"/>
                                            <span className="log-status-failed">Failed</span>
                                        </Typography>)}
                                    </TableCell>
                                </TableRow>)}

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </React.Fragment> : !props.loading && <React.Fragment>
                <Grid item xs={12} className="log-table-description">List of synced demand/thunderstorm tags</Grid>
                <Grid item xs={12} className="log-table-description" sx={{textAlign: "center"}}>
                    No details available for this job.
                </Grid>
            </React.Fragment>}
        </Grid>
        <Grid item xs={12} className="sidebar-footer">
            <Divider className="divider-add-macros"/>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" className="cancel-button" style={{marginRight: "10px"}}
                        onClick={() => onClickCancel()}>
                    Close
                </Button>

            </Grid>
        </Grid>
    </Drawer>

}

export default DescriptionSideBar;

