

import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";
const getAllAuditLogUsers = async (limit, offset, data) => {
    try {
        const query = `
            query MyQuery {
                getUniqueUserAuditLogs {
                     user_id
                 }
            }
        `;
        const headers = adsHeader()

        const response = await axios.post(BFF_LAYER_URI, {
            query
        }, {
            headers: headers
        });

        if (response?.data?.data?.getUniqueUserAuditLogs) {
            return response.data.data.getUniqueUserAuditLogs;
        }
        return [];
    } catch (error) {
        console.error("Error fetching while getUniqueUserAuditLogs:", error);
        return null;
    }
};
export default getAllAuditLogUsers;