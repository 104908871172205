import axios from 'axios';
import {SELF_SERVE_POST_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

const putRevertJob = async (jobId, email, role) => {
    try {
        const headers = adsHeader()
        headers['x-user-role'] = role
        const response = await axios.put(
            `${SELF_SERVE_POST_URI}rollback/bulk/job/${jobId}`,
            null, // Pass null as the request body since you don't have one
            {
                headers: headers
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error In Rollback Job :', error);
        throw error; // Re-throw the error for handling in the caller function
    }
};

export default putRevertJob;
