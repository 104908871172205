import axios from "axios";
import { MAX_LIMIT_SHOW_TABLE_DATA } from "../Constant";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getDemandTagListingByPlatformId = (id = "", limit = MAX_LIMIT_SHOW_TABLE_DATA, offset = 0, email = "") => {
    const data = JSON.stringify({
        query: `
            query MyQuery($limit: Int!, $offset: Int!, $where: DemandTagPlatformInput) {
                getDemandTagListingByPlatformId(limit: $limit, offset: $offset, where: $where) {
                    data{
                        demand_partner_id
                        demand_partner_name
                        demand_tag_id
                        demand_tag_name
                        channel_name
                        demand_class
                        vast_endpoint_url
                        channel_id
                        supply_router_id
                        supply_router_name
                        created_at
                        updated_at
                    }
                    total_count
                }
            }
        `,
        variables: {
            "limit": limit,
            "offset": offset,
            "where": {
                "amg_id": id
            }
        }
    });

    const headers = adsHeader()

    const config = {
        method: 'post',
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getDemandTagListingByPlatformId && response.status === 200) {
                return response.data.data.getDemandTagListingByPlatformId;
            } else {
                return [];
            }
        })
        .catch((_) => {
            return null;
        });
};
