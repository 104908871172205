const DropIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M11.9999 12.5274L16.0475 16.3452L14.6977 17.6187L12.9538 15.9735V21H11.0459V15.9717L9.30211 17.6187L7.95227 16.3452L11.9999 12.5274ZM11.9999 3C13.6378 3.00007 15.2186 3.568 16.4417 4.59581C17.6648 5.62361 18.4449 7.03962 18.6337 8.5746C19.8206 8.87998 20.8561 9.56919 21.5591 10.5218C22.2621 11.4744 22.5879 12.6297 22.4796 13.786C22.3713 14.9422 21.8358 16.0257 20.9666 16.8472C20.0974 17.6687 18.9499 18.1759 17.7245 18.2802V16.4676C18.1635 16.4085 18.5856 16.2674 18.9662 16.0527C19.3468 15.8379 19.6782 15.5539 19.9411 15.217C20.204 14.8801 20.3932 14.4972 20.4976 14.0906C20.6019 13.6839 20.6194 13.2618 20.5489 12.8488C20.4785 12.4357 20.3215 12.0401 20.0872 11.6849C19.8529 11.3297 19.546 11.0221 19.1843 10.78C18.8226 10.538 18.4134 10.3663 17.9807 10.2751C17.5479 10.1838 17.1002 10.1748 16.6637 10.2486C16.8131 9.5924 16.8051 8.91297 16.6401 8.2601C16.4751 7.60722 16.1575 6.99743 15.7104 6.47538C15.2633 5.95333 14.698 5.53225 14.0561 5.24298C13.4142 4.9537 12.7118 4.80357 12.0004 4.80357C11.289 4.80357 10.5866 4.9537 9.94462 5.24298C9.30268 5.53225 8.73746 5.95333 8.29035 6.47538C7.84324 6.99743 7.52557 7.60722 7.36061 8.2601C7.19565 8.91297 7.18757 9.5924 7.33697 10.2486C6.46664 10.0944 5.56702 10.2727 4.83604 10.7443C4.10505 11.2159 3.60257 11.9421 3.43913 12.7632C3.27568 13.5843 3.46468 14.433 3.96452 15.1227C4.46437 15.8123 5.23413 16.2864 6.10447 16.4406L6.27618 16.4676V18.2802C5.05078 18.1761 3.90313 17.669 3.03382 16.8476C2.16451 16.0261 1.62884 14.9426 1.52042 13.7863C1.412 12.6301 1.73773 11.4747 2.44069 10.522C3.14365 9.56934 4.17911 8.88005 5.36611 8.5746C5.55471 7.03954 6.33475 5.62342 7.55788 4.59558C8.78102 3.56774 10.3619 2.99988 11.9999 3Z"
                fill="#E7E7E7"/>
        </svg>
    )
}

export default DropIcon