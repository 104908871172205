import axios from "axios";
import { BFF_LAYER_URI, MAX_LIMIT_SHOW_TABLE_DATA } from "../Constant";
import { getPlatformListingQuery } from "../query/getPlatformListingQuery";
import {adsHeader} from "../utils/helper";

export const getPlatformListing = (offSet = 0, limit = MAX_LIMIT_SHOW_TABLE_DATA, email = "", platform_name = "") => {
    let data = JSON.stringify({
        query: getPlatformListingQuery,
        variables: {
            platform_name: platform_name,
            limit: limit,
            offset: offSet
        }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getPlatformListing && response.status === 200) {
                return response.data.data.getPlatformListing;
            } else {
                return [];
            }
        })
        .catch((_) => {
            return null;
        });
};


