import React from "react";
import PropTypes from "prop-types";
import {INITIAL_PAGE} from "../../../Constant";

function ChannelBreadCrumbs (props) {
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/platforms"
                )}
                className="amg-bread-crumbs-links"
            >
                All Platforms &nbsp;
            </span>
            / &nbsp;<span> {props?.platformName} </span>

        </React.Fragment>
    )
}

ChannelBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
    platformName: PropTypes.string,
    channelId: PropTypes.string
}

export default ChannelBreadCrumbs