import { useState, useEffect } from "react";
import { getMacroListByChannelId } from "../../../api/getMacroListByChannelId";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Grid } from "@mui/material";
import { Input } from "@mui/material";
import { InputAdornment } from "@mui/material";
import SearchIcon from "../../../assests/icons/searchIcon";
import Chip from "@mui/material/Chip";
import { useParams } from "react-router-dom";
import { getMacroListByAmgId } from "../../../api/getMacroListByAmgId";
import {SEVERITY_TYPE,NO_MACROS_FOUND} from "../../../Constant";

export default function MacroOption(props) {
    const { platformId, channelId } = useParams();
    const [selectedMacros, setSelectedMacros] = useState([]);
    const [macroListData, setMacroListData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        fetchData();
    }, [props.screen, props.channelId]);

    const fetchData = async () => {
        try {
            const macrosData = await getMacroListByAmgId(platformId?platformId:props.platform_id, props.userEmail);

            if (macrosData && macrosData.length > 0) {
                setMacroListData(macrosData);
            } else {
                props.message(NO_MACROS_FOUND);
                props.alert(SEVERITY_TYPE);
                props.isOpen();
            }
        } catch (error) {
            console.error("Error fetching macro list:", error);
        }
    };


    const selectMacros = (macro) => {
        if (!selectedMacros.includes(macro)) { // Check if the macro is not already selected
            setSelectedMacros((prevMacros) => [...prevMacros, macro]);
            props.macrosSelected(macro);
        }
    };

    const handleDelete = (remove_macro) => {
        setSelectedMacros((prevMacros) =>
            prevMacros.filter((macro) => macro !== remove_macro)
        );
        props.removeMacro(remove_macro)

    };


    //const filteredMacroList = macroListData.filter(data => data.amagi_key.toLowerCase().includes(searchQuery.toLowerCase()));
    const filteredMacroList = macroListData.filter(data => {
        const amagiKeyLowerCase = data.amagi_key.toLowerCase();
        const searchQueryLowerCase = searchQuery.toLowerCase();
        // Check if the amagi_key includes the search query and is not in selectedMacros
        return amagiKeyLowerCase.includes(searchQueryLowerCase) && !selectedMacros.includes(amagiKeyLowerCase);
    });

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div>
                    <Grid
                        sx={{ marginTop: "8px" }}
                        className="add-macro-option"
                        {...bindTrigger(popupState)}
                        item
                    >
                        + Add macros
                        {selectedMacros.map((option, index) => (
                            <Chip
                                key={index}
                                className="selected-macros-chip"
                                label={option}
                                onDelete={() => handleDelete(option)}
                            />
                        ))}
                    </Grid>

                    <Popover
                        className="add-macros-autocomplete"
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 300, left: 800 }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        {...bindPopover(popupState)}
                    >
                        <Grid className="autocomplete-grid" item>
                            <Input
                                className="amg-input-white"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                size="small"
                                fullWidth
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </Grid>
                        {filteredMacroList.map((data, index) => (
                            <div
                                key={index}
                                className="macros-option-list"
                                onClick={() => selectMacros(data.amagi_key)}
                            >
                                {data.amagi_key}
                            </div>
                        ))}
                        <Grid></Grid>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}
