import Papa from 'papaparse';
import {SAMPLE_CSV_DOWNLOAD_DATA} from "../Constant";

export const downloadCSV = (data = SAMPLE_CSV_DOWNLOAD_DATA, filename = 'data.csv') => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};