import { Grid, Typography, TableCell, TableRow, Skeleton } from "@mui/material";
import "./index.css";
import { SKELETON_COUNT } from "../../../Constant";
import React from "react";

export const LoadingScreen = (props) => {
    return (
        <React.Fragment>
            {[...Array(SKELETON_COUNT)].map((_, index) => (
                <TableRow key={`${Math.random()} - ${index}`}>
                    {[...Array(props.rowsNumber)].map((_, index) => (
                        <TableCell key={`${Math.random()}-${index}`}>
                            <Grid className="empty-screen-grid">
                                <Skeleton key={index} className="skleton-loading-screen" animation="wave" />
                            </Grid>
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </React.Fragment>
    );
};
