import { gql } from "@apollo/client";
import axios from "axios";
import { BFF_LAYER_URI, MAX_LIMIT_SHOW_TABLE_DATA } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getDemandTagListingByChannelsId = async (limit = MAX_LIMIT_SHOW_TABLE_DATA, offset = 0, email = "", channelIdArr=[""], type="") => {
    const query = gql`
        query MyQuery($where: DemandTagChannelsInput = {}) {
            getDemandTagListingByChannelsId(limit: 10, offset: 0, where: $where) {
                data{
                    channel_name
                    demand_class
                    demand_partner_id
                    demand_partner_name
                    demand_tag_id
                    demand_tag_name
                    vast_endpoint_url
                    channel_id
                    supply_router_id
                    supply_router_name
                    created_at
                    updated_at
                }
            }
        }
    `;
    const headers = adsHeader()

    const variables = {
        "where": {
            "tag_name": "",
            "type": type,
            "channel_ids": channelIdArr
        }
    };

    const dataString = JSON.stringify({
        query: query.loc.source.body,
        variables
    });

    const config = {
        method: 'post',
        url: BFF_LAYER_URI,
        headers: headers,
        data: dataString
    };

    try {
        const response = await axios.request(config);
        if (response?.data?.data?.getDemandTagListingByChannelsId && response.status === 200) {
            return response.data.data.getDemandTagListingByChannelsId;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching demand tag listing:", error);
        return null;
    }
};
