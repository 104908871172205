import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, Tooltip } from '@mui/material';
import './index.css';
import {
    COMMENTS_FAILURE_NOTIFICATION_TYPE,
    COMMENTS_SUCCESS_NOTIFICATION_TYPE,
    NA,
    REQUEST_DETAILS_PAGE_TYPE, REQUEST_LISTING_PAGE_TYPE, REQUEST_STATUS_REVIEW,
    STATUS_APPROVED, SUCCESS_TYPE,
    USER_DATA_KEY, WARNING_NOTIFICATION_TYPE
} from '../../Constant';
import { getLocalStorageData } from '../../utils/localStorageHelper';
import addRequestComment from '../../api/addRequestComment';
import CrossIcon from "../../assests/icons/crossIcon";
import {formatDateTime} from "../../utils/helper";

function CommentsSidebar(props) {
    const [loading, setLoading] = useState(false);
    const userData = getLocalStorageData(USER_DATA_KEY, true);
    const [commentMessage, setCommentMessage] = useState({
        comment_text: "",
        comment_user_id: userData?.user_email || "",
        time: "",
        comment_user_name: userData?.nickname?.split('.').map(word => word[0].toUpperCase() + word.slice(1)).join(' ') || ""
    });
    const [isFocused, setIsFocused] = useState(false);

    let body = props?.pageType === REQUEST_LISTING_PAGE_TYPE ? props?.commentsData : props?.requestDetails;

    const handleCommentInputChange = (event) => {
        setCommentMessage({
            ...commentMessage,
            comment_text: event.target.value,
            time: new Date().toLocaleString()
        });
    }

    const handleSendComment = async () => {
        const trimmedComment = commentMessage.comment_text.trim();
        setLoading(true);
        body.comments = body.comments || [];
        let commentData = body.comments;
        commentData.unshift({
            ...commentMessage,
            comment_text: trimmedComment
        });
        try {
            const response = await addRequestComment(body.config, REQUEST_STATUS_REVIEW, body.request_job_id, commentData, body.requested_user, body.type);
            if (response.request_job_id) {
                props.showNotifications(COMMENTS_SUCCESS_NOTIFICATION_TYPE, SUCCESS_TYPE)
                setLoading(false);
                body.comments = commentData;
            }
        } catch (error) {
            setLoading(false);
            props.showNotifications(COMMENTS_FAILURE_NOTIFICATION_TYPE, WARNING_NOTIFICATION_TYPE)
        }
        setCommentMessage({
            comment_text: "",
            time: "",
        });
        setIsFocused(false);
    }

    const extractDate = (dateString) => {
        if (!dateString) return "Invalid date";
        const [datePart, timePart] = dateString.split(', ');
        if (!datePart) return "Invalid date";
        return datePart;
    }

    return (
        <div className="comments-sidebar">
            <div className="comments-sidebar-header">
                <div>Comments {body?.comments?.length === 0 ? '' : `(${body?.comments?.length})`}</div>
                <CrossIcon toggleDrawer={props?.toggleDrawer} />
            </div>
            <div className="tab-divider-comments"></div>
            {props.pageType === REQUEST_DETAILS_PAGE_TYPE && props.requestStatus !== STATUS_APPROVED && (
                <React.Fragment>
                    <div className="add-comment">
                    <div className={`comments-user ${isFocused || commentMessage.comment_text ? 'visible' : ''}`}>
                        <div className="user-logo">
                            {userData?.nickname?.split('.').map(word => word[0].toUpperCase()).join('')}
                        </div>
                        <div>
                            {userData?.nickname?.split('.').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}
                        </div>
                    </div>
                    <div className="comments-sidebar-text">
                        <TextField
                            id="outlined-multiline-flexible"
                            className="comments-text-field"
                            placeholder="Type to comment..."
                            multiline
                            maxRows={6}
                            value={commentMessage.comment_text}
                            onChange={handleCommentInputChange}
                            onFocus={() => setIsFocused(true)}
                            inputProps={{ maxLength: 400 }}
                            helperText={`${commentMessage?.comment_text?.length}/400`}
                        />
                    </div>
                    <div className={`actions-button ${isFocused || commentMessage.comment_text ? 'visible' : ''}`}>
                        <div>
                            {!loading && <Button className="report-cancel-button" onClick={() => {
                                setCommentMessage({
                                    comment_text: "",
                                    comment_user_id: userData?.user_email || "",
                                    time: "",
                                    comment_user_name: userData?.nickname?.split('.').map(word => word[0].toUpperCase() + word.slice(1)).join(' ') || ""
                                });
                                setIsFocused(false);
                            }}>Cancel</Button>}
                        </div>
                        <div>
                            {!loading && commentMessage.comment_text.trim() ? (
                                <Button className="orange-button" onClick={handleSendComment}>
                                    Send
                                </Button>
                            ) : loading === true ? (
                                <Button className="orange-button-disabled" disabled={true} >
                                    Sending...
                                </Button>
                            ) : (
                                <Tooltip title="No comment added" arrow>
                                    <div className='disable-click-action'>
                                        <Button className="orange-button-disabled" disabled={true} >
                                            Send
                                        </Button>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div className="tab-divider-comments"></div>
                    </div>
                </React.Fragment>
            )}
            <div className="view-comments">
            {body?.comments?.map((data, index) => (
                <React.Fragment key={index}>
                    <div className="comments-sidebar-comments">
                        <div className="comments-text">
                            {data?.comment_text || ''}
                        </div>
                        <div className="comments-details">
                            <div className="comments-name">
                                {data?.comment_user_name || NA}
                            </div>
                            <div className="comments-time">
                                <Tooltip title={extractDate(data?.time || NA )} arrow>
                                    <span>{formatDateTime(data?.time) || NA}</span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="tab-divider-comments"></div>
                </React.Fragment>
            ))}
            </div>
        </div>
    );
}

export default CommentsSidebar;
