const DisabledDragIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="dis-drag-icon">
            <g opacity="0.5">
                <path d="M12 12.6667C12 13.403 11.403 14 10.6667 14H10.4242C9.68786 14 9.09091 13.403 9.09091 12.6667C9.09091 11.9303 9.68786 11.3333 10.4242 11.3333H10.6667C11.403 11.3333 12 11.9303 12 12.6667ZM6.90909 12.6667C6.90909 13.403 6.31214 14 5.57576 14H5.33333C4.59695 14 4 13.403 4 12.6667C4 11.9303 4.59695 11.3333 5.33333 11.3333H5.57576C6.31214 11.3333 6.90909 11.9303 6.90909 12.6667ZM12 8C12 8.73638 11.403 9.33333 10.6667 9.33333H10.4242C9.68786 9.33333 9.09091 8.73638 9.09091 8C9.09091 7.26362 9.68786 6.66667 10.4242 6.66667H10.6667C11.403 6.66667 12 7.26362 12 8ZM6.90909 8C6.90909 8.73638 6.31214 9.33333 5.57576 9.33333H5.33333C4.59695 9.33333 4 8.73638 4 8C4 7.26362 4.59695 6.66667 5.33333 6.66667H5.57576C6.31214 6.66667 6.90909 7.26362 6.90909 8ZM12 3.33333C12 4.06971 11.403 4.66667 10.6667 4.66667H10.4242C9.68786 4.66667 9.09091 4.06971 9.09091 3.33333C9.09091 2.59695 9.68786 2 10.4242 2H10.6667C11.403 2 12 2.59695 12 3.33333ZM6.90909 3.33333C6.90909 4.06971 6.31214 4.66667 5.57576 4.66667H5.33333C4.59695 4.66667 4 4.06971 4 3.33333C4 2.59695 4.59695 2 5.33333 2H5.57576C6.31214 2 6.90909 2.59695 6.90909 3.33333Z" fill="#E7E7E7" />
            </g>
        </svg>
    )
}

export default DisabledDragIcon