import React, { useState, useEffect } from "react";
import { Grid, Typography, Tabs, Tab, Skeleton,ToggleButton,ToggleButtonGroup } from "@mui/material";
import {
    CREATE_DEMAND_TAGS_PATH,
    CREATE_SUPPLY_ROUTER_PATH,
    TABS_CHANNEL,
    VIEW_DEMAND_TAG_TYPE,
    VIEW_MACRO_TYPE,
    VIEW_THUNDERSTORM_TAGS,
    SELECTED_CHANNEL_KEY,
    SELECTED_PLATFORM_KEY,
    ALL_DATA_FETCHED,
    SEVERITY_TYPE,
    SUCCESS_TYPE,
    NO_DATA_AVAILABLE,
    DEMAND_TAG_TITLE_HEADER,
    SUPPLY_ROUTER_TAG_TITLE_HEADER,
    WARNING_TYPE,
    SEARCHED_TEXT_NOT_FOUND,
    USER_DATA_KEY, NON_TS_PLATFORM_TYPE, ADS_PLUS_PLATFORM_ID, TS_PLATFORM_TYPE, VIEW_SUPPLY_TAG_TYPE, INTERNAL_AMG_ID,
} from "../../../Constant";
import { useParams, useNavigate } from "react-router-dom";
import TableContentDemand from "../../../Components/Tables/TableContentDemand";
import TableContentMacro from "../../../Components/Tables/TableContentMacro";
import TableSupplyTags from "../../../Components/Tables/TableSupplyTags";
import FilterContent from "../../../Components/TableTopFilters/FilterContent";
import { MAX_LIMIT_SHOW_TABLE_DATA } from "../../../Constant";
import {isEmpty, replaceUnderscoresWithSpaces} from "../../../utils/helper";
import PropTypes from "prop-types";
import { getMacroListByChannelId } from "../../../api/getMacroListByChannelId";
import { getDemandTagListingByChannelsId } from "../../../api/getDemandTagListingByChannelId";
import { getChannelDetails } from "../../../api/getChannelDetails";
import FilterTableWithAction from "../../../Components/TableTopFilters/FilterTableWithAction";
import TemporaryDrawer from "../../../Components/AddMacroSidebar";
import {getSupplyRoutersByChannelId} from "../../../api/getSupplyRoutersByChannelId";
import { getLocalStorageData, setLocalStorageData } from "../../../utils/localStorageHelper";
import { ONGOING_API_CALL_KEY } from "../../../Constant";
import { getPlatformDetails } from "../../../api/getPlatformDetails";
import Notification from "../../../Components/Notification";
import ChannelBreadCrumbs from "../../../Components/BreadCrumbs/ChannelBreadCrumbs";
import FilterTableWithClearAllButton from "../../../Components/TableTopFilters/FilterTableWithClearAllButton";
import PlatformHeader from "../../../Components/PlatformHeader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {getSupplyTagsByChannelId} from "../../../api/getSupplyTagsByChannelId";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";

function ChannelDetails(props) {
    const [titleTextHeader, setTitleTextHeader] = useState("");
    const [selectedTabType, setSelectedType] = useState("");
    const [channelDetails, setChannelDetails] = useState({})
    const [showAddButton, setShowAddButton] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [offset, setOffset] = useState(0);
    const [channelDetailsData, setChannelDetailsData] = useState([]);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [selectedRowsDemand, setSelectedRowsDemand] = useState([]);
    const [selectedRowsMacro, setSelectedRowsMacro] = useState([]);
    const [loading, setLoading] = useState(true)
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [platformDetail, setPlatformDetail] = useState("")
    const [sideBarTitleHeader, setSideBarTitleHeader] = useState("")
    const [searchText, setSearchText] = useState("")
    const [alertType, setAlertType] = useState("")
    const [searchedData, setSearchedData] = useState([])
    const [userEmail, setUserEmail] = useState("")
    const { channelId } = useParams();
    const navigation = useNavigate();
    const [platformType, setPlatformType] = useState("");
    const [userData, setUserData] = useState({});
    const [platformDealTypeInventory, setPlatformDealTypeInventory] = useState(false);

    useEffect(() => {
        if(props.type !== selectedTabType){
            setChannelDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setSelectedRowsDemand([])
            setShowAddButton(false)
            setLoading(true)
            setOffset(0)
            setSearchText("")
            setSideBarTitleHeader("")
            setUserData(getLocalStorageData(USER_DATA_KEY, true))
        }
        if (props.type !== "") {
            setSelectedType(props.type);
            loadData(props.type, 0);
        }

        useWebStorageHelper.removeOnbFile();
    }, [props?.type])

    const loadData = async (type = "") => {
        const getLocalStorageValue = getLocalStorageData(SELECTED_CHANNEL_KEY, false)
        const getLocalStoragePlatformValue = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
        const user_data = getLocalStorageData(USER_DATA_KEY,true);
        let contentData = []
        let userEmail = user_data?.email || ""
        let platform_type;
        getLocalStoragePlatformValue === ADS_PLUS_PLATFORM_ID ?
            platform_type=NON_TS_PLATFORM_TYPE  :platform_type=TS_PLATFORM_TYPE;
        getLocalStoragePlatformValue === ADS_PLUS_PLATFORM_ID ?
            setPlatformType(NON_TS_PLATFORM_TYPE ): setPlatformType(TS_PLATFORM_TYPE);
        setLoading(true)
        setHasMoreData(false)
        setUserEmail(userEmail)

        if (!getLocalStorageValue || getLocalStorageValue !== channelId) {
            setLocalStorageData(SELECTED_CHANNEL_KEY, false, channelId)
        }

        const getChannelResponseData = await getChannelDetails(channelId, userEmail, platform_type);
        if (!isEmpty(getChannelResponseData)) {
            setChannelDetails(getChannelResponseData)
            if (getChannelResponseData?.platform_id) {
                const platform_id = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
                const getPlatformResponseData = await getPlatformDetails(platform_id)
                if(getPlatformResponseData.deal_type.includes("Inventory")){
                    setPlatformDealTypeInventory(true);
                }
                setPlatformDetail(getPlatformResponseData)
            }
        }

        if (type === VIEW_THUNDERSTORM_TAGS) {
            let content = await getSupplyRoutersByChannelId(channelId, userEmail, platformType);
            contentData = content?.data || []
            setTitleTextHeader("Thunderstorm Tags");

        }
        if (type=== VIEW_SUPPLY_TAG_TYPE ) {
            let content = await getSupplyTagsByChannelId(channelId, platform_type, userEmail);
            contentData = content?.data?.data || [];
            setTitleTextHeader("Supply Tags");
        }
        if (type === VIEW_DEMAND_TAG_TYPE) {
            const channelIdArr = [channelId]
            let content = await getDemandTagListingByChannelsId(
                MAX_LIMIT_SHOW_TABLE_DATA,
                offset,
                userEmail,
                channelIdArr,
                platformType
            );
            contentData = content?.data || [];
            setTitleTextHeader("Demand Tags");
            setSideBarTitleHeader(DEMAND_TAG_TITLE_HEADER)

        }
        if (type === VIEW_MACRO_TYPE) {
            contentData = await getMacroListByChannelId(channelId, userEmail, platform_type);
            setTitleTextHeader("Macros");
            setSideBarTitleHeader(SUPPLY_ROUTER_TAG_TITLE_HEADER)
        }

        if (contentData && contentData.length > 0) {
            const getStateData = offset > 0 ? Object.assign([], channelDetailsData) : []
            getStateData.push(...contentData)
            setChannelDetailsData(getStateData);
            setSearchedData(getStateData)
            setHasMoreData(true)
            setLoading(false)
        } else if (contentData && contentData.length === 0) {
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(ALL_DATA_FETCHED)
            setAlertType(SUCCESS_TYPE)
            setLoading(false)
        } else {
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(NO_DATA_AVAILABLE)
            setAlertType(SEVERITY_TYPE)
            setLoading(false)
            setChannelDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setShowAddButton(false)
            setIsOpen(true)
        }
    };

    const onClickOnItem = (id) => {
        if (id && selectedTabType === VIEW_THUNDERSTORM_TAGS) {
            navigation(`${CREATE_SUPPLY_ROUTER_PATH}${id}`);
        }

        if (id && selectedTabType === VIEW_DEMAND_TAG_TYPE ) {
            navigation(`${CREATE_DEMAND_TAGS_PATH}${id}`);
        }
    };
    const onClickHandleChange = (value, path) => {
        if (selectedTabType !== value) {
            setChannelDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setShowAddButton(false)
            setSelectedRowsDemand([])
            setSearchText("")
            setSelectedRowsMacro([])
            setLoading(true)
            setOffset(0)
            setSideBarTitleHeader("")
            setSelectedType(value);
            navigation(`${path}${channelId}`);
        }
    };

    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };
    const onShowAddButton = (value) => {
        setShowAddButton(value);
    };

    const handleSelectedRowsChangeMacro = (rows) => {
        setSelectedRowsMacro(rows);
    };

    const onClickAddMacros = () => {
        setSidebarOpen(true);
    };

    const handleSelectedRowsChangeDemand = (rows) => {
        setSelectedRowsDemand(rows);
    };

    const handleNotificationClose = () => {
        setIsOpen(false);
    }

    const searchFunction = (text) => {
        let newData = []
        if (props.type === VIEW_THUNDERSTORM_TAGS) {
            if (text !== "") {
                newData = channelDetailsData.filter((data) =>
                    data?.supply_partner_name?.toLowerCase()?.includes(text?.toLowerCase())
                );
            }
        }
        if (props.type === VIEW_SUPPLY_TAG_TYPE) {
            if (text !== "") {
                newData = channelDetailsData.filter((data) =>
                    data?.supply_partner_name?.toLowerCase()?.includes(text?.toLowerCase()) ||
                    data?.supply_tag_name?.toLowerCase()?.includes(text?.toLowerCase())
                );
            }
        }
        else if (props.type === VIEW_DEMAND_TAG_TYPE) {
            if (text !== "") {
                newData = channelDetailsData.filter((data) =>
                    data?.demand_tag_name?.toLowerCase()?.includes(text?.toLowerCase())
                );
            }
        }
        else {
            if (text !== "") {
                newData = channelDetailsData.filter((data) =>
                    data?.amagi_key?.toLowerCase()?.includes(text?.toLowerCase())
                );
            }
        }

        if (newData.length > 0 && text !== "") {
            setSearchedData(newData);
            setHasMoreData(false)
        } else if (newData.length === 0 && text !== "") {
            setSearchedData([]);
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(SEARCHED_TEXT_NOT_FOUND)
            setAlertType(WARNING_TYPE)
        } else {
            setHasMoreData(true)
            setSearchedData(channelDetailsData);
        }
    }

    const changeOffset = (count) => {
        const getOnGoingApiCall = getLocalStorageData(ONGOING_API_CALL_KEY, false)
        if (count > 0  && getOnGoingApiCall === "false") {
            setOffset(count)
            loadData(props.type, count)
        }
    }

    const onSearchFilter = (text = "") => {
        setSearchText(text)
        searchFunction(text)
    }

    const clearSelection = () => {
        setSelectedRows([])
        setSelectedRowsDemand([])
        setShowAddButton(false);
    }

    const onShowNotification = (text = "", type = SUCCESS_TYPE) => {
        setIsOpen(true)
        setNotificationMessage(text)
        setAlertType(type)
    }
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                setIsOpen(true)
                setNotificationMessage("URL copied to clipboard")
                setAlertType(SUCCESS_TYPE)
            })
            .catch((error) => {
                console.error('Error copying URL to clipboard: ', error);
                setIsOpen(true)
                setNotificationMessage("Error copying to clipboard")
                setAlertType(WARNING_TYPE)
            });
    };

    return (
        <React.Fragment>
            {isSidebarOpen && (
                <TemporaryDrawer
                    isSidebarOpen={isSidebarOpen}
                    closeSidebar={() => setSidebarOpen(false)}
                    selectedChannels={selectedRows}
                    selectedDemandTags={selectedRowsDemand}
                    tabType={props.type}
                    screen="channel"
                    platform_id={channelDetails.platform_id}
                    sideBarTitleHeader={sideBarTitleHeader}
                    onShowNotification={onShowNotification}
                    selectedChannelId={[channelId]}
                    userEmail={userEmail}
                />
            )}
            <Grid item xs={12} className="amg-bread-crumbs">
                {loading && searchedData.length === 0 ?
                    <Skeleton className="text-skeleton-breadcrumbs" animation="wave"/> :
                    <ChannelBreadCrumbs
                        onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                        platformName={platformDetail?.name}
                        platformId={channelDetails?.platform_id}
                        channelName={channelDetails?.name}
                    />
                }
            </Grid>

            {loading && searchedData.length === 0 ?
                (<Skeleton animation="wave" className="text-skeleton-loading" />) :
                <PlatformHeader
                    userData={userData}
                    Detail={channelDetails}
                    platformType={platformType}
                    headerType="Channel"
                    status="Running"
                    platformName={platformDetail?.name}
                    platformDealTypeInventory={platformDealTypeInventory}
                />
            }

            <Grid item xs={12} className="amg-body-content">
                <Grid container className="amg-body-tab-content">
                    <Grid item xs={12} className="amg-white-tabs-content">
                        {
                            loading && searchedData.length === 0 ?
                                <Skeleton  animation="wave" className="tab-skeleton-loading"/> : (
                                <Tabs className="amg-white-tabs" >
                                    {platformType === TS_PLATFORM_TYPE ? (<Tab
                                        label="SS Core"
                                        value="SS Core"
                                        className={platformType===TS_PLATFORM_TYPE ? "active" : ""}
                                    />): (
                                        <Tab
                                            label="SS Ads+"
                                            value="SS Ads+"
                                            className={platformType===NON_TS_PLATFORM_TYPE ? "active" : ""}
                                        />
                                    )}
                                </Tabs>
                            )
                        }

                    </Grid>
                    <Grid item xs={12} className="copy-vast-url" style={{marginTop:"16px"}}>
                        <Typography>
                            {loading ? (
                                <Skeleton animation="wave" sx={{ height: "40px", width: "800px", backgroundColor: "#1e1e1d" }} />
                            ) : (
                                <><span className="tag-url-span">Supply Router : </span>
                                    <span className="url-span">
                                        {channelDetails?.supply_router_url?.slice(0,150)}
                        </span> <span className="copy-icon-span"><ContentCopyIcon className="copy-icon" onClick={() => copyToClipboard(channelDetails.supply_router_url)} /></span>

                                </>
                            )}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} className="segment-channel-tab">
                        {loading ? (
                            <Skeleton animation="wave" sx={{ height: "40px", width: "300px", backgroundColor: "#1e1e1d" }} />
                        ) : (
                        <ToggleButtonGroup
                            value={selectedTabType}
                            exclusive
                            onChange={(event, newValue) => {
                                const selectedTab = TABS_CHANNEL.find(tab => tab.value === newValue );
                                if (selectedTab) {
                                    onClickHandleChange(newValue, selectedTab.path);
                                }
                            }}
                            aria-label="Platform"
                        >
                            {  TABS_CHANNEL.map(tab => (
                                <ToggleButton
                                    key={tab.value}
                                    value={tab.value}
                                    className={`toggle-tab-${tab.value} ${selectedTabType === tab.value ? 'toggle-tab-selected' : 'toggle-tab'}`}
                                >
                                    {tab.name}
                                </ToggleButton>
                            )) }
                        </ToggleButtonGroup>
                        )}
                    </Grid>
                </Grid>
                    {selectedTabType === VIEW_SUPPLY_TAG_TYPE && (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                                <FilterContent
                                    count={searchedData.length}
                                    titleText={titleTextHeader}
                                    onSearch={onSearchFilter}
                                />
                            </Grid>
                            <Grid container className="amg-body-table-content">
                                <TableSupplyTags
                                    tableContent={searchedData}
                                    onClick={onClickOnItem}
                                    isLoading={loading}
                                    searchedText={searchText}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    {selectedTabType === VIEW_DEMAND_TAG_TYPE && (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                                <FilterTableWithClearAllButton
                                    titleText={titleTextHeader}
                                    showActionButton={showAddButton}
                                    actionButtonName="Add Macros"
                                    onClickActionButton={onClickAddMacros}
                                    count={searchedData.length}
                                    onSearch={onSearchFilter}
                                    selectedRowLength={selectedRowsDemand.length}
                                    clearSelection={clearSelection}
                                />
                            </Grid>
                            <Grid container className="amg-body-table-content">
                                <TableContentDemand
                                    tableContent={searchedData}
                                    onClick={onClickOnItem}
                                    onShowAddButton={onShowAddButton}
                                    onSelectedRowsChangeDemand={handleSelectedRowsChangeDemand}
                                    newOffset={changeOffset}
                                    isLoading={loading}
                                    isHavingMoreData={false}
                                    currentOffset={offset}
                                    selectedRowData={selectedRowsDemand}
                                    searchedText={searchText}
                                    adsPlusPlatform={platformDetail?.amg_id === INTERNAL_AMG_ID}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    {selectedTabType === VIEW_MACRO_TYPE && (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                                <FilterTableWithAction
                                    onSearch={onSearchFilter}
                                    showActionButton={true}
                                    titleText={titleTextHeader}
                                    actionButtonName="Add Macros"
                                    onClickActionButton={onClickAddMacros}
                                    count={searchedData.length}
                                    platformType={platformType}
                                />
                            </Grid>
                            <Grid container className="amg-body-table-content">
                            <TableContentMacro
                                tableContent={searchedData}
                                onClick={onClickOnItem}
                                onShowAddButton={onShowAddButton}
                                onSelectedRowsChangeMacro={handleSelectedRowsChangeMacro}
                                isLoading={loading}
                                searchedText={searchText}

                            />
                            </Grid>
                        </React.Fragment>
                    )}
                <Notification
                    open={isOpen}
                    message={notificationMessage}
                    handleClose={handleNotificationClose}
                    type={alertType}
                />
            </Grid>
        </React.Fragment>
    );
}

ChannelDetails.propTypes = {
    type: PropTypes.string,
};

export default ChannelDetails;
