import React, { useState, useEffect } from "react"
import { Grid, Typography, Tabs, Tab, Skeleton } from "@mui/material";
import {
    CREATE_DEMAND_TAGS_PATH,
    TABS_THUNDERSTORM,
    VIEW_DEMAND_TAG_TYPE,
    SEVERITY_TYPE,
    ONGOING_API_CALL_KEY,
    SELECTED_ROUTER_KEY,
    MAX_LIMIT_SHOW_TABLE_DATA,
    ALL_DATA_FETCHED,
    SUCCESS_TYPE,
    NO_DATA_AVAILABLE,
    TITLE_HEADER_TYPE,
    DEMAND_TAG_TITLE_HEADER,
    SELECTED_PLATFORM_KEY,

    VIEW_DEMAND_TAG_WITH_TS_API_CALL,
    WARNING_TYPE,
    SEARCHED_TEXT_NOT_FOUND,
    USER_DATA_KEY,
    TIMEOUT_NAVIGATION_CALL,
    DATA_IN_LOADING_STATE, WARNING_NOTIFICATION_TYPE

} from "../../../Constant";
import { useParams, useNavigate } from "react-router-dom"
import TableContentDemand from "../../../Components/Tables/TableContentDemand";
import PropTypes from "prop-types";
import { getDemandTagListingBySupplyRouterId } from "../../../api/getDemandTagListingBySupplyRouterId";
import { getSupplyRouterTagDetails } from "../../../api/getSupplyRouterTagDetails";
import { getLocalStorageData, setLocalStorageData } from "../../../utils/localStorageHelper";
import FilterTableWithAction from "../../../Components/TableTopFilters/FilterTableWithAction";
import TemporaryDrawer from "../../../Components/AddMacroSidebar";
import SupplyRouterBreadCrumbs from "../../../Components/BreadCrumbs/SupplyRouterBreadCrumbs";
import FilterTableWithClearAllButton from "../../../Components/TableTopFilters/FilterTableWithClearAllButton";
import {getPlatformDetails} from "../../../api/getPlatformDetails";
import Notification from "../../../Components/Notification";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {isEmpty} from "../../../utils/helper";




function ThunderstormTagDetails(props) {
    const [titleTextHeader, setTitleTextHeader] = useState("")
    const [selectedTabType, setSelectedType] = useState("")
    const { supplyRouterId } = useParams()
    const [thunderstormTagsData, setThunderstormTagsData] = useState({})
    const [thunderstormTagDetailsData, setThunderstormTagDetailsData] = useState([]);
    const [showAddButton, setShowAddButton] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [selectedRowsDemand, setSelectedRowsDemand] = useState([]);
    const [selectedRowsMacro, setSelectedRowsMacro] = useState([]);
    const [loading, setLoading] = useState(true)
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [notificationType, setNotificationType] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);
    const [platformName, setPlatformName] = useState("")
    const [searchText, setSearchText] = useState("")
    const [alertType, setAlertType] = useState("")
    const [searchedData, setSearchedData] = useState([])
    const [sideBarTitleHeader, setSideBarTitleHeader] = useState("")
    const [offset, setOffset] = useState(0)
    const [platformDetails,setPlatformDetails] = useState({})
    const [userEmail, setUserEmail] = useState("")

    const navigation = useNavigate();

    useEffect(() => {
        if(props.type !== selectedTabType){
            setThunderstormTagDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setSelectedRowsDemand([])
            setShowAddButton(false)
        }
        if (props.type !== "") {
            setSelectedType(props.type);
            loadData(props.type, 0);
        }
    }, [props?.type])

    const loadData = async (type = "") => {
        setLoading(true)
        const getLocalStorageValue = getLocalStorageData(SELECTED_ROUTER_KEY, false)
        const getLocalStoragePlatformValue = getLocalStorageData(SELECTED_PLATFORM_KEY, false)
        const user_data = getLocalStorageData(USER_DATA_KEY,true);
        let contentData = []
        const userEmail = user_data?.email
        setLoading(true)
        setHasMoreData(false)
        setUserEmail(userEmail)

        if (!getLocalStorageValue || getLocalStorageValue !== supplyRouterId) {
            setLocalStorageData(SELECTED_ROUTER_KEY, false, supplyRouterId)
        }
        const getSupplyTagResponseData = await getSupplyRouterTagDetails(supplyRouterId, userEmail)
        if (getSupplyTagResponseData?.name !== "") {
            setThunderstormTagsData(getSupplyTagResponseData)
        }
        const getPlatformResponseData = await getPlatformDetails(getLocalStoragePlatformValue, userEmail);
        if (getPlatformResponseData?.name !== "") {
            setPlatformDetails(getPlatformResponseData)
        }

        if (type === VIEW_DEMAND_TAG_TYPE) {
            contentData = await getDemandTagListingBySupplyRouterId(
                +supplyRouterId,
                MAX_LIMIT_SHOW_TABLE_DATA,
                0,
                userEmail
            );
            setTitleTextHeader("Demand Tags");
            setSideBarTitleHeader(DEMAND_TAG_TITLE_HEADER)
        }
        if (contentData && contentData.length > 0) {
            setThunderstormTagDetailsData(prevData => [...prevData, ...contentData]);
            setSearchedData(prevData => [...prevData, ...contentData])
            setHasMoreData(true)
            setLoading(false)
        } else if (contentData && contentData.length === 0) {
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(ALL_DATA_FETCHED)
            setAlertType(SUCCESS_TYPE)
            setLoading(false)
        } else {
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(NO_DATA_AVAILABLE)
            setAlertType(SEVERITY_TYPE)
            setLoading(false)
            setThunderstormTagDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setShowAddButton(false)
            setIsOpen(true)
        }
    };


    const searchFunction = (text) => {
        let newData = []
        if (searchText !== "") {
            newData = thunderstormTagDetailsData.filter((data) =>
                data.demand_tag_name.toLowerCase().includes(text.toLowerCase())
            );
        } if (newData.length > 0 && text !== "") {
            setSearchedData(newData);
            setHasMoreData(false)
        } else if (newData.length === 0 && text !== "") {
            setSearchedData([]);
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(SEARCHED_TEXT_NOT_FOUND)
            setAlertType(WARNING_TYPE)
        } else {
            setHasMoreData(true)
            setSearchedData(thunderstormTagDetailsData);
        }
    }
    const onShowAddButton = (value) => {
        setShowAddButton(value);
    };
    const onClickAddMacros = () => {
        setSidebarOpen(true);
    };
    const handleSelectedRowsChangeDemand = (rows) => {
        setSelectedRowsDemand(rows);
    };
    const handleNotificationClose = (event, reason) => {
        setIsOpen(false);
    }

    const onClickOnItem = (id) => {
        if (id && selectedTabType === VIEW_DEMAND_TAG_TYPE) {
            navigation(`${CREATE_DEMAND_TAGS_PATH}${id}`)
        }

    }
    const changeOffset = (count) => {
        if (count > 0) {
            setOffset(count)
            loadData(props.type, count)
        }

    }

    const onSearchFilter = (text = "") => {
        setSearchText(text)
        searchFunction(text)
    }

    const onClickHandleChange = (value, path) => {
        if (selectedTabType !== value) {
            setThunderstormTagDetailsData([])
            setSearchedData([])
            setSelectedRows([])
            setShowAddButton(false)
            setSearchText("")
            setSelectedType(value);
            navigation(`${path}${supplyRouterId}`);
        }
    };

    const onClickBreadCrumbsLink = (link) => {
        navigation(link)
    }

    const clearSelection = () => {
        setSelectedRows([])
        setSelectedRowsDemand([])
        setShowAddButton(false);
    }

    const onShowNotification = (text = "", type = SUCCESS_TYPE) => {
        setIsOpen(true)
        setNotificationMessage(text)
        setAlertType(type)
    }

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                console.log("Url copied")
            })
            .catch((error) => {
                console.error('Error copying URL to clipboard: ', error);
            });
    };
    return (
        <React.Fragment>
            {isSidebarOpen && (
                <TemporaryDrawer
                    isSidebarOpen={isSidebarOpen}
                    closeSidebar={() => setSidebarOpen(false)}
                    selectedChannels={[thunderstormTagsData.channel_id]}
                    selectedDemandTags={selectedRowsDemand}
                    tabType={VIEW_DEMAND_TAG_WITH_TS_API_CALL}
                    screen="channel"
                    channelId={thunderstormTagsData?.channel_id}
                    platform_id={platformDetails.amg_id}
                    onShowNotification={onShowNotification}
                    selectedChannelId={[thunderstormTagsData.channel_id]}
                    userEmail={userEmail}
                />
            )}
            <Grid item xs={12} className="amg-bread-crumbs">
                {loading ? <Skeleton className="text-skeleton-breadcrumbs"  animation="wave"/> :
                    <SupplyRouterBreadCrumbs
                        onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                        platformName={platformDetails.name}
                        channelId={thunderstormTagsData.channel_id}
                        channelName={thunderstormTagsData.delivery_name}
                        supplyRouterId={supplyRouterId}
                        supplyRouterName={thunderstormTagsData.supply_router_name}
                        platformId={platformDetails.amg_id}
                    />
                }
            </Grid>
            <Grid item xs={12} className="amg-body-page-header">
                <Typography> {loading ? (<Skeleton animation="wave" sx={{ height: "40px", width: "300px", backgroundColor: "#1e1e1d" }} />) : (`Thunderstorm tag:${thunderstormTagsData.supply_router_name}`)} </Typography>
            </Grid>
            <Grid item xs={12} className="copy-vast-url">
                <Typography>
                    {loading ? (
                        <Skeleton animation="wave" sx={{ height: "40px", width: "300px", backgroundColor: "#1e1e1d" }} />
                    ) : (
                        <><span className="tag-url-span">Tag url :</span>
                        <span className="url-span">
                         {  thunderstormTagsData.ad_tag_url.split(' ').slice(0, 5).join(' ')}
                             {thunderstormTagsData.ad_tag_url.split(' ').length > 5? '...' : ''}
                        </span> <span className="copy-icon-span"><ContentCopyIcon className="copy-icon" onClick={() => copyToClipboard(thunderstormTagsData.ad_tag_url)} /></span>

                        </>
                    )}
                </Typography>

            </Grid>
            <Grid item xs={12} className="amg-body-content">
                <Grid container className="amg-body-tab-content">
                    <Grid item xs={12} className="amg-white-tabs-content">
                        {loading && searchedData.length === 0 ? <Skeleton  animation="wave" className="tab-skeleton-loading"/> : <Tabs className="amg-white-tabs">
                            {
                                TABS_THUNDERSTORM.map((data) => {
                                    return (
                                        <Tab label={data.name} value={data.value} className={selectedTabType === data.value && "active"} onClick={() => onClickHandleChange(data.value, data.path)} />
                                    )
                                })
                            }
                        </Tabs>
                        }
                    </Grid>
                </Grid>
                    {selectedTabType === VIEW_DEMAND_TAG_TYPE && (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                                <FilterTableWithClearAllButton
                                    titleText={titleTextHeader}
                                    showActionButton={showAddButton}
                                    actionButtonName="Add Macros"
                                    onClickActionButton={onClickAddMacros}
                                    count={searchedData.length}
                                    onSearch={onSearchFilter}
                                    clearSelection={clearSelection}
                                    selectedRowLength={selectedRowsDemand.length}
                                />
                            </Grid>
                            <Grid container className="amg-body-table-content">
                                <TableContentDemand
                                    tableContent={searchedData}
                                    onClick={onClickOnItem}
                                    onShowAddButton={onShowAddButton}
                                    onSelectedRowsChangeDemand={handleSelectedRowsChangeDemand}
                                    newOffset={changeOffset}
                                    isLoading={loading}
                                    currentOffset={offset}
                                    isHavingMoreData={false}
                                    selectedRowData={selectedRowsDemand}
                                    searchedText={searchText}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                <Notification
                    open={isOpen}
                    message={notificationMessage}
                    handleClose={handleNotificationClose}
                    type={alertType}
                />
            </Grid>
        </React.Fragment>
    )
}

ThunderstormTagDetails.propTypes = {
    type: PropTypes.string
}

export default ThunderstormTagDetails