import axios from "axios";
import { BFF_LAYER_URI, MAX_LIMIT_SHOW_TABLE_DATA, MAX_ROW_COUNT_FROM_DB } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getDemandTagListingBySupplyRouterId = (id, limit = MAX_LIMIT_SHOW_TABLE_DATA, offset = 0, email = "") => {
    let data = JSON.stringify({
        query: `query MyQuery($data: DemandTagSupplyRouterInput = {}) {
  getDemandTagListingBySupplyRouterId(data: $data) {
    adserver_domain
    channel_name
    demand_class
    demand_partner_id
    demand_partner_name
    demand_tag_id
    demand_tag_name
    vast_endpoint_url
    channel_id
    supply_router_id
    supply_router_name
    created_at
    updated_at
  }
}`,
        variables: {
            "data": {
                "offset_limit": offset,
                "limit": limit,
                "supply_router_id": id
            }
        }
    });

    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getDemandTagListingBySupplyRouterId && response.status === 200) {
                return response.data.data.getDemandTagListingBySupplyRouterId
            } else {
                return []
            }
        })
        .catch((error) => {
            console.log(error)
            return null
        });
}