
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getAllDemandPartner = async () => {
    try {
        const query = `
        query MyQuery {
            demand_partner {
            demand_partner_id
            demand_partner_name
          }
        } `;
        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        if(response?.data?.data?.demand_partner){
            return response.data.data.demand_partner;
        }

        return []
    } catch (error) {
        console.error("Error fetching while getAllDemandPartner:", error);
        return null;
    }
};

export default getAllDemandPartner;
