const ProcessingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <g id="processing">
          <path
            d="M9.09874 14.0309C8.73424 14.0877 8.36511 14.1091 7.9965 14.0948H7.19609L7.30585 12.8733L7.52339 12.8937C7.97894 12.9366 8.43821 12.9169 8.8884 12.8349L9.10293 12.7959L9.31869 13.9966L9.09874 14.0309Z"
            fill="#E7E7E7"
          />
          <path
            d="M4.99872 13.2994L5.18775 13.4066L5.77735 12.3701L5.5899 12.2621C5.19364 12.0339 4.83072 11.752 4.51141 11.4244L4.35854 11.2675L3.5051 12.1103L3.65623 12.265C4.05303 12.6712 4.50496 13.0194 4.99872 13.2994Z"
            fill="#E7E7E7"
          />
          <path
            d="M10.5837 12.1453L11.2566 13.1322L11.435 13.0166C11.9005 12.7149 12.3226 12.3509 12.6897 11.9348L12.8378 11.767L11.9149 10.9986L11.7749 11.1549C11.4777 11.4864 11.1393 11.7784 10.7679 12.0237L10.5837 12.1453Z"
            fill="#E7E7E7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.28329 10.1489L2.36303 10.362L3.48484 9.88379L3.41013 9.68824C3.25072 9.27098 3.14958 8.83366 3.1096 8.38867L3.09013 8.17198L1.89185 8.28L1.91037 8.49576C1.95894 9.06123 2.0844 9.61741 2.28329 10.1489Z"
            fill="#E7E7E7"
          />
          <path
            d="M12.6562 9.53285L13.8462 9.8666L13.9013 9.65098C14.0393 9.11185 14.106 8.55691 14.0996 8.0004V7.6H12.8987L12.8987 7.97947C12.9006 8.43376 12.8389 8.88608 12.7154 9.3232L12.6562 9.53285Z"
            fill="#E7E7E7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.10729 6.19178L3.29195 6.57151L3.35977 6.36716C3.50248 5.9372 3.70478 5.52946 3.96073 5.15591L4.0827 4.97788L3.10217 4.29514L2.97694 4.46842C2.64185 4.93209 2.37437 5.44117 2.18253 5.98033L2.10729 6.19178Z"
            fill="#E7E7E7"
          />
          <path
            d="M12.4215 5.84644L12.5155 6.04666L13.5942 5.51841L13.5053 5.32551C13.2686 4.81215 12.9575 4.33662 12.582 3.91434L12.437 3.75136L11.5493 4.54444L11.6871 4.70611C11.9815 5.05172 12.2285 5.43523 12.4215 5.84644Z"
            fill="#E7E7E7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.64616 2.84904L5.26824 3.87958L5.4536 3.77079C5.85042 3.53789 6.27942 3.36502 6.72668 3.25778L6.94217 3.2061L6.63257 2.01336L6.42145 2.0689C5.86342 2.21572 5.32884 2.44061 4.83352 2.73695L4.64616 2.84904Z"
            fill="#E7E7E7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0404 3.49778L10.2471 3.59069L10.6922 2.48218L10.5034 2.39592C9.99818 2.16504 9.46376 2.00445 8.91504 1.91861L8.69868 1.88477L8.51912 3.0838L8.73469 3.11569C9.18539 3.18236 9.62477 3.31093 10.0404 3.49778Z"
            fill="#E7E7E7"
          />
        </g>
      </g>
    </svg>
  );
};

export default ProcessingIcon;
