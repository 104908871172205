

const ReportLogIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
            <path
                d="M34.8333 40.3333H9.16663C7.70794 40.3333 6.30899 39.7538 5.27754 38.7224C4.24609 37.6909 3.66663 36.292 3.66663 34.8333V5.49996C3.66663 5.01373 3.85978 4.54741 4.2036 4.2036C4.54741 3.85978 5.01373 3.66663 5.49996 3.66663H31.1666C31.6529 3.66663 32.1192 3.85978 32.463 4.2036C32.8068 4.54741 33 5.01373 33 5.49996V27.5H40.3333V34.8333C40.3333 36.292 39.7538 37.6909 38.7224 38.7224C37.6909 39.7538 36.292 40.3333 34.8333 40.3333ZM33 31.1666V34.8333C33 35.3195 33.1931 35.7858 33.5369 36.1297C33.8807 36.4735 34.3471 36.6666 34.8333 36.6666C35.3195 36.6666 35.7858 36.4735 36.1297 36.1297C36.4735 35.7858 36.6666 35.3195 36.6666 34.8333V31.1666H33ZM29.3333 36.6666V7.33329H7.33329V34.8333C7.33329 35.3195 7.52645 35.7858 7.87026 36.1297C8.21408 36.4735 8.6804 36.6666 9.16663 36.6666H29.3333ZM11 12.8333H25.6666V16.5H11V12.8333ZM11 20.1666H25.6666V23.8333H11V20.1666ZM11 27.5H20.1666V31.1666H11V27.5Z"
                fill="#E7E7E7"/>
        </svg>
    )
}

export default ReportLogIcon