import { Grid, Typography, InputAdornment, Button } from "@mui/material";
import "./index.css";
import SearchIcon from "../../../assests/icons/searchIcon";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import {TS_PLATFORM_TYPE} from "../../../Constant";

function FilterTableWithAction(props) {

    const onSearchSetText = (e) => {
        props.onSearch(e?.target?.value)
    }

    return (
        <Grid container className="amg-table-content">
            <Grid item xs className="amg-table-content-filter">
                <Grid container>
                    <Grid item xs className="amg-table-content-count">
                        <Typography>
                            <span className="amg-white-text"> {props?.count && props.count !== 0 ? props.count : ""} </span>
                            {props?.count && props.count !== 0 ?props.titleText: ""}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs
                        className="amg-table-content-search"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <TextField
                            value={props.searchText}
                            onChange={(e) => onSearchSetText(e)}
                            placeholder={`Search`}
                            variant="outlined"
                            className="generic-search-container"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon></SearchIcon>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {props?.showActionButton && props?.platformType === TS_PLATFORM_TYPE &&(
                            <Grid item className="amg-table-content-action">
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    className="amg-add-new-platform-button"
                                    onClick={props.onClickActionButton}
                                >
                                    {props.actionButtonName}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

FilterTableWithAction.propTypes = {
    count: PropTypes.number,
    titleText: PropTypes.string,
    showActionButton: PropTypes.bool,
    onClickCreateNewPlatform: PropTypes.func,
    onSearch: PropTypes.func,
    actionButtonName: PropTypes.string,
    onClickActionButton: PropTypes.func,
};

export default FilterTableWithAction;
