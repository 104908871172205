import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Checkbox,
    Typography, Grid,
} from "@mui/material";
import PropTypes from "prop-types";
import {getEpochData, getImageName, removeDashFromString, reduceString} from "../../../utils/helper";
import {MAX_LIMIT_SHOW_TABLE_DATA, SEARCHED_TEXT_NOT_FOUND} from "../../../Constant";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen";
import {isEmpty} from "../../../utils/helper";
import {EmptyScreen} from "../../CommonScreens/EmptyScreen";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function TableContentChannels(props) {
    const [selectedRows, setSelectedRows] = useState([]);

    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection);
        if (observer && elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [props?.tableContent]);

    const onIntersection = ([entry]) => {
        if (entry.isIntersecting && props?.isHavingMoreData && !props.isLoading && props?.tableContent?.length >= MAX_LIMIT_SHOW_TABLE_DATA) {
            const newCount = props?.currentOffset + MAX_LIMIT_SHOW_TABLE_DATA;
            props?.newOffset(newCount);
        }
    }

    //CHECKBOX LOGIC
    const handleSelectAll = () => {
        if (props?.selectedRowData?.length === props?.tableContent?.length) {
            props?.onShowAddButton(false);
            props?.onSelectedRowsChange([]); // Notify parent component about the changes
        } else {
            const allRows = props?.tableContent?.map((data) => data.channel_id);
            props?.onShowAddButton(true);
            props?.onSelectedRowsChange(allRows); // Notify parent component about the changes
        }
    };

    const handleRowCheckboxChange = (channel_id) => {
        const newSelectedRows = Object.assign([], props?.selectedRowData);
        const index = newSelectedRows.indexOf(channel_id);
        if (index === -1) {
            newSelectedRows.push(channel_id);
        } else {
            newSelectedRows.splice(index, 1);
        }
        props?.onShowAddButton(true);
        props?.onSelectedRowsChange(newSelectedRows);
    };

    return (
        <Grid item xs={12}>
            <Table className="off-white-table sticky-column-last" stickyHeader>
                <TableHead>
                    {!props.adsPlusPlatform && <TableCell width={1}>
                        <Checkbox
                            className="amg-white-checkbox"
                            checked={
                                props?.selectedRowData?.length === props?.tableContent?.length &&
                                props?.tableContent?.length !== 0
                            }
                            onChange={handleSelectAll}
                        />
                    </TableCell>}
                    <TableCell colSpan={3}> CHANNEL NAME </TableCell>
                    <TableCell width={"10%"}> STREAM ID </TableCell>
                    <TableCell> TS TAGS</TableCell>
                    <TableCell> SUPPLY PARTNER </TableCell>
                    <TableCell> SUPPLY PARTNER ID </TableCell>
                    <TableCell>SELF SERVE POLICY</TableCell>
                    <TableCell> LAST UPDATED </TableCell>
                    <TableCell style={{ position: 'sticky',right: 0}}>STATUS</TableCell>
                </TableHead>
                <TableBody>
                    {props?.tableContent?.length > 0 ?
                        props.tableContent.map((data, index) => {
                            return (
                                <TableRow key={`${index}-${data.channel_id}`}>
                                    {!props.adsPlusPlatform && <TableCell width={1}>
                                        <Checkbox
                                            className="amg-white-checkbox"
                                            checked={props?.selectedRowData?.includes(data?.channel_id)}
                                            onChange={() => handleRowCheckboxChange(data?.channel_id)}
                                        />
                                    </TableCell>}
                                    <TableCell colSpan={1} width={1}>
                                        <div className="logo-div white-bg">
                                            <Typography className="img-typography">
                                                {getImageName(data?.name)}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        className="action-table-button"

                                        onClick={() => props?.onClick(data?.channel_id)}
                                    >
                                        {data?.name ? data?.name?.replace(/_/g, " ") : "N/A"}

                                    </TableCell>
                                    <TableCell> {data?.channel_id} </TableCell>
                                    <TableCell> {data?.ts_tags} </TableCell>
                                    <TableCell> {removeDashFromString(data?.supply_partner_name) || "N/A"} </TableCell>
                                    <TableCell> {data?.supply_partner_id} </TableCell>
                                    <TableCell>{data?.policy?"Enabled" : "Not enabled"}</TableCell>
                                    <TableCell> {getEpochData(data?.updated_at)} </TableCell>
                                    <TableCell style={{ position: 'sticky',right: 0}}>
                                        <div className="status-div">
                                        <CheckCircleOutlineIcon className="error-success"
                                                                sx={{marginRight: "3px"}}/>
                                        <Typography className="log-status-success">Active</Typography>
                                    </div></TableCell>
                                </TableRow>
                        )}) : (!isEmpty(props?.searchedText) && !props?.isLoading) && (
                            <TableRow>
                                <TableCell colSpan={11}> <EmptyScreen message="No results found"/> </TableCell>
                            </TableRow>
                        )
                    }
                    {!props?.isLoading && props?.isHavingMoreData && (
                        <TableRow ref={elementRef} >
                            <TableCell colSpan={props.adsPlusPlatform ? 10 : 11}></TableCell>
                        </TableRow>
                    )}
                    {
                        props?.isLoading && (
                            <LoadingScreen rowsNumber={props.adsPlusPlatform? 10 : 11}/>
                        )
                    }
                </TableBody>
            </Table>
        </Grid>
    );
}

TableContentChannels.propTypes = {
    tableContent: PropTypes.arrayOf(PropTypes.shape({
        channel_id: PropTypes.string.isRequired,
        image_path: PropTypes.string,
        name: PropTypes.string,
        ts_tags: PropTypes.string,
        supply_partner_name: PropTypes.string,
        supply_partner_id: PropTypes.string,
        updated_at: PropTypes.string,
    })),
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isHavingMoreData: PropTypes.bool,
    onShowAddButton: PropTypes.func.isRequired,
    onSelectedRowsChange: PropTypes.func.isRequired,
    newOffset: PropTypes.func.isRequired,
    currentOffset: PropTypes.number,
    selectedRowData: PropTypes.arrayOf(PropTypes.string),
    searchedText: PropTypes.string
};


export default TableContentChannels;