import React from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

function TableContactsDetails (props) {
    return (
        <React.Fragment>
            <React.Fragment>
                <Grid item xs={12} className="app-supply-details-table-frame">
                    <Table className="off-white-table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" size="medium" >
                                    CONTACT TYPE
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium" >
                                    NAME
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium" >
                                    EMAIL ID
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="request-table-body">
                            <React.Fragment>
                                {props?.ContactsData?.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='table-cell'> {data.label} </TableCell>
                                        <TableCell className='table-cell'> {data.full_name} </TableCell>
                                        <TableCell className='table-cell'> {data.email_id} </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        </TableBody>
                    </Table>
                </Grid>
            </React.Fragment>
        </React.Fragment>
    )
}

export default TableContactsDetails;