const ContactTableConfig = {
    headers: ["CONTACT TYPE", "NAME", "EMAIL ID"],
    rows: [
        {
            contactType: "Partner primary POC",
            fields: [
                {
                    type: "text_input",
                    width: "200px",
                    placeholder: "Enter name",
                    key: "full_name",

                },
                {
                    type: "text_input",
                    width: "200px",
                    placeholder: "Enter email",
                    key: "email_id",
                }
            ]
        },
        {
            contactType: "Project Lead",
            fields: [
                {
                    type: "text_input",
                    width: "200px",
                    placeholder: "Enter name",
                    key: "full_name",
                },
                {
                    type: "text_input",
                    width: "200px",
                    placeholder: "Enter email",
                    key: "email_id",
                }
            ]
        },
        {
            contactType: "AdOps contact",
            fields: [
                {
                    type: "text_input",
                    width: "200px",
                    placeholder: "Enter name",
                    key: "full_name",
                },
                {
                    type: "text_input",
                    width: "200px",
                    placeholder: "Enter email",
                    key: "email_id",
                }
            ]
        }
    ]
};

export default ContactTableConfig;
