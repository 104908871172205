import React from "react";
import PropTypes from "prop-types";

function IntegratedSupplyBreadCrumbs(props){
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/reports"
                )}
                className="amg-bread-crumbs-links"
            >
                Reports &nbsp;
            </span>
            / &nbsp;
            <span> New supply transparency report </span>

        </React.Fragment>
    )
}

IntegratedSupplyBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func
}

export default IntegratedSupplyBreadCrumbs