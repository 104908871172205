
import axios from "axios";
import {AVAILS_WHERE_QUERY, REPORT_BFF_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

const getAvailsReport = async (where =  AVAILS_WHERE_QUERY,) => {
    try {
        let data = JSON.stringify({
            query: `query MyQuery( $where: avails_filter = {}) {
              avails_report( where: $where) {
                avails_list {
                  result_set {
                    average_clearing_price
                    country
                    amagi_monetization_rights
                    channel_name
                    device_type
                    max_floor_price
                    monthly_available_30s
                    region
                    supply_partner_name
                
                  }
                  total_count
                }
                avails_metrics {
                  max_clearing_price
                  max_floor_price
                  total_30s
                }
                supply_list {
                  total_count
                  result_set {
                    amagi_monetization_rights
                    app_store_url
                    channel_name
                    device_type
                    app_site_name
                    bundle_id
                    operating_system
                    supply_partner_name
                  }
                }
              }
            }`,
            variables: {...where}
        });
        const headers = adsHeader()

        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: `${REPORT_BFF_URI}`,
            headers: headers,
            data : data
        };

        const response = await axios.request(config);
        if(response?.data?.data?.avails_report && response.status === 200){
            return response.data.data.avails_report;
        }

        return {}

    } catch (error) {
        console.error("Error fetching while getSupplyHealthTracker:", error);
        return null;
    }
};

export default getAvailsReport;
