import {MenuItem, Skeleton} from "@mui/material";
import "./index.css";
import React from "react";

export const SelectLoadingOption = (props) => {

    const skeletonOptions = [];

    for (let i = 0; i < props.count; i++) {
        skeletonOptions.push(
            <MenuItem disabled key={i}>
                <Skeleton
                    key={i}
                    animation="wave"
                    className="skleton-loading-screen skeleton-display"
                    width={props.width}
                    height={props.height}
                />
            </MenuItem>
        );
    }

    return <React.Fragment>{skeletonOptions}</React.Fragment>;
};
