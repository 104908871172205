
import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getSupplyTagIds = async () => {
  try {
    // Construct the GraphQL query
    const query = `
            query MyQuery {
                supply_tag {
                    supply_tag_id
                }
            }
        `;

    const headers = adsHeader()

    // Make the API call using Axios
    const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });

    // Extract and return the stream_ids from the response data
    return response.data.data.supply_tag;
  } catch (error) {
    console.error("Error fetching Supply Tag IDs:", error);
    throw error;
  }
};

export default getSupplyTagIds;
