import React from "react";
import {TextField} from "@mui/material";
import PropTypes from "prop-types";

function AdsSimpleTextInput(props){
    const handleChange = (e) => {
        const value = e?.target?.value || "";
        props.onChange(value, props.index, props.type, props.formKey);
    }

    return(
        <React.Fragment>
            {props.preUnit && <span className="pre-unit-simple-input">{props.preUnit}</span>}
            <TextField
                variant="standard"
                placeholder={props.placeholder || "Enter the value"}
                size="small"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                value={props.value}
                className="number-simple-input"
                style={{width: props?.textWidth || "100%"}}
                inputProps={{ maxLength: props?.textSize || 200 }}
                helperText={props?.textSize ? `${props.value.length}/${props.textSize}` : null}
                disabled={props?.disabled}
            />
            {props.postUnit && ( <span className="post-unit-simple-input">{props.postUnit}</span>)}
        </React.Fragment>
    )
}

AdsSimpleTextInput.propTypes = {
    "value": PropTypes.string.isRequired,
    "type": PropTypes.string.isRequired,
    "index": PropTypes.number.isRequired,
    "onChange": PropTypes.func.isRequired,
    "formKey": PropTypes.string.isRequired,
    "postUnit": PropTypes.string,
    "preUnit": PropTypes.string,
}

export default AdsSimpleTextInput