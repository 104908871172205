import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import "./index.css"
import PropTypes from "prop-types";
import LoadingQuickFilter from "../../Common/LoadingQuickFilter";
import {formatNumber, isEmpty, isNegative, removeFractions, removeNegative} from "../../utils/helper";
import {
    DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE,
    NUMBER_DENOTE_TYPE,
    PERCENTAGE_DENOTE_TYPE, QUICK_ADS_PLUS_DEMAND_FILTER_MAPPING,
    QUICK_ADS_PLUS_SUPPLY_FILTER_MAPPING,
    SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE
} from "../../Constant";
import NoDataQuickFilterView from "../../Common/NoDataQuickFilterView";

function AdsPlusTopQuickView(props) {
    const [quickFilterMapping, setQuickFilterMapping] = useState({});
    const [configHealthData, setConfigHealthData] = useState({});
    const [viewData, setViewData] = useState(false);

    useEffect(() => {
        if (!isEmpty(props?.supplyHealthTracker?.metrics) && !props?.loading && props?.dashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
            setConfigHealthData(props.supplyHealthTracker.metrics)
            setQuickFilterMapping(QUICK_ADS_PLUS_SUPPLY_FILTER_MAPPING)
            setViewData(true)
        } else if (!isEmpty(props?.demandHealthTracker?.metrics) && !props?.loading && props?.dashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
            setConfigHealthData(props.demandHealthTracker.metrics)
            setQuickFilterMapping(QUICK_ADS_PLUS_DEMAND_FILTER_MAPPING)
            setViewData(true)
        } else {
            const filters = props.dashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE ? QUICK_ADS_PLUS_SUPPLY_FILTER_MAPPING : QUICK_ADS_PLUS_DEMAND_FILTER_MAPPING
            setQuickFilterMapping(filters)
            setConfigHealthData({})
            setViewData(false)
        }
    }, [props])

    return (
        <Grid container className="quick-view-container" >
            {(props?.loading) &&
                <React.Fragment>
                    <LoadingQuickFilter/>
                    <LoadingQuickFilter/>
                    <LoadingQuickFilter/>
                    <LoadingQuickFilter/>
                    <LoadingQuickFilter/>
                    <LoadingQuickFilter/>
                </React.Fragment>
            }
            {!props?.isDataAvailable && !props?.loading && <React.Fragment>
                {Object.keys(quickFilterMapping).map(key => (
                    <NoDataQuickFilterView
                        name={quickFilterMapping[key]?.label}
                        key={quickFilterMapping[key]?.label}
                    />
                ))}
            </React.Fragment>}
            {!props.loading && viewData && !isEmpty(configHealthData) && !isEmpty(quickFilterMapping) &&
                <React.Fragment>
                    {
                        Object.keys(quickFilterMapping).map((key) => {
                            return (
                                <Grid item className="quick-view-item" key={key} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography className="amg-quick-view-text">
                                                {quickFilterMapping[key]?.denote === NUMBER_DENOTE_TYPE ?
                                                    formatNumber(configHealthData?.[key]?.total) :
                                                    quickFilterMapping[key]?.denote === PERCENTAGE_DENOTE_TYPE ?
                                                        `${removeFractions(configHealthData?.[key]?.total)}%` :
                                                        `$${formatNumber(configHealthData?.[key]?.total)}`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className="amg-quick-view-text-type">
                                                {quickFilterMapping[key]?.label} &nbsp;
                                                {isNegative(configHealthData?.[key]?.change) ?
                                                    <span
                                                        className="error-metrics"> <ArrowDownwardIcon/> {removeNegative(Math.ceil(configHealthData?.[key]?.change))}% </span> :
                                                    <span
                                                        className="success-metrics"> <ArrowUpwardIcon/> {Math.ceil(configHealthData?.[key]?.change)}% </span>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </React.Fragment>
            }
        </Grid>
    )
}

AdsPlusTopQuickView.propTypes = {
    "loading": PropTypes.bool.isRequired,
    "supplyHealthTracker": PropTypes.shape({
        "metrics": PropTypes.shape({
            "ad_requests": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "impressions": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "revenue": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "fill_rate": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "margin_per": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired,
            "ecpm": PropTypes.shape(
                {
                    "total": PropTypes.number.isRequired,
                    "change": PropTypes.number.isRequired
                }
            ).isRequired
        }),
        "daily": PropTypes.arrayOf(PropTypes.shape(
            {
                "date": PropTypes.string.isRequired,
                "ad_requests": PropTypes.number.isRequired,
                "impressions": PropTypes.number.isRequired,
                "revenue": PropTypes.number.isRequired,
                "fill_rate": PropTypes.number.isRequired,
                "margin_per": PropTypes.number.isRequired
            }
        ).isRequired)
    }),
    "demandHealthTracker": PropTypes.shape({
        "metrics": PropTypes.shape({
            "ad_requests": PropTypes.shape({
                "total": PropTypes.number.isRequired,
                "change": PropTypes.number.isRequired,
            }),
            "erpm": PropTypes.shape({
                "total": PropTypes.number.isRequired,
                "change": PropTypes.number.isRequired,
            }),
            "revenue": PropTypes.shape({
                "total": PropTypes.number.isRequired,
                "change": PropTypes.number.isRequired,
            }),
            "bid_rate": PropTypes.shape({
                "total": PropTypes.number.isRequired,
                "change": PropTypes.number.isRequired,
            }),
            "win_rate": PropTypes.shape({
                "total": PropTypes.number.isRequired,
                "change": PropTypes.number.isRequired,
            }),
            "use_rate": PropTypes.shape({
                "total": PropTypes.number.isRequired,
                "change": PropTypes.number.isRequired,
            })
        }),
        "date": PropTypes.arrayOf(PropTypes.shape({
            "date": PropTypes.string.isRequired,
            "revenue": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "impressions": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "e_rpm": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "bid_rate": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "win_rate": PropTypes.shape({"total": PropTypes.number.isRequired}),
            "use_rate": PropTypes.shape({"total": PropTypes.number.isRequired})
        }))
    }),
    "dashboardType": PropTypes.string.isRequired,
    "isDataAvailable": PropTypes.bool.isRequired,
}

export default AdsPlusTopQuickView;