import { gql } from "@apollo/client";
import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getMacroListByAmgId = async (id, email = "") => {
    const query = gql`
        query GetMacroListByAmgId($id: String!) {
            getMacroListingByAmgId(id: $id) {
                amagi_key
                required
                web
                ctv
                hardcoded
                spring_serve_macro
                ts_version
                missing_in_platform
                source
                created_at
                updated_at
            }
        }
    `;

    const variables = { id };

    const data = JSON.stringify({
        query: query.loc.source.body,
        variables
    });
    const headers = adsHeader()

    const config = {
        method: 'post',
        url: BFF_LAYER_URI,
        headers: headers,
        data
    };

    try {
        const response = await axios.request(config);
        if (response?.data?.data?.getMacroListingByAmgId && response.status === 200) {
            return response.data.data.getMacroListingByAmgId;
        } else {
            return [];
        }
    } catch (_) {
        return null;
    }
};
