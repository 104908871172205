

const SkippedIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M8.57591 2.3243L14.9109 13.027C14.9693 13.1256 15 13.2375 15 13.3513C15 13.4652 14.9693 13.5771 14.9109 13.6757C14.8525 13.7743 14.7686 13.8562 14.6675 13.9131C14.5664 13.97 14.4517 14 14.335 14H1.665C1.54827 14 1.43359 13.97 1.3325 13.9131C1.23141 13.8562 1.14746 13.7743 1.08909 13.6757C1.03073 13.5771 0.999999 13.4652 1 13.3513C1 13.2375 1.03073 13.1256 1.0891 13.027L7.42409 2.3243C7.48246 2.2257 7.56641 2.14382 7.6675 2.08689C7.7686 2.02997 7.88327 2 8 2C8.11673 2 8.2314 2.02997 8.3325 2.08689C8.43359 2.14382 8.51754 2.2257 8.57591 2.3243ZM2.81682 12.7027H13.1832L8 3.94592L2.81682 12.7027ZM7.33498 10.7567H8.66502V12.054H7.33498V10.7567ZM7.33498 6.2162H8.66502V9.45945H7.33498V6.2162Z"
                fill="#F1B521"/>
        </svg>
    )
}

export default SkippedIcon