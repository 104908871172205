import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getStreamIds = async () => {
    try {
        const query = `
            query MyQuery {
                stream_ids {
                    stream_id
                }
            }
        `;
        const headers = adsHeader()
        const response = await axios.post(REPORT_BFF_URI, { query }, {headers: headers});
        return response.data.data.stream_ids;
    } catch (error) {
        console.error("Error fetching stream IDs:", error);
        return null;
    }
};

export default getStreamIds;
